import { reset } from 'redux-form';
import { history } from '../store.js';
import { changeReportModalPosition } from './report';

export const EXPAND_NEW_PICK = 'view/EXPAND_NEW_PICK';
export const COLLAPSE_NEW_PICK = 'view/COLLAPSE_NEW_PICK';
export const OPEN_MODAL = 'view/OPEN_MODAL';
export const CLOSE_MODAL = 'view/CLOSE_MODAL';
export const OPEN_TERMS = 'view/OPEN_TERMS';
export const CLOSE_TERMS = 'view/CLOSE_TERMS';
export const OPEN_WIDGET = 'view/OPEN_WIDGET';
export const CLOSE_WIDGET = 'view/CLOSE_WIDGET';
export const OPEN_CHAT = 'view/OPEN_CHAT';
export const CLOSE_CHAT = 'view/CLOSE_CHAT';
export const SHOW_CHANGE_PASSWORD = 'view/SHOW_CHANGE_PASSWORD';
export const HIDE_CHANGE_PASSWORD = 'view/HIDE_CHANGE_PASSWORD';
export const CLOSE_REPORT_MODAL = 'view/CLOSE_REPORT_MODAL';
export const TOGGLE_TAB = 'alerts/TOGGLE_TAB';
// export const OPEN_SUBLEAGUE = 'alerts/OPEN_SUBLEAGUE';
// export const CLOSE_SUBLEAGUE = 'alerts/OPEN_SUBLEAGUE';

export const toggleTab = data => async (dispatch) => {
  dispatch({
    type: TOGGLE_TAB,
    payload: data,
  });
};

// export const openSubLeagueFunc = () => async (dispatch) => {
//   dispatch({
//     type: OPEN_SUBLEAGUE,
//   });
// };

// export const closeSubLeagueFunc = () => async (dispatch) => {
//   dispatch({
//     type: CLOSE_SUBLEAGUE,
//   });
// };

export const expandNewPick = () => async (dispatch, getState) => {
  const { icon } = getState().report.modal;
  dispatch({
    type: EXPAND_NEW_PICK,
  });

  if (icon) {
    await dispatch(changeReportModalPosition());
  }
};

export const collapseNewPick = () => async (dispatch, getState) => {
  const { icon } = getState().report.modal;
  dispatch({
    type: COLLAPSE_NEW_PICK,
  });

  if (icon) {
    await dispatch(changeReportModalPosition());
  }
};

export const showChangePassword = () => async (dispatch) => {
  dispatch({
    type: SHOW_CHANGE_PASSWORD,
  });
};

export const hideChangePassword = () => async (dispatch) => {
  dispatch({
    type: HIDE_CHANGE_PASSWORD,
  });
  await dispatch(reset('changePasswordForm'));
};

export const openModal = modal => async (dispatch) => {
  dispatch({
    type: OPEN_MODAL,
    payload: modal
  });
};

export const closeModal = modal => async (dispatch) => {
  dispatch({
    type: CLOSE_MODAL,
    payload: modal,
  });
};
export const closeWithRedirect = modal => async (dispatch) => {
  dispatch({
    type: CLOSE_MODAL,
    payload: modal,
  });
  await history.push('/');
};
export const openTerms = () => async (dispatch) => {
  dispatch({
    type: OPEN_TERMS,
  });
};

export const closeTerms = () => async (dispatch) => {
  dispatch({
    type: CLOSE_TERMS,
  });
};

export const openWidget = () => async (dispatch) => {
  dispatch({
    type: OPEN_WIDGET,
  });
};

export const closeWidget = () => async (dispatch) => {
  dispatch({
    type: CLOSE_WIDGET,
  });
};

export const openChat = data => async (dispatch) => {
  dispatch({
    type: OPEN_CHAT,
    payload: data,
  });
};

export const closeChat = () => async (dispatch) => {
  dispatch({
    type: CLOSE_CHAT,
  });
};
