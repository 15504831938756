import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import Profile from '@/components/Profile';
import {
  preloadProfile
  // getCountriesList
} from '@/store/actions/profile';

const lifecycleMethod = lifecycle({
  async componentDidMount () {
    // await this.props.getCountriesList();
    this.props.preloadProfile();
  }
});

const mapStateToProps = state => ({
  hasTerms: state.profile.hasTerms,
  account: state.user.account
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    // getCountriesList,
    preloadProfile
  },
  dispatch
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycleMethod
)(Profile);
