import firebase from 'firebase/app';
import 'firebase/messaging';
import { initializeFirebase } from '@/services/firebase';
import store from '../store/store';
import { TOGGLE_FLOAT_CHAT } from "@/store/actions/chat-with-trader";

export class WebNotifications {

    static init() {
        if (!firebase.apps.length) {
            initializeFirebase();
        }

        if (typeof navigator === 'object' && navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', WebNotifications.onMessageFromSW);
        }
    }

    /**
     * On message from service worker
     * @param data
     */
    static onMessageFromSW = ({ data }) => {
        if (data && data.action === 'open_chat') {
            const state = store.getState();
            const chatWithTrader = state.chatWithTrader;
            let payload = !chatWithTrader.isOpenFloatChat;
            store.dispatch({
                type: TOGGLE_FLOAT_CHAT,
                payload
            });
        }
    };
}
