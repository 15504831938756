import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Wrapper from './styles';
import Logo from '../../assets/images/logo.svg';

class UnauthHeader extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Wrapper>
        <Link to="/">
          <img src={Logo} alt="Punteam" />
        </Link>
      </Wrapper>
    );
  }
}

export default UnauthHeader;
