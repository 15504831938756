/**
 * External dependencies
 */
import { call, put, takeEvery, select } from '@redux-saga/core/effects';

/**
 * Internal dependencies
 */
import { 
  GET_SELECTED_MATCH_RESULTS,
  // GET_FILTERED_MATCH_RESULTS
} from '@/store/actions/statistics-actions/constants';
import { getResultsOfSelectedMatch } from '@/utils/bet365/get-results-of-selected-match';
import {
  setSelectedMatch,
  failedSelectedMatch
} from '@/store/actions/statistics-actions/statistics-actions';
import { callEventInPlayAPI } from '@/utils/bet365';


function* handleMatchSelection() {
  const idOfSelectedEvent = yield select(state => state.newStatistics.idOfSelectedEvent);
  const filteredTime = yield select(state => state.newStatistics.filteredTime);
  const gamesResults =
    yield call(getResultsOfSelectedMatch, idOfSelectedEvent);

  const eventStats =
    yield call(callEventInPlayAPI, idOfSelectedEvent);

  try {
    yield put(setSelectedMatch(gamesResults, eventStats, filteredTime))
  } catch (e) {
    yield put(failedSelectedMatch(e));
  }
}

export function* WatchMatchSelection() {
  yield takeEvery(GET_SELECTED_MATCH_RESULTS, id => handleMatchSelection(id));
}
