import { reduxForm } from 'redux-form';
import { withProps, compose } from 'recompose';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { recommendNewMember } from '../store/actions/user';
import {
  openModal, closeModal, openTerms, closeTerms,
} from '../store/actions/view';

import RecommendNewMember from '../components/RecommendNewMember';

const validate = (values) => {
  const errors = {};
  if (!values.fullName) {
    errors.fullName = 'Enter full name';
  }
  if (!values.email) {
    errors.email = 'Enter email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  // if (!values.skype) {
  //   errors.skype = 'Enter skype';
  // }
  if (!values.countriesLeagues) {
    errors.countriesLeagues = 'Required';
  }
  if (!values.whyYouRecommending) {
    errors.whyYouRecommending = 'Enter message';
  }
  if (!values.terms) {
    errors.terms = 'Accept T&C';
  }

  return errors;
};

const form = reduxForm({
  form: 'recommendForm',
  validate,
});

const props = withProps(ownProps => ({
  onSubmit: ownProps.recommendNewMember,
}));

const mapStateToProps = ({ view }) => ({
  termsOpened: view.termsOpened,
  recommendModalOpened: view.modals.recommend,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    recommendNewMember,
    openTerms,
    closeTerms,
    openRecommendModal: () => openModal('recommend'),
    closeRecommendModal: () => closeModal('recommend'),
    openRecommendModalThank: () => openModal('recommendThank'),
  },
  dispatch,
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  props,
  form,
)(RecommendNewMember);
