import http from '@/services/http-service';
import _get from 'lodash/get';
import moment from 'moment';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';
import consoleError from '@/helpers/consoleError';

export const GET_MEMBER_UNITS_REPORT_SUCCESS = 'report/GET_MEMBER_UNITS_REPORT_SUCCESS';
export const GET_MEMBER_UNITS_REPORT_REJECTED = 'report/GET_MEMBER_UNITS_REPORT_REJECTED';
export const GET_MEMBER_UNITS_REPORT_ALLOWANCES_SUCCESS = 'report/GET_MEMBER_UNITS_REPORT_ALLOWANCES_SUCCESS';
export const GET_MEMBER_UNITS_REPORT_ALLOWANCES_REJECTED = 'report/GET_MEMBER_UNITS_REPORT_ALLOWANCES_REJECTED';
export const SEND_MEMBER_UNITS_ALLOWANCES_REPORT_SUCCESS = 'report/SEND_MEMBER_UNITS_ALLOWANCES_REPORT_SUCCESS';
export const SEND_MEMBER_UNITS_ALLOWANCES_REPORT_REJECTED = 'report/SEND_MEMBER_UNITS_ALLOWANCES_REPORT_REJECTED';
export const GET_MEMBER_UNITS_REPORT_COUNTRIES_SUCCESS = 'report/GET_MEMBER_UNITS_REPORT_COUNTRIES_SUCCESS';
export const GET_MEMBER_UNITS_REPORT_COUNTRIES_REJECTED = 'report/GET_MEMBER_UNITS_REPORT_COUNTRIES_REJECTED';
export const GET_MEMBER_UNITS_REPORT_LEAGUES_BY_COUNTRY_SUCCESS = 'report/GET_MEMBER_UNITS_REPORT_LEAGUES_BY_COUNTRY_SUCCESS';
export const GET_MEMBER_UNITS_REPORT_LEAGUES_BY_COUNTRY_REJECTED = 'report/GET_MEMBER_UNITS_REPORT_LEAGUES_BY_COUNTRY_REJECTED';
export const GET_UNITS_REPORT_NEW_ALLOWANCE_REQUESTS_SUCCESS = 'report/GET_UNITS_REPORT_NEW_ALLOWANCE_REQUESTS_SUCCESS';
export const GET_UNITS_REPORT_NEW_ALLOWANCE_REQUESTS_REJECTED = 'report/GET_UNITS_REPORT_NEW_ALLOWANCE_REQUESTS_REJECTED';
export const SET_NEW_ALLOWANCE_REQUEST_SUCCESS = 'report/SET_NEW_ALLOWANCE_REQUEST_SUCCESS';
export const SET_NEW_ALLOWANCE_REQUEST_REJECTED = 'report/SET_NEW_ALLOWANCE_REQUEST_REJECTED';
export const SET_NEW_ALLOWANCE_ALL_LEAGUES_REQUEST_SUCCESS = 'report/SET_NEW_ALLOWANCE_ALL_LEAGUES_REQUEST_SUCCESS';
export const SET_NEW_ALLOWANCE_ALL_LEAGUE_REQUEST_REJECTED = 'report/SET_NEW_ALLOWANCE_ALL_LEAGUE_REQUEST_REJECTED';
export const GET_SEARCH_LEAGUES_SUCCESS = 'report/GET_SEARCH_LEAGUES_SUCCESS';
export const GET_SEARCH_LEAGUES_REJECTED = 'report/GET_SEARCH_LEAGUES_REJECTED';
export const CHANGE_UNIT_TAB = 'report/CHANGE_UNIT_TAB';
export const OPEN_REPORT_MODAL = 'report/OPEN_REPORT_MODAL';
export const CHANGE_REPORT_MODAL_POSITION = 'report/CHANGE_REPORT_MODAL_POSITION';
export const CLOSE_REPORT_MODAL = 'report/CLOSE_REPORT_MODAL';

export const getMemberUnitsReport = () => async (dispatch, getState) => {
  const state = getState();

  let trace;
  try {
    const params = {
      months: _get(state, 'form.lastUnitsReportForm.values.lastMonth', 3),
    };

    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMemberUnitsReport');
      trace.start();
    }

    const { data: { memberUnitsReport, error }} = await http.post('/GetMemberUnitsReport', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MEMBER_UNITS_REPORT_SUCCESS,
      payload: memberUnitsReport
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_MEMBER_UNITS_REPORT_REJECTED
    });
  }
};

export const getMemberUnitsReportAllowances = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMemberUnitsReportAllowances');
      trace.start();
    }

    const { data: { betsPrecentages, error }} = await http.post('/GetMemberUnitsReportAllowances');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MEMBER_UNITS_REPORT_ALLOWANCES_SUCCESS,
      payload: betsPrecentages
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_MEMBER_UNITS_REPORT_ALLOWANCES_REJECTED
    });
  }
};

export const sendMemberUnitsAllowancesReport = values => async (dispatch, getState) => {
  const {
    betPrecentage, shortCut, leagueName, coveragePercent, weightPercent, id
  } = getState().report.modal.report;
  let trace;
  try {
    const params = {
      reportAllowance: {
        id,
        betPrecentage,
        shortCut,
        leagueName,
        coveragePercent,
        weightPercent,
        description: values.reportText,
      }
    };

    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SendMemberUnitsAllowancesReport');
      trace.start();
    }

    const { data: { error }} = await http.post('/SendMemberUnitsAllowancesReport', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SEND_MEMBER_UNITS_ALLOWANCES_REPORT_SUCCESS
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: SEND_MEMBER_UNITS_ALLOWANCES_REPORT_REJECTED
    });
  }
};

export const getMemberUnitsReportCountries = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMemberUnitsReportCountries');
      trace.start();
    }

    const { data: { error, countries }} = await http.post('/GetMemberUnitsReportCountries');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MEMBER_UNITS_REPORT_COUNTRIES_SUCCESS,
      payload: countries
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_MEMBER_UNITS_REPORT_COUNTRIES_REJECTED,
    });
  }
};

export const getMemberUnitsReportLeaguesByCountry = countryId => async (dispatch) => {
  let trace;
  try {
    const params = { countryId };

    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMemberUnitsReportLeaguesByCountry');
      trace.start();
    }

    const { data: { error, leagues }} = await http.post('/GetMemberUnitsReportLeaguesByCountry', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MEMBER_UNITS_REPORT_LEAGUES_BY_COUNTRY_SUCCESS,
      payload: leagues
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_MEMBER_UNITS_REPORT_LEAGUES_BY_COUNTRY_REJECTED,
    });
  }
};

export const getUnitsReportNewAllowanceRequests = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMemberUnitsReportAllowances');
      trace.start();
    }

    const { data: { leagues, error }} = await http.post('/GetMemberUnitsReportAllowances');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_UNITS_REPORT_NEW_ALLOWANCE_REQUESTS_SUCCESS,
      payload: leagues
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_UNITS_REPORT_NEW_ALLOWANCE_REQUESTS_REJECTED
    });
  }
};

export const setNewAllowanceRequest = allowances => async dispatch => {
  return Promise.all(allowances.map(({ countryId, leagueId, forRemove, note }) => {
    // return http.post('/SetNewAllowanceRequest', { countryId, leagueId, forRemove, note });
    return new Promise((resolve, reject) => {
      const params = { countryId, leagueId, forRemove, note };
      let trace;
      if (process.env.REACT_APP_ENV === 'production') {
        trace = performance.trace('SetNewAllowanceRequest');
        trace.start();
      }
      http.post('/SetNewAllowanceRequest', params).then(response => {
        const error = response.data.error;

        if (process.env.REACT_APP_ENV === 'production') {
          if (error.errorCode !== 0) {
            trace.putAttribute('errorCode', `${error.errorCode}`);
          }
          trace.stop();
        }
        if (error.errorCode !== 0) {
          throw new ApiError(error.errorText, error.errorCode);
        }
        resolve(response);
      }).catch(error => {
        reject(error);
      });
    })
  })).then(() => {
    const createDate = moment.utc().format('DD/MM/YY, HH:mm');
    allowances.forEach(({ forRemove, name }) => {
      dispatch({
        type: SET_NEW_ALLOWANCE_REQUEST_SUCCESS,
        payload: {
          name,
          status: 'Pending',
          createDate,
          typeAction: forRemove ? 'Removing' : 'Adding',
        }
      });
    });
  }).catch(error => {
    console.warn(error);
    dispatch({
      type: SET_NEW_ALLOWANCE_REQUEST_REJECTED
    });
  });
};

export const setNewAllowancesAllLeaguesRequest = (countryId, forRemove = false) => async (dispatch, getState) => {
  const { leagues } = getState().report;
  const payload = leagues.map(league => ({
    name: league.name,
    status: 'Pending',
    createDate: moment.utc().format('DD/MM/YY, HH:mm'),
    typeAction: forRemove ? 'Removing' : 'Adding',
  }));
  const params = {
    countryId,
    forRemove,
    leagueId: 0
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetNewAllowanceRequest');
      trace.start();
    }

    const { data: { error }} = await http.post('/SetNewAllowanceRequest', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_NEW_ALLOWANCE_ALL_LEAGUES_REQUEST_SUCCESS,
      payload
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: SET_NEW_ALLOWANCE_ALL_LEAGUE_REQUEST_REJECTED
    });
  }
};

export const getSearchLeagues = searchCriteria => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetSearchLeagues');
      trace.start();
    }

    const { data: { error, leagues }} = await http.post('/GetSearchLeagues', { searchCriteria });

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_SEARCH_LEAGUES_SUCCESS,
      payload: leagues
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_SEARCH_LEAGUES_REJECTED
    });
  }
};

export const changeUnitTab = tab => async (dispatch) => {
  dispatch({
    type: CHANGE_UNIT_TAB,
    payload: tab,
  });
};

export const openReportModal = (e, week, reportLevel, day, game) => async (dispatch, getState) => {
  e.preventDefault();

  const state = getState();
  const { icon } = state.report.modal;

  if (icon) {
    icon.className = '';
  }

  const getDiv = (el) => {
    if (el.nodeName === 'DIV') {
      return el;
    }

    return getDiv(el.parentElement);
  };

  const divWrapper = getDiv(e.target);
  const rect = divWrapper.getBoundingClientRect();
  divWrapper.className = 'opened-modal';

  dispatch({
    type: OPEN_REPORT_MODAL,
    payload: {
      icon: divWrapper,
      x: rect.x + window.scrollX,
      y: reportLevel === 'week' ? rect.y + window.scrollY - 7 : rect.y + window.scrollY - 20,
      report: {
        week,
        day,
        game,
      },
      reportLevel,
    },
  });
};

export const openAllowanceReportModal = (e, allowanceData) => async (dispatch, getState) => {
  e.preventDefault();

  const state = getState();
  const { icon } = state.report.modal;

  if (icon) {
    icon.className = '';
  }

  const getDiv = (el) => {
    if (el.nodeName === 'DIV') {
      return el;
    }

    return getDiv(el.parentElement);
  };

  const divWrapper = getDiv(e.target);
  const rect = divWrapper.getBoundingClientRect();

  divWrapper.className = `${divWrapper.className} opened-modal`;

  dispatch({
    type: OPEN_REPORT_MODAL,
    payload: {
      icon: divWrapper,
      x: rect.x,
      y: rect.y,
      report: {
        ...allowanceData,
      },
    },
  });
};

export const changeReportModalPosition = () => async (dispatch, getState) => {
  const state = getState();
  const { icon, reportLevel } = state.report.modal;
  const { scrollX, scrollY } = window;
  const { scrollHeight, clientHeight } = document.querySelector('body');
  const rect = icon.getBoundingClientRect();
  dispatch({
    type: CHANGE_REPORT_MODAL_POSITION,
    payload: {
      x: scrollHeight > clientHeight ? rect.x + scrollX : rect.x + scrollX + 17,
      y: reportLevel === 'week' ? rect.y + scrollY - 7 : rect.y + scrollY - 20,
    },
  });
};

export const closeReportModal = () => async (dispatch, getState) => {
  const state = getState();
  state.report.modal.icon.className = state.report.modal.icon.className.split(' ').filter(i => i !== 'opened-modal');

  dispatch({
    type: CLOSE_REPORT_MODAL,
  });
};

export const sendReport = values => async (dispatch, getState) => {
  if (!values.reportText) {
    alert('Please, enter report');
    return;
  }

  const {
    report: {
      modal: {
        report: { week: oldWeek, day: oldDay, game: oldGame },
        reportLevel,
        icon,
      },
    },
  } = getState();

  const week = Object.assign({}, oldWeek);
  const day = Object.assign({}, oldDay);
  const game = Object.assign({}, oldGame);

  let memberUnitsReport = {};

  if (reportLevel === 'week') {
    memberUnitsReport = week;
  } else if (reportLevel === 'day') {
    memberUnitsReport = week;
    memberUnitsReport.unitsReportDays = [day];
  } else if (reportLevel === 'game') {
    memberUnitsReport = week;
    memberUnitsReport.unitsReportDays = [day];
    memberUnitsReport.unitsReportDays[0].unitsReportGames = [game];
  }

  const params = {
    memberUnitsReport,
    reportLevel,
    description: values.reportText
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SendMemberUnitsReport');
      trace.start();
    }

    const { data: { error }} = await http.post('/SendMemberUnitsReport', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    icon.className = '';

    dispatch({
      type: CLOSE_REPORT_MODAL
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
  }
};
