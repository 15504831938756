import React, { Fragment } from 'react';
import ContactForm from '../../containers/ContactForm';
import contactusImage from '../../assets/images/contact-us.svg';

import Wrapper from './styles';

const ContactUs = () => (
  <Fragment>
    <Wrapper>
      <div className="info-page">
        <div className="left-block">
          <div className="div-info">
            <h1 className="title-page">Contact Us</h1>
            <p>
              Please use this form to contact us.
              <br />
              All fields are mandatory.
            </p>
            <div className="block-img">
              <img src={contactusImage} alt="contact us" />
            </div>
          </div>
        </div>
        <div className="right-block">
          <ContactForm />
        </div>
      </div>
    </Wrapper>
  </Fragment>
);

export default ContactUs;
