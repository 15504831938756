import styled from 'styled-components';

import fonts from '@/config/fonts';
import colors from '@/config/colors';

export default styled.div`
  padding: 20px;

  .block {
    background-color: white;
    margin: 20px;
    padding: 43px 25px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e6e6e6;

    color: ${colors.secondaryBlue};
    font-family: ${fonts.proximaNova};
    font-size: 20px;
    font-weight: 600;
    line-height: 1.5em;
    margin: 0;
  }
`;
