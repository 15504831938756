import styled from 'styled-components';
import colors from '../../config/colors';

export default styled.li`
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: 1px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.199);
  }

  &.active {
    background-color: ${colors.mainBlue};
  }

  a {
    display: flex;
    align-items: center;
    font-weight: 400;
    text-decoration: none;
    color: #fff;
    padding: 0 13px;
    width: 100%;
    height: 100%;
    font-size: 14px;
    position: relative;

    img {
      margin-right: 10px;
    }
    img.icon-new{
        display: block;
        width: 56px;
        margin-right: 0;
        position: absolute;
        right: -6px;
        top: -6px;
        transform: rotate(90deg);
    }
  }

  .unread-messages {
    background-color: #ff2060;
    font-size: 10px;
    line-height: 12px;
    min-width: 19px;
    height: 19px;
    padding: 2px 3px;
    text-align: center;
    border-radius: 10px;
    position: absolute;
    border: 2px solid #597bd5;
    top: 7px;
    left: 23px;
  }
`;
