import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default class LeagueItem extends Component {
  state = {
    note: ''
  };

  toggleCheckbox = ({ id, name }, selected) => {
    const { note } = this.state;
    this.props.onChange({ id, name, selected, note });
  };

  updateNote = (note) => {
    const { league: { id, name }, selected } = this.props;
    this.setState({ note });
    this.props.onChange({ id, name, selected, note });
  };

  render() {
    const { league, international, isRequested, selected } = this.props;
    return (
      <Fragment>
        <li
          className={classnames('league', {
            'league-request': international,
            'league-selected': selected
          })}
        >
          {!isRequested && (
            <label className="checkbox">
              <input
                type="checkbox"
                checked={selected}
                onChange={() => this.toggleCheckbox(league, !selected)}
              />
              <span className="checkmark" />
            </label>
          )}
          <p className="title">{`${league.name} ${isRequested ? '(requested)' : ''}`}</p>
          <input
            className="note"
            type="text"
            placeholder="note"
            value={this.state.note}
            readOnly={isRequested}
            onChange={e => this.updateNote(e.target.value)}
          />
        </li>
      </Fragment>
    );
  }
}

LeagueItem.propTypes = {
  league: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequested: PropTypes.bool,
  international: PropTypes.bool
};

LeagueItem.defaultProps = {
  isRequested: false,
  international: false
};
