import styled from 'styled-components';

export default styled.div`
  height: 60px;
  background-color: #dde0e6;
  color: #38415f;
  background-image: linear-gradient(349deg, #8e70ee, #4a7ece);
  padding: 0 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;

  img {
    width: 152px;
  }

  & > div {
    display: flex;
    align-items: center;
    font-weight: 600;
    height: 100%;
  }

  .blue-bell {
    margin-top: -4px;
  }

  .profile-link {
    position: relative;
  }

  .profile-link:before {
    position: absolute;
    content: '';
    display: inline-block;
    height: 23px;
    border: solid 1px #818fa9;
    left: 0;
  }

  .active + .profile-link:before,
  .profile-link.active:before,
  a:hover + .profile-link:before,
  .profile-link:hover:before {
    display: none;
  }
`;
