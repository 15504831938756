export const getDay = (datestring) => {
  const date = new Date(datestring);
  const day = date.getDate();
  return day;
};

export const getMonth = (datestring) => {
  const date = new Date(datestring);
  const month = date.getMonth();
  return month;
};

export const getYear = (datestring) => {
  const date = new Date(datestring);
  const year = date.getFullYear();
  return year;
};

export const getFormattedDate = timestamp => `${getDay(timestamp)}.${
  getMonth(timestamp) + 1 <= 9 ? `0${getMonth(timestamp) + 1}` : getMonth(timestamp) + 1
}.${getYear(timestamp)}`;
