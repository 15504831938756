import capitalize from '@/utils/capitalize';
import {checkAccess, listOfSection} from "@/helpers/access";

export const prepareOdds = (odd) => odd < 1 ? 1 : odd;

const downCents = 0.02;
const formatOdds = (val, bet, accessData) => {
  const line2Sign =  bet ===  'Total' ? 1 : -1;
  let odds1
  let odds2
  if (val[`odds1${bet}`]) {
    odds1 = accessData[listOfSection.Down2CentBetsOdds] ? (Math.max(parseFloat(val[`odds1${bet}`]) - downCents, 1)).toFixed(2) : parseFloat(val[`odds1${bet}`]).toFixed(2);
  } else {
    odds1 = null
  }
  if (val[`odds2${bet}`]) {
    odds2 = accessData[listOfSection.Down2CentBetsOdds] ? (Math.max(parseFloat(val[`odds2${bet}`]) - downCents, 1)).toFixed(2) : parseFloat(val[`odds2${bet}`]).toFixed(2);
  } else {
    odds2 = null
  }
  // const odds1 = accessData[listOfSection.Down2CentBetsOdds] ? (Math.max(parseFloat(val[`odds1${bet}`]) - downCents, 1)).toFixed(2) : parseFloat(val[`odds1${bet}`]).toFixed(2);
  // const odds2 = accessData[listOfSection.Down2CentBetsOdds] ? (Math.max(parseFloat(val[`odds2${bet}`]) - downCents, 1)).toFixed(2) : parseFloat(val[`odds2${bet}`]).toFixed(2);
  //

  return {
    odds1: odds1 ? prepareOdds(odds1) : null,
    odds2: odds2 ? prepareOdds(odds2) : null,
    line1: accessData[listOfSection.Down2CentBetsOdds] ? Number((parseFloat(val[`line${bet}`])).toFixed(2)) : val[`line${bet}`],
    line2: accessData[listOfSection.Down2CentBetsOdds] ? Number((parseFloat(val[`line${bet}`] * line2Sign)).toFixed(2)) : val[`line${bet}`] * line2Sign
  }
};

export const gamesFormatted = (games, userLevel) => {
  const accessData = checkAccess(userLevel);
  const result = {};
  games.map((g) => ({
    ...g,
    hcap: g.odds1Hcap !== 0 ? formatOdds(g, 'Hcap', accessData) : undefined,
    total: g.odds1Total !== 0 ? formatOdds(g, 'Total', accessData) : undefined,
  })).forEach(game => {
    if (!result[game.gameId]) {
      result[game.gameId] = {
        ...game,
        additionalEvents: []
      }
    } else {
      result[game.gameId].additionalEvents.push(game);
    }
  });

  return Object.values(result || {}).sort((a, b) => a.gameTimestamp - b.gameTimestamp);

  // return Object.values(result || {}).sort((a, b) => {
  //   return new Date(a.eventDateTime).getTime() - new Date(b.eventDateTime).getTime();
  // });
}

const colors = ['#ff5766', '#492a8b', '#ffb946', '#4a90e2', '#50c14e'];

const getColor = (i) => {
  if (i >= colors.length) {
    return getColor(i - colors.length);
  }

  return colors[i];
};

export const handicapGraph = (graphData) => {
  let lines = [];
  let series = [];

  if (graphData.points) {
    lines = graphData.lines.map((v) => ({
      id: `${v}`,
      name: `${v}`,
    }));

    series = graphData.lines.reduce((acc, val) => {
      if (`${val}` !== graphData.line) {
        return acc;
      }

      const points = graphData.points
        .filter((point) => point.line === val)
        .sort((a, b) => (new Date(a.time) - new Date(b.time)))
        .map((point) => [+new Date(point.time), point.odds]);

      acc.push({
        data: points,
        step: true,
        name: `Line ${val}`,
        color: getColor(acc.length),
        enableMouseTracking: true,
      });

      return acc;
    }, []);

    if (graphData.redPointOdds) {
      series.push({
        data: [[+new Date(graphData.redTime), graphData.redPointOdds]],
        step: true,
        name: 'Your odds',
        color: '#ff0000',
        marker: {
          enabled: true,
          radius: 4,
          lineWidth: 2,
          lineColor: '#ff959e',
        },
        enableMouseTracking: true,
      });
    }
  }

  if (!series.length) {
    series = [
      {
        data: [
          [1543622400000, 1.13],
          [1544054400000, 2.06],
          [1544486400000, 2.09],
          [1544918400000, 2.14],
          [1545350400000, 2.21],
          [1545782400000, 2.13],
          [1546214400000, 2.06],
        ],
        step: true,
        color: 'rgba(0,0,0,0)',
        enableMouseTracking: false,
      },
    ];
  }

  return {
    lines,
    options: {
      series,
      chart: {
        zoomType: 'x',
        height: 238,
      },
      title: {
        text: '',
      },
      tooltip: {
        shared: true,
        backgroundColor: '#fff',
        borderColor: '#d1d7e9',
        borderRadius: 5,
        headerFormat:
          '<span style="font-size: 12px;color:#38415f;font-family:proxima-nova;font-weight:600;display:inline-block;margin-bottom:8px">{point.key}</span><br/>',
        pointFormat:
          '<span style="color:{point.color}">●</span>    <span style="font-size:13px;font-weight:600;font-family:proxima-nova;color:#38415f;">{series.name}: {point.y}</span><br/>',
        padding: 13,
        useHTML: true,
        shadow: false,
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      yAxis: [
        {
          title: {
            text: '',
          },
          labels: {
            overflow: 'justify',
            style: {
              fontFamily: 'proxima-nova',
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#888888',
            },
          },
          gridLineColor: '#dedede',
          gridLineDashStyle: 'dash',
          tickPixelInterval: 50,
        },
        {
          linkedTo: 0,
          opposite: true,
          title: {
            text: '',
          },
          labels: {
            style: {
              fontFamily: 'proxima-nova',
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#888888',
            },
          },
          gridLineColor: '#dedede',
          gridLineDashStyle: 'dash',
          tickPixelInterval: 50,
        },
      ],
      xAxis: [
        {
          type: 'datetime',
          labels: {
            style: {
              fontFamily: 'proxima-nova',
              fontSize: '12px',
              fontWeight: 'bold',
              color: '#8f9ea6',
            },
          },
          gridLineColor: '#edeff5',
          gridLineWidth: 1,
          tickPixelInterval: 140,
        },
      ],
      plotOptions: {
        line: {
          lineWidth: 3,
          marker: {
            symbol: 'circle',
            states: {
              hover: {
                lineWidth: 2,
              },
            },
          },
          states: {
            hover: {
              halo: {
                opacity: 0.1,
                size: 10,
              },
            },
          },
        },
      },
      colors,
    },
  };
};

export const myPicksList = picks => picks.map(p => {
  const splitBet = /^([\S]*|[\S]*\s[\S]*)\s(-?[\d.?]*)\s(-?[\d.?]*)$/;
  const _split = p.bet.match(splitBet);
  if (_split) {
    const [fullBet, _bet, _line, _h] = p.bet.match(splitBet); // eslint-disable-line no-unused-vars
    const bet = [capitalize(_bet), _line, _h, fullBet];
    return { ...p, bet };
  }
  return { ...p, bet: ['', '', '']};
});

export const fullfillGamesTable = (games) => {
  if (games.length < 5) {
    games.push({});

    return fullfillGamesTable(games);
  }

  return games;
};
