import styled from 'styled-components';

import colors from '../../config/colors';
import fonts from '../../config/fonts';

export default styled.div`
  border: 1px solid ${colors.lightGray_1};
  border-radius: 5px;
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 0;
  overflow: hidden;
  

  .btn {
    cursor: pointer;
    color: ${colors.secondaryBlue};
    display: inline-block;
    font-family: ${fonts.proximaNova};
    font-size: 18px;
    font-weight: 600;
    line-height: 0.89;
    min-width: 124px;
    outline: none;
    padding: 10px 13px 12px 18px;
    text-align: center;
    text-decoration: none;
    transition: 0.7s;
    border: none;

     span {
      opacity: 0.42;
      transition: 0.7s;
    }

    &:first-child {
      border-right: 1px solid ${colors.lightGray_1};
    }

    &:hover span,
    &:focus span,
    &:active span {
      opacity: 1;
    }
    &.active {
      background-color: ${colors.lightGray_7};

      span {
        opacity: 1;
      }
    }
  }

`;
