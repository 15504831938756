import React from 'react';

import Styles from './styles';
import ScreenLogin from '../../assets/images/PunteamMobileAppLogin-372.png';
import ScreenHome from '../../assets/images/PunteamMobileAppHome-372.png';

const MobileApp = () => (
  <Styles>
    <h1>Punteam Mobile app</h1>
    <h2>
      We have a new mobile app - this allows people to access Punteam on the go via their mobile device.
      <br/>Go to <a target="_blank" rel="noopener noreferrer" href="https://m.punteam.com/">m.punteam.com</a> and make sure to enable push notifications.
    </h2>
    <div className="screens">
      <img src={ScreenLogin} alt="screen login page of mobile app" />
      <img src={ScreenHome} alt="screen home page of mobile app" />
    </div>
  </Styles>
);

export default MobileApp;
