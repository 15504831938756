import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';
import PageSection from './elementStyles';
import LastForm from '../../containers/UnitsReport/LastForm';
import MainTable from '../../containers/UnitsReport/MainTable';
import AllowancesPart from '../../containers/UnitsReport/AllowancesPart';
import SwitcherTop from '../../containers/SwitcherTop';
import ReportModal from '../../containers/UnitsReport/ReportModal';
import NewAllowancesModal from '../../containers/UnitsReport/NewAllowancesModal';
import RequestsPart from '../../containers/UnitsReport/RequestsPart';
import UnitsExplanation from './UnitsExplanation';

class UnitsReport extends React.PureComponent {
  render() {
    const {
      unitTab,
      allowancesTab,
      modalOpened,
      openModal,
      allowancesModalIsOpened
    } = this.props;

    return (
      <Wrapper>
        <PageSection>
          <div className='units-actions-container'>
            <div className="units-actions-tabs-and-allowance">
              <div className='units-actions-tabs-container'>
                <SwitcherTop />
                <LastForm />
              </div>
              <div className='units-actions-allowances-part'>
                <AllowancesPart />
                {modalOpened && <ReportModal allowanceReport />}
              </div>
            </div>
            <div>
              <button 
                onClick={() => openModal('allowances')}
                className="button"
                type="button"
              >
                Request New Allowances
              </button>
            </div>
          </div>
          {unitTab && (
            <>
              <MainTable />
              {modalOpened && <ReportModal />}
            </>
          )}
          {allowancesModalIsOpened && <NewAllowancesModal />}
          <UnitsExplanation />
        </PageSection>
        {allowancesTab && (
          <>
            <h2 className="section-title">Allowances Request History</h2>
            <PageSection>
              <RequestsPart />
            </PageSection>
          </>
        )}
      </Wrapper>
    );
  };
}


UnitsReport.propTypes = {
  unitTab: PropTypes.bool.isRequired,
  allowancesTab: PropTypes.bool.isRequired,
  modalOpened: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  allowancesModalIsOpened: PropTypes.bool.isRequired,
};

export default UnitsReport;
