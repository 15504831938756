import React from 'react';

const confirmedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd" transform="translate(1 1)">
        <circle cx="14" cy="14" r="14" stroke="#50C14E" strokeWidth="2"/>
        <path fill="#50C14E" fillRule="nonzero" d="M12.457 17.043l7.296-8.207a1 1 0 1 1 1.494 1.328l-8 9a1 1 0 0 1-1.659-.252.998.998 0 0 1-.295-.205l-4-4a1 1 0 0 1 1.414-1.414l3.75 3.75z"/>
    </g>
  </svg>
);

export default confirmedIcon;
