import {
  SET_PROFILE_INFO_FAIL,
  SET_PROFILE_INFO_SUCCESS,
  PRELOAD_PROFILE_FORM,
  GET_COUNTRIES_LIST_FAIL,
  GET_COUNTRIES_LIST_SUCCESS,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAIL,
  CHANGE_EMAIL_FAIL,
  CHANGE_EMAIL_SUCCESS,
  CHECK_EMAIL_CHANGE_FAIL,
  CHECK_EMAIL_CHANGE_SUCCESS,
  EMAIL_WAS_CONFIRMED,
  SET_HAS_TERMS,
  SET_DATA_TERMS
} from '../actions/profile';

const initialState = {
  profile: {},
  error: '',
  countries: [],
  resendError: '',
  checkError: '',
  changeEmailError: '',
  changeText: '',
  terms: null,
  hasTerms: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EMAIL_WAS_CONFIRMED:
      return state;
    case CHECK_EMAIL_CHANGE_SUCCESS:
      return { ...state, checkError: '' };
    case CHECK_EMAIL_CHANGE_FAIL:
      return { ...state, checkError: action.payload, changeText: '' };
    case CHANGE_EMAIL_SUCCESS:
      return { ...state, changeEmailError: '', changeText: action.payload };
    case CHANGE_EMAIL_FAIL:
      return { ...state, changeEmailError: action.payload, changeText: '' };
    case RESEND_EMAIL_SUCCESS:
      return state;
    case RESEND_EMAIL_FAIL:
      return { ...state, resendError: action.payload };
    case SET_PROFILE_INFO_SUCCESS:
      return {
        ...state,
        profile: action.payload
      };
    case SET_PROFILE_INFO_FAIL:
      return {
        ...state,
        error: action.payload
      };
    case GET_COUNTRIES_LIST_SUCCESS:
      return {
        ...state,
        countries: action.payload
      };
    case GET_COUNTRIES_LIST_FAIL:
      return state;

    case PRELOAD_PROFILE_FORM:
      return { ...state, data: action.payload };

    case SET_HAS_TERMS:
      return { ...state, hasTerms: action.payload };

    case SET_DATA_TERMS:
      return { ...state, terms: action.payload };

    default:
      return state;
  }
};
