import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styled';
import Modal from '../../containers/Modal';

const SuccessModal = ({ opened }) => (
  opened ? (
    <Wrapper>
      <Modal className="modal approve-modal " modalName="success">
        <div className="title-wrapper">
          <span className="modal-title">Your pick has been approved</span>
        </div>
      </Modal>
    </Wrapper>
  ) : null
);

SuccessModal.propTypes = {
  opened: PropTypes.bool.isRequired
};

export default SuccessModal;
