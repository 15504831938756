import React from 'react';

// import ExpandIcon from '../../assets/images/ic-expand';
// import CollapseIcon from '../../assets/images/ic-collapse';
import Wrapper from './styles';
// import PageSection from './elementStyles';

const firstTableHeaders = ['Member name', 'Leagues covered', 'Weekly fixtures', 'Units allocated'];
const firstTableRows = [
  ['John Smith', 'English Premier League, Germany Budesliga', 20, 5],
  ['Punter Punty', 'Italy Serie B', 12, 3]
];

const secondTableHeaders = [
  'Member name',
  'Leagues covered',
  'Weekly fixtures',
  'Fixtures with open market on Monday',
  'Units on Monday',
  'Total Fixtures opened on Saturday',
  'Total units'
];
const secondTableRows = [
  ['Leo Maradona', 'Czech 1, Czech 2, Slovenia 1', 30, 15, 4, 30, 7],
];

class UnitsExplanation extends React.PureComponent {
  // constructor(props) {
  //   super(props)
  //   this.state = {
  //     collapsed: false 
  //   }
  // }

  // toggleCollapsibleSection = () => {
  //   this.setState({ 
  //     collapsed: !this.state.collapsed
  //   })
  // }

  renderFirstPartInfo = () => (
    <div>
      <span className='units-explanation'>Units assignment made automatically by our system every Monday.
        <br />
        <br />Your weekly units assignment decided every Monday by the available fixtures you have in each and every week. 
        (When your leagues are off your units will do down and when a team from your league playing cup/international games that will be included in your units count)
        <br />
        <br />Usually it’s around 25% of your available fixtures and <b>capped to 9 weekly units.</b>
      </span>
    </div>
  )

  renderExampleTable = (headers = [], rows = []) => (
    <div className="units-explanation-table">
      <span className='units-explanation-table-example'>
        <b>Example:</b>
      </span>
      <table>
        <thead>
          <tr>
            {headers.map(name => (
              <th
                key={name}
                className='units-explanation-table-col units-explanation-table-header-col'
              >
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((rowColumns, index) => (
            <tr key={rowColumns[0]}>
              {rowColumns.map((col, index) => (
                <th key={`${col}-${index}`} className='units-explanation-table-col'>{col}</th>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  renderSecondPartInfo = () => (
    <div>
      <span className='units-explanation'>
        <br />Cup games and cross-country competitions:
        <br />
        <br />If a team from <b>*a league you cover*</b> play national cup game, Champions leagues, Libertadores, etc. this game will be count on your weekly fixtures list and will be calculate for units purposes.
        <br />
        <br />If you would like to add leagues to your cover, please share with me your track record for these specific leagues and I'll consider the case.
        <br />
        <br />For further assistance please contact Eliav, members manager, at:
        <br />Skype: <a href="skype:live:eliav_28?chat">Eliav_Punteam</a> || Email Address: <a href="mailto:eliav@punteam.com">Eliav@punteam.com</a> || Telegram: <a href="https://t.me/Eliav_Punteam">Eliav_Punteam</a>
      </span>
    </div>
  )

  render () {
    // const { collapsed } = this.state;
    return (
      <Wrapper>
        <div className="units-explanation-section">
          {/* <PageSection> */}
            <div className="units-explanation-content">
              {this.renderFirstPartInfo()}
              {/* <div className="expand">
                 <button type="button" onClick={this.toggleCollapsibleSection}>
                  <span>{collapsed ? 'Expand' : 'Collapse'}</span>
                  {' '}
                  {collapsed ? <ExpandIcon /> : <CollapseIcon />}
                </button>
              </div>
              {!collapsed && ( */}
                <div>
                  <br />
                  {this.renderExampleTable(firstTableHeaders, firstTableRows)}
                  <br/>
                  <span className='units-explanation'>
                    Fixtures counts for units calculation purposes only when market is open in Asia.
                  </span>
                  <br/>
                  <br/>
                  {this.renderExampleTable(secondTableHeaders, secondTableRows)}
                  {this.renderSecondPartInfo()}
                </div>
              {/* )} */}
            </div>
          {/* </PageSection> */}
        </div>
      </Wrapper>
    );
  }
};

export default UnitsExplanation;
