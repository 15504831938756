import React from 'react';
import Wrapper from './styles';

const TraderStatus = () => (
  <Wrapper>
    Trader Status:
    <div className="status-indicator" />
    <b>Online Karl</b>
  </Wrapper>
);

export default TraderStatus;
