import styled from 'styled-components';

import colors from '../../config/colors';

export default styled.div`
  padding-left: 35px;

  .item {
    min-height: 30px;
    border-radius: 21px;
    box-shadow: 0 5px 8px 0 rgba(73, 42, 139, 0.04);
    background-color: #ffffff;
    margin-top: 30px;
    display: inline-flex;
    padding: 6px 20px 6px 8px;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    font-style: italic;
    color: ${colors.secondaryBlue};
    margin-right: 28px;

    img {
      margin-right: 10px;
      width: auto;
      height: 30px;
    }
  }
`;
