import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import VisibilitySensor from 'react-visibility-sensor';
import htmlParser from 'html-react-parser';

import PersonBlue from '@/assets/images/ic-person-blue.svg';

import Wrapper from './styled';

const MessagesList = ({ messages, onEnterMessageViewport }) => {
  return (
    messages.reduce((res, message) => {
      if (res.lastDate !== message._formatedDate) {
        res.messages.push(<div key={`${message.id}_d`} className="chat-date">{message._formatedDate}</div>);
      }

      return {
        lastDate: message._formatedDate,
        messages: [
          ...res.messages,
          <VisibilitySensor
            key={message.id}
            onChange={isVisible => onEnterMessageViewport(message, isVisible)}
          >
            <div>
              <h5 className={`sender ${!message.isIncomming ? 'owner' : ''}`}>
                {message.senderName}, {message.messageTime} {message.messageDate}
              </h5>
              <div className={`chat-item ${message.isIncomming ? '' : 'chat-item_own'}`}>
                <div className="chat-item-author">
                  <img src={PersonBlue} alt=""/>
                </div>
                <div className="chat-item-body">
                  <div className="chat-item-message">
                    {htmlParser(message.message)}
                  </div>
                </div>
              </div>
            </div>
          </VisibilitySensor>
        ]
      };
    }, {lastDate: '', messages: []})
    .messages
  );
};

class StaticChat extends Component {
  scrollToBottom = (behavior = 'auto') => {
    if (this.scrollBox) {
      this.scrollBox.scrollTo({
        top: this.scrollBox.scrollHeight,
        left: 0,
        // @ts-ignore
        behavior
      });
    }
  }

  componentDidMount () {
    (async () => {
      this.chatId = await this.props.addOpenedChat();
    })();

    this.props.getLastMessages();
    this.scrollToBottom();
  }

  componentWillUnmount () {
    this.props.removeOpenedChat(this.chatId);
  }

  componentDidUpdate ({ lastChatId }) {
    if (lastChatId !== this.props.lastChatId) {
      this.scrollToBottom(lastChatId === 0 ? 'auto' : 'smooth');
    }
  }

  onEnterMessageViewport = (message, isVisible) => {
    if (isVisible) {
      this.props.setAsRead(message);
    }
  };

  render () {
    const {
      title,
      name,
      messages,
      handleSubmit
    } = this.props;

    const messagesList = <MessagesList
                          messages={messages}
                          onEnterMessageViewport={
                            (message, isVisible) => this.onEnterMessageViewport(message, isVisible)
                          }
                        />;

    return (
      <Wrapper className="chat-wrapper chat-type-static">
        <h2 className="chat-title">{title} <span>{name} <span className="status status-online"></span></span></h2>
        <div className="chat">
          <div className="chat-body" ref={el => this.scrollBox = el}>
            {messagesList}

            {/* <div className="chat-item">
              <div className="chat-item-author">
                <img src={PersonBlue} alt=""/>
              </div>
              <div className="chat-item-body">
                <div className="chat-item-message">Lorem ipsum dolor sit amet, consecte adipisicing elit, sed do eiusmod tempor
                  incididunt ut labore et</div>
              </div>
            </div>

            <div className="chat-date">Today</div>

            <div className="chat-item">
              <div className="chat-item-author">
                <img src={PersonBlue} alt=""/>
              </div>
              <div className="chat-item-body">
                <div className="chat-item-message">Hi!</div>
                <div className="chat-item-message">I have some questions</div>
              </div>
            </div> */}
          </div>
          <div className="chat-action">
            <form className="chat-form" noValidate onSubmit={handleSubmit}>
              <Field
                name="message"
                type="text"
                component="input"
                className="chat-input"
                autoFocus
                autoComplete="off"
                placeholder="Write your message"
              />
              <button className="btn chat-btn" type="submit">Send</button>
            </form>
          </div>
        </div>
      </Wrapper>
    );
  }

  static defaultProps = {
    title: ''
  };

  static propTypes = {
    messages: PropTypes.arrayOf(PropTypes.object).isRequired,
    sendMessage: PropTypes.func.isRequired,
    title: PropTypes.string,
    setAsRead: PropTypes.func.isRequired,
    lastChatId: PropTypes.number.isRequired,
    getLastMessages: PropTypes.func.isRequired,
    addOpenedChat: PropTypes.func.isRequired,
    removeOpenedChat: PropTypes.func.isRequired
  };
};

export default StaticChat;
