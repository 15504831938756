import React, { Fragment } from 'react';
import AffiliatesContainer from '../../containers/Affiliates';

const Affiliates = () => (
  <Fragment>
    <AffiliatesContainer />
  </Fragment>
);

export default Affiliates;
