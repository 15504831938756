import styled from 'styled-components';
import fonts from '../../config/fonts';

export default styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.proximaNova};

  .block {
    background-color: white;
    margin: 20px;
    flex-grow: 1;
    padding: 10px 15px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e6e6e6;
  }
  .title {
    margin-top: 0;
    margin-bottom: 5px;
    color: #3c4144;
    font-size: 18px;
    font-weight: 600;
  }

  .x-axis-labels {
    fill: #8f9ea6 !important;
  }

  .general-block,
  .chart,
  .bets-block {
    position: relative;
    height: auto;
  }

  .general-block {
    height: 100px;
  }

  .general-block .error,
  .bets-block .error,
  .chart .error {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9;
  }

  .general-block .error .reload-btn,
  .bets-block .error .reload-btn,
  .chart .error .reload-btn {
    border: none;
    padding: 10px 40px;
    background-image: linear-gradient(349deg, #8e70ee, #4a7ece);
    color: white;
    cursor: pointer;
  }

  .info-wrapper {
    display: flex;
    justify-content: baseline;
    margin-bottom: 20px;
  }

  .info-block {
    width: 130px;
    margin-right: 10px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e2e2e2;
    padding: 8px 10px;
  }

  .info-block small {
    color: #6a7088;
    font-size: 12px;
    font-weight: 600;
  }

  .info-block p {
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #161616;
  }

  .info-block p.small-info {
    font-size: 12px;
    margin-top: 5px;
    margin-right: 20px;
  }

  .chart {
    position: relative;

    .loading-progress {
      &:first-child {
        position: relative;
        width: 92%;
        // height: 3px;
        border-radius: 2px;
        background: #edeff5;
        overflow: hidden;
        top: 20px;
        z-index: 9999;
        left: 6.9%;
      }
    }
  }

  .circle-info {
    position: absolute;
    top: 27px;
    left: 10px;
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: right;
    color: #9b9b9b;
    display: none;
  }
  .spinner {
    display: block;
    margin: 150px auto;
  }

  .circle-info span {
    padding-top: 2px;
  }
  .circle {
    width: 6px;
    height: 6px;
    background-color: #ffffff;
    border: solid 2px #ffb946;
    border-radius: 50%;
    margin-right: 5px;
  }

  #betForm {
    margin-top: 20px;
  }

  #betForm select {
    padding: 5px;
    width: 145px;
    height: 32px;
    margin-right: 15px;
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 1px #e2e2e2;
    font-family: ${fonts.proximaNova};
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #818fa9;
    cursor: pointer;
  }

  td.league {
    font-family: ${fonts.proximaNova};
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  .betinfo-block {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 25px;
  }

  .betinfo-block div {
    font-size: 42px;
    letter-spacing: 0.3px;
    color: #38415f;
    padding: 5px 25px;
    border-left: 1px solid #e2e2e2;
  }

  table.table {
    width: 100%;
    text-align: left;
    border-spacing: unset;

    td {
      &:first-child {
        border-left: none !important;
      }
    }

    thead,
    tr {
      width: 100%;
      display: flex;
    }

    th,
    td {
      width: 16%;
      padding: 8px;
      font-size: 12px;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: #38415f;
      display: flex;
    }

    thead tr {
      background-color: white !important;
    }

    thead td {
      font-size: 14px;
    }

    tr {
      background-color: #fbfbfb;
    }

    tr:nth-child(2n + 1) {
      background-color: #edeff5;
    }

    tr.marked {
      background-color: #d1d7e9;
    }

    tr.marked td:first-child {
      border-left: 2px solid white;
      font-weight: bold;
    }

    tr.marked td {
      border-left: 2px solid white;
    }
  }

  .tableTitles-block {
    display: flex;
  }

  .tableTitles-block div {
    width: 16%;
    padding: 8px;
    font-family: ${fonts.proximaNova};
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    font-weight: 700;
    letter-spacing: normal;
    color: #38415f;
    display: flex;
  }

  .color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 9px;
  }

  // Highcharts
  .highcharts-yaxis-grid .highcharts-grid-line {
    stroke-width: 1px;
    stroke: #d8d8d8;
    stroke-dasharray: 4, 2;
  }

  .highcharts-tooltip {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  }

  .highcharts-tooltip text {
    font-size: 12px;
    font-family: ${fonts.proximaNova};
  }

  .highcharts-title {
    font-family: ${fonts.proximaNova};
    font-size: 18px;
    font-weight: 600;
    color: #3c4144;
  }

  .highcharts-legend-item-hidden,
  .highcharts-legend-box text {
    font-family: ${fonts.proximaNova};
    font-size: 13px;
    font-weight: 600;
    fill: green;
  }

  // .highcharts-credits {
  //   fill: #fff !important;
  // }

  .pie-charts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .pie-chart {
      flex: 0 49.9%;
      margin-bottom: 40px;

      &.empty-chart {
        text-align: center;

        h2 {
          color: #3c4144;
          font-size: 18px;
          font-weight: bold;
          margin: 0;
        }
      }
    }

    .description {
      text-align: center;
    }

    .center {
      display: inline-block;
      text-align: left;
    }
  }

  @media screen and (max-width: 1200px) {
    .general-block {
      height: auto;
    }
    .info-wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .info-block {
      width: 15%;
      margin-bottom: 10px;
    }

    .betinfo-block {
      justify-content: flex-start;
      margin-top: 20px;
    }

    .betinfo-block div {
      font-size: 30px;
      padding: 5px 10px;
    }

    select {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 800px) {
    .info-block {
      width: 30%;
      margin-bottom: 10px;
    }
  }
`;
