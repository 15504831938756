import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { logOut } from '@/store/actions/auth';
import Header from '@/components/Header';

const mapDispatchToProps = dispatch => bindActionCreators({
  logOut
}, dispatch);

const mapStateToProps = ({ user, auth }) => ({
  utcOffset: auth.utcOffset,
  account: user.account,
  unitLeft: get(user, 'webtemplate.user.unitLeft', 0),
  userLevel:  user.account.userLevel
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(Header);
