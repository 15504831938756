import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

const BetsByLeagues = ({
  betsByLeague: {
    roi, unitsBet, unitsWon, countries,
  },
  general: { gameTypes, betTypes, timesTypes },
  onChange,
  betsError,
  generalError,
  reloadBets,
}) => (
  <div className="block">
    <p className="title">Bets By Leagues</p>
    <div className="bets-block">
      {betsError ? (
        <div className="error">
          <p>Something went wrong...</p>
          <button type="button" onClick={reloadBets} className="reload-btn">
            Reload
          </button>
        </div>
      ) : (
        <span />
      )}
      {generalError ? (
        <span />
      ) : (
        <form id="betForm">
          <Field onChange={() => setTimeout(onChange, 100)} name="onlyLive" component="select">
            {gameTypes ? (
              gameTypes.map(game => (
                <option key={Date.now() + game.name + game.id} value={game.id}>
                  {game.name}
                </option>
              ))
            ) : (
              <option />
            )}
          </Field>
          <Field onChange={() => setTimeout(onChange, 100)} name="onlyBet" component="select">
            {betTypes ? (
              betTypes.map(bet => (
                <option key={Date.now() + bet.name + bet.id} value={bet.id}>
                  {bet.name}
                </option>
              ))
            ) : (
              <option />
            )}
          </Field>
          <Field onChange={() => setTimeout(onChange, 100)} name="onlyDate" component="select">
            {timesTypes ? (
              timesTypes.map(time => (
                <option
                  key={Date.now() + time.name + time.id}
                  orderid={time.orderId}
                  value={time.id}
                >
                  {time.name}
                </option>
              ))
            ) : (
              <option />
            )}
          </Field>
        </form>
      )}
      <div className="betinfo-block">
        <div>{unitsBet}</div>
        <div>{unitsWon}</div>
        <div>{roi}</div>
      </div>
      <div className="tableTitles-block">
        <div>Country</div>
        <div>League</div>
        <div>%</div>
        <div>Units Bet</div>
        <div>Units Won</div>
        <div>ROI</div>
      </div>

      {countries ? (
        countries.map(country => (
          <table className="table" key={Date.now() + country.country}>
            <tbody>
              <tr className="marked">
                <td>{country.country}</td>
                <td>&nbsp;</td>
                <td>{country.countryPercent}</td>
                <td>{country.unitsBet}</td>
                <td>{country.unitsWon}</td>
                <td>{country.roi}</td>
              </tr>
              {country.leagues ? (
                country.leagues.map(league => (
                  <tr key={Date.now() + league.league}>
                    <td>&nbsp;</td>
                    <td className="league">{league.league}</td>
                    <td>{league.leaguePercent}</td>
                    <td>{league.unitsBet}</td>
                    <td>{league.unitsWon}</td>
                    <td>{league.roi}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>No data available...</td>
                </tr>
              )}
            </tbody>
          </table>
        ))
      ) : (
        <div>Loading...</div>
      )}
    </div>
  </div>
);

BetsByLeagues.propTypes = {
  betsByLeague: PropTypes.object.isRequired,
  general: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default BetsByLeagues;
