import React from 'react';

const Messages = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" stroke="#38415F" strokeWidth="2" transform="translate(2 5)">
      <rect width="18" height="13" x="1" y="1" rx="2" />
      <path strokeLinejoin="round" d="M1.077 2l9 6.69 9-6.69" />
    </g>
  </svg>
);

export default Messages;
