import React from 'react';
import classnames from 'classnames';

const FormInput = ({
  input,
  label,
  textarea,
  disabled,
  type,
  placeholder,
  meta: {
    touched, error, warning, submitted,
  },
}) => (
  <div>
    {type === 'checkbox' ? (
      <div>
        <label className="label-checkbox" htmlFor={input.name}>
          {label}
          <input {...input} placeholder={label} type={type} id={input.name} />
          <span className="checkmark" />
        </label>
        {touched
          && ((error && <span className="form-error form-error-checkbox">{error}</span>)
            || (warning && <span>{warning}</span>))}
      </div>
    ) : (
      <div
        className={classnames({
          'form-element': true,
          'red-error': touched && error,
        })}
      >
        <label htmlFor={input.name}>{label}</label>
        <div>
          {textarea ? (
            <textarea {...input} placeholder={placeholder || label} id={input.name} />
          ) : (
            <input
              {...input}
              placeholder={placeholder || label}
              type={type}
              id={input.name}
              disabled={!!disabled}
            />
          )}

          {touched
            && ((error && (
              <span
                className={classnames({
                  'form-error': true,
                  'textarea-error': textarea,
                })}
              >
                {error}
              </span>
            ))
              || (warning && <span>{warning}</span>))}
        </div>
      </div>
    )}
  </div>
);

export default FormInput;
