import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose } from 'recompose';

import Finance from '../components/Finance';
import {
  getMyFinanceInfo,
  getMyAffiliateInfo,
  preloadPaymentDetails,
  setPaymentsDetails
} from '../store/actions/finance';

const mapStateToProps = state => ({
  finance: state.finance.myfinance,
  affiliateCard: state.finance.myaffiliate.affiliateCard,
  affiliatePaymentsDetails: state.finance.myaffiliate.affiliatePaymentsDetails,
  affiliateRevenues: state.finance.myaffiliate.affiliateRevenues,
  initialValues: state.finance.data,
  isAffiliate: state.user.webtemplate.user,
  userLevel:  state.user.account.userLevel
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getMyFinanceInfo,
    getMyAffiliateInfo,
    preloadPaymentDetails,
    setPaymentsDetails
  },
  dispatch
);


const form = reduxForm({
  form: 'paymentsForm',
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  form
)(Finance);
