import styled from 'styled-components';

export default styled.div`
  & > div {
    border: none;
    border-radius: 5px;
    margin: 0 0 23px;
  }

`;
