import React from 'react'
import { isMobile } from 'react-device-detect';
import Wrapper from './styles';

class NotificationsAlert extends React.PureComponent {
  enableNotifications = async () => {
    if (window.Notification) {
      const permission = await Notification.requestPermission()
      if (permission  === 'granted' || permission  === 'denied') {
        this.forceUpdate()
      }
    }
  }

  render() {
    const hasNotificationAPI = window.Notification && Notification.permission  !== 'default'

    if (isMobile || hasNotificationAPI) {
      return null
    }

    return (
      <Wrapper>
        <div className="notifications-alerts">
          <p className="notifications-alerts__content">
            {`Desktop alerts are not enabled on this browser. `}
            <span className="notifications-alerts__link" onClick={this.enableNotifications}>
             {`Click here`} 
            </span>
            {` to enable desktop alerts`}
          </p>
        </div>
      </Wrapper>
  )
  }
}

export default NotificationsAlert