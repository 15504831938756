import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { change } from 'redux-form';

import Wrapper from './styled';
import Modal from '../../containers/Modal';
import Input from '../ui/form/Input';
import SlideToggle from '../ui/form/SlideToggle';

const SETTINGS = [{
  key: 'urgentBet',
  title: 'Urgent Bet'
}, {
  key: 'highValues',
  title: 'High Values'
}, {
  key: 'teamNews',
  title: 'Team News'
}]

const initialState = {
  normalBet: true,
  highValues: false,
  teamNews: false,
  urgentBet: false
};

class BetSettingsModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
  }


  componentDidUpdate = (prevProps) => {
    if (!prevProps.opened) {
      this.setState(initialState);
    }
  }

  changePicksForm = (name, value) => {
    const { dispatch } = this.props;
    if (name !== 'normalBet') {
      dispatch(change('picksForm', name, value));
    }
  }

  setCheckbox = (name) => (event) => {
    const { checked } = event.target;

    const getAdditionalConditions = () => {
      if (checked) {
        if (name === 'normalBet') {
          return {
            highValues: false,
            teamNews: false,
            urgentBet: false
          };
        }

        return {
          normalBet: false,
        };
      }

      return {};
    }


    const additionalConditions = getAdditionalConditions()
    Object.keys(additionalConditions).forEach(key => {
      this.changePicksForm(key, additionalConditions[key]);
    });

    this.changePicksForm(name, checked);

    this.setState({
      [name]: checked,
      ...getAdditionalConditions()
    });
  }

  onChangeNotes = (event) => {
    const value = event.target.value;
    this.changePicksForm('notes', value);
  }

  render() {
    const {
      notes,
      opened,
      close,
      submit,
    } = this.props;
    return (
      opened ? (
        <Wrapper>
          <Modal className="modal bet-settings-modal" modalName="betSettings" closeModal={close}>
            <div className="title-wrapper">
              <span className="modal-title">Do any of these apply to this bet?</span>
            </div>
            <div className="bet-settings-checkboxes-container">
                <SlideToggle 
                  id="normalBet"
                  label="Normal Bet"
                  labelClassName="checkbox"
                  input={{
                    type: 'checkbox',
                    className: 'bet-settings-checkbox',
                    value: this.state['normalBet'],
                    onChange: this.setCheckbox('normalBet')
                  }}
                />
              <div className="bet-additional-settings">
                {SETTINGS.map(({ key, title }) => (
                  <SlideToggle 
                    key={key}
                    id={key}
                    label={title}
                    labelClassName="checkbox"
                    input={{
                      type: 'checkbox',
                      className: 'bet-settings-checkbox',
                      value: this.state[key],
                      onChange: this.setCheckbox(key)
                    }}
                  />
                ))}
              </div>
            </div>
            <div className="notes-input-container">
              <Input 
                id="pick-notes"
                name="notes"
                label="Notes"
                className="pick-notes"
                input={{ onChange: this.onChangeNotes, value: notes || '' }}
              />
            </div>
            <div className="center">
              <button className="reload send-btn" onClick={submit}>Send</button>
            </div>
          </Modal>
        </Wrapper>
      ) : null
    );
  }
}

BetSettingsModal.propTypes = {
  opened: PropTypes.bool.isRequired
};

export default connect()(BetSettingsModal);
