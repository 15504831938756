import {setGameLines} from '../store/actions/picks'
import {MAX_ID, MIN_ID, FILTER_ID} from "@/components/CreateNewPick";

const GAMES_INTERVAL = 5000;
const LEAGUES_INTERVAL = 20000;
const EXCLUDED_GAMES_INTERVAL = 20000;

let websocketData = {}
let leagueData = {}
let websocketExcludedData = {}
let excludedData = {}
let intervalId = null
let prepareLeaguesIntervalId = null
let excludedGamesIntervalId = null
let ws = null;
export const initWebsocket = (dispatch, getLeagues) => {
    const userId = localStorage.getItem('userId')
    const idInWhiteList = (((userId >= MIN_ID) && (userId <= MAX_ID)) || (userId == FILTER_ID))
    const isPunteamMember = process.env.REACT_APP_PUNTEAM_MEMBER_ID === userId
    const wsUrl = idInWhiteList
        ? process.env.REACT_APP_INNER_WEBSOCKET_URL
        : process.env.REACT_APP_PFSTREAM

    let ws = new WebSocket(wsUrl);
    ws.onopen = () => {
        console.log("connected websocket main component");
        if (!intervalId) {
            intervalId = setInterval(() => {
                setGameLines(dispatch)
            }, GAMES_INTERVAL)
        }

        if (!prepareLeaguesIntervalId) {
            prepareLeaguesIntervalId = setInterval(() => {
                prepareLeagueData()
            }, LEAGUES_INTERVAL)
        }

        if (!excludedGamesIntervalId) {
            excludedGamesIntervalId = setInterval(() => {
                showExcludedGamesInfo()
            }, EXCLUDED_GAMES_INTERVAL)
        }
    };

    ws.onmessage = evt => {
        const data = JSON.parse(evt.data);

        if (data.type === 'init') {
            if (data.events && data.events.length === 1) {
                const value = data.events[0];
                if (!value.metadata.MasterGameId) {
                    websocketExcludedData[value.metadata.event_id] = {
                        ...value,
                        reason: 'MasterGameId is empty',
                    };
                    return;
                }
                if (!value.snapshot || !value.snapshot.length) {
                    websocketExcludedData[value.metadata.event_id] = {
                        ...value,
                        reason: 'Snapshot is empty',
                    };
                    return;
                }
                websocketData[value.metadata.event_id] = value;
            } else if (data.data && data.data.events && data.data.events.length > 1) {
                websocketData = data.data.events.reduce((accumulator, value) => {
                    if (!value.metadata.MasterGameId) {
                        websocketExcludedData[value.metadata.event_id] = {
                            ...value,
                            reason: 'MasterGameId is empty',
                        };
                        return accumulator;
                    }
                    if (!value.snapshot || !value.snapshot.length) {
                        websocketExcludedData[value.metadata.event_id] = {
                            ...value,
                            reason: 'Snapshot is empty',
                        };
                        return accumulator;
                    }
                    return {...accumulator, [value.metadata.event_id]: value};
                }, {})
                prepareLeagueData();
                getLeagues();
                showExcludedGamesInfo();
                setGameLines(dispatch);
            }
        } else if (data.type === 'change' && data.events && data.events.length) {
            if (data.events && data.events[0] && data.events[0].metadata && data.events[0].metadata.event_id) {
                const eventId = data.events[0].metadata.event_id;
                if (!data.events[0].metadata.MasterGameId) {
                    websocketExcludedData[eventId] = {
                        ...data.events[0],
                        reason: 'MasterGameId is empty',
                    };
                    return;
                }

                if (!data.events[0].snapshot || !data.events[0].snapshot.length) {
                    websocketExcludedData[eventId] = {
                        ...data.events[0],
                        reason: 'Snapshot is empty',
                    };
                }

                if (!websocketData[eventId]) {
                    websocketData[eventId] = {}
                }

                websocketData[eventId] = data.events[0]
                if (!websocketData[eventId].snapshot
                    || !websocketData[eventId].snapshot.length) {
                    delete websocketData[eventId];
                }

                if (websocketData[eventId] && websocketExcludedData[eventId]) {
                    delete websocketExcludedData[eventId];
                }
            }
        } else if (data.type === 'delete' && data.events && data.events.length) {
            const eventId = data.events[0].event_id;
            if (websocketData[eventId]) {
                delete websocketData[eventId];
            }
            if (websocketExcludedData[eventId]) {
                delete websocketExcludedData[eventId];
            }
        }
    }

    ws.onclose = e => {
        console.log(`Socket is closed`);
        if (intervalId) {
            clearInterval(intervalId)
            intervalId = null;
        }
        if (prepareLeaguesIntervalId) {
            clearInterval(prepareLeaguesIntervalId)
            prepareLeaguesIntervalId = null;
        }
        if (excludedGamesIntervalId) {
            clearInterval(excludedGamesIntervalId)
            excludedGamesIntervalId = null;
        }
        ws = null;
    };

    ws.onerror = err => {
        console.log("Socket encountered error: " + err.message + "Closing socket");

        ws.close();
        alert('Socket encountered error, please reload the page')
    };
}

export const closeWebsocket = () => {
    if (ws) {}
    ws.close();
}

export const getWebsocketData = () => {
    return websocketData
}

const prepareLeagueData = () => {
    leagueData = {}
    for (const eventId in websocketData) {
        const value = websocketData[eventId];
        leagueData[value.metadata.MasterGameId] = {
            eventId: value.metadata.event_id,
            gameDate: value.metadata.start_time,
            gameId: value.metadata.MasterGameId,
            leagueId: value.metadata.leagueId,
            leagueName: value.metadata.competition_name,
            leagueNameId: value.metadata.leagueNameId,
            market: value.metadata.market,
            team1Name: value.metadata.home,
            team2Name: value.metadata.away,
            competitionId: value.metadata.competition_id
        }
    }
}

export const getLeaguesData = () => {
    return Object.values(leagueData);
}

const showExcludedGamesInfo = () => {
    const data = {
        2: {},
        3: {},
        8: {},
    }
    for (const eventId in websocketExcludedData) {
        const value = websocketExcludedData[eventId];
        if (!data[value.metadata.market][value.metadata.competition_name]) {
            data[value.metadata.market][value.metadata.competition_name] = [];
        }

        const gameName = `${value.metadata.home} - ${value.metadata.away}`;
        if (!data[value.metadata.market][value.metadata.competition_name][gameName]) {
            data[value.metadata.market][value.metadata.competition_name][gameName] = [];
        }
        data[value.metadata.market][value.metadata.competition_name][gameName] = value
    }

    excludedData = {
        '1_LIVE': data[2],
        '2_TODAY': data[3],
        '3_EARLY': data[8],
    }

    console.log('List of Games that do not display in UI:', excludedData)
}