import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';

const Checkbox = ({
  input, label, id, disabled, wrapperClass,
}) => (
  <Wrapper className={wrapperClass}>
    <input type="checkbox" id={id} {...input} disabled={disabled} checked={input.value} />
    <span />
    <label htmlFor={id}>{label}</label>
  </Wrapper>
);

Checkbox.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

Checkbox.defaultProps = {
  disabled: false,
  wrapperClass: null,
};

export default Checkbox;
