/**
 * Internal dependencies
 */
import {
  GET_LEAGUE_ERROR,
  LEAGUE,
  SOCCER,
  NAME,
  SPORT,
  ID
} from './constants';

export const getLeagues = ({
  gamesInPlay = [],
  sport = SOCCER,
  onlyNames = true
}) => {
  if (!gamesInPlay.length) throw Error(GET_LEAGUE_ERROR);

  const sportID = gamesInPlay.find(
    item => item.type === SPORT && item[NAME] === sport
  )[ID];

  let leaguesList =
    gamesInPlay.filter(item => {
      return item.type === LEAGUE && item[ID] === sportID;
    });

  return onlyNames
    ? leaguesList.map(league => league[NAME])
    : leaguesList
};
