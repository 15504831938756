import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';
import PicksChart from '../PicksChart';
import Filter from './Filter';
import PicksTable from './PicksTable';

const MyPicks = ({
  pickModalOpened,
  graphData,
  options,
  graphLoading,
  picks,
  onGraphClick,
  filterByRuning,
  filterByLive,
  filterByStatus,
  allStatuses,
}) => (
  <Wrapper>
    <Filter />
    {pickModalOpened && (
      <PicksChart
        graphData={graphData}
        options={options}
        graphLoading={graphLoading}
        modalName="myPicksGame"
        disableSelect
      />
    )}
    <PicksTable 
      picks={picks}
      onGraphClick={onGraphClick}
      filterByRuning={filterByRuning}
      filterByLive={filterByLive}
      filterByStatus={filterByStatus}
      allStatuses={allStatuses}
    />
  </Wrapper>
);

MyPicks.propTypes = {
  picks: PropTypes.array.isRequired,
  onGraphClick: PropTypes.func.isRequired,
  pickModalOpened: PropTypes.bool.isRequired,
  graphLoading: PropTypes.bool.isRequired,
  graphData: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
};

export default MyPicks;
