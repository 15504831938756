import firebase from 'firebase/app';
import 'firebase/messaging';
import {initialConfig, publicKey} from '@/config/firebase';

export const initializeFirebase = async () => {
    firebase.initializeApp(initialConfig);
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey(publicKey);

    Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
            console.log('Notification permission granted.');
            messaging.getToken().then((currentToken) => {
                console.log('%currentToken', 'color:red');
                console.log(currentToken);
            });
        } else {
            console.log('Unable to get permission to notify.');
        }
    });
};

export const getToken = () => {
    return new Promise((resolve, reject) => {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                const messaging = firebase.messaging();

                messaging.getToken().then((currentToken) => {
                    resolve(currentToken);
                }).catch(err => {
                    reject('Unable to get permission to notify.')
                });
            } else {
                reject('Unable to get permission to notify.')
            }
        });
    });
}

export const unsubscribe = async () => {
    try {
        const messaging = firebase.messaging();
        if (!messaging) return;
        
        const token = await messaging.getToken();
        messaging.deleteToken(token);
    } catch(error) {
        console.log("Unsubscribe:", error.message )
    }
};
