import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';
import Header from '../UnauthHeader';
import Footer from '../UnauthFooter';

const UnauthPagesWrapper = ({ children }) => (
  <Fragment>
    <Header />
    <Wrapper>
      <main>
        <div className="info-container">
          {children}
        </div>

        <Footer />
      </main>
    </Wrapper>
  </Fragment>
);

UnauthPagesWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UnauthPagesWrapper;
