import {
  GET_CREATE_PICK_PAGE_INFO_SUCCESS,
  CREATE_PICK_PAGE_LEAGUES_SUCCESS,
  CREATE_PICK_PAGE_GAMES_SUCCESS,
  SELECT_LEAGUE,
  DISABLE_PICKS_FORM,
  ENABLE_PICKS_FORM,
  GET_GRAPH_BY_PICK_REQUESTED,
  GET_GRAPH_BY_PICK_SUCCESS,
  GET_CREATE_PICK_PAGE_INFO_REJECTED,
  CHANGE_LINE_ON_GRAPH,
  GET_MY_PICKS_LAST_SUCCESS,
  GET_MY_PICKS_SUCCESS,
  CLEAR_GAMES,
  SET_FILTER,
  INIT_WEBSOCKET_SUCCESS,
  CLOSE_WEBSOCKET_SUCCESS
} from '../actions/picks';

const initialState = {
  betGameParts: [],
  betTypes: [],
  marketTypes: [],
  sports: [],
  units: [],
  urgency: [],
  leagues: [],
  allGames: [],
  games: [],
  selectedLeague: null,
  selectedLeagueEvents: [],
  formDisabled: false,
  graphData: {},
  stats: {
    picksLast: [],
  },
  websocket: null,
  list: [],
  graphLoading: false,
  filter: {
    market: {
      value: '',
      values: [
        {
          label: 'All games',
          value: ''
        },
        {
          label: 'Live',
          value: 'live'
        },
        {
          label: 'Today and early',
          value: 'pregame'
        }
      ]
    },
    status: {
      value: '',
      values: [
        {
          label: 'All Statuses',
          value: ''
        },
        {
          label: 'Win',
          value: 'win'
        },
        {
          label: 'Loss',
          value: 'loss'
        },
        {
          label: 'HWin',
          value: 'hwin'
        },
        {
          label: 'HLoss',
          value: 'hloss'
        },
        {
          label: 'Pending',
          value: 'pending'
        },
        {
          label: 'Rejected',
          value: 'rejected'
        },
      ]
    },
    date: {
      value: [null, null]
    },
    isRunning: {
      value: false
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CREATE_PICK_PAGE_INFO_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: undefined,
        userlevel: undefined,
      };
    case CREATE_PICK_PAGE_LEAGUES_SUCCESS:
      return {
        ...state,
        leagues: action.payload.leagues,
        allGames: action.payload.allGames,
        leagueEvents: action.payload.leagueEvents,
        error: undefined
      };
    case CREATE_PICK_PAGE_GAMES_SUCCESS:
      return {
        ...state,
        games: action.payload.games,
        error: undefined
      };

    case SELECT_LEAGUE:
      return {
        ...state,
        selectedLeague: action.payload.league,
        selectedLeagueEvents: action.payload.leagueEvents,
      };

    case DISABLE_PICKS_FORM:
      return {
        ...state,
        formDisabled: true,
      };
    case ENABLE_PICKS_FORM:
      return {
        ...state,
        formDisabled: false,
      };
    case GET_GRAPH_BY_PICK_REQUESTED:
      return {
        ...state,
        graphData: {},
        graphLoading: true,
      };
    case GET_CREATE_PICK_PAGE_INFO_REJECTED:
      return {
        ...state,
        graphData: {},
        graphLoading: false,
      };
    case GET_GRAPH_BY_PICK_SUCCESS: {
      const { lines, line } = action.payload;
      const lineIndex = lines.map(l => l.toString()).indexOf(line);

      let selectedLine;

      if (lineIndex >= 0) {
        selectedLine = line;
      } else {
        selectedLine = lines[0].toString();
      }

      return {
        ...state,
        graphData: {
          ...action.payload,
          noData: !!action.payload.points,
          line: selectedLine,
        },
        graphLoading: false,
      };
    }
    case CHANGE_LINE_ON_GRAPH:
      return {
        ...state,
        graphData: {
          ...state.graphData,
          line: action.payload,
        },
      };
    case GET_MY_PICKS_LAST_SUCCESS:
      return {
        ...state,
        stats: {
          ...action.payload,
          error: undefined,
        },
      };
    case GET_MY_PICKS_SUCCESS:
      return {
        ...state,
        list: action.payload
      };
    case CLEAR_GAMES:
      return {
        ...state,
        games: [],
      };

    case SET_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.payload.name]: {
            ...state.filter[action.payload.name],
            value: action.payload.value
          }
        }
      };

    case INIT_WEBSOCKET_SUCCESS:
      return {
        ...state,
        websocket: action.payload
      };

    case CLOSE_WEBSOCKET_SUCCESS:
      return {
        ...state,
        websocket: null
      };
    default:
      return state;
  }
};
