import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import ComposeConfimMessage from '../components/ComposeConfimMessage';
import { closeModal } from '../store/actions/view';

const mapStateToProps = state => ({
  opened: state.view.modals.composeConfirm,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    closeModal,
  },
  dispatch,
);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(ComposeConfimMessage);
