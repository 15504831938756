import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Wrapper from './styles';

const SwitcherTop = ({
  activateUnitTab, activateAllowancesTab, unitTab, allowancesTab,
}) => (
  <Wrapper className="top-btns">
    <button
      type="button"
      className={classNames('btn', { active: unitTab })}
      onClick={activateUnitTab}
    >
      <span>Units</span>
    </button>
    <button
      type="button"
      className={classNames('btn', { active: allowancesTab })}
      onClick={activateAllowancesTab}
    >
      <span>Allowances</span>
    </button>
  </Wrapper>
);

SwitcherTop.propTypes = {
  activateUnitTab: PropTypes.func.isRequired,
  activateAllowancesTab: PropTypes.func.isRequired,
  unitTab: PropTypes.bool.isRequired,
  allowancesTab: PropTypes.bool.isRequired,
};

export default SwitcherTop;
