import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {Field} from 'redux-form';
import Wrapper from './styles';
import FormInput from '../FormInput';
import Select from '../ui/form/Select';

import financeImg from '../../assets/images/ic-my-finance.svg';
import affiliatsImg from '../../assets/images/ic-affiliats-finance.svg';
import {checkAccess, listOfSection} from "@/helpers/access";

class Finance extends Component {
    state = {
        prefilled: false,
    };

    static propTypes = {
        finance: PropTypes.object.isRequired,
        affiliateCard: PropTypes.object,
        affiliatePaymentsDetails: PropTypes.array,
        affiliateRevenues: PropTypes.array,
        setPaymentsDetails: PropTypes.func.isRequired,
        handleSubmit: PropTypes.func.isRequired,
        preloadPaymentDetails: PropTypes.func.isRequired,
    };

    async componentDidMount() {
        await this.props.getMyFinanceInfo();
        this.props.getMyAffiliateInfo();
    }

    componentDidUpdate() {
        const {finance, preloadPaymentDetails} = this.props;
        const {prefilled} = this.state;

        if (finance.memberPaymentMethodDetails && !prefilled) {
            const prefilledData = {
                paymentMethod: finance.memberPaymentMethodDetails.paymentMethodId || '',
                paymentCurrency: finance.memberPaymentMethodDetails.currencyId || '',
                walletEmail: finance.memberPaymentMethodDetails.walletEmail || '',
            };
            preloadPaymentDetails(prefilledData);

            this.setState({prefilled: true}); // eslint-disable-line
        }
    }

    render() {
        const {
            finance,
            affiliateCard,
            affiliatePaymentsDetails,
            affiliateRevenues,
            setPaymentsDetails,
            handleSubmit,
            isAffiliate,
            userLevel
        } = this.props;
        const accessData = checkAccess(userLevel);
        return (
            <Wrapper>
                {accessData[listOfSection.FinancePrivate] ?
                    <>
                        <div className="block payments-form">
                            <h2 className="title">Payment Details</h2>
                            <form
                                id="paymentsForm"
                                noValidate
                                onSubmit={handleSubmit(() => setTimeout(setPaymentsDetails, 100))}
                            >
                                <div>
                                    <span>Payment Menthod &nbsp;</span>
                                    <Field
                                        onChange={() => setTimeout(setPaymentsDetails, 100)}
                                        name="paymentMethod"
                                        component={Select}
                                        options={finance.paymentMethods}
                                        id="paymentMethod"
                                    />
                                </div>
                                <div>
                                    <span>Payment Currency &nbsp;</span>
                                    <Field
                                        onChange={() => setTimeout(setPaymentsDetails, 100)}
                                        name="paymentCurrency"
                                        component={Select}
                                        options={finance.currency}
                                        id="paymentCurrency"
                                        addBlankZero
                                    />
                                </div>
                                <Field name="walletEmail" label="Payment Address" component={FormInput} type="text"/>
                                <div className="payment-button-wrapper">
                                    <button onClick={() => setTimeout(setPaymentsDetails, 100)}>Submit</button>
                                </div>
                            </form>
                        </div>
                    </> : null}

                <div className="row-wrapper">
                    {accessData[listOfSection.FinancePrivate]
                        ?
                        <div className="wrapper">
                            <h2 className="title">My Finance</h2>
                            <div className="block">
                                <div className="color-block blue">
                                    <div className="value-wrapper">
                                        <div className="value">{finance.balance}</div>
                                        <div className="value-description">
                                            <p>
                                                Your next payment is &nbsp;
                                                {finance.nextPayment}
                                                <br/>
                                                Next Payment Date:&nbsp;
                                                <span>{finance.nextPaymentDate}</span>
                                            </p>
                                        </div>
                                    </div>
                                    <p className="total">Total Revenue</p>
                                    <img src={financeImg} alt="My Finance"/>
                                </div>
                                <div
                                    className={classNames({
                                        'block-with-tables': isAffiliate && !isAffiliate.isAffiliate,
                                    })}
                                >
                                    <div
                                        className={classNames({
                                            'table-1': isAffiliate && !isAffiliate.isAffiliate,
                                        })}
                                    >
                                        <div className="table-block revenue-table">
                                            <div>Bet Size</div>
                                            <div>Revenue</div>
                                        </div>
                                        <table className="revenue-table">
                                            <tbody>
                                            {finance.revenueDetails ? (
                                                finance.revenueDetails.map((data, index) => (
                                                    <tr key={Date.now() + index}>
                                                        <td>{data.betSize}</td>
                                                        <td>{data.revenue}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td>No data available...</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div
                                        className={classNames({
                                            'table-2': isAffiliate && !isAffiliate.isAffiliate,
                                        })}
                                    >
                                        <p>
                                            <i>{finance.nextPaymentExplain}</i>
                                        </p>
                                        <p>
                                            <i>
                                                Total Paid: &nbsp;
                                                {finance.totalPayed}
                                                &nbsp;
                                                <br/>
                                                Balance: &nbsp;
                                                {finance.balance}
                                            </i>
                                        </p>
                                    </div>

                                    <div
                                        className={classNames({
                                            'table-3': isAffiliate && !isAffiliate.isAffiliate,
                                        })}
                                    >
                                        <h2 className="table-title">Payments</h2>
                                        <div className="table-block payments-table">
                                            <div>Date</div>
                                            <div className="amount">Amount ($)</div>
                                            <div>Transfered</div>
                                            <div>Rate</div>
                                            <div>Method</div>
                                        </div>
                                        <table className="revenue-table details">
                                            <tbody>
                                            {finance.myPaymentDetails && finance.myPaymentDetails.length > 0 ? (
                                                finance.myPaymentDetails.map((data, index) => (
                                                    <tr key={Date.now() + index}>
                                                        <td className="date">{data.date}</td>
                                                        <td className="amount">{data.amount}</td>
                                                        <td>{data.transfered}</td>
                                                        <td>{data.rate}</td>
                                                        <td>{data.method}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td>No data available...</td>
                                                </tr>
                                            )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}


                    <div
                        className={classNames({
                            wrapper: true,
                            wrapperHidden: isAffiliate && !isAffiliate.isAffiliate,
                        })}
                    >
                        <h2 className="title">Affiliates Finance</h2>
                        <div className="block">
                            <div className="color-block violet">
                                <div className="value-wrapper">
                                    <div className="value">{affiliateCard && affiliateCard.totalRevenue}</div>
                                    <div className="value-description">
                                        <p>
                                            Your next payment is
                                            {' '}
                                            {affiliateCard
                                            && affiliateCard.balance
                                            && (affiliateCard.balance.slice(0, affiliateCard.balance.length - 1) < 0
                                                ? '0'
                                                : affiliateCard.balance)}
                                            <br/>
                                            Next Payment Date:&nbsp;
                                            <span>{affiliateCard && affiliateCard.nextPayment}</span>
                                        </p>
                                    </div>
                                </div>
                                <p className="total">Total Revenue</p>
                                <img src={affiliatsImg} alt="Affiliats Finance"/>
                            </div>

                            <table className="revenue-table affiliate">
                                <tbody>
                                <tr>
                                    <td>Units Won</td>
                                    <td>{affiliateCard && affiliateCard.unitsWon}</td>
                                </tr>
                                <tr>
                                    <td>Total Payments</td>
                                    <td>{affiliateCard && affiliateCard.totalPayments}</td>
                                </tr>
                                <tr className="marked">
                                    <td>Balance:</td>
                                    <td>{affiliateCard && affiliateCard.balance}</td>
                                </tr>
                                </tbody>
                            </table>

                            <h2 className="table-title">Revenues</h2>
                            <div className="table-block payments-table">
                                <div>Bet Size</div>
                                <div>Units Won</div>
                                <div>Revenue</div>
                                <div>Start Date</div>
                            </div>
                            <table className="revenue-table details revenues">
                                <tbody>
                                {affiliateRevenues ? (
                                    affiliateRevenues.map((data, index) => (
                                        <tr key={Date.now() + index}>
                                            <td>{data.betSize}</td>
                                            <td>{data.unitsWon}</td>
                                            <td>{data.revenue}</td>
                                            <td className="start-date">{data.startDate}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No data available...</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>

                            <h2 className="table-title">Payments</h2>
                            <div className="table-block payments-table">
                                <div>Date</div>
                                <div className="amount">Amount ($)</div>
                                <div>Transfered</div>
                                <div>Rate</div>
                                <div>Method</div>
                            </div>
                            <table className="revenue-table details">
                                <tbody>
                                {affiliatePaymentsDetails ? (
                                    affiliatePaymentsDetails.map((data, index) => (
                                        <tr key={Date.now() + index}>
                                            <td className="date">{data.date}</td>
                                            <td className="amount">{data.ammount}</td>
                                            <td>{data.transfered}</td>
                                            <td>{data.rate}</td>
                                            <td>{data.method}</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No data available...</td>
                                    </tr>
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </Wrapper>
        );
    }
}

export default Finance;
