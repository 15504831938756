import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styled';
import Modal from '../../containers/Modal';

const AutoFetchModal = ({ opened, clearFormNotes }) => (
  opened ? (
    <Wrapper>
      <Modal onCloseAction={clearFormNotes} className="approve-modal" modalName="autoFetch">
        <div className="title-wrapper">
          <span className="modal-title">Line has changed</span>
        </div>
      </Modal>
    </Wrapper>
  ) : null
);

AutoFetchModal.propTypes = {
  opened: PropTypes.bool.isRequired,
  clearFormNotes: PropTypes.func.isRequired,
};

export default AutoFetchModal;
