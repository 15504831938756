import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESTORE_PASSWORD_REQUEST,
  RESTORE_PASSWORD_SUCCESS,
  RESTORE_PASSWORD_FAILURE,
  LOGOUT,
  SET_UTC_OFFSET
} from '@/store/actions/auth';

if (localStorage.getItem('tiltMode') === null) {
  localStorage.setItem('tiltMode', '1');
}

const initialState = {
  userId: localStorage.getItem('userId'),
  authorizationKey: localStorage.getItem('authorizationKey'),
  tiltMode: localStorage.getItem('tiltMode'),
  utcOffset: localStorage.getItem('utcOffset') || '0' ,
  loginErrorMessage: null,
  isLoginProcessing: false,
  restorePasswordErrorMessage: null,
  isRestorePasswordProcessing: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loginErrorMessage: null,
        isLoginProcessing: true
      };

      case LOGIN_SUCCESS:
      return {
        ...state,
        userId: action.payload.userId,
        authorizationKey: action.payload.authorizationKey,
        tiltMode: action.payload.tiltMode,
        loginErrorMessage: null,
        isLoginProcessing: false
      };

    case LOGIN_FAILURE:
      return {
        ...state,
        loginErrorMessage: action.payload.errorMessage,
        isLoginProcessing: false
      };

    case RESTORE_PASSWORD_REQUEST:
      return {
        ...state,
        restorePasswordErrorMessage: null,
        isRestorePasswordProcessing: true
      };

    case RESTORE_PASSWORD_SUCCESS:
      return {
        ...state,
        restorePasswordErrorMessage: null,
        isRestorePasswordProcessing: false
      };

    case RESTORE_PASSWORD_FAILURE:
      return {
        ...state,
        restorePasswordErrorMessage: action.payload.errorMessage,
        isRestorePasswordProcessing: false
      };

    case LOGOUT:
      return {
        ...state,
        userId: null,
        authorizationKey: null,
        loginErrorMessage: null,
        isLoginProcessing: false
      };
    case SET_UTC_OFFSET: 
      return {
        ...state,
        utcOffset: action.payload.utcOffset,
      }
    default:
      return state;
  }
}
