import React from 'react';

const BtnTrashIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48">
    <g fill="none" fillRule="evenodd">
      <path stroke="#4A90E2" strokeWidth="2" d="M16 17v15a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V17H16z" />
      <path
        fill="#4A90E2"
        fillRule="nonzero"
        d="M14 18c-.543 0-1-.448-1-1s.457-1 1-1h20c.543 0 1 .448 1 1s-.457 1-1 1H14zM20 22a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0v-7a1 1 0 0 1 1-1zM24 22a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0v-7a1 1 0 0 1 1-1zM28 22a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0v-7a1 1 0 0 1 1-1z"
      />
      <path
        fill="#4A90E2"
        stroke="#4A90E2"
        strokeWidth="2"
        d="M20 17h8v-2a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v2z"
      />
    </g>
  </svg>
);

export default BtnTrashIcon;
