import React from 'react';
import Wrapper from './styles';

import UnauthPagesWrapper from '../../components/UnauthPagesWrapper';
import TermsOfUseContainer from '../../assets/staticTexts/TermsOfUse';

const UnauthTermsOfUse = () => (
  <UnauthPagesWrapper>
    <Wrapper>
      <TermsOfUseContainer />
    </Wrapper>
  </UnauthPagesWrapper>
);

export default UnauthTermsOfUse;
