import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styled';
import Modal from '../../containers/Modal';

const InfoModal = ({ opened, renderTitle, className, renderContent }) => (
  opened ? (
    <Wrapper>
      <Modal className="modal info-modal" modalName="infoModal">
        <div className="title-wrapper">
          <span className="modal-title">
            {renderTitle()}
          </span>
        </div>
        {renderContent && (
          <div>
            <span className="modal-text">
              {renderContent()}
            </span>
          </div>
        )}
      </Modal>
    </Wrapper>
  ) : null
);

InfoModal.propTypes = {
  opened: PropTypes.bool.isRequired
};

export default InfoModal;
