import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import PerfectScrollbar from 'perfect-scrollbar';
import FormInput from '../FormInput';

import Wrapper from './styles';
import Modal from '../../containers/Modal';

export default class RecommendNewMember extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    termsOpened: PropTypes.bool.isRequired,
    openTerms: PropTypes.func.isRequired,
    closeTerms: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.modalRBody = createRef();
    this.scroll = null;
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden';
    this.scroll = new PerfectScrollbar(this.modalRBody, {
      maxScrollbarLength: 54,
      wheelPropagation: true,
    });
    this.scroll.scrollTop = 0;
  }

  componentDidUpdate() {
    this.scroll.update();
  }

  componentWillUnmount() {
    document.body.style.overflow = 'visible';
    this.scroll.destroy();
  }

  render() {
    const {
      handleSubmit, termsOpened, openTerms, closeTerms,
    } = this.props;
    return (
      <Wrapper>
        <Modal className="recommend-modal" modalName="recommend">
          <div className="title-wrapper">
            <span className="modal-title">RECOMMEND A NEW MEMBER</span>
          </div>
          <form noValidate>
            <div className="content-wrapper" ref={ref => (this.modalRBody = ref)}>
              <div className="block">
                <Field
                  name="fullName"
                  type="text"
                  component={FormInput}
                  label="New Member Name*"
                  placeholder="Full Name"
                />
                <Field
                  name="email"
                  type="email"
                  component={FormInput}
                  label="Email*"
                  placeholder="email@something.com"
                />
                <Field name="skype" type="text" component={FormInput} label="Telegram" />
                <Field
                  name="countriesLeagues"
                  type="text"
                  component={FormInput}
                  label="Countries/Leagues*"
                />
                <Field
                  name="whyYouRecommending"
                  type="text"
                  textarea="true"
                  component={FormInput}
                  label="Why are you recommending this person?"
                />
                <Field
                  name="canContact"
                  type="checkbox"
                  component={FormInput}
                  label="Can we contact the suggested member?"
                />
                <Field
                  name="contacted"
                  type="checkbox"
                  component={FormInput}
                  label="Does the person know we will contact him?"
                />
                <div className="terms-checkbox">
                  <Field name="terms" type="checkbox" component={FormInput} />
                  <span>
                    I Agree to the
                    {' '}
                    <button type="button" className="terms-link" onClick={openTerms}>
                      Affiliate Terms and Conditions
                    </button>
                  </span>
                </div>
              </div>
              <div className="block">
                {!termsOpened ? (
                  <p>
                    Recommend a friend by filling in this form, increase your profit as they profit!
                    <br />
                    Anyone you recommend and they work with us, &nbsp;
                    <strong>
                      you'll get $100 placed on every bet they place – as easy as that!
                    </strong>
                    <br />
                    <br />
                    <strong>What kind of people do we look for?</strong>
                    <br />
                    <span className="small-text">
                      Experts who have deep knowledge in the specific leagues they cover.
                      <br />
                      Experts who have a record of beating the Asian market. Experts you can
                      recommend who will be disciplined, selective and do what's best to profit for
                      us, them and you.
                      <br />
                      <br />
                      Recommend anyone you think who can work with Punteam. We'll look into all
                      suggestions, contact them and work with the people who we feel fit the Punteam
                      style and can profit and co-operate with us!
                    </span>
                  </p>
                ) : (
                  <div>
                    <button className="terms-link-back" type="button" onClick={closeTerms}>
                      <div className="back-image" />
                      Back
                    </button>
                    <div className="terms-block">
                      <p className="terms-title">
                        <strong>Affiliste Terms & Conditions</strong>
                      </p>
                      <p>
                        <strong>1.</strong>
                        &nbsp;An expert will only be registered under your affiliate ID if you
                        initially recommend him to us initially using the 'Recommend Member' page on
                        the punteam.com website.
                      </p>
                      <p className="sub">
                        <strong>a.</strong>
                        &nbsp; After you 'Recommend a Member' - we will approach them to see if they
                        are suitable for Punteam. Only when they are brought in as a Full Member
                        (not Trial or Paid Trial) will they begin to count towards your affiliate
                        balance.
                      </p>
                      <p>
                        <strong>2.</strong>
                        &nbsp;As an affiliate for Punteam, you will be paid according to the success
                        of the experts you bring to Punteam. Punteam will bet $100 for you on every
                        bet we take with them. In this regard, this is your 'unit' size as an
                        affiliate.
                      </p>
                      <p className="sub">
                        <strong>a.</strong>
                        This is regardless of the plan the expert you brought chooses with Punteam.
                      </p>
                      <p className="sub">
                        <strong>b.</strong>
                        &nbsp; Example: If you bring a member who earns 5.4 units over a year, you
                        would be paid 5.4 * $100 = $540.
                      </p>
                      <p>
                        <strong>3.</strong>
                        &nbsp;As an affiliate for Punteam, you will be paid yearly. Your payment
                        date each year will be the month and day of the first bet your expert(s)
                        placed with Punteam.
                      </p>
                      <p>
                        <strong>4.</strong>
                        &nbsp;Your affiliate payment dates and payments are completely unrelated to
                        the payments you receive as an expert with Punteam.
                      </p>
                      <p>
                        <strong>5.</strong>
                        &nbsp;Your affiliate members must place at least 100 bets in this yearly
                        period for you to be paid. If this amount has not been reached, you will not
                        be paid until the 100th bet has been reached - at this point, you will be
                        paid the full amount owed on the Monday after this bet has been settled.
                      </p>
                      <p>
                        <strong>6.</strong>
                        &nbsp;Affiliates and the experts they bring must both agree to be able to
                        view the expert's balance at all times - though, this is limited to the
                        profit of units ONLY - not any other data. Example: If a member has sent a
                        100 bets and is +5 units. All the affiliate user will see is that the member
                        is +5 units. They will not see how many bets they have placed, which bets or
                        any other details. Units are needed to be shown to show the affiliate user
                        how the balance has been calculated.
                        <br />
                        If the expert does not agree to this, he cannot be under that affiliate.
                        <br />
                        Your balance will be calculated by the cumulative amount of units your
                        experts have won for Punteam.
                        <br />
                        Example: If you have 6 experts. Three experts have -2 units and three
                        experts have +2 units, your total balance is +0 units, as this is the
                        cumulative figure of the experts.
                        <br />
                        If you only have one active expert under your affiliate ID, then this expert
                        no longer works with Punteam, you will be paid under the same conditions as
                        above (on the agreed payment date). After this payment, if you have no
                        active members under your affiliate ID, you will have no payment date - and
                        a new payment date will be set once you bring in another member under your
                        ID.
                        <br />
                        If a member has already been recommended to Punteam by another affiliate;
                        and you have recommended the same member; we will inform you and take them
                        off your Recommended list. We will only allow the first person to recommend
                        an expert to be the affiliate of that member. After any payment date, any
                        negative balance will always be carried over for the affiliate. As an
                        affiliate, you agree to not to influence the way your experts will work with
                        Punteam. Punteam reserves the right to accept or reject any recommended
                        expert at Punteam's discretion. Punteam reserves the right to terminate the
                        membership of any expert brought to Punteam immediately.
                        <br />
                        Punteam reserves the right to terminate the membership of any affiliate
                        immediately.
                        <br />
                        An affiliate can quit Punteam at any time - but his payment will be
                        according to the rules above. If an affiliate chooses 'Bank Wire' as his
                        payment option, the member will have to pay the fees associated with this
                        payment option.
                        <br />
                        Punteam are allowed to change the terms in this page after notifying the
                        affiliates to the planned changes.
                        <br />
                        These Terms & Conditions will be in affect from 14/04/2015 onwards.
                        <br />
                        <strong>Privacy Policy</strong>
                        <br />
                        {' '}
At Punteam, we respect our affiliates privacy, all personal details,
                        conversations and bets will be kept private. We have developed a secure
                        website where all tipsters' information is kept private at highest levels.
                        Any information passed to us via punteam.com will be only used by us and not
                        shared to anyone outside of the Punteam operation.
                        <br />
                        The member agrees not to share any information including picks, balances,
                        usernames and passwords from Punteam, with any third party without Punteam
                        consent.
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="submit-block">
              <button
                className="terms-btn"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal>
      </Wrapper>
    );
  }
}
