import React, { Fragment } from 'react';
import Messages from '../../containers/Messages';

const MessagesPage = () => (
  <Fragment>
    <Messages />
  </Fragment>
);

export default MessagesPage;
