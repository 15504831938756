import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';

const Select = ({
  input,
  label,
  id,
  wrapperClass,
  options,
  addBlank,
  disabled,
  addBlankZero,
  attrValue,
}) => {
  const props = {
    'data-value': attrValue ? input.value : undefined,
  };

  return (
    <Wrapper className={wrapperClass}>
      <select id={id} {...input} disabled={disabled} {...props}>
        {addBlank && <option />}
        {addBlankZero && <option value="0" />}
        {options.map(o => (
          <option value={o.id} key={`${id}_${o.id}`}>
            {o.name}
          </option>
        ))}
      </select>
      {label && <label htmlFor={id}>{label}</label>}
    </Wrapper>
  );
};

Select.propTypes = {
  input: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  wrapperClass: PropTypes.string,
  options: PropTypes.array,
  addBlank: PropTypes.bool,
  addBlankZero: PropTypes.bool,
  disabled: PropTypes.bool,
  attrValue: PropTypes.bool,
};

Select.defaultProps = {
  label: null,
  wrapperClass: null,
  options: [],
  addBlank: false,
  addBlankZero: false,
  disabled: false,
  attrValue: false,
};

export default Select;
