import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styled';
import Modal from '../../containers/Modal';

const TrialSuccessModal = ({ opened }) => (
  opened ? (
    <Wrapper>
      <Modal className="modal approve-modal " modalName="trialSuccess">
        <div className="title-wrapper">
          <span className="modal-title">Thank you</span>
        </div>
        <div>
          <span className="modal-text">Your pick has been accepted</span>
        </div>
      </Modal>
    </Wrapper>
  ) : null
);

TrialSuccessModal.propTypes = {
  opened: PropTypes.bool.isRequired
};

export default TrialSuccessModal;
