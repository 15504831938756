import styled from 'styled-components';
import { Link } from 'react-router-dom';
import fonts from '../../config/fonts';

const styles = `display: flex;
align-items: center;
color: #38415f;
text-decoration: none;
padding: 0 25px;
height: 100%;

.alerts-count {
  width: 20px;
  height: 20px;
  background-color: #4a90e2;
  color: white;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  font-family: ${fonts.proximaNova};
  margin-left: 5px;
}

&.active,
&:hover.active {
  background-color: #4a90e2;
  color: #fff;

  .alerts-count {
    background-color: #fff;
    color: #4a90e2;
  }
}

img {
  margin-right: 5px;
}

&:hover {
  background-color: rgb(74, 144, 226, 0.2);
}`;

const LinkStyled = styled(Link)`
  ${styles};
`;
const DivStyled = styled.div`
  ${styles};
`;

export default { LinkStyled, DivStyled };
