import firebase from 'firebase/app';
import 'firebase/performance';

import { initialConfig } from '@/config/firebase';

if (!firebase.apps.length) {
  firebase.initializeApp(initialConfig);
};

const performance = firebase.performance();

export default performance;
