import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, lifecycle } from 'recompose';
import get from 'lodash/get';

import CreateNewPick from '../../components/CreateNewPick';
import { getUserAccountInfo, getWebTemplate } from '../../store/actions/user';
import { expandNewPick, collapseNewPick, openModal, closeModal } from '../../store/actions/view';
import {
  getCreatePickPageInfo,
  getLeagues,
  submitPicksForm,
  changeLineOnGraph,
  prefillOddsReaches,
} from '../../store/actions/picks';
import {
  setAlertGame,
  setOddsReaches,
  setLines,
  getMemberOddsAlertGamesTemporary,
  getAlertsDefaults,
} from '../../store/actions/oddsalerts';
import { handicapGraph } from '../../selectors/picks';

let gamesIntervalId = null;
const gamesInterval = 30 * 1000;

const mapStateToProps = ({
  view, picks, user, alerts, auth, form
}) => ({
  betSettingsModalOpened: view.modals.betSettings,
  pickRejectedModalOpened: view.modals.pickRejected,
  infoModalOpened: view.modals.infoModal,
  refreshModalOpened: view.modals.refresh,
  waitModalOpened: view.modals.wait,
  successModalOpened: view.modals.success,
  trialSuccessModalOpened: view.modals.trialSuccess,
  collapsed: view.newPickCollapsed,
  pickModalOpened: view.modals.pick,
  ...picks,
  ...handicapGraph(picks.graphData),
  account: user.account,
  gameId: alerts.gameId,
  line: alerts.line,
  oddsReaches: alerts.oddsReaches,
  userLevel:  user.account.userLevel,
  trader: user.webtemplate.traderStatus,
  tiltMode: auth.tiltMode,
  utcOffset: auth.utcOffset,
  picksFormValues: form.picksForm ? form.picksForm.values : {},
  picksList: picks.list,
  unitLeft: get(user, 'webtemplate.user.unitLeft', 0),
  userId: get(user, 'webtemplate.user.userId', null)
});

const mapDispatchToProps = (dispatch, getState) => bindActionCreators(
  {
    expandNewPick,
    collapseNewPick,
    openPickModal: () => openModal('pick'),
    openBetSettingsModal: () => openModal('betSettings'),
    closeBetSettingsModal: () => closeModal('betSettings'),
    openInfoModal: () => openModal('infoModal'),
    getCreatePickPageInfo,
    getLeagues,
    submitPicksForm,
    getUserAccountInfo,
    changeLineOnGraph,
    getWebTemplate,
    setAlertGame,
    setOddsReaches,
    setLines,
    getMemberOddsAlertGamesTemporary,
    prefillOddsReaches,
    getAlertsDefaults,
  },
  dispatch,
);

const form = reduxForm({
  form: 'alertModalForm',
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const lifecycleMethod = lifecycle({
  componentDidMount() {
    this.props.getUserAccountInfo();
    this.props.getWebTemplate();
    this.props.getCreatePickPageInfo();
    // this.props.getLeagues();
    this.props.getAlertsDefaults();

    gamesIntervalId = setInterval(() => {
        this.props.getLeagues();
    }, gamesInterval);
  },

  componentWillUnmount() {
    clearInterval(gamesIntervalId);
  }
});

export default compose(
  connected,
  lifecycleMethod,
  form,
)(CreateNewPick);
