import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';
import TotalUnitBet from '@/assets/images/ic-total-unit-bet.png';
import TotalUnitsWon from '@/assets/images/ic-total-units-won.png';
import TotalRoi from '@/assets/images/ic-total-roi.png';
import TotalRevenue from '@/assets/images/ic-total-revenue.png';
import FinanceImg from '@/assets/images/ic-my-finance.svg';

const PicksStats = ({ stats, balance, totalPayed }) => (
  <Wrapper>
    <div className="item">
      <img src={TotalUnitBet} alt="Total unit bet" />
      <span className="title">Total unit bet:</span>
      <span className="result">{stats.totalBet}</span>
    </div>
    <div className="item">
      <img src={TotalUnitsWon} alt="Total Units Won" />
      <span className="title">Total Units Won:</span>
      <span className="result">{stats.totalWin}</span>
    </div>
    <div className="item">
      <img src={TotalRoi} alt="Total ROI" />
      <span className="title">Total ROI:</span>
      <span className="result">{stats.totalRoi}</span>
    </div>
    <div className="item">
      <img src={TotalRevenue} alt="Balance" />
      <span className="title">Balance:</span>
      <span className="result">{balance}</span>
    </div>
    <div className="item">
      <img src={FinanceImg} alt="Total Paid" />
      <span className="title">Total Paid:</span>
      <span className="result">{totalPayed}</span>
    </div>
  </Wrapper>
);

PicksStats.propTypes = {
  stats: PropTypes.object.isRequired,
};

export default PicksStats;
