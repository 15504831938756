import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { getUnitsReportNewAllowanceRequests } from '../../store/actions/report';

import RequestsPart from '../../components/UnitsReport/RequestsPart';

const mapStateToProps = ({ report: { requests }}) => ({
  requests
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getUnitsReportNewAllowanceRequests,
  },
  dispatch
);

const cLifecycle = lifecycle({
  componentDidMount() {
    this.props.getUnitsReportNewAllowanceRequests();
  },
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  cLifecycle
)(RequestsPart);
