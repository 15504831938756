import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import onClickOutside from 'react-onclickoutside';
import Help from '../../assets/images/ic-help';
import HelpSelected from '../../assets/images/ic-help-selected.svg';
import User from '../../assets/images/ic-user';
import UserSelected from '../../assets/images/web-ic-user-selected.svg';

class SidebarMenu extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  };

  state = {
    helpVisible: false,
    profileVisible: false,
  };

  handleClickOutside = (e) => {
    this.setState({
      helpVisible: false,
      profileVisible: false,
    });
  };

  toggleHelp = () => this.setState({
    helpVisible: !this.state.helpVisible,
  });

  toggleProfile = () => this.setState({
    profileVisible: !this.state.profileVisible,
  });

  render() {
    const { logout } = this.props;
    const { profileVisible, helpVisible } = this.state;
    return (
      <div className="sidebar-links">
        <div>
          <div
            className={classnames({ help: true, 'help-selected': helpVisible })}
            onClick={() => {
              if (profileVisible) {
                this.toggleProfile();
              }
              this.toggleHelp();
            }}
            role="dialog"
          >
            {!helpVisible ? <Help /> : <img alt="help selected" src={HelpSelected} />}
          </div>
          <div
            className={classnames({
              'inner-links': true,
              'links-opened': helpVisible,
            })}
          >
            <Link onClick={() => this.setState({ helpVisible: false })} to="/how-it-works">
              How it works
            </Link>
            <Link onClick={() => this.setState({ helpVisible: false })} to="/contact-us">
              Contact us
            </Link>
            <Link onClick={() => this.setState({ helpVisible: false })} to="/terms-of-use">
              Terms of Use
            </Link>
            <Link onClick={() => this.setState({ helpVisible: false })} to="/about-us">
              About us
            </Link>
          </div>
        </div>
        <div>
          <div
            className={classnames({
              profile: true,
              'profile-selected': profileVisible,
            })}
            onClick={() => {
              this.handleClickOutside();
              if (helpVisible) {
                this.toggleHelp();
              }
              this.toggleProfile();
            }}
            role="dialog"
          >
            {!profileVisible ? <User /> : <img alt="user selected" src={UserSelected} />}
          </div>
          <div
            className={classnames({
              'inner-links': true,
              'profile-link': true,
              'links-opened': profileVisible,
            })}
          >
            <Link onClick={() => this.setState({ profileVisible: false })} to="/edit-profile">
              Edit my profile
            </Link>
            <span className="link" onClick={logout}>Logout</span>
          </div>
        </div>
      </div>
    );
  }
}

const Sidebarmenu = onClickOutside(SidebarMenu);
export default Sidebarmenu;
