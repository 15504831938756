import React from 'react';
import Wrapper from './styles';

import UnauthPagesWrapper from '../../components/UnauthPagesWrapper';
import AboutUsContainer from '../../assets/staticTexts/AboutUs';

const UnauthAboutUs = () => (
  <UnauthPagesWrapper>
    <Wrapper>
      <AboutUsContainer />
    </Wrapper>
  </UnauthPagesWrapper>
);

export default UnauthAboutUs;
