import React from 'react';
import PropTypes from 'prop-types';

import ReactTable from 'react-table';
import 'react-table/react-table.css';

import moment from 'moment';

import WaitingIcon from '@/assets/images/ic-waiting';
import RejectedIcon from '@/assets/images/ic-rejected';
import ConfirmedIcon from '@/assets/images/ic-confirmed';

import ReactTableStyles from './react-table-styles';

const RequestsPart = ({ requests }) => {
  const tableData = requests || [];
  const defaultPageSize = 20;

  return (
    <div className="requests-part">
      <ReactTableStyles>
        <ReactTable
          pageSize={undefined}
          defaultPageSize={defaultPageSize}
          resizable={false}
          minRows={2}
          showPagination={tableData.length > defaultPageSize}
          data={tableData}
          columns={[
            {
              Header: 'Request Date',
              accessor: 'createDate',
              width: 170,
              sortMethod: (a, b) => {
                const momentA = moment(a, 'MMM D, YY HH:MM');
                const momentB = moment(b, 'MMM D, YY HH:MM');
                return momentA.diff(momentB);
              }
            },
            {
              Header: 'League Name',
              accessor: 'name'
            },
            {
              Header: 'Request Type',
              accessor: 'typeAction',
              width: 170,
              Cell: ({ value }) => <>{getFromLib(value)}</>
            },
            {
              Header: 'Request Status',
              accessor: 'status',
              width: 170,
              Cell: data => {
                const { value } = data;
                let StatusIcon;
                switch (value.toLowerCase()) {
                  case 'pending':
                    StatusIcon = WaitingIcon;
                    break;
                  case 'rejected':
                    StatusIcon = RejectedIcon;
                    break;
                  case 'approved':
                    StatusIcon = ConfirmedIcon;
                    break;
                  default:
                    StatusIcon = WaitingIcon;
                }
                return (
                  <>
                    <StatusIcon />
                    {value}
                  </>
                );
              }
            }
          ]}
        />
      </ReactTableStyles>
      {/* <table cellPadding="0" cellSpacing="0">
        <thead>
          <tr>
            <th>Request Date</th>
            <th>League Name</th>
            <th>Request Type</th>
            <th>Request Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {requests.map((request, i) => {
            const {
              createDate,
              name,
              status,
              typeAction,
            } = request;
            const StatusIcon = status === 'Pending' ? WaitingIcon : RejectedIcon;
            return (
              <tr key={`request-${i}`}>
                <td>{createDate}</td>
                <td>{name}</td>
                <td>{getFromLib(typeAction)}</td>
                <td>
                  <StatusIcon />
                  {status}
                </td>
                <td />
              </tr>
            );
          })}
        </tbody>
      </table> */}
    </div>
  );
};

const getFromLib = label => {
  const library = {
    'Adding': 'New allowance'
  };

  return library[label] || label;
};

RequestsPart.propTypes = {
  requests: PropTypes.array.isRequired
};

export default RequestsPart;
