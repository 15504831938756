const GET_EVENTS_IN_PLAY = "GET_EVENTS_IN_PLAY";
const GET_EVENTS_IN_PLAY_SUCCESS = "GET_EVENTS_IN_PLAY_SUCCESS";
const GET_EVENTS_IN_PLAY_ERROR = "GET_EVENTS_IN_PLAY_ERROR"

const GET_SELECTED_MATCH_RESULTS = "GET_SELECTED_MATCH_RESULTS";
const GET_SELECTED_MATCH_RESULTS_SUCCESS = "GET_SELECTED_MATCH_RESULTS_SUCCESS";
const GET_SELECTED_MATCH_RESULTS_ERROR = "GET_SELECTED_MATCH_RESULTS_ERROR";

const GET_FILTERED_MATCH_RESULTS = "GET_FILTERED_MATCH_RESULTS";


const GET_LEAGUES_LIST = "GET_LEAGUES_LIST";

export  {
  GET_EVENTS_IN_PLAY,
  GET_EVENTS_IN_PLAY_SUCCESS,
  GET_EVENTS_IN_PLAY_ERROR,
  GET_SELECTED_MATCH_RESULTS,
  GET_SELECTED_MATCH_RESULTS_SUCCESS,
  GET_SELECTED_MATCH_RESULTS_ERROR,
  GET_LEAGUES_LIST,
  GET_FILTERED_MATCH_RESULTS
};
