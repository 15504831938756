import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';

const Input = ({
  input, label, id, inputClass, disabled, wrapperClass,
}) => (
  <Wrapper className={wrapperClass}>
    <input type="text" id={id} className={inputClass} {...input} disabled={disabled} />
    <label htmlFor={id}>{label}</label>
  </Wrapper>
);

Input.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  inputClass: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  inputClass: null,
  disabled: false,
};

export default Input;
