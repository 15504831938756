import styled from 'styled-components';
import fonts from '../../config/fonts';
import closeIcon from '../../assets/images/ic-cloes.svg';
import closeIconHover from '../../assets/images/ic-close-hover.svg';
import closeIconSelected from '../../assets/images/ic-cloes-selected.svg';
import arrowImg from '../../assets/images/ic-arrow-back-norm.svg';
import arrowImgHover from '../../assets/images/ic-arrow-back-hover.svg';
import arrowImgSelected from '../../assets/images/ic-arrow-back-selected.svg';

export default styled.div`
  width: 932px;
  font-family: ${fonts.proximaNova};

  & > div {
    background: rgba(0, 0, 0, 0.36);
    z-index: 999;
  }

  .form-error-checkbox {
    top: 3px !important;
    left: 360px !important;
  }

  .recommend-modal {
    padding: 0 !important;
    width: 1012px !important;
    border: none !important;
    max-height: 930px;
  }

  .close-icon {
    right: 28px !important;

    button {
      width: 34px !important;
      height: 34px !important;
      background-image: url(${closeIcon});
      font-size: 15px;
      z-index: 100;
      position: relative;

      &:before {
        background-image: url(${closeIconHover});
      }

      &:after {
        background-image: url(${closeIconSelected});
      }

      &:before,
      &:after {
        content: '';
        width: 100%;
        height: 34px;
        position: absolute;
        top: 0;
        left: 0;
        box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
        opacity: 0;
        z-index: -100;
        transition: opacity 0.3s ease-in;
      }

      &:hover {
        &:before {
          opacity: 1;
        }
      }

      &:active {
        &:after {
          opacity: 1;
        }
      }

      &:focus {
        outline: none;
      }

      svg {
        display: none;
      }
    }
  }

  .title-wrapper {
    width: 1012px;
    height: 101px;
    background-image: linear-gradient(96deg, #8e70ee, #4a7ece);
    display: flex;
    align-items: center;
    padding: 0 28px;
  }

  .modal-title {
    font-size: 28px;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
  }

  .close-icon {
    top: 32px !important;
  }

  svg {
    width: 34px;
    height: 34px;
  }

  g {
    stroke: #ffffff;
  }

  button:hover g {
    stroke: #ffffff !important;
  }

  button:active g,
  button:focus g {
    fill: #ffffff !important;
  }

  form {
    padding-bottom: 20px;
  }

  .content-wrapper {
    display: flex;
    overflow-y: scroll;
    flex-direction: row;
    position: relative;
    max-height: 770px;
    padding-left: 40px;
    padding-right: 40px;
    margin-top: 30px;
  }

  .block > p {
    width: 421px;
  }

  .terms-block {
    padding-bottom: 40px;
    max-height: 630px;
  }

  .terms-block p {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.47;
    letter-spacing: normal;
    color: #38415f;
    margin: 0;
    text-align: justify;
    padding-right: 15px;

    &.sub {
      padding-left: 30px;
    }

    &.terms-title {
      font-size: 18px;
      margin-bottom: 24px;
    }
  }

  .block {
    flex-grow: 1;
    flex-basis: 300px;
  }

  .block:first-child {
    margin-right: 63px;
    margin-bottom: 0;
    height: 770px;
  }

  .block:last-child {
    margin-left: 33px;
  }

  label {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
    margin-bottom: 3px;
    margin-top: 20px;
    display: block;
  }

  label[for='whyYouRecommending'] {
    margin-bottom: 20px;
  }

  form div {
    position: relative;
  }

  input,
  textarea {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid #4a90e2;
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
    font-family: ${fonts.proximaNova};
    padding: 5px;
  }

  textarea {
    height: 143px;
    border: 1px solid #4a90e2;
  }

  textarea::placeholder,
  input::placeholder {
    color: #818fa9;
    font-weight: 300;
  }

  textarea:focus::placeholder,
  input:focus::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  .form-element {
    width: 413px;
  }

  .form-error {
    position: absolute;
    right: 2px;
    top: 0;
    color: red;
  }

  .terms-btn {
    width: 413px;
    height: 40px;
    background-image: linear-gradient(96deg, #8e70ee, #4a7ece);
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    border: navajowhite;
    color: white;
    cursor: pointer;
    margin-top: 44px;
    font-size: 16px;
    z-index: 100;
    position: relative;

    &:before {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:after {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:active {
      &:after {
        opacity: 1;
      }
    }

    &:focus {
      outline: none;
    }
  }

  .small-text {
    font-family: ${fonts.proximaNova};
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.79;
    letter-spacing: normal;
    color: #38415f;
  }

  p {
    font-size: 18px;
    line-height: 1.56;
    color: #38415f;
  }

  .label-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-top: 3px;
  }

  /* Hide the browser's default checkbox */
  .label-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: #ffffff;
    border: 1px solid #4a90e2;

    &:hover {
      width: 24px;
      height: 24px;
      border-style: solid;
      border-width: 1px;
      border-image-source: linear-gradient(135deg, #8e70ee, #4a7ece);
      border-image-slice: 1;
      background-color: white;
    }

    &:checked {
      width: 24px;
      height: 24px;
      border: solid 1px #4a90e2;
      background-color: white;
    }
  }

  /* On mouse-over, add a grey background color */
  .label-checkbox:hover input ~ .checkmark {
    background-color: #fff;
  }

  /* When the checkbox is checked, add a blue background */
  .label-checkbox input:checked ~ .checkmark {
    background-color: #fff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .label-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .label-checkbox .checkmark:after {
    left: 8px;
    top: 2px;
    width: 5px;
    height: 12px;
    border: solid #4a90e2;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .terms-checkbox {
    display: block;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
    margin-top: 20px;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  textarea:focus::placeholder,
  input:focus::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 1px solid #4a90e2;
    -webkit-text-fill-color: #38415f;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
  }

  .terms-checkbox > span {
    padding-left: 35px;
  }

  .terms-checkbox label {
    margin: 0;
  }

  .terms-link {
    color: #4a90e2;
    text-decoration: underline;
    border: none;
    background: none;
    font-size: 14px;
    padding: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      color: #1d7bea;
      text-decoration: underline;
    }

    &:active {
      color: #366fb1;
      text-decoration: underline;
    }
  }

  textarea {
    resize: none !important;
  }

  .form-error {
    font-size: 13px !important;
    position: absolute;
    right: -100px;
    top: 8px;
    color: #ff5766;
    width: 85px;
    height: 22px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .textarea-error {
    top: 2px;
  }

  .red-error {
    label {
      color: #ff5766;
    }

    textarea {
      border-color: #ff5766;
    }
    input {
      border-bottom-color: #ff5766;
    }
  }

  .terms-link-back {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #818fa9;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    padding-left: 0;

    &:focus {
      outline: none;
    }

    &:hover {
      color: #1d7bea;
      text-decoration: underline;
      transition: all 0.3s ease-in;

      .back-image {
        background-image: url(${arrowImgHover});
      }
    }

    &:active {
      color: #366fb1;
      text-decoration: underline;
      transition: all 0.3s ease-in;

      .back-image {
        background-image: url(${arrowImgSelected});
      }
    }

    .back-image {
      width: 7px;
      height: 13px;
      margin-right: 9px;
      background-image: url(${arrowImg});
    }
  }

  .submit-block {
    position: absolute;
    bottom: 0;
    background: white;
    width: 47%;
    padding: 0 0 40px 40px;
  }

  @media screen and (max-height: 900px) {
    height: 600px;

    .content-wrapper {
      height: 490px;
      overflow-y: scroll;
    }

    .terms-block {
      max-height: 100%;
      overflow: auto;
    }
  }
`;
