import { reduxForm } from 'redux-form';
import { compose, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';

import LastForm from '../../components/UnitsReport/LastForm';
import { getMemberUnitsReport } from '../../store/actions/report';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getMemberUnitsReport,
  },
  dispatch,
);

const form = reduxForm({
  form: 'lastUnitsReportForm',
});

const props = withProps(ownProps => ({
  initialValues: {
    lastMonth: 3,
  },
  options: [
    {
      id: 1,
      name: 1,
    },
    {
      id: 2,
      name: 2,
    },
    {
      id: 3,
      name: 3,
    },
    {
      id: 4,
      name: 4,
    },
    {
      id: 5,
      name: 5,
    },
    {
      id: 6,
      name: 6,
    },
    {
      id: 7,
      name: 7,
    },
    {
      id: 8,
      name: 8,
    },
    {
      id: 9,
      name: 9,
    },
    {
      id: 10,
      name: 10,
    },
    {
      id: 11,
      name: 11,
    },
    {
      id: 12,
      name: 12,
    },
  ],
  onChange: () => {
    setTimeout(ownProps.getMemberUnitsReport);
  },
  value: get(ownProps, 'form.lastUnitsReportForm.values.lastMonth', 3),
}));

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  props,
  form,
)(LastForm);
