import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FFB946"
        d="M19.066 18.007H5.976V5.802S5.908 5 4.958 5 4 5.802 4 5.802V20h15.066s.934-.03.934-.94c0-.911-.934-1.053-.934-1.053z"
      />
      <path
        stroke="#FFB946"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.475 14.61l2.561-6.456 2.833 6.254 2.302-3.15 2.19 3.352"
      />
    </g>
  </svg>
);
