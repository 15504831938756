import React from 'react';
import Wrapper from './styles';
import howItWorksImage from '../../images/ic-how-it-works.png';

const HowItWorks = () => (
  <Wrapper>
    <div className="info-page">
      <div className="left-block">
        <div className="div-info">
          <h1 className="title-page">How It Works</h1>
          <div className="block-img">
            <img src={howItWorksImage} alt="How It Works" />
          </div>
        </div>
      </div>
      <div className="right-block">
        <p>
          We want to make the pick process as easy as possible, for us and for you. This is why we
          have implemented an XML feed is directly with all Asian Odds live - you'll be able to find
          the match, click the bet you want and it will automatically be entered for you. We're in
          the teething process - the site will still have bugs in the system, the XML may to, we'd
          like you to point them out to us where possible so we can continually make the site more
          efficient for everyone.
        </p>
        <p>
          If in the future the feed happens to be down at any point, we’ll need the following
          details to process the bet:
        </p>
        <ul>
          <li>Home Team (Bayern Munich)</li>
          <li>Away Team (Barcelona)</li>
          <li>Bet (Barcelona +0.25)</li>
          <li>Minimum Odds To Take (1.85).</li>
        </ul>
        <p>We also have more optional details for pre-game bets:</p>

        <ul>
          <li>Urgent - Odds Dropping (Click the box if odds are likely to drop soon)</li>
          <li>Urgent - Time (Click the box if the match is about to begin soon)</li>
        </ul>

        <p>If it’s Live, we’ll need additional information:</p>

        <ul>
          <li>Current Score (1-0)</li>
          <li>Half Time Button (Click the box if the match is in the Half Time period)</li>
        </ul>

        <p>
          Whenever the XML Feed isn't functioning, this is the information that we'll need off you
          to place the bet. When XML feed is available, you'll select the bet you want to take and
          the website will input all details for you... all you need to add are the minimum odds
          acceptable to take on this bet.
        </p>

        <p>
          Once you've passed us this information, we request that you wait 1-2 minutes to receive
          confirmation of your bet. This will be in three stages:
        </p>

        <ol>
          <li>The trader will alert you he's received your bet, with 'OK' or 'Thanks'.</li>
          <li>The trader will take the bet and alert you he's taken it, with 'Took' or 'Taken'.</li>
          <li>The trader will inform you of the odds that he's taken (IE, 1.96).</li>
        </ol>

        <p>
          The bet will appear in the Chat Box after you've submitted it to the trader. This Chat Box
          is here for you to communicate as swiftly as possible with the trader online. You may need
          to communicate for several reasons. Prime examples would be:
        </p>

        <ul>
          <li>Alerting him of a Live Bet coming soon.</li>
          <li>
            If trader needs to tell the tipster of unavailable odds due to drops or other reasons.
          </li>
          <li>To write comments/questions to our traders.</li>
        </ul>

        <p>
          Basically, the Chat Box can be used for any tip related questions to our trader on at that
          time, making our co-operation as swift and efficient as possible.
        </p>
        <p>
          After this, the bet is complete and you're required to do nothing else. The bet will be
          added to your personal record for to keep track of at all times.
        </p>
      </div>
    </div>
  </Wrapper>
);

export default HowItWorks;
