import {change, reduxForm} from 'redux-form';
import {compose, withProps} from 'recompose';
import get from 'lodash/get';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import LeagueForm from '../../components/CreateNewPick/LeagueForm';
import {
    clearLeagueSearch,
    clearPicksForm,
    getLeagues,
    selectLeague,
} from '../../store/actions/picks';
import {FILTERED_OUT_LEAGUE_ID_ID, MAX_ID, MIN_ID, FILTER_ID} from "@/components/CreateNewPick";

const form = reduxForm({
    form: 'leagueForm',
});

const mapStateToProps = ({picks, form: formState}) => {
    const leagueSearch = (get(formState, 'leagueForm.values.leagueSearch', '')).trim().toLowerCase();
    const marketType = Number(get(formState, 'leagueForm.values.marketType', 3));
    const allGames = get(picks, 'allGames', []);
    let leagues = [];
    const localUserId = localStorage.getItem('userId')
    const idInWhiteList = (((localUserId >= MIN_ID) && (localUserId <= MAX_ID)) || (localUserId == FILTER_ID))
    const searchResult = [];
    if (leagueSearch.length >= 3) {
        leagues = picks.leagues.filter(league =>
            league.leagueName.toLowerCase().includes(leagueSearch));
        const games = allGames.filter(v => idInWhiteList ? v: v.leagueId !== FILTERED_OUT_LEAGUE_ID_ID)
            .filter(game =>
                game.team1Name.toLowerCase().includes(leagueSearch) ||
                game.team2Name.toLowerCase().includes(leagueSearch)
            );

        if (leagues && leagues.length) {
            searchResult.push({
                title: 'Leagues',
                isLeague: true,
                data: leagues
            });
        }

        if (games && games.length) {
            searchResult.push({
                title: 'Games',
                isGame: true,
                data: games
            });
        }
    } else {
        if (picks.selectedLeague && picks.selectedLeague.leagueName === leagueSearch) {
            ({leagues} = picks);
        } else {
            leagues = picks.leagues.filter(l => {
                if (!leagueSearch) {
                    return l.market === marketType;
                } else {
                    return l.market === marketType && l.leagueName.match(new RegExp(leagueSearch, 'gi'));
                }

            });
        }
    }

    return {
        sports: picks.sports,
        marketTypes: picks.marketTypes,
        betGameParts: picks.betGameParts,
        leagues,
        initialValues: {
            sport: '1',
            marketType: '3',
            betGamePart: '0',
        },
        leagueSearch,
        searchResult: searchResult
    };
};

const props = withProps(ownProps => ({
    selLeague: (league) => {
        ownProps.dispatch(change('leagueForm', 'marketType', league.market));
        ownProps.dispatch(change('leagueForm', 'leagueSearch', ''));
        ownProps.clearPicksForm();
        ownProps.selectLeague(league);

        setTimeout(() => {
            ownProps.getLeagues();
        });

    },
    onChangeLeagueParams: () => {
        ownProps.clearLeagueSearch();
        ownProps.clearPicksForm();
        setTimeout(() => {
            ownProps.getLeagues();
        });
    }
}));

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        getLeagues,
        selectLeague,
        clearLeagueSearch,
        clearPicksForm
    },
    dispatch,
);

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    form,
    props,
)(LeagueForm);
