import React from 'react';

const OpenMessagesIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" stroke="#38415F" strokeLinejoin="round" strokeWidth="2">
      <path d="M4 8l8 5 8-5" />
      <path strokeLinecap="round" d="M4 8v12h16V8l-8-5z" />
    </g>
  </svg>
);

export default OpenMessagesIcon;
