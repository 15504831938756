import http from '@/services/http-service';
import _get from 'lodash/get';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';

// tabs
export const FETCH_TABS_REQUEST = 'odds-alerts/FETCH_TABS_REQUEST';
export const FETCH_TABS_SUCCESS = 'odds-alerts/FETCH_TABS_SUCCESS';
export const FETCH_TABS_FAILURE = 'odds-alerts/FETCH_TABS_FAILURE';
export const SET_ACTIVE_TAB = 'odds-alerts/SET_ACTIVE_TAB';

// defaultSettings
export const FETCH_DEFAULT_SETTINGS_REQUEST = 'odds-alerts/FETCH_DEFAULT_SETTINGS_REQUEST';
export const FETCH_DEFAULT_SETTINGS_SUCCESS = 'odds-alerts/FETCH_DEFAULT_SETTINGS_SUCCESS';
export const FETCH_DEFAULT_SETTINGS_FAILURE = 'odds-alerts/FETCH_DEFAULT_SETTINGS_FAILURE';

export const SET_DEFAULT_SETTINGS_REQUEST = 'odds-alerts/SET_DEFAULT_SETTINGS_REQUEST';
export const SET_DEFAULT_SETTINGS_SUCCESS = 'odds-alerts/SET_DEFAULT_SETTINGS_SUCCESS';
export const SET_DEFAULT_SETTINGS_FAILURE = 'odds-alerts/SET_DEFAULT_SETTINGS_FAILURE';

// oddsAlertsSelectedPermanent
export const FETCH_ODDS_ALERTS_SELECTED_PERMANENT_REQUEST = 'odds-alerts/FETCH_ODDS_ALERTS_SELECTED_PERMANENT_REQUEST';
export const FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUCCESS = 'odds-alerts/FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUCCESS';
export const FETCH_ODDS_ALERTS_SELECTED_PERMANENT_FAILURE = 'odds-alerts/FETCH_ODDS_ALERTS_SELECTED_PERMANENT_FAILURE';

export const FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_REQUEST = 'odds-alerts/FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_REQUEST';
export const FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_SUCCESS = 'odds-alerts/FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_SUCCESS';
export const FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_FAILURE = 'odds-alerts/FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_FAILURE';

export const SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_REQUEST = 'odds-alerts/SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_REQUEST';
export const SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_SUCCESS = 'odds-alerts/SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_SUCCESS';
export const SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_FAILURE = 'odds-alerts/SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_FAILURE';

export const TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_OPEN = 'odds-alerts/TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_OPEN';
export const TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_SETTINGS_POPUP = 'odds-alerts/TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_SETTINGS_POPUP';

// oddsAlertsSearch
export const FETCH_SEARCHFIELD_LIST_REQUEST = 'odds-alerts/FETCH_SEARCHFIELD_LIST_REQUEST';
export const FETCH_SEARCHFIELD_LIST_SUCCESS = 'odds-alerts/FETCH_SEARCHFIELD_LIST_SUCCESS';
export const FETCH_SEARCHFIELD_LIST_FAILURE = 'odds-alerts/FETCH_SEARCHFIELD_LIST_FAILURE';

export const FETCH_SEARCH_COUNTRIES_REQUEST = 'odds-alerts/FETCH_SEARCH_COUNTRIES_REQUEST';
export const FETCH_SEARCH_COUNTRIES_SUCCESS = 'odds-alerts/FETCH_SEARCH_COUNTRIES_SUCCESS';
export const FETCH_SEARCH_COUNTRIES_FAILURE = 'odds-alerts/FETCH_SEARCH_COUNTRIES_FAILURE';

export const FETCH_SEARCH_RESULT_LEAGUES_REQUEST = 'odds-alerts/FETCH_SEARCH_RESULT_LEAGUES_REQUEST';
export const FETCH_SEARCH_RESULT_LEAGUES_SUCCESS = 'odds-alerts/FETCH_SEARCH_RESULT_LEAGUES_SUCCESS';
export const FETCH_SEARCH_RESULT_LEAGUES_FAILURE = 'odds-alerts/FETCH_SEARCH_RESULT_LEAGUES_FAILURE';

export const FETCH_SEARCH_RESULT_TEAMS_REQUEST = 'odds-alerts/FETCH_SEARCH_RESULT_TEAMS_REQUEST';
export const FETCH_SEARCH_RESULT_TEAMS_SUCCESS = 'odds-alerts/FETCH_SEARCH_RESULT_TEAMS_SUCCESS';
export const FETCH_SEARCH_RESULT_TEAMS_FAILURE = 'odds-alerts/FETCH_SEARCH_RESULT_TEAMS_FAILURE';

export const SET_CHECKED_SEARCH_RESULT_ITEM_REQUEST = 'odds-alerts/SET_CHECKED_SEARCH_RESULT_TEAM_REQUEST';
export const SET_CHECKED_SEARCH_RESULT_ITEM_SUCCESS = 'odds-alerts/SET_CHECKED_SEARCH_RESULT_ITEM_SUCCESS';
export const SET_CHECKED_SEARCH_RESULT_ITEM_FAILURE = 'odds-alerts/SET_CHECKED_SEARCH_RESULT_TEAM_FAILURE';

export const TOGGLE_OPEN_ODDS_ALERT_SEARCH_RESULT = 'odds-alerts/TOGGLE_OPEN_ODDS_ALERT_SEARCH_RESULT_OPEN';

export const SET_ODDS_ALERT_SEARCH_PARAMETERS = 'odds-alerts/SET_ODDS_ALERT_SEARCH_PARAMETERS';
export const TOGGLE_ODDS_ALERT_SEARCH_RESULT_SETTINGS_POPUP = 'odds-alerts/TOGGLE_ODDS_ALERT_SEARCH_RESULT_SETTINGS_POPUP';

export const fetchTabs = () => async dispatch => {
  dispatch({ type: FETCH_TABS_REQUEST });
  try {
    // TODO: get data tabs from backend
    const tabs = await Promise.resolve([
      { id: 'permanent', label: 'Permanent' },
      { id: 'temporary', label: 'Temporary' }
    ]);

    dispatch({
      type: FETCH_TABS_SUCCESS,
      payload: tabs
    });
  } catch (error) {
    dispatch({
      type: FETCH_TABS_FAILURE,
      payload: error
    });
  }
};

export const setActiveTab = id => dispatch => {
  dispatch({
    type: SET_ACTIVE_TAB,
    payload: id
  });
};

// default settings
export const fetchDefaultSettings = () => async dispatch => {
  dispatch({ type: FETCH_DEFAULT_SETTINGS_REQUEST });

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMemberOddsAlertsDefaults');
      trace.start();
    }

    const { data: { error, ...data } } = await http.post('/GetMemberOddsAlertsDefaults');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode === 0) {
      dispatch({
        type: FETCH_DEFAULT_SETTINGS_SUCCESS,
        payload: data
      });
    } else {
      dispatch({
        type: FETCH_DEFAULT_SETTINGS_FAILURE,
        payload: error
      });
    }
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    dispatch({
      type: FETCH_DEFAULT_SETTINGS_FAILURE,
      payload: error
    });
  }
};

export const setDefaultSettings = newData => async (dispatch, getState) => {
  const { oddsAlerts: { defaultSettings: { data: oldData }}} = getState();

  const keysOfChanged = Object.keys(newData)
    .filter(key => oldData[key] !== newData[key]);

  if (!keysOfChanged.length) return;

  dispatch({ type: SET_DEFAULT_SETTINGS_REQUEST });

  const data = { ...oldData, ...newData };
  // transform string to number if possible
  Object.keys(data).forEach(key => {
    if (typeof data[key] === 'string' && !isNaN(data[key])) {
      data[key] = Number(data[key]);
    }
  });
  const params = {
    oddsMoveMore: data.oddsMoveMore,
    // oddsAlertMove: data.oddsAlertMove, // TODO: this prop does not exist on backend
    isNewGameOpen: data.isNewGameOpen
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetMemberOddsAlertsDefaults');
      trace.start();
    }
    const { data: { error }} = await http.post('/SetMemberOddsAlertsDefaults', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_DEFAULT_SETTINGS_SUCCESS,
      payload: newData
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    dispatch({
      type: SET_DEFAULT_SETTINGS_FAILURE,
      payload: error
    });
  }
};

// odds alerts list SelectedPermanent
export const fetchOddsAlertsSelectedPermanent = () => async dispatch => {
  dispatch({ type: FETCH_ODDS_ALERTS_SELECTED_PERMANENT_REQUEST });
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMemberOddsAlertPermanent');
      trace.start();
    }

    const { data: { error, alerts } } = await http.post('/GetMemberOddsAlertPermanent');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUCCESS,
      payload: alerts
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    dispatch({
      type: FETCH_ODDS_ALERTS_SELECTED_PERMANENT_FAILURE,
      payload: error
    });
  }
};

export const toggleOddsAlertSelectedPermanent = rootAlert => async dispatch => {
  dispatch({
    type: TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_OPEN,
    payload: rootAlert.id
  });
};

export const fetchOddsAlertsTeamsByLeaguePermanent = rootAlert => async dispatch => {
  dispatch({
    type: FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_REQUEST,
    payload: { id: rootAlert.id }
  });

  const params = {
    countryId: rootAlert.countryId,
    leagueId: rootAlert.leagueId
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMemberOddsAlertsTeamsByLeaguePermanent');
      trace.start();
    }

    const { data: { error, alerts } } = await http.post('/GetMemberOddsAlertsTeamsByLeaguePermanent', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_SUCCESS,
      payload: { id: rootAlert.id, data: alerts }
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    dispatch({
      type: FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_FAILURE,
      payload: { id: rootAlert.id, error }
    });
  }
};

export const toggleCheckedSelectedPermanent = ({ item, checked: isAdd }) => async (dispatch, getState) => {
  let url, data;
  const { oddsAlerts: { defaultSettings: { data: defaults }}} = getState();

  if (item.isLeague) {
    url = '/SetOddsAlertsLeague';
    data = {
      countryId: item.countryId,
      leagueId: item.leagueId,
      isNewGameOpen: isAdd ? defaults.isNewGameOpen : item.isNewGameOpen,
      oddsMoveMore: isAdd ? defaults.oddsMoveMore : item.oddsMoveMore,
      typeAction: isAdd ? 1 : 3
    };
  } else {
    url = '/SetOddsAlertsTeam';
    data = {
      countryId: item.countryId,
      leagueId: item.leagueId,
      teamId: item.teamId,
      isNewGameOpen: isAdd ? defaults.isNewGameOpen : item.isNewGameOpen,
      oddsMoveMore: isAdd ? defaults.oddsMoveMore : item.oddsMoveMore,
      typeAction: isAdd ? 1 : 3
    };
  }

  console.log(
    '%toggleCheckedSelectedPermanent',
    'background-color: lime; color: black; padding: 2px; border-radius: 3px;',
    item,
    data
  );

  dispatch({
    type: SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_REQUEST,
    payload: { id: item.id }
  });

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace(url.slice(1));
      trace.start();
    }

    const { data: { error }} = await http.post(url, data);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    await Promise.all([
      dispatch(fetchOddsAlertsSelectedPermanent()),
      dispatch(fetchSearchResult())
    ]);

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_SUCCESS,
      payload: { id: item.id }
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    dispatch({
      type: SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_FAILURE,
      payload: { id: item.id, error }
    });
  }
};

// odds alerts list Search
const fetchLeaguesByCountry = async countryId => {
  let result = null;

  if (countryId) {
    let trace;

    try {
      if (process.env.REACT_APP_ENV === 'production') {
        trace = performance.trace('GetOddsAlertLeaguesByCountry');
        trace.start();
      }

      const { data } = await http.post('/GetOddsAlertLeaguesByCountry', { countryId });

      if (process.env.REACT_APP_ENV === 'production') {
        if (data.error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${data.error.errorCode}`);
        }
        trace.stop();
      }

      result = data;
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
        trace.putAttribute('error', error.message);
        trace.stop();
      }

      console.warn(error);
    }
  }
  return result;
};

const fetchTeamsByLeague = async (countryId, leagueId) => {
  let result = null;

  if (countryId && leagueId) {
    let trace;
    try {
      if (process.env.REACT_APP_ENV === 'production') {
        trace = performance.trace('GetOddsAlertsTeamsByLeague');
        trace.start();
      }
      const { data } = await http.post('/GetOddsAlertsTeamsByLeague', { countryId, leagueId });
      if (process.env.REACT_APP_ENV === 'production') {
        if (data.error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${data.error.errorCode}`);
        }
        trace.stop();
      }
      result = data;
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
        trace.putAttribute('error', error.message);
        trace.stop();
      }

      console.warn(error);
    }
  }
  return result;
};

export const fetchSearchFieldList = searchCriteria => async dispatch => {
  dispatch({ type: FETCH_SEARCHFIELD_LIST_REQUEST });

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetOddsAlertsSearchTeams');
      trace.start();
    }

    const { data: { error, teams }} = await http.post('/GetOddsAlertsSearchTeams', { searchCriteria });
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: FETCH_SEARCHFIELD_LIST_SUCCESS,
      payload: teams
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);

    dispatch({
      type: FETCH_SEARCHFIELD_LIST_FAILURE,
      payload: error
    });
  }
};

export const fetchCountries = () => async dispatch => {
  dispatch({
    type: FETCH_SEARCH_COUNTRIES_REQUEST
  });
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMemberUnitsReportCountries');
      trace.start();
    }
    const { data: { error, countries }} = await http.post('/GetMemberUnitsReportCountries');
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: FETCH_SEARCH_COUNTRIES_SUCCESS,
      payload: countries
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);

    dispatch({
      type: FETCH_SEARCH_COUNTRIES_FAILURE,
      payload: error
    });
  }
};

export const fetchSearchResult = () => async (dispatch, getState) => {
  const {
    oddsAlerts: {
      searchAlerts: {
        searchField: { selectedId: leagueId },
        countries: { selectedId: countryId },
        result: { data: resultItems }
      }
    }
  } = getState();

  console.log('%cfetchSearchResult', 'color: yellow', countryId, leagueId);

  dispatch({ type: FETCH_SEARCH_RESULT_LEAGUES_REQUEST });
  dispatch({ type: FETCH_SEARCH_RESULT_TEAMS_REQUEST, payload: { countryId, leagueId } });

  try {
    // find opened league
    const openedLeague = resultItems.find(item => (item.isGroup && item.groupItems.isOpen));

    const [leagues, teams] = await Promise.all([
      fetchLeaguesByCountry(countryId),
      fetchTeamsByLeague(countryId, leagueId)
    ]);

    console.log('%cfetchSearchResult', 'color: lime', leagues, teams);

    if (leagues && leagues.error.errorCode === 0) {
      dispatch({
        type: FETCH_SEARCH_RESULT_LEAGUES_SUCCESS,
        payload: { data: leagues.leagues, countryId }
      });

      if (leagueId) { // open selected league
        const currentState = getState();
        const leagues = _get(currentState, 'oddsAlerts.searchAlerts.result.data', []);
        const targetLeague = leagues.find(league => league.leagueId === leagueId);

        if (targetLeague) {
          dispatch({
            type: TOGGLE_OPEN_ODDS_ALERT_SEARCH_RESULT,
            payload: { id: targetLeague.id, isOpen: true }
          });
        }
      }
    } else if (leagues) {
      dispatch({
        type: FETCH_SEARCH_RESULT_LEAGUES_FAILURE,
        payload: leagues.error
      });
    }

    console.log('%cgetState().oddsAlerts.searchAlerts.result.data', 'color:magenta', getState().oddsAlerts.searchAlerts.result.data);

    if (teams && teams.error.errorCode === 0) {
      dispatch({
        type: FETCH_SEARCH_RESULT_TEAMS_SUCCESS,
        payload: { data: teams.teams, countryId, leagueId, id: openedLeague ? openedLeague.id : null }
      });
    } else if (teams) {
      dispatch({
        type: FETCH_SEARCH_RESULT_TEAMS_FAILURE,
        payload: { error: teams.error, countryId, leagueId }
      });
    }
  } catch (error) {
    console.warn(error);
  }
};

export const toggleOddsAlertSearch = rootAlert => async dispatch => {
  const { id, countryId, leagueId } = rootAlert;

  dispatch({
    type: TOGGLE_OPEN_ODDS_ALERT_SEARCH_RESULT,
    payload: { id }
  });

  dispatch({
    type: SET_ODDS_ALERT_SEARCH_PARAMETERS,
    payload: {
      countryId,
      leagueId: rootAlert.groupItems.isOpen ? null : leagueId
    }
  });
};

export const fetchOddsAlertsTeamsByLeagueSearch = rootAlert => async dispatch => {
  console.log('%cFAKE fetchOddsAlertsTeamsByLeagueSearch', 'color:yellow', rootAlert);

  const { id, countryId, leagueId } = rootAlert;

  dispatch({
    type: FETCH_SEARCH_RESULT_TEAMS_REQUEST,
    payload: { id }
  });

  try {
    const { error, teams: data } = await fetchTeamsByLeague(countryId, leagueId);

    if (error.errorCode === 0) {
      dispatch({
        type: FETCH_SEARCH_RESULT_TEAMS_SUCCESS,
        payload: { data, id }
      });
    } else {
      dispatch({
        type: FETCH_SEARCH_RESULT_TEAMS_FAILURE,
        payload: { error, id }
      });
    }
  } catch (error) {
    console.warn(error);
  }
};

export const toggleCheckedSearchResult = ({ item, checked: isAdd }) => async (dispatch, getState) => {
  let url, data;
  const { oddsAlerts: { defaultSettings: { data: defaults }}} = getState();

  if (item.isLeague) {
    url = '/SetOddsAlertsLeague';
    data = {
      countryId: item.countryId,
      leagueId: item.leagueId,
      isNewGameOpen: isAdd ? defaults.isNewGameOpen : item.isNewGameOpen,
      oddsMoveMore: isAdd ? defaults.oddsMoveMore : item.oddsMoveMore,
      typeAction: isAdd ? 1 : 3
    };
  } else {
    url = '/SetOddsAlertsTeam';
    data = {
      countryId: item.countryId,
      leagueId: item.leagueId,
      teamId: item.teamId,
      isNewGameOpen: isAdd ? defaults.isNewGameOpen : item.isNewGameOpen,
      oddsMoveMore: isAdd ? defaults.oddsMoveMore : item.oddsMoveMore,
      typeAction: isAdd ? 1 : 3
    };
  }

  console.log(
    '%ctoggleCheckedSearchResult',
    'background-color: lime; color: black; padding: 2px; border-radius: 3px;',
    item,
    data
  );

  dispatch({
    type: SET_CHECKED_SEARCH_RESULT_ITEM_REQUEST,
    payload: { id: item.id }
  });

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace(url.slice(1));
      trace.start();
    }

    const { data: { error }} = await http.post(url, data);
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    await Promise.all([
      dispatch(fetchOddsAlertsSelectedPermanent()),
      dispatch(fetchSearchResult())
    ]);

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_CHECKED_SEARCH_RESULT_ITEM_SUCCESS,
      payload: { id: item.id }
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    dispatch({
      type: SET_CHECKED_SEARCH_RESULT_ITEM_FAILURE,
      payload: { id: item.id, error }
    });
  }
};

export const setOddsAlertSettings = item => async dispatch => {
  let url, data;

  if (item.isLeague) {
    url = '/SetOddsAlertsLeague';
    data = {
      countryId: item.countryId,
      leagueId: item.leagueId,
      isNewGameOpen: item.isNewGameOpen,
      oddsMoveMore: item.oddsMoveMore,
      typeAction: 2
    };
  } else {
    url = '/SetOddsAlertsTeam';
    data = {
      countryId: item.countryId,
      leagueId: item.leagueId,
      teamId: item.teamId,
      isNewGameOpen: item.isNewGameOpen,
      oddsMoveMore: item.oddsMoveMore,
      typeAction: 2
    };
  }

  console.log(
    '%cSET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_REQUEST',
    'background-color: lime; color: black; padding: 2px; border-radius: 3px;',
    item,
    data
  );

  dispatch({
    type: SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_REQUEST,
    payload: { id: item.id }
  });

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace(url.slice(1));
      trace.start();
    }
    const { data: { error }} = await http.post(url, data);
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }
    await dispatch(fetchOddsAlertsSelectedPermanent());

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_SUCCESS,
      payload: { id: item.id }
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    dispatch({
      type: SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_FAILURE,
      payload: { id: item.id, error }
    });
  }
};

export const toggleSettingsPopup = item =>  async dispatch => {
  dispatch({
    type: TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_SETTINGS_POPUP,
    payload: { id: item.id }
  });
};

export const setSearchParams = (countryId, leagueId) => (dispatch, getState) => {
  dispatch({
    type: SET_ODDS_ALERT_SEARCH_PARAMETERS,
    payload: { countryId, leagueId }
  });

  dispatch(fetchSearchResult());
};

export const toggleAllLeaguesOfCountry = checked => async (dispatch, getState) => {
  const state = getState();
  const selectedCountryId = _get(state, 'oddsAlerts.searchAlerts.countries.selectedId', undefined);
  const defaults = _get(state, 'oddsAlerts.defaultSettings.data', {});
  const searchItems = _get(state, 'oddsAlerts.searchAlerts.result.data', []);

  console.log('toggleAllLeaguesOfCountry', state.oddsAlerts.searchAlerts.result.data);

  if (selectedCountryId !== undefined) {

    searchItems.forEach(item => {
      dispatch({
        type: SET_CHECKED_SEARCH_RESULT_ITEM_REQUEST,
        payload: { id: item.id }
      });
    });

    const params = {
      countryId: selectedCountryId,
      leagueId: 0, // select all leagues
      isNewGameOpen: defaults.isNewGameOpen,
      oddsMoveMore: defaults.oddsMoveMore,
      typeAction: checked ? 1 : 3
    };

    let trace;
    try {
      if (process.env.REACT_APP_ENV === 'production') {
        trace = performance.trace('SetOddsAlertsLeague');
        trace.start();
      }
      const { data: { error }} = await http.post('/SetOddsAlertsLeague', params);
      if (process.env.REACT_APP_ENV === 'production') {
        if (error.errorCode !== 0) {
          trace.putAttribute('errorCode', `${error.errorCode}`);
        }
        trace.stop();
      }
      await Promise.all([
        dispatch(fetchOddsAlertsSelectedPermanent()),
        dispatch(fetchSearchResult())
      ]);

      if (error.errorCode !== 0) {
        throw new ApiError(error.errorText, error.errorCode);
      }

      searchItems.forEach(item => {
        dispatch({
          type: SET_CHECKED_SEARCH_RESULT_ITEM_SUCCESS,
          payload: { id: item.id }
        });
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
        trace.putAttribute('error', error.message);
        trace.stop();
      }

      searchItems.forEach(item => {
        dispatch({
          type: SET_CHECKED_SEARCH_RESULT_ITEM_FAILURE,
          payload: { id: item.id, error }
        });
      });
    }
  }
};
