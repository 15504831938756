import React from 'react';

import Wrapper from './styles';
import BallImage from '../../../assets/images/iconfinder-icon-sets-100-popular-requests-fill-iconfinder-72-2037708.svg';
import OvalImage from '../../../assets/images/oval.png';

const Loader = () => (
  <Wrapper className="loader">
    <img src={BallImage} alt="ball" />
    <img src={OvalImage} alt="oval" className="oval" />
  </Wrapper>
);

export default Loader;
