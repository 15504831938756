import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import Wrapper from './styles';
import SidebarLinkItem from '../../containers/SidebarLinkItem';
import Logo from '../../assets/images/log-0-152-25.svg';
import MyPick from '../../assets/images/ic-my-pick.svg';
import CreatePick from '../../assets/images/plus-icon-white.svg';
import MyStats from '../../assets/images/ic-my-stats.svg';
import IconNew from '../../assets/images/icon-new.png';
import Finance from '../../assets/images/ic-finance.svg';
import UnitReport from '../../assets/images/ic-unit-report.svg';
import Members from '../../assets/images/ic-members.svg';
// import DownloadApp from '../../assets/images/ic-download-app.svg';
import MobileApp from '../../assets/images/ic-punteam-white.svg';
import MessagesWhite from '../../assets/images/ic-messages-white.svg';
import CoolHand from '../../assets/images/cool-hand.svg';
import TraderStatus from '../TraderStatus';

import RecommendNewMember from '../../containers/RecommendNewMember';
import Modal from '../../containers/Modal';
import Sidebarmenu from './SidebarMenu';
import {checkAccess, listOfSection} from "@/helpers/access";

const Sidebar = ({
   recommendModalOpened,
   openRecommendModal,
   recommendModalThankOpened,
   closeRecommendThankModal,
   logOut,
   userLevel,
   unreadMessages,
   hasTerms
}) => {
    const accessData = checkAccess(userLevel, hasTerms);
    return (
        <Wrapper>
            <Link to="/" className="logo-container">
                <img src={Logo} alt="logo"/>
            </Link>
            <div className="content-wrapper">
                <div>
                    <TraderStatus/>
                    <ul>
                        {accessData[listOfSection.MyPicks] ?
                            <>
                                <SidebarLinkItem to="/#create-pick" title="Create New Pick" icon={CreatePick}/>
                                <SidebarLinkItem to="/" title="My Picks" icon={MyPick}/>
                            </>
                             : null}
                        {accessData[listOfSection.MyStats] ?
                            <SidebarLinkItem to="/my-stats" title="My Stats" icon={MyStats}/> : null}
                        {accessData[listOfSection.Finance] ?
                            <SidebarLinkItem to="/finance" title="Finance" icon={Finance}/> : null}
                        {accessData[listOfSection.UnitsReport] ?
                            <SidebarLinkItem to="/units-report" title="Units Report" icon={UnitReport}/> : null}
                        {accessData[listOfSection.Affiliates] ?
                            <>
                                {accessData[listOfSection.MyPicks] ?
                                    <SidebarLinkItem to="/affiliates" title="Affiliates" icon={Members}/>
                                    : <SidebarLinkItem to="/" title="Affiliates" icon={Members}/>
                                }
                            </>
                            : null}
                        {/* {accessData[listOfSection.Affiliates] ?
                            <SidebarLinkItem to="/affiliates" title="Affiliates" icon={Members}/> : null} */}
                        {/* {accessData[listOfSection.DownloadApp] ?
                            <SidebarLinkItem to="/download-app" title="Download App" icon={DownloadApp}/> : null} */}
                        {accessData[listOfSection.MobileApp] ?
                            <SidebarLinkItem to="/mobile-app" title="Mobile App" icon={MobileApp}/> : null}
                        {accessData[listOfSection.Messages] ?
                            <SidebarLinkItem
                                to="/messages"
                                title="Messages"
                                icon={MessagesWhite}
                                unreadMessages={unreadMessages}
                            /> : null}
                        {accessData[listOfSection.Statistics] ?
                          (
                            <div className="new-statistics">
                              <SidebarLinkItem
                                to="/new-statistics"
                                title="In-Play statistics!"
                                icon={MyStats}
                                icon2={IconNew}
                              />
                            </div>
                          )
                            : null}

                    </ul>
                    <button onClick={() => openRecommendModal()} className="recommend-user" type="button">
                        <img src={CoolHand} alt="cool hand"/>
                        <span>Recommend New Member</span>
                    </button>
                </div>
                <Sidebarmenu logout={logOut} enableOnClickOutside eventTypes="click"/>
            </div>
            {recommendModalOpened && <RecommendNewMember/>}
            {recommendModalThankOpened && (
                <div className="thank-wrapper">
                    <Modal className="recommend-modal-thank" modalName="recommendThank">
                        <div className="title-wrapper-thank">
                            <span className="modal-title-thank">Thank you!</span>
                        </div>
                        <div>
              <span className="modal-text-thank">
                Your recommendation has been sent to us and is now waiting for our approval.
                <br/>
                After approval, you will be able to monitor his performance at
                  {' '}
                  <Link to="/">Affiliate Center</Link>
              </span>
                            <button
                                onClick={() => closeRecommendThankModal()}
                                className="modal-link-thank"
                                type="button"
                            >
                                Close
                            </button>
                        </div>
                    </Modal>
                </div>
            )}
        </Wrapper>
    )
};

Sidebar.propTypes = {
    openRecommendModal: PropTypes.func.isRequired,
    closeRecommendThankModal: PropTypes.func.isRequired,
    logOut: PropTypes.func.isRequired,
    recommendModalOpened: PropTypes.bool.isRequired,
    recommendModalThankOpened: PropTypes.bool.isRequired,
    unreadMessages: PropTypes.number.isRequired,
    hasTerms: PropTypes.bool.isRequired
};

export default Sidebar;
