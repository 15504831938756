import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classNames from 'classnames';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Wrapper from './edit-profile-styles';
import FormInput from '../FormInput';
import ProfileImage from './ProfileImage';
import ChangePassword from '@/containers/Profile/ChangePassword';
import { renderOption } from '@/utils/renderHelpers.jsx';
import CloseIcon from '@/assets/images/ic-close';

const EditProfile = ({
  checkEmail,
  changePasswordVisible,
  showChangePassword,
  hideChangePassword,
  handleSubmit,
  countries,
  isEmailApproved,
  resendEmailApproval,
  checkError,
  successEmailChangeText,
  submitting,
  invalid
}) => {
  const onSubmitHandler = async form => {
    if (invalid) return;

    await handleSubmit(form);

    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-modal" style={{minWidth: 320}}>
            <div className="close-icon">
              <button type="button" onClick={onClose}>
                <CloseIcon />
              </button>
            </div>
            <div className="body">
              <p>Profile has been changed</p>
              <div className="buttons">
                <button className="button" onClick={onClose}>Ok</button>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <Wrapper>
      <div className="block">
        <div className="left-block">
          <h1 className="title-user">Edit My Profile</h1>
          <ProfileImage/>
        </div>
        <div className="right-block">
          <div className="top-block">
            <div className="left-info">
              <div className="block-info required">
                <Field name="firstName" type="text" component={FormInput} label="*First Name" />
              </div>
              <div className="block-info required">
                <Field name="lastName" type="text" component={FormInput} label="*Last Name" />
              </div>
              <div className="block-info required">
                <Field name="phone" type="text" component={FormInput} label="*Mobile" />
              </div>
            </div>
            <div className="block-text">
              <div className="error-text">* Required Field</div>
              <h4>Please note:</h4>
              <p>
                Few fields are not editable. If you would like to change of those fields, please
                contact us.
              </p>
            </div>
          </div>

          <div className="all-info">
            <div className="block-info block-info-btn">
              <div className="block-label">What Do You Use?</div>
              <div className="block-checkboxes">
                <Field name="hasWhatsapp" type="checkbox" component={FormInput} label="Whatsapp" />
                <Field name="hasTelegram" type="checkbox" component={FormInput} label="Telegram" />
                <Field name="hasWeChat" type="checkbox" component={FormInput} label="WeChat" />
              </div>
              <div className="block-text" />
            </div>
            <div
              className={classNames({
                'block-info': true,
                error: isEmailApproved && isEmailApproved.isNeedApprovalEmail,
              })}
            >
              <div>
                <Field
                  name="newEmail"
                  type="email"
                  onBlur={() => setTimeout(checkEmail, 100)}
                  component={FormInput}
                  label="Email"
                />
                {successEmailChangeText && (
                  <div className="check-success">{successEmailChangeText}</div>
                )}
                {checkError ? <div className="check-error">{checkError}</div> : <span />}
              </div>
              <div className="block-text email-error">
                {isEmailApproved && isEmailApproved.isNeedApprovalEmail ? (
                  <>
                    <div className="error-text">Email still not approved</div>
                    <button type="button" onClick={resendEmailApproval} className="link-btn">
                      Resend
                    </button>
                  </>
                ) : (
                  <span />
                )}
              </div>
            </div>
            <div className="block-info">
              <Field name="skype" type="text" component={FormInput} label="Skype Address" />
              <div className="block-text" />
            </div>
            <div className="block-info">
              <Field name="addressLine1" type="text" component={FormInput} label="Address - Line 1" />
              <div className="block-text" />
            </div>
            <div className="block-info">
              <Field name="addressLine2" type="text" component={FormInput} label="Address - Line 2" />
              <div className="block-text" />
            </div>
            <div className="block-info">
              <Field name="city" type="text" component={FormInput} label="City" />
              <div className="block-text" />
            </div>
            <div className="block-info">
              <Field name="zip" type="text" component={FormInput} label="ZIP Code" />
              <div className="block-text" />
            </div>
            <div className="block-info">
              <label htmlFor="countryId">Country</label>
              <Field name="countryId" type="text" component="select" id="country">
                {countries
                  && countries.map(country => (
                    <option value={country.id} key={country.id}>
                      {country.name}
                    </option>
                  ))}
              </Field>
              <div className="block-text" />
            </div>
            <div className="block-info">
              <Field
                name="nativeLanguage"
                type="text"
                component={FormInput}
                label="Native Language"
              />
              <div className="block-text" />
            </div>
            <div className="block-info">
              <Field name="favouriteTeam" type="text" component={FormInput} label="Favourite Team" />
              <div className="block-text" />
            </div>
            <div className="block-info block-info-btn">
              <div className="block-label">Birth Date</div>
              <div className="block-input block-input-selects">
                <Field name="dayOfBirth" component="select" id="dayOfBirth">
                  <option value="0" disabled>
                    Day
                  </option>
                  {renderOption(1, 32)}
                </Field>
                <Field name="monthOfBirth" component="select" id="monthOfBirth">
                  <option disabled>Month</option>
                  <option value="0">January</option>
                  <option value="1">February</option>
                  <option value="2">March</option>
                  <option value="3">April</option>
                  <option value="4">May</option>
                  <option value="5">June</option>
                  <option value="6">July</option>
                  <option value="7">August</option>
                  <option value="8">September</option>
                  <option value="9">October</option>
                  <option value="10">November</option>
                  <option value="11">December</option>
                </Field>
                <Field name="yearOfBirth" component="select" id="yearOfBirth">
                  <option disabled value="0">
                    Year
                  </option>
                  {renderOption(1918, 2009)}
                </Field>
              </div>
              <div className="block-text" />
            </div>

            <div className="block-info block-info-btn">
              <div className="block-label" />
              <div className="block-input">
                <button
                  onClick={e => onSubmitHandler(e)}
                  type="button"
                  className="color-btn"
                  disabled={submitting}
                >
                  Submit
                </button>
              </div>
              <div className="block-text" />
            </div>

            <div
              className={classNames({
                'block-info': true,
                'block-info-btn': true,
                'no-margin': changePasswordVisible,
              })}
            >
              <div className="block-label" />
              <div className="block-input">
                <button
                  type="button"
                  className={classNames({
                    'color-border-btn': true,
                    closed: true,
                    opened: !changePasswordVisible,
                  })}
                  onClick={showChangePassword}
                >
                  <span>Click to Change Password</span>
                </button>
              </div>
              <div className="block-text" />
            </div>
            <div className={classNames({ closed: true, opened: changePasswordVisible })}>
              <ChangePassword hide={hideChangePassword} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

EditProfile.defaultProps = {
  isEmailApproved: {},
};

EditProfile.propTypes = {
  changePasswordVisible: PropTypes.bool.isRequired,
  showChangePassword: PropTypes.func.isRequired,
  hideChangePassword: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  countries: PropTypes.array.isRequired,
  isEmailApproved: PropTypes.object,
  resendEmailApproval: PropTypes.func.isRequired,
  checkEmail: PropTypes.func.isRequired,
  checkError: PropTypes.string.isRequired,
  successEmailChangeText: PropTypes.string.isRequired,
};

export default EditProfile;
