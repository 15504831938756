import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PerfectScrollbar from 'perfect-scrollbar';
import Modal from '../Modal/index';
import SearchIcon from '../../assets/images/ic-search';
import LeagueItem from './LeagueItem';
import 'flag-icon-css/css/flag-icon.css';

class newAllowancesModal extends PureComponent {
  prediction;
  countriesScroll;
  leagues;
  leaguesScroll;

  state = {
    country: null,
    search: '',
    countriesVisible: '',
    countrySearchValue: '',
    touchedLeagues: [],
    hasChangedLeague: false,
    showCompleteMessage: false
  };

  async componentDidMount() {
    if (!this.props.requests.length) {
      this.props.getUnitsReportNewAllowanceRequests();
    }
    await this.props.getMemberUnitsReportCountries();
    if (this.props.countries.length) {
      this.setState({
        country: this.props.countries[0]
      });
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { getMemberUnitsReportLeaguesByCountry, leagues } = this.props;
    const { country, search } = this.state;
    if (!this.countriesScroll && country) {
      this.countriesScroll = new PerfectScrollbar(this.prediction, {
        minScrollbarLength: 66,
      });
    }

    if (!this.leaguesScroll && leagues.length) {
      this.leaguesScroll = new PerfectScrollbar(this.leagues, {
        minScrollbarLength: 66,
        suppressScrollX: true,
      });
    }
    if (this.countriesScroll) {
      this.countriesScroll.update();
    }

    if (country) {
      if (!prevState.country || country.id !== prevState.country.id) {
        getMemberUnitsReportLeaguesByCountry(country.id);

        this.setState({
          touchedLeagues: [],
          hasChangedLeague: false
        });
      }
    }

    if (prevState.search !== search) {
      this.searchLeagues();
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.outsideSelecCountryClick);

    if (this.countriesScroll) {
      this.countriesScroll.destroy();
    }
  }

  setCountry = (country) => {
    this.setState({
      country,
      countrySearchValue: ''
    });
  };

  outsideSelecCountryClick = e => {
    if (!this.countrySelect || !this.countrySelect.contains(e.target)) {
      this.setState({
        countriesVisible: false
      });
    }
  };

  toggleCountries = () => {
    this.setState(prevState => {
      const countriesVisible = !prevState.countriesVisible;
      if (countriesVisible) {
        this.searchCountry.focus();
        document.addEventListener('mousedown', this.outsideSelecCountryClick);
      } else {
        this.searchCountry.blur();
        document.removeEventListener('mousedown', this.outsideSelecCountryClick);
      }
      return { countriesVisible };
    });
  }

  handleSearchResultSelection = ({ countryId }) => {
    const country = this.props.countries.find(country => country.id === countryId);
    if (country) {
      this.setState({ country });
    }
    // this.setState({
    //   countryId
    // }, () => {
    //   this.setAllowance(leagueId, false, name);
    // });
  };

  handleInputChange = ({ target }) => {
    const { name, value } = target;
    this.setState({ [name]: value });
  };

  handleSearch = e => {
    e.preventDefault();
    this.searchLeagues();
  };

  searchLeagues = () => {
    const { search } = this.state;
    if (search && search.length >= 2) {
      this.props.getSearchLeagues(search);
    }
  };

  selectAll = checked => {
    const { leagues } = this.props;
    const unrequestedLeagues = leagues.filter(l => !l.isExist);
    const touchedLeagues = unrequestedLeagues.map(l => ({ id: l.id, selected: checked }));

    const hasChangedLeague = leagues.some(league => {
      return touchedLeagues.some(updatedLeague => {
        return updatedLeague.id === league.id && updatedLeague.selected !== league.isExist;
      });
    });

    this.setState({
      touchedLeagues,
      hasChangedLeague
    });
  };

  setAllowanceAll = () => {
    const { country } = this.state;
    const { setNewAllowancesAllLeaguesRequest } = this.props;
    setNewAllowancesAllLeaguesRequest(country.id);
  };

  touchLeague = touchedLeague => {
    const { touchedLeagues } = this.state;
    const touchedIdx = touchedLeagues.findIndex(el => el.id === touchedLeague.id);
    let updatedTouchedLeagues;

    if (touchedIdx === -1) {
      updatedTouchedLeagues = [...touchedLeagues, touchedLeague];
    } else {
      updatedTouchedLeagues = [
        ...touchedLeagues.slice(0, touchedIdx),
        touchedLeague,
        ...touchedLeagues.slice(touchedIdx + 1)
      ];
    }

    const hasChangedLeague = this.props.leagues.some(league => {
      return updatedTouchedLeagues.some(updatedLeague => {
        return updatedLeague.id === league.id && updatedLeague.selected !== league.isExist;
      });
    });

    this.setState({
      touchedLeagues: updatedTouchedLeagues,
      hasChangedLeague
    });
  };

  sendRequest = async () => {
    this.setState({
      hasChangedLeague: false
    });

    const { touchedLeagues, country } = this.state;
    const { setNewAllowanceRequest, leagues } = this.props;

    const changedLeagues =
      touchedLeagues
      .filter(touchedLeague => {
        return leagues.some(league => {
          return touchedLeague.id === league.id && touchedLeague.selected !== league.isExist;
        });
      }).map(({ id, name, selected, note }) => ({
        name,
        countryId: country.id,
        leagueId: id,
        forRemove: !selected,
        note
      }));

    await setNewAllowanceRequest(changedLeagues);
    this.setState({ showCompleteMessage: true });
  };

  render() {
    const {
      countries,
      closeModal,
      requests,
      searchResults,
      leagues
    } = this.props;

    const { country, countriesVisible, countrySearchValue, hasChangedLeague, touchedLeagues, showCompleteMessage } = this.state;
    const showAllCountries = !countrySearchValue.trim();
    const internationalLeagues = leagues.filter(l => l.id < 0);
    const filteredLeagues = leagues.filter(l => !internationalLeagues.find(iL => l.id === iL.id));
    const unrequestedLeagues = leagues.filter(l => !l.isExist);
    const filteredSearch = searchResults.filter(s => !requests.find(r => r.name === s.name));

    const isCheckedAllCheck = unrequestedLeagues.every(league => {
      const touched = touchedLeagues.find(touchedLeague => touchedLeague.id === league.id);
      const selected = touched ? touched.selected : league.isExist;
      return selected;
    });

    return (
      <Modal className="allowances-modal" modalName="allowances" closeModal={closeModal}>
        <form className="search" onSubmit={this.handleSearch}>
          <input
            name="search"
            onChange={this.handleInputChange}
            type="text"
            autoComplete="off"
          />
          <button type="submit">
            <SearchIcon />
          </button>
          <div className="prediction">
            <ul>
              {!!filteredSearch.length
                && filteredSearch.map(result => (
                  <li key={result.leagueId} onClick={() => this.handleSearchResultSelection(result)}>
                    <span>{result.name}</span>
                  </li>
                ))
              }
            </ul>
          </div>
        </form>

        <div className="top-block-info">
          {country && !!Object.keys(country).length && (
            <div className="block-select">
              <div
                className="select select-2"
                onClick={this.toggleCountries}
                ref={el => this.countrySelect = el}
              >
                <div
                  className={classnames('flag-icon flag-icon-squared img-select', {
                    [`flag-icon-${country.code.toLowerCase()}`]: !countrySearchValue
                  })}
                />
                <input
                  ref={el => this.searchCountry = el}
                  className="country-name"
                  type="text"
                  placeholder={country.name}
                  value={countrySearchValue}
                  onChange={e => {
                    this.setState({
                      countrySearchValue: e.target.value
                    })
                  }}
                />
                <div
                  ref={el => this.prediction = el}
                  className={classnames({
                    'select-country': true,
                    'select-country-2': true,
                    visible: countriesVisible
                  })}
                >
                  {countries && countries
                    .filter(c => showAllCountries || c.name.toLowerCase().indexOf(countrySearchValue.toLowerCase()) > -1)
                    .map(c => (
                      <div
                        key={c.id}
                        onClick={() => this.setCountry(c)}
                      >
                        <div className={`flag-icon flag-icon-${c.code.toLowerCase()} flag-icon-squared`} />
                        <span>{c.name}</span>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
          )}

          <label className={classnames('checkbox text-block labeled-checkbox', { disabled: !unrequestedLeagues.length })}>
            <input
              onChange={e => this.selectAll(e.target.checked)}
              checked={isCheckedAllCheck}
              disabled={!unrequestedLeagues.length}
              type="checkbox"
            />
            <span className="checkmark"></span>
            <span className="checkbox-desc">Select All</span>
          </label>
        </div>

        {!!requests.length && (
          <ul className="leagues-list">
            {internationalLeagues.map((league, i) => {
              const touched = touchedLeagues.find(t => t.id === league.id);
              const selected = touched ? touched.selected : league.isExist;
              return (
                <LeagueItem
                  selected={selected}
                  key={`intLeague-${i}`}
                  league={league}
                  international
                  isRequested={league.isExist}
                  // isRequested={!!requests.find(r => r.name === league.name)}
                  onChange={item => this.touchLeague(item)}
                />
              );
            })}
          </ul>
        )}

        {!!leagues.length && (
          <ul
            ref={el => this.leagues = el}
            className={classnames('leagues-list', {
              hidden: !filteredLeagues.length
            })}
          >
            {filteredLeagues.map(league => {
              const touched = touchedLeagues.find(t => t.id === league.id);
              const selected = touched ? touched.selected : league.isExist;
              return (
                <LeagueItem
                  selected={selected}
                  key={`league-${league.id}`}
                  league={league}
                  isRequested={league.isExist}
                  // isRequested={!!requests.find(r => r.name === league.name)}
                  onChange={item => this.touchLeague(item)}
                />
              );
            })}
          </ul>
        )}
        <button type="button" className="button" disabled={!hasChangedLeague} onClick={() => this.sendRequest()}>Send Request</button>

        {showCompleteMessage &&
          <div className="complete-send-message">
            <div className="complete-send-message-frame">
              <div className="title">You request has been submitted</div>
              <button className="button" type="button" onClick={() => closeModal('allowances')}>Ok</button>
            </div>
          </div>
        }
      </Modal>
    );
  }
}

newAllowancesModal.propTypes = {
  countries: PropTypes.array.isRequired,
  leagues: PropTypes.array.isRequired,
  requests: PropTypes.array.isRequired,
  searchResults: PropTypes.array.isRequired,
  closeModal: PropTypes.func.isRequired,
  getMemberUnitsReportLeaguesByCountry: PropTypes.func.isRequired,
  setNewAllowanceRequest: PropTypes.func.isRequired,
  setNewAllowancesAllLeaguesRequest: PropTypes.func.isRequired,
  getMemberUnitsReportCountries: PropTypes.func.isRequired,
  getUnitsReportNewAllowanceRequests: PropTypes.func.isRequired,
  getSearchLeagues: PropTypes.func.isRequired,
};

export default newAllowancesModal;
