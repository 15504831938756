import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import Select from '../ui/form/Select';

const LastForm = ({ options, onChange }) => (
  <div className="lastForm">
    Last
    <Field
      component={Select}
      name="lastMonth"
      id="lastForm-lastMonth"
      wrapperClass="lastMonth"
      options={options}
      onChange={onChange}
      attrValue
    />
    Months
  </div>
);

LastForm.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LastForm;
