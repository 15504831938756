import { reduxForm } from 'redux-form';
import { withProps, compose } from 'recompose';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import MessageForm from '../../components/Messages/MessageForm';
import { sendReply, closeReply, getMailMessages } from '../../store/actions/messages';
import { getSubject } from '../../selectors/messages';

const validate = (values) => {
  const errors = {};

  if (!values.reply) {
    errors.reply = 'Required';
  }

  return errors;
};

const form = reduxForm({
  form: 'messageForm',
  validate,
});

const props = withProps(ownProps => ({
  onSubmit: async (values) => {
    await ownProps.sendReply(ownProps.subject, values.reply, ownProps.parentId);
    ownProps.getMailMessages();
  },
}));

const mapStateToProps = ({ messages }) => ({
  subject: `Re: ${getSubject(messages)}`,
  parentId: messages.replyMessage,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    sendReply,
    closeReply,
    getMailMessages,
  },
  dispatch,
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  props,
  form,
)(MessageForm);
