import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import Affiliates from '../components/Affiliates';
import { getMyMembersBet, getMyRecomendedMembers, getWebTemplate } from '../store/actions/user';
import { getMyAffiliateInfo } from '../store/actions/finance';

const initMystats = lifecycle({
  componentDidMount() {
    this.props.getMyMembersBet();
    this.props.getMyRecomendedMembers();
    this.props.getMyAffiliateInfo();
    if (!this.props.webtemplate.user) {
      this.props.getWebTemplate();
    }
  },
});

const mapStateToProps = ({ user, finance }) => ({
  myMembersBet: user.affiliates.myMembersBet,
  myRecommendedMembers: user.affiliates.recomendedMembers,
  myAffiliateInfo: finance.myaffiliate,
  // userLevel: user.webtemplate.user.userlevel,
  webtemplate: user.webtemplate,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getMyMembersBet,
    getMyRecomendedMembers,
    getMyAffiliateInfo,
    getWebTemplate
  },
  dispatch
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  initMystats
)(Affiliates);
