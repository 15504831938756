import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, withProps, lifecycle } from 'recompose';

import MessageItem from '@/components/Messages/MessageItem';
import { getOpened, getUnreadMessages } from '@/selectors/messages';
import { toggleMessage, showReply, setReadChatMessage } from '@/store/actions/messages';

const mapStateToProps = ({ messages, auth }, { message }) => ({
  utcOffset: auth.utcOffset,
  opened: getOpened(messages, message),
  replyOpened: messages.replyMessage === message.id,
  messages
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    toggleMessage,
    showReply,
    setReadChatMessage
  },
  dispatch
);

const wLifecycle = lifecycle({
  componentDidMount () {
    const unreadMessages = getUnreadMessages(this.props.messages, this.props.messages.activeGroup);
    if (unreadMessages.length) {
      this.props.setReadChatMessage(unreadMessages);
    }
  }
});

const wProps = withProps(ownProps => ({
  replyMessage: () => {
    ownProps.showReply(ownProps.message.id);
  },
}));

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  wLifecycle,
  wProps
)(MessageItem);
