import styled, { injectGlobal } from 'styled-components';

import colors from '../../config/colors';
import fonts from '../../config/fonts';

injectGlobal`
  #react-confirm-alert {
    .react-confirm-alert-overlay {
      background: rgba(0, 0, 0, 0.2);
      z-index: 9999;
    }

    .confirm-modal {
      background: #fff;
      padding: 43px 15px 15px;
      border: 1px solid #e6e6e6;
      box-shadow: 0 3px 10px 0 rgba(0,0,0,0.1);
      position: relative;

      .body {
        font-size: 16px;
        line-height: 1.5em;
        font-weight: 600;
        color: #38415f;

        p {
          margin: 0 0 1.5em;
        }
      }

      .close-icon {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;

        button {
          border: 0;
          background-color: rgba(0, 0, 0, 0);
          cursor: pointer;
          padding: 0;
          width: 24px;
          height: 24px;
        }

        &:hover g {
          stroke: #4a90e2;
        }

        &:active g,
        &:focus g {
          fill: #4a90e2;

          & > g {
            stroke: #fff;
          }
        }
      }

      .button {
        border: 0;
        cursor: pointer;
        height: 40px;
        min-height: 40px;
        background-image: linear-gradient(107deg, #8e70ee, #4a7ece);
        box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
        font-size: 15px;
        color: #fff;
        outline: none;
        margin-left: 15px;
        padding: 0 15px;

        &:hover {
          background-image: linear-gradient(105deg, #7b57ee, #306ece);
        }

        &:active,
        &:focus {
          background-image: linear-gradient(105deg, #6952b3, #2558a8);
        }
        &[disabled],
        &[disabled]:hover {
          cursor: default;
          background-image: linear-gradient(107deg, #8e70ee, #4a7ece);
          opacity: 0.7;
        }
      }

      .buttons {
        text-align: right;
      }
    }
  }
`;

export default styled.div`
  .cursor-pointer {
    cursor: pointer;
  }

  .units-explanation-section {
    margin-top: 20px;
  }

  .expand {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    width: 97%;

    span {
      margin-right: 15px;
      font-size: 12px;
      color: ${colors.lightBlue};
      font-weight: 600;
    }

    button {
      background-color: rgba(0, 0, 0, 0);
      border: 0;
      display: flex;
      align-items: center;
      outline: none;
      cursor: pointer;

      &:hover {
        span {
          text-decoration: underline;
          color: ${colors.thirdBlue};
        }

        g {
          stroke: ${colors.thirdBlue};
        }
      }

      &:active,
      &:focus {
        span {
          text-decoration: underline;
          color: ${colors.mainBlue};
        }

        g {
          stroke: ${colors.mainBlue};
        }
      }
    }
  }

  .units-actions-container {
    display: flex;
    justify-content: space-between;

    .allowances-part {
      margin-left: 15px;
    }
    .units-actions-tabs-container {
      border-right: 1px solid #8080805c;
      padding-right: 15px;
    }
  }

  .units-actions-tabs-and-allowance {
    display: flex;
    flex-wrap: wrap;
  }

  .units-explanation {
    color: #38415fd4;
    font-family: proxima-nova, Georgia, sans-serif;
    font-size: 16px;
  }

  .units-explanation-content {
    max-width: 1160px;
    margin: 0 auto;
  }

  .units-explanation-table-example {
    font-size: 14px;
  }

  .units-explanation-table .units-explanation-table-header-col   {
    font-weight: bold;
    color: black;
  }

  .units-explanation-table-col {
    outline: 1px solid grey;
    padding: 2px 5px;
    font-weight: normal;
    font-family: proxima-nova,Georgia,sans-serif;
    font-size: 15px;
    color: #38415f;
  }

  .lastForm {
    color: ${colors.secondaryBlue};
    font-weight: 600;
    margin: 30px 0;

    .lastMonth {
      margin: 0 10px;

      select {
        min-width: 38px;
        background-image: none;

        &[data-value='1'] {
          padding: 0 11px 0 15px;
        }

        &[data-value='2'],
        &[data-value='3'],
        &[data-value='4'],
        &[data-value='5'],
        &[data-value='6'],
        &[data-value='7'],
        &[data-value='8'],
        &[data-value='9'],
        &[data-value='11'] {
          padding: 0 12px 0 14px;
        }

        &[data-value='10'],
        &[data-value='12'] {
          padding: 0 14px 0 12px;
        }
      }
    }
  }

  .main-table {
    margin-top: 30px;
    display: block;
    max-width: 97%;
    overflow-x: auto;
    max-height: 236px;

    // width: calc(100vw - 278px);

    table {
      border-bottom: solid 1px ${colors.lightGray_2};
      display: table;
      text-align: center;
      width: 100%;

      table {
        border-bottom: 0;
      }

      & > tbody {
        tr {
          td:first-child {
            border-left: solid 1px ${colors.lightGray_2};
          }
          td:last-child {
            border-right: solid 1px ${colors.lightGray_2};
          }
        }
        tr:first-child {
          td {
            border-top: solid 1px ${colors.lightGray_2};
          }
        }
      }
    }

    .title-table {
      .tds-table:first-child .td-table {
        font-family: ${fonts.proximaNova};
        font-size: 15px;
        font-weight: 600;
        color: ${colors.secondaryBlue};
      }
      th {
        white-space: nowrap;

        &:nth-child(1),
        &:nth-child(2) {
          text-align: left;
        }
        &:last-child {
          padding-right: 18px;
          text-align: right;
        }
      }
    }

    .tr-table {
      &.open + .tr-table-colspan {
        display: table-row;

        & > td {
          background-color: ${colors.white};
          padding: 30px 20px;
        }
      }

      td {
        background-color: ${colors.lightGray_3};
        font-size: 13px;

        &:nth-child(1),
        &:nth-child(2) {
          text-align: left;
        }
        &:last-child {
          padding-right: 32px;
          text-align: right;
          width: 20%;
        }
      }

      &:nth-child(4n + 3) {
        td {
          background-color: ${colors.lightGray_7};
        }
      }

      &.tr-table-colspan {
        .title-table {
          th {
            font-size: 12px;
            line-height: 2.3;
            width: 10%;

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4)
            /* &:nth-child(5) */ {
              text-align: center;
            }

            &:nth-child(2) {
              width: 15%;
            }
            &:last-child {
              padding-right: 4px;
            }
          }
        }

        .tr-table {
          &.open + .tr-table-colspan {
            display: table-row;

            & > td {
              background-color: ${colors.white};
              padding-top: 30px;
            }
          }

          &.tr-table-colspan {
            .title-table th {
              font-size: 13px;
              line-height: 1.2;
              width: auto;

              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3),
              &:nth-child(4) {
                text-align: left;
              }

              &:first-child {
                cursor: default;
                padding-left: 23px;
                width: auto;
              }

              &:last-child {
                padding-right: 10px;
              }
            }
            .tr-table {
              td {
                background-color: ${colors.white};
                border: 0;
                border-top: solid 1px ${colors.lightGray_5};
                font-size: 12px;
                line-height: 3.6;
                width: auto;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                  text-align: left;
                }

                &:first-child {
                  cursor: default;
                  padding-left: 23px;
                  width: auto;
                }

                &:last-child {
                  width: 18%;
                }
              }

              &:last-child {
                td {
                  border-bottom: solid 2px ${colors.lightBlue};
                }
              }
            }
          }

          td {
            background-color: ${colors.lightYellow_1};
            border: 0;
            border-bottom: 1px solid ${colors.black_2};
            font-size: 13px;
            line-height: 2.3;
            width: 10%;

            &:nth-child(2) {
              width: 15%;
            }

            &:nth-child(2),
            &:nth-child(3) {
              text-align: center;
            }

            &:first-child {
              width: 36px;
            }

            &:last-child {
              padding-right: 11px;
              width: 17%;
            }
          }

          &:nth-child(4n + 3) {
            td {
              background-color: ${colors.white};
            }
          }
        }

        &:last-child > td {
          border-bottom: none;
        }
      }
    }
    .td-table {
      color: ${colors.secondaryBlue};
      font-family: ${fonts.proximaNova};
      font-size: 15px;
      font-weight: 600;
      line-height: 3.54;
      padding-left: 9px;
      padding-right: 9px;
      position: relative;
      white-space: nowrap;


      &:first-child {
        padding-left: 43px;

        svg {
          left: 18px;
          margin-top: -8px;
          position: absolute;
          top: 50%;
        }
      }

      svg {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .tr-table-opacity .td-table {
      color: ${colors.lightGray_5};

      &:first-child {
        svg {
          opacity: 0.36;
        }
      }
    }

    .tr-table.tr-table-color {
      .td-table {
        background-color: ${colors.lightBlue_6} !important;
      }
    }

    .tr-table-colspan {
      display: none;
    }
  }

  .allowances-part {
    max-width: 100%;
    overflow-x: auto;
    padding-top: 10px;
    width: 100%;
    overflow-x: visible;

    table {
      border-bottom: solid 1px ${colors.lightGray_2};
      border-collapse: collapse;
      border-spacing: 0;
      // min-width: 100%;

      & > tbody {
        tr {
          td:first-child {
            border-left: solid 1px ${colors.lightGray_2};
          }
          td:last-child {
            border-right: solid 1px ${colors.lightGray_2};
          }
        }

        tr:first-child {
          td {
            border-top: solid 1px ${colors.lightGray_2};
          }
        }

        tr:nth-child(odd) {
          td {
            background-color: ${colors.lightGray_2};
          }
        }

        tr.red {
          td {
            background-color: ${colors.red_2};
          }
        }
      }

      th {
        border: 0;
        color: ${colors.secondaryBlue};
        font-family: ${fonts.proximaNova};
        font-size: 15px;
        font-weight: 600;
        line-height: 1.1;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: left;
        white-space: nowrap;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 22px;
          text-align: right;
          // width: 5%;
        }

        // &:nth-child(3) {
        //   width: 30%;
        // }
      }
      td {
        background-color: ${colors.lightGray_7};
        border: 0;
        color: ${colors.secondaryBlue};
        font-family: ${fonts.proximaNova};
        font-size: 16px;
        line-height: 1.5em;
        padding-top: 6px;
        padding-bottom: 6px;
        font-weight: 600;
        /* line-height: 2.88; */
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          /* padding-right: 20px; */
          text-align: right;
          // width: 5%;
        }

        // &:nth-child(3) {
        //   width: 30%;
        // }

        svg {
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }

  .section-title{
    font-size: 18px;
    font-weight: 600;
    margin: 2px 0 -5px 19px;
    color: #3c4144;
  }

.requests-part {
    max-width: 100%;
    overflow-x: auto;
    width: 100%;
    overflow-x: visible;

    table {
      border-bottom: solid 1px ${colors.lightGray_2};
      border-collapse: collapse;
      border-spacing: 0;
      min-width: 100%;

      & > tbody {
        tr {
          td:first-child {
            border-left: solid 1px ${colors.lightGray_2};
          }
          td:last-child {
            border-right: solid 1px ${colors.lightGray_2};
          }
        }

        tr:first-child {
          td {
            border-top: solid 1px ${colors.lightGray_2};
          }
        }

        tr:nth-child(odd) {
          td {
            background-color: ${colors.lightGray_2};
          }
        }

        tr.red {
          td {
            background-color: ${colors.red_2};
          }
        }
      }

      th {
        border: 0;
        color: ${colors.secondaryBlue};
        font-family: ${fonts.proximaNova};
        font-size: 15px;
        font-weight: 600;
        line-height: 1.1;
        padding-bottom: 20px;
        padding-left: 10px;
        padding-right: 10px;
        text-align: left;
        white-space: nowrap;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 22px;
          text-align: right;
          width: 5%;
        }

        &:nth-child(3) {
          width: 30%;
        }
      }
      td {
        background-color: ${colors.lightGray_7};
        border: 0;
        color: ${colors.secondaryBlue};
        font-family: ${fonts.proximaNova};
        font-size: 16px;
        font-weight: 600;
        line-height: 2.88;
        padding-left: 10px;
        padding-right: 10px;
        white-space: nowrap;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 32px;
          text-align: right;
          width: 5%;
        }

        svg {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
      }
    }
  }
  .button {
    border: 0;
    cursor: pointer;
    float: right;
    min-width: 249px;
    height: 40px;
    min-height: 40px;
    background-image: linear-gradient(107deg, #8e70ee, #4a7ece);
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    font-size: 15px;
    color: #fff;
    outline: none;

    &:hover {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:active,
    &:focus {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }
    &[disabled],
    &[disabled]:hover {
      cursor: default;
      background-image: linear-gradient(107deg, #8e70ee, #4a7ece);
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 1024px) {
    .main-table,
    .allowances-part {
      max-width: calc(100vw - 278px);
    }
    .allowances-part {
      td,
      th {
        width: auto !important;
      }
    }
    button.request-btn {
      margin-top: 20px;
    }
  }

  .report-modal {
    box-shadow: 0 0 15px 0 rgba(48, 2, 104, 0.2);
    background-color: #ffffff;
    width: calc(300px - 34px);
    padding: 11px 17px 17px;
    position: absolute;
    border-radius: 5px;

    &:before {
      content: '';
      border: 6px solid #fff;
      display: inline-block;
      border-top-color: rgba(0, 0, 0, 0);
      border-right-color: rgba(0, 0, 0, 0);
      border-bottom-color: rgba(0, 0, 0, 0);
      position: absolute;
      right: -12px;
      bottom: 11px;
    }

    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      color: ${colors.secondaryBlue};
      font-weight: bold;

      & > button {
        background-color: rgba(0, 0, 0, 0);
        border: 0;
        position: relative;
        top: -4px;
        right: -12px;
      }
    }

    form textarea {
      border-radius: 4px;
      border: solid 1px ${colors.lightGray_1};
      background-color: #ffffff;
      height: 118px;
      width: 100%;
      font-size: 13px;
      padding: 10px;
      resize: none;
      margin-bottom: 12px;
      color: ${colors.secondaryBlue};

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #d1d7e9;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #d1d7e9;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #d1d7e9;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #d1d7e9;
      }
    }

    form button {
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      background-image: linear-gradient(99deg, #8e70ee, #4a7ece);
      width: 100%;
      height: 40px;
      border: none;
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      font-family: ${fonts.proximaNova};

      &:hover {
        background-image: linear-gradient(105deg, #7b57ee, #306ece);
      }

      &:focus {
        outline: none;
      }

      &:active {
        background-image: linear-gradient(105deg, #6952b3, #2558a8);
      }

      &:disabled {
        opacity: 0.5;
      }
    }

    button {
      cursor: pointer;
    }
  }

  .opened-modal {
    path {
      fill: ${colors.thirdBlue};
    }

    circle {
      stroke: ${colors.thirdBlue};
    }
  }

  .allowances-modal{
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      align-items: center;
      padding-top: 43px;
      max-height: 668px;
      max-width: 740px;
      position: relative;

      .complete-send-message {
        position: absolute;
        z-index: 10;
        top: -1px;
        left: -1px;
        right: -1px;
        bottom: -1px;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-content: center;
        align-items: center;

        .complete-send-message-frame {
          width: 400px;
          padding: 20px;
          text-align: center;
          background: #fff;

          .title {
            font-size: 20px;
            font-weight: 600;
            color: #38415f;
            margin: 0 0 20px;
          }

          .button {
            float: none;
          }
        }
      }

      .checkbox {
        display: block;
        position:relative;
        min-width: 24px;
        min-height: 24px;
        &.labeled-checkbox {
          padding-left: 35px;
          .checkbox-desc {
            font-family: ${fonts.proximaNova};
            font-weight: 700;
            color: ${colors.secondaryBlue};
            font-size: 14px;
            line-height: 2;
          }
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 24px;
          height: 24px;
          border: 1px solid ${colors.lightGray_1};
          background: ${colors.white};
        }
        &.disabled::after {
          content: '';
          background: ${colors.lightGray_8};
        }
        .checkmark {
          display: none;
          position: absolute;
          border-bottom: 3px solid ${colors.thirdBlue};
          border-left: 3px solid ${colors.thirdBlue};
          top: 7px;
          left: 4px;
          width: 14px;
          height: 6px;
          transform: rotate(-45deg);
          z-index: 1;
        }
        input {
          display: none;
          &:checked + .checkmark {
            display: block;
          }
        }
      }
      .search{
        width: 100%;
        position: relative;
        margin-top: 15px;
         input {
          width: 100%;
          height: 40px;
          border: 1px solid ${colors.lightGray_1};
          font-size: 14px;
          color: #38415f;
          border-radius: 4px;
          padding: 1px 13px;
          outline: none;
          &:focus,
          &:active {
            & ~ .prediction {
              visibility: visible;
              opacity: 1;
            }
          }
        }
        button {
          height: 40px;
          background: rgba(0,0,0,0);
          border: 0;
          position: absolute;
          padding: 0;
          right: 13px;
          z-index: 9;
          cursor: pointer;
          &:focus{
            outline: none;
          }
        }
        .prediction {
          max-height: 351px;
          overflow-y: auto;
          background-color: ${colors.white};
          box-shadow: 0 2px 4px 0 ${colors.black_1};
          visibility: hidden;
          opacity: 0;
          position: absolute;
          top: 40px;
          width: 100%;
          transition: visibility 0.4s ease;
          z-index: 9999;

          ul {
            list-style: none;
            margin: 0;
            padding: 0;
            max-width: 100%;

            li {
              font-size: 14px;
              font-weight: normal;
              color: ${colors.secondaryBlue};
              height: 44px;
              line-height: 44px;
              padding: 0 9px;
              position: relative;
              cursor: pointer;
              max-width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &:last-child:after {
                display: none;
              }

              &:after {
                content: '';
                height: 1px;
                width: calc(100% - 18px);
                background-color: ${colors.lightGray_6};
                position: absolute;
                left: 9px;
                bottom: 0;
              }

              span {
                font-weight: 600;
                padding: 0 3px;
              }
            }
          }
        }
      }
      //  .select-country {
      //   margin-top: 18px;
      //   width: 353px;
      //   height: 58px;
      //   line-height: 22px;
      //   vertical-align: middle;
      //   position: relative;
      //   background: white;
      //   // border: 1px solid #ccc;
      //   overflow: hidden;
      //   display: flex;
      //   align-items: center;
      //   select {
      //     background: transparent;
      //     height: 58px;
      //     min-width: 353px;
      //     padding-left: 62px;
      //     font-family: ${fonts.proximaNova};
      //     font-size: 14px;
      //     font-weight: 600;
      //     color: #38415f;
      //     border: solid 1px ${colors.lightGray_1};
      //     outline: none;
      //     appearance: none;
      //     background-repeat: no-repeat;
      //     background-position-x: calc(100% - 9px);
      //     background-position-y: center;
      //     background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23818FA9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");
      //
      //   &:hover {
      //       border-color: #a5c8f1;
      //       background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23A5C8F1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");
      //     }
      //
      //   &:active,
      //   &:focus {
      //       border-color: #4a90e2;
      //       background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='15' viewBox='0 0 7 15'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%234A90E2' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 5l2.5-3L6 5M1 10l2.5 3L6 10'/%3E%3C/g%3E%3C/svg%3E%0A");
      //     }
      //
      //   }
      // }
      .top-block-info{
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
        min-height: 110px;
        .checkbox{
          align-self: flex-start;
          margin-top: 5px;
          margin-left: 12px;
        }
      }

      // .select-country::after {
      //   content: '▼';
      //   font-size: 0.5em;
      //   font-family: arial;
      //   position: absolute;
      //   top: 50%;
      //   right: 5px;
      //   transform: translate(0, -50%);
      // }



      // .select-country .options .option input:checked + label {
      //   display: flex;
      //   align-items: center;
      //   width: 100%;
      //
      //
      //   span {
      //     width: 37px;
      //     height: 37px;
      //     margin-left: 10px;
      //   }
      // }
      //
      // .select-country:hover .options .option input + label {
      //   display: block;
      //   height: 100%;
      //   display: flex;
      //   align-items: center;
      //   cursor: pointer;
      //   font-family: ${fonts.proximaNova};
      //   color: #38415f;
      //   font-size: 14px;
      //   font-weight: 600;
      // }
      //
      // .select-country:hover .options .option input:checked + label {
      //   background: rgba(74, 144, 226, 0.26);
      //
      //   span {
      //     width: 16px;
      //     height: 16px;
      //   }
      // }
      .leagues-list {
        position: relative;
        width: 100%;
        overflow: hidden;
        list-style: none;
        padding: 0;
        border: 1px solid ${colors.lightGray_2};
        min-height: 46px;

        &.hidden {
          display: none;
        }

        .league {
          display: flex;
          align-items: center;
          height: 46px;
          width: 100%;
          padding: 0 12px;
          background: ${colors.white};
          &:nth-child(2n) {
            background: ${colors.lightGray_3};
          }
          &.league-selected{
          background: ${colors.lightBlue_4};
          }
          &.league-request{
          background: ${colors.red_1};
          }
          & > * {
             display: inline;
             margin-right: 5px;
          }
          .title {
            margin-left: 5px;
            font-family: ${fonts.proximaNova};
            font-size: 13px;
            color: #39415f;
            font-weight: 700;
          }
          .note {
            margin-left: auto;
            width: 200px;
            padding: 5px;
            outline: none;
            border: 1px solid #dbdbdb;
            font-size: 14px;
            color: #38415f;
            border-radius: 4px;

            &[readonly],
            &[disabled] {
              cursor: default;
              opacity: 0.6;
            }
          }
        }
      }
    }
     .select-country {
    width: 353px;
    height: 58px;
    line-height: 22px;
    vertical-align: middle;
    position: relative;
    background: white;
    // border: 1px solid #ccc;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .select-2 {
    height: 58px;
    min-width: 353px;
    border: 1px solid #dbdbdb;
    margin-top: 10px;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 9px);
    background-position-y: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23818FA9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");
    cursor: pointer;

    .country-name {
      font-family: ${fonts.proximaNova};
      font-size: 14px;
      font-weight: 600;
      color: #38415f;
      background: transparent;
      cursor: pointer;

      outline: none;
      border: 0;
      padding: 22px 0 0 62px;
      margin: 0;
      width: 100%;

      &::-webkit-input-placeholder {
        color: #38415f;
      }

      &:-ms-input-placeholder {
        color: #38415f;
      }

      &::placeholder {
        color: #38415f;
      }
    }

    .ps__rail-x {
      visibility: hidden;
    }
  }

  .tooltip-field {
    display: flex;
    margin-bottom: 15px;

    label {
      margin: 0;
    }

    input[type=text] {
      margin-right: 5px;
      width: 54px;
      height: 24px;
      color: #38415f;
      border: 1px solid #dbdbdb;
      padding: 0 5px;
      font-family: inherit;
      font-size: 20px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
    }

    .tooltip-text {
      position: relative;
      bottom: 3px;
      font-size: 13px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.46;
      color: #38415f;
    }
  }

  .select-country-2 {
    z-index: 9;
    position: absolute;
    top: 58px;
    left: 0;
    top: 58px;
    width: 350px;
    max-height: 200px;
    height: auto;
    overflow-y: hidden;
    font-family: ${fonts.proximaNova};
    font-size: 14px;
    font-weight: 600;
    color: #38415f;
    outline: none;
    appearance: none;
    flex-wrap: wrap;
    background-color: white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    display: none;

    &:hover {
      overflow-y: hidden !important;
    }

    .ps__rail-y {
      width: 10px !important;
    }

    > div {
      cursor: pointer;
      width: 100%;
      height: 48px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-bottom: solid 1px rgba(151, 151, 151, 0.22);

      .flag-icon {
        width: 37px;
        height: 37px;
        border-radius: 100%;
        margin-right: 12px;
        margin-left: 12px;
      }
    }
  }

  .select-country-2.visible {
    display: block !important;
  }

  // .select-country::after {
  //   content: '▼';
  //   font-size: 0.5em;
  //   font-family: arial;
  //   position: absolute;
  //   top: 50%;
  //   right: 5px;
  //   transform: translate(0, -50%);
  // }

  .select-country:hover::after {
    content: '';
  }

  .select-country:hover {
    overflow: visible;
  }

  .select-country:hover .options .option label {
    display: inline-block;
  }

  .select-country:hover .options {
    background: white;
    border: 1px solid #ccc;
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 200px;
    overflow-y: scroll;
  }

  .select-country .options .option {
    overflow: hidden;
    cursor: pointer;

    span {
      margin-right: 12px;
      border-radius: 50%;
    }
  }

  .select-country:hover .options .option {
    height: 30px;
    overflow: hidden;
    padding: 5px;
  }

  .select-country .options .option img {
    vertical-align: middle;
  }

  .select-country .options .option label {
    display: none;
  }

  .select-country .options .option input {
    width: 0;
    height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    float: left;
    display: inline-block;
    /* fix specific for Firefox */
    position: absolute;
    left: -10000px;
  }

  .select-country .options .option input:checked + label {
    display: flex;
    align-items: center;
    width: 100%;


    span {
      width: 37px;
      height: 37px;
      margin-left: 10px;
    }
  }

  .select-country:hover .options .option input + label {
    display: block;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-family: ${fonts.proximaNova};
    color: #38415f;
    font-size: 14px;
    font-weight: 600;
  }

  .select-country:hover .options .option input:checked + label {
    background: rgba(74, 144, 226, 0.26);

    span {
      width: 16px;
      height: 16px;
    }
  }
  .block-select {
    text-align: center;


    .select {
      display: inline-block;
      position: relative;
      text-align: left;

      select {
        height: 58px;
        min-width: 353px;
        padding-left: 62px;
        font-family: ${fonts.proximaNova};
        font-size: 14px;
        font-weight: 600;
        color: #38415f;
        border: solid 1px #dbdbdb;
        outline: none;
        appearance: none;
        background-repeat: no-repeat;
        background-position-x: calc(100% - 9px);
        background-position-y: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23818FA9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");

        &:hover {
          border-color: #a5c8f1;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23A5C8F1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");
        }

        &:active,
        &:focus {
          border-color: #4a90e2;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='15' viewBox='0 0 7 15'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%234A90E2' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 5l2.5-3L6 5M1 10l2.5 3L6 10'/%3E%3C/g%3E%3C/svg%3E%0A");
        }

      }
    }

    .img-select {
      background-color: ${colors.lightGray};
      background-position: center;
      background-size: cover;
      border-radius: 100%;
      left: 13px;
      position: absolute;
      top: 10px;
      height: 37px;
      width: 37px;
      z-index: 1;
    }
  }

  .btn-remove-allowance {
    border: 0;
    background: ${colors.red};
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 5px 6px 5px 4px;
    outline: none;
    cursor: pointer;

    img {
      width: 100%;
      height: auto;
    }
  }
`;
