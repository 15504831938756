import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Wrapper from './styles';

class FooterLinkItem extends PureComponent {
  static propTypes = {
    to: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    linkClass: PropTypes.string,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    linkClass: null,
  };

  render() {
    const {
      to, title, linkClass, location,
    } = this.props;
    const active = location.pathname === to;
    const linkClasses = classNames({ [linkClass]: true, active });

    return (
      <Wrapper to={to} className={linkClasses}>
        {title}
      </Wrapper>
    );
  }
}

export default FooterLinkItem;
