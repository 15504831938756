/**
 * External dependencies
 */
import { all } from 'redux-saga/effects';

/**
 * Internal dependencies
 */
import { WatchInPlayGamesLoading } from '@/store/sagas/games-in-play-statistics-saga';
import { WatchMatchSelection } from '@/store/sagas/selected-match-results-statistics-saga';

export default function* RootSaga() {
  yield all([
    WatchInPlayGamesLoading(),
    WatchMatchSelection()
  ]);
}
