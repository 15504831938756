import { reduxForm } from 'redux-form';
import { withProps, compose } from 'recompose';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ComposeRightColumn from '../../components/Messages/ComposeRightColumn';
import {
  sendReply,
  closeReply,
  getMailMessages,
  closeComposeMessage,
} from '../../store/actions/messages';

const validate = (values) => {
  const errors = {};

  if (!values.message) {
    errors.message = 'Required';
  }

  if (!values.subject) {
    errors.subject = 'Required';
  }

  return errors;
};

const form = reduxForm({
  form: 'composeMessage',
  validate
});

const props = withProps(ownProps => ({
  onSubmit: async (values) => {
    await ownProps.sendReply(values.subject, values.message);
    ownProps.getMailMessages(true);
    ownProps.closeComposeMessage();
  },
}));

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    sendReply,
    closeReply,
    getMailMessages,
    closeComposeMessage,
  },
  dispatch,
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  props,
  form,
)(ComposeRightColumn);
