import React, { Fragment } from 'react';

import Content from './styles';
import DownloadIcon from '../../assets/images/ic-download.svg';
import AppleIcon from '../../assets/images/ic-ios.png';
import AndroidIcon from '../../assets/images/icon-android.png';

const DownloadApp = () => (
  <Fragment>
    <Content>
      <h1>Download App</h1>
      <h2>
        We encourage all members to download our app,
        {' '}
        <br />
        {' '}
this makes sending picks and
        communicating with traders much easier when on-the-go!
      </h2>
      <div className="apps-wrapper">
        <div className="app ios-app">
          iPhone App
          <a
            href="https://ios.punteam.com/PunteamSM/IOS/iosdownload.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DownloadIcon} alt="download icon" />
            Download
          </a>
          <img src={AppleIcon} alt="apple icon" />
        </div>
        <div className="app android-app">
          Android App
          <a
            href="https://play.google.com/store/apps/details?id=com.punteam.pt"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={DownloadIcon} alt="download icon" />
            Download
          </a>
          <img src={AndroidIcon} alt="apple icon" />
        </div>
      </div>
      <div className="note">
        <p>
          <b>Note for iPhone users:</b>
          <br />
          If you have tried to access the iPhone, you may have had a problem regarding the need to
          'Trust' the app.
          <br />
          The reason this is needed is because it's a direct app via a link, not via the app store.
          <br />
          You have to ensure you trust it before you are allowed to access it, as it's a private
          app.
        </p>
        <p>
          The link below explains how to install Enterprise apps and then 'Trust' them on the iPhone
          -
          <br />
          <a
            href="http://www.imore.com/how-install-enterprise-apps-ios-9"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.imore.com/how-install-enterprise-apps-ios-9
          </a>
        </p>
        <p>
          If you do not want to use the link (though, the link has screenshots which will help the
          process), then here are the steps copied from the link above:
        </p>
        <p className="how-to">
          <b>How to trust Enterprise apps on iPhone or iPad</b>
        </p>
        <ol>
          <li>
            Launch
            {' '}
            <b>Settings</b>
            {' '}
from your Home Screen.
          </li>
          <li>
            Tap on
            {' '}
            <b>General</b>
.
          </li>
          <li>
            Tap on
            {' '}
            <b>Profiles</b>
.
            {' '}
          </li>
          <li>
            Tap the
            {' '}
            <b>name of the distributor (for us, it's A.W. RuchTech LTD)</b>
            {' '}
under the
            Enterprise App section.
          </li>
          <li>
            Tap to
            {' '}
            <b>trust</b>
.
          </li>
          <li>
            Tap to
            {' '}
            <b>confirm</b>
.
          </li>
        </ol>
        <p>
          Only then, when it's made super-double-extra sure you really want to trust an app, will
          iOS set it as trusted. And only then can you launch it.
        </p>
      </div>
    </Content>
  </Fragment>
);

export default DownloadApp;
