import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import BtnTrashIcon from '../../assets/images/ic-btn-trash';

const ComposeRightColumn = ({ handleSubmit, closeComposeMessage }) => (
  <div className="right-column right-column-compose">
    <form onSubmit={handleSubmit}>
      <div className="right-header">
        <div className="search">
          <Field component="input" name="subject" placeholder="Subject" />
        </div>
      </div>

      <div className="block-message">
        <div className="message-item answer-block-open open">
          <div className="main-block">
            <div className="answer-block">
              <Field component="textarea" autoFocus name="message" />
              <div className="bottom-block">
                <button className="send-btn" type="submit">
                  Send
                </button>
                <div className="btn" onClick={closeComposeMessage} role="main">
                  {' '}
                  <BtnTrashIcon />
                  {' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
);

ComposeRightColumn.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  closeComposeMessage: PropTypes.func.isRequired,
};

export default ComposeRightColumn;
