/**
 * API urls
 */
// place to edit for testing with mocked data
// export const BET365_API_INPLAY = '_fake_api/bet365_inplay.json';
export const BET365_API_INPLAY = 'https://api.betsapi.com/v1/bet365/inplay?token=34404-vPEIXqYSZZXjH9';
// export const RESULTS_API = '_fake_api/bet365_result.json';
export const RESULTS_API = 'https://api.betsapi.com/v1/bet365/result?token=34404-vPEIXqYSZZXjH9&event_id=';
// export const INPLAY_EVENT_API = '_fake_api/bet365_inplay-event.json';
export const INPLAY_EVENT_API = 'https://api.betsapi.com/v1/bet365/event?token=34404-vPEIXqYSZZXjH9';
// export const HARDCODED_RESULT_ID = 233630;

/**
 * Messages
 */
export const GET_LEAGUE_ERROR = 'Options error: required option "gamesInPLay" is empty or undefined';

/**
 * Sports names
 */
export const SOCCER = 'Soccer';

/**
 * Codes
 */
export const NAME = 'NA';
export const LEAGUE = 'CT';
export const RELATED_LEAGUE = 'CT'
export const SPORT = 'CL';
export const ID = 'ID';
export const EVENT_ID = 'ID'

/**
 * History events
 */
export const CORNER_RAW = 'corner';
export const CORNER = 'CORNER';
export const GOAL_RAW = 'goal';
export const GOAL = 'GOAL';
export const YC_RAW = 'yellow card';
export const YC = 'YELLOW_CARD';
export const RC_RAW = 'red card';
export const RC= 'RED_CARD';
export const PENALTY_RAW = 'penalty';
export const PENALTY = 'PENALTY';

/**
 * Event in play codes
 */
export const COLOR_KIT = 'KC';
export const TEAM = 'TE';

/**
 * Colors
 */
export const setOfDefaultColors = {
  primaryColorHome: '#EB543E',
  secondaryColorHome: '#366fb1',
  primaryColorAway: '#FDD92A',
  secondaryColorAway: '#50c14e'
};
