import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Wrapper from './styles';

class HeaderLinkItem extends PureComponent {
  static propTypes = {
    image: PropTypes.string.isRequired,
    hoveredImage: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    imageClass: PropTypes.string,
    linkClass: PropTypes.string,
    to: PropTypes.string,
    disableLink: PropTypes.bool,
    children: PropTypes.node,
    location: PropTypes.object.isRequired,
  };

  static defaultProps = {
    imageClass: null,
    linkClass: null,
    disableLink: false,
    children: null,
    to: null,
  };

  render() {
    const {
      to,
      image,
      hoveredImage,
      title,
      imageClass,
      linkClass,
      disableLink,
      children,
      location,
      alertsCount,
    } = this.props;
    const active = location.pathname === to;
    const linkClasses = classNames({
      [linkClass]: true,
      [imageClass]: location.pathname === to,
      active: location.pathname === to,
    });

    return disableLink ? (
      <Wrapper.DivStyled className={linkClasses}>
        <img src={active ? hoveredImage : image} className={imageClass} alt={to} />
        {title}
        {children}
      </Wrapper.DivStyled>
    ) : (
      <Wrapper.LinkStyled to={to} className={linkClasses}>
        <img src={active ? hoveredImage : image} className={imageClass} alt={to} />
        {title}
        &nbsp;
        {alertsCount && <div className="alerts-count">{alertsCount}</div>}
      </Wrapper.LinkStyled>
    );
  }
}

export default HeaderLinkItem;
