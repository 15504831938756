import styled from 'styled-components';

export default styled.div`
  .notifications-alerts {
    padding: 3px;
    font-size: 14px;
    text-align: center;
    background-image: linear-gradient(349deg,#8e70ee,#4a7ece);
  }

  .notifications-alerts__content {
    margin: 0;
    color: white;
  }

  .notifications-alerts__link {
    text-decoration: underline;
    cursor: pointer;
  }
`;
