import {
  GET_MEMBER_UNITS_REPORT_SUCCESS,
  GET_MEMBER_UNITS_REPORT_ALLOWANCES_SUCCESS,
  GET_MEMBER_UNITS_REPORT_COUNTRIES_SUCCESS,
  GET_MEMBER_UNITS_REPORT_LEAGUES_BY_COUNTRY_SUCCESS,
  GET_UNITS_REPORT_NEW_ALLOWANCE_REQUESTS_SUCCESS,
  CHANGE_UNIT_TAB,
  OPEN_REPORT_MODAL,
  CHANGE_REPORT_MODAL_POSITION,
  CLOSE_REPORT_MODAL,
  GET_SEARCH_LEAGUES_SUCCESS,
  SET_NEW_ALLOWANCE_REQUEST_SUCCESS,
  SET_NEW_ALLOWANCE_ALL_LEAGUES_REQUEST_SUCCESS,
} from '../actions/report';
import { UNITS/* , ALLOWANCES */ } from '../../config/constants';

const initialState = {
  reports: [],
  allowances: [],
  countries: [],
  leagues: [],
  requests: [],
  searchResults: [],
  tab: UNITS,
  modal: {
    opened: false,
    x: 0,
    y: 0,
    icon: null,
    report: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBER_UNITS_REPORT_SUCCESS:
      return {
        ...state,
        reports: action.payload,
      };
    case GET_MEMBER_UNITS_REPORT_ALLOWANCES_SUCCESS:
      return {
        ...state,
        allowances: action.payload,
      };
    case GET_MEMBER_UNITS_REPORT_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.payload,
      };
    case GET_MEMBER_UNITS_REPORT_LEAGUES_BY_COUNTRY_SUCCESS:
      return {
        ...state,
        leagues: action.payload,
      };
    case GET_UNITS_REPORT_NEW_ALLOWANCE_REQUESTS_SUCCESS:
      return {
        ...state,
        requests: action.payload,
      };
    case SET_NEW_ALLOWANCE_REQUEST_SUCCESS:
      return {
        ...state,
        requests: [action.payload, ...state.requests],
      };
    case SET_NEW_ALLOWANCE_ALL_LEAGUES_REQUEST_SUCCESS:
      return {
        ...state,
        requests: [...action.payload, ...state.requests],
      };
    case GET_SEARCH_LEAGUES_SUCCESS:
      return {
        ...state,
        searchResults: action.payload,
      };
    case CHANGE_UNIT_TAB:
      return {
        ...state,
        tab: action.payload,
      };
    case OPEN_REPORT_MODAL:
      return {
        ...state,
        modal: {
          ...state.modal,
          opened: true,
          ...action.payload,
        },
      };
    case CHANGE_REPORT_MODAL_POSITION:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.payload,
        },
      };
    case CLOSE_REPORT_MODAL:
      return {
        ...state,
        modal: {
          ...initialState.modal,
        },
      };
    default:
      return state;
  }
};
