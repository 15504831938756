import React, { Component } from 'react';
import AvatarEditor from 'react-avatar-editor'
import imgProfile from '../../assets/images/ic-profile.png';

export default class ProfileImage extends Component {
  editor = null;

  state = {
    placeholderImage: imgProfile,
    image: imgProfile,
    scale: 1
  };

  handleImage = image => {
    this.setState({ image });
  };

  handleScale = scale => {
    this.setState({ scale });
  };

  render () {
    return (
      <div className="profile-image block-img disabled">
        <AvatarEditor
          ref={el => this.editor = el}
          image={this.state.image}
          scale={this.state.scale}
          width={250}
          height={250}
          border={20}
        />
        <div className="profile-image-settings">
          <input
            type="file"
            accept="image/*"
            onChange={e => this.handleImage(e.target.files[0])}
            disabled
          />
          <br/>
          <input
            name="scale"
            type="range"
            onChange={e => this.handleScale(Number(parseFloat(e.target.value)))}
            min="0.5"
            max="3"
            step="0.01"
            defaultValue="1"
            disabled
          />
        </div>
      </div>
    );
  }
}