import {
  GET_MY_AFFILIATE_FINANCE_SUCCESS,
  GET_MY_AFFILIATE_FINANCE_FAIL,
  GET_MY_FINANCE_SUCCESS,
  GET_MY_FINANCE_FAIL,
  PRELOAD_PAYMENT_DETAILS,
  SET_PAYMENT_DETAILS_SUCCESS,
  SET_PAYMENT_DETAILS_FAIL,
} from '../actions/finance';

const initialState = {
  myfinance: {},
  myaffiliate: {},
  error: '',
  paymentDetailsError: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_MY_FINANCE_SUCCESS:
      return {
        ...state,
        myfinance: action.payload,
      };
    case PRELOAD_PAYMENT_DETAILS:
      return { ...state, data: action.payload };

    case GET_MY_FINANCE_FAIL:
      return { ...state, error: action.payload };

    case GET_MY_AFFILIATE_FINANCE_SUCCESS: {
      return { ...state, myaffiliate: action.payload };
    }
    case GET_MY_AFFILIATE_FINANCE_FAIL: {
      return { ...state, error: action.payload };
    }
    case SET_PAYMENT_DETAILS_SUCCESS: {
      return state;
    }
    case SET_PAYMENT_DETAILS_FAIL: {
      return { ...state, paymentDetailsError: action.payload };
    }
    default:
      return state;
  }
};
