import React, { Fragment } from 'react';
import ProfileContainer from '../../containers/Profile/Profile';

const Profile = () => (
  <Fragment>
    <ProfileContainer />
  </Fragment>
);

export default Profile;
