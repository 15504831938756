export default {
  mainBlue: '#366fb1',
  leafyGreen: '#50c14e',
  secondaryBlue: '#38415f',
  thirdBlue: '#4A90E2',
  lightBlue: '#818fa9',
  fourthBlue: 'rgba(74, 144, 226, 0.2)',
  white: '#fff',
  black: '#000',
  red: '#ff5766',
  red_1: 'rgba(255, 87, 102, 0.14)',
  red_2: '#ffe7e9',
  red_dark: 'rgba(214,87,87,0.73)',
  black_1: 'rgba(0, 0, 0, 0.2)',
  black_2: 'rgba(0, 0, 0, 0.08)',
  black_3: '#38415F',
  lightGray: '#e6e6e6',
  lightGray_1: '#dbdbdb',
  lightGray_2: '#dde0e6',
  lightGray_3: '#edeff5',
  lightGray_4: 'rgba(74, 144, 226, 0.5)',
  lightGray_5: '#d1d7e9',
  lightGray_6: 'rgba(151, 151, 151, 0.22)',
  lightGray_7: '#fbfbfb',
  lightGray_8: '#e4e4e4',
  lightGray_9: '#9a9a9a',
  lightBlue_1: '#a5c8f1',
  lightBlue_2: '#4a90e2',
  lightBlue_3: 'rgba(48, 2, 104, 0.2)',
  lightBlue_4: 'rgba(74, 144, 226, 0.26)',
  lightBlue_5: 'rgba(73, 42, 139, 0.36)',
  lightBlue_6: 'rgba(73, 42, 139, 0.1)',
  lightBlue_7: '#BDC6D9',
  lightYellow_1: '#fafcf3',
  transparent: 'transparent',
  azul: '#1d7bea',
};
