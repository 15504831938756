import {
  GET_MAIL_MESSAGES,
  SHOW_MESSAGES,
  TOGGLE_MESSAGE,
  EXPAND_ALL_MESSAGES,
  SHOW_REPLY,
  CLOSE_REPLY,
  SEND_MESSAGE,
  SHOW_COMPOSE_MESSAGE,
  CLOSE_COMPOSE_MESSAGE,
  SET_INBOX_FILTER,
  SHOW_MESSAGES_FROM_WIDGET,
  SET_AS_READ_MESSAGES,
  SET_UNREAD_COUNT,
  CLEAR_CHOSEN_MESSAGE
} from '../actions/messages';
import { getMessages } from '../../selectors/messages';

const initialValue = {
  messages: [],
  activeGroup: null,
  openedMessages: [],
  replyMessage: null,
  showCompose: false,
  inboxFilter: 'inbox',
  unreadCount: 0
};

export default (state = initialValue, { type, payload, showFirst }) => {
  switch (type) {
    case GET_MAIL_MESSAGES:
      const messageData = state.messages.filter((msg) => {
        return msg.messages[0].isInbox
      })

      const activeGroup = showFirst && messageData[0] && messageData[0].messages
        ? messageData[0].messages[0].id
        : state.activeGroup

      const firstMessageId = payload[0] && payload[0].messages && payload[0].messages[0] && payload[0].messages[0].id

      return {
        ...state,
        messages: payload,
        activeGroup,
        openedMessages: showFirst && firstMessageId
          ? [firstMessageId]
          : state.openedMessages,
      };
    case SHOW_MESSAGES: {
      const messages = getMessages({ ...state, activeGroup: payload });

      return {
        ...state,
        activeGroup: payload,
        openedMessages: messages.length > 0 ? [messages[0].id] : [],
        replyMessage: null,
        showCompose: false,
      };
    }
    case SHOW_MESSAGES_FROM_WIDGET: {
      const messages = getMessages({ ...state, activeGroup: payload });

      return {
        ...state,
        activeGroup: payload,
        openedMessages:
          messages.length > 0 ? [messages[0].id, messages[messages.length - 1].id] : [],
        replyMessage: null,
        showCompose: false,
      };
    }
    case TOGGLE_MESSAGE: {
      const { openedMessages } = state;
      const messageIndex = openedMessages.indexOf(payload);
      const haveMessage = messageIndex > -1;

      if (haveMessage) {
        openedMessages.splice(messageIndex, 1);
      } else {
        openedMessages.push(payload);
      }

      return {
        ...state,
        openedMessages: [...openedMessages],
        replyMessage:
          haveMessage && state.replyMessage === payload ? null : state.replyMessage,
      };
    }
    case EXPAND_ALL_MESSAGES: {
      const messages = getMessages(state);
      const openedAll = state.openedMessages.length === messages.length;

      return {
        ...state,
        openedMessages: openedAll ? [] : messages.map(m => m.id),
        replyMessage: openedAll ? null : state.replyMessage,
      };
    }
    case SHOW_REPLY:
      return {
        ...state,
        replyMessage: payload,
      };
    case CLOSE_REPLY:
    case SEND_MESSAGE:
      return {
        ...state,
        replyMessage: null,
      };
    case SHOW_COMPOSE_MESSAGE:
      return {
        ...state,
        showCompose: true,
        openedMessages: [],
        activeGroup: null,
      };
    case CLEAR_CHOSEN_MESSAGE:
      return {
        ...state,
        activeGroup: null,
      };
    case CLOSE_COMPOSE_MESSAGE:
      return {
        ...state,
        showCompose: false,
        openedMessages: [],
        activeGroup: null,
      };
    case SET_INBOX_FILTER: {
      const inBoxMessages = state.messages.filter((msg) => {
        switch (payload) {
          case 'inbox':
            return msg.messages[0].isInbox
          case 'sent':
            return !msg.messages[0].isInbox
          default:
            return true
        }
      })

      const messageData = inBoxMessages[0] && inBoxMessages[0].messages
       ? inBoxMessages[0].messages[0]
       : {}

      return {
        ...state,
        inboxFilter: payload,
        activeGroup: messageData
          ? messageData.id
          : null,
      };
    }
    case SET_AS_READ_MESSAGES:
      return {
        ...state,
        messages: state.messages.map(group => {
          return { ...group, messages: group.messages.map(message => {
            return { ...message, isNew: payload.includes(message.id) ? false : message.isNew };
          })};
        })
      };
    case SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: payload
      };
    default:
      return state;
  }
};
