/**
 * Internal dependencies
 */
import { RELATED_LEAGUE } from './constants';

export const getEvents = ({ league = null, games = [] }) => {
  const gamesData = games
    .filter(game => game[RELATED_LEAGUE] === league)
    .reduce((prevList, game) => ({ 
      ...prevList,
      [`${game.NA}-${game.ID}`]: game
    }), {});

  return Object.values(gamesData).sort();
}
