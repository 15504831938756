/**
 * Internal dependencies
 */
import { callResultsAPI } from '@/utils/bet365';
// import { HARDCODED_RESULT_ID } from './constants';

export const getResultsOfSelectedMatch = async (eventId) => {
  const results = await callResultsAPI(eventId);

  // place to edit for testing with mocked data
  // return results.find(item => item.id == HARDCODED_RESULT_ID);
  return results.find(item => parseInt(item.bet365_id, 10) === parseInt(eventId, 10));
};

