import { reduxForm } from 'redux-form';
import { withProps, compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { openModal, closeModal } from '../store/actions/view';
import { restorePassword } from '../store/actions/auth';

import RestorePasswordForm from '../components/RestorePassword/RestorePasswordForm';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Enter email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

const form = reduxForm({
  form: 'restorePasswordForm',
  validate
});

const props = withProps(ownProps => ({
  onSubmit: ownProps.restorePassword
}));

const mapStateToProps = ({ view, auth }) => ({
  restoreModalOpened: view.modals.restore,
  errorMessage: auth.restorePasswordErrorMessage
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    restorePassword,
    openRestoreModal: () => openModal('restore'),
    closeRestoreModal: () => closeModal('restore'),
  },
  dispatch
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  props,
  form
)(RestorePasswordForm);
