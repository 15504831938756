import {
  SET_ACTIVE_SPORT_ID,
  SET_SPORTS,
  SET_LEAGUES,
  SET_EVENTS,

  SET_WATCH_EVENT,
  SET_EVENT_STATS,
  SET_ACTIVE_TAB,

  EVENTS_IN_PLAY_REQUEST,
  EVENTS_IN_PLAY_SUCCESS,
  EVENTS_IN_PLAY_FAILURE,

  EVENTS_UPCOMING_REQUEST,
  EVENTS_UPCOMING_SUCCESS,
  EVENTS_UPCOMING_FAILURE
} from '@/store/actions/statistics'

const initialState = {
  tabs: {
    value: 'schedule',
    values: [
      {
        label: 'In Live',
        value: 'inlive'
      },
      {
        label: 'Schedule',
        value: 'schedule'
      },
      {
        label: 'Latest Goals',
        value: 'latest-goals'
      }
    ]
  },

  eventsInLive: [],
  eventsInLiveLoading: false,
  eventsInLiveError: null,

  eventsUpcoming: [],
  eventsUpcomingLoading: false,
  eventsUpcomingError: null,

  test: {
    inTest: {
      inInTest: 1
    }
  },

  activeSportID: '',
  sports: [],
  leagues: [],
  events: [],

  watchEventID: '',
  eventStats: {}
};

export default (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_ACTIVE_SPORT_ID:
      return {
        ...state,
        activeSportID: payload
      };

    case SET_SPORTS:
      return {
        ...state,
        sports: payload
      };

    case SET_LEAGUES:
      return {
        ...state,
        leagues: payload
      };

    case SET_EVENTS:
      return {
        ...state,
        events: payload
      };

    case SET_WATCH_EVENT:
      return {
        ...state,
        watchEventID: payload
      };

    case SET_EVENT_STATS:
      return {
        ...state,
        eventStats: {
          ...state.eventStats,
          [payload.event_id]: payload.data
        }
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        tabs: { ...state.tabs, value: payload }
      };

    case EVENTS_IN_PLAY_REQUEST:
      return {
        ...state,
        eventsInLiveError: null,
        eventsInLiveLoading: true
      };
    case EVENTS_IN_PLAY_SUCCESS:
      return {
        ...state,
        eventsInLive: payload,
        eventsInLiveError: null,
        eventsInLiveLoading: false
      };
    case EVENTS_IN_PLAY_FAILURE:
      return {
        ...state,
        eventsInLiveError: payload,
        eventsInLiveLoading: false
      };
    case EVENTS_UPCOMING_REQUEST:
      return {
        ...state,
        eventsUpcomingError: null,
        eventsUpcomingLoading: true
      };
    case EVENTS_UPCOMING_SUCCESS:
      return {
        ...state,
        eventsUpcoming: payload,
        eventsUpcomingError: null,
        eventsUpcomingLoading: false
      };
    case EVENTS_UPCOMING_FAILURE:
      return {
        ...state,
        eventsUpcomingError: payload,
        eventsUpcomingLoading: false
      };

    default:
      return state;
  }
};
