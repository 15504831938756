import tabs, {
  getInitialState as tabsGetInitialState,
  SET_ACTIVE_TAB,
  FETCH_TABS_REQUEST,
  FETCH_TABS_SUCCESS,
  FETCH_TABS_FAILURE
} from './tabs';

import defaultSettings, {
  getInitialState as defaultSettingsGetInitialState,
  FETCH_DEFAULT_SETTINGS_REQUEST,
  FETCH_DEFAULT_SETTINGS_SUCCESS,
  FETCH_DEFAULT_SETTINGS_FAILURE,
  SET_DEFAULT_SETTINGS_REQUEST,
  SET_DEFAULT_SETTINGS_SUCCESS,
  SET_DEFAULT_SETTINGS_FAILURE
} from './default-settings';

import oddsAlertsSelectedPermanent, {
  getInitialState as oddsAlertsSelectedPermanentGetInitialState,

  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_REQUEST,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUCCESS,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_FAILURE,
  SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_REQUEST,
  SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_SUCCESS,
  SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_FAILURE,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_REQUEST,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_SUCCESS,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_FAILURE,
  TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_OPEN,
  TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_SETTINGS_POPUP
} from './odds-alerts-selected-permanent';

import searchAlerts, {
  getInitialState as searchAlertsGetInitialState,
  FETCH_SEARCHFIELD_LIST_REQUEST,
  FETCH_SEARCHFIELD_LIST_SUCCESS,
  FETCH_SEARCHFIELD_LIST_FAILURE,
  FETCH_SEARCH_COUNTRIES_REQUEST,
  FETCH_SEARCH_COUNTRIES_SUCCESS,
  FETCH_SEARCH_COUNTRIES_FAILURE,
  FETCH_SEARCH_RESULT_LEAGUES_REQUEST,
  FETCH_SEARCH_RESULT_LEAGUES_SUCCESS,
  FETCH_SEARCH_RESULT_LEAGUES_FAILURE,
  FETCH_SEARCH_RESULT_TEAMS_REQUEST,
  FETCH_SEARCH_RESULT_TEAMS_SUCCESS,
  FETCH_SEARCH_RESULT_TEAMS_FAILURE,
  SET_CHECKED_SEARCH_RESULT_ITEM_REQUEST,
  SET_CHECKED_SEARCH_RESULT_ITEM_SUCCESS,
  SET_CHECKED_SEARCH_RESULT_ITEM_FAILURE,
  SET_ODDS_ALERT_SEARCH_PARAMETERS,
  TOGGLE_OPEN_ODDS_ALERT_SEARCH_RESULT,
  TOGGLE_ODDS_ALERT_SEARCH_RESULT_SETTINGS_POPUP
} from './search-alerts';

const initialState = {
  tabs: tabsGetInitialState(),
  defaultSettings: defaultSettingsGetInitialState(),
  oddsAlertsSelectedPermanent: oddsAlertsSelectedPermanentGetInitialState(),
  searchAlerts: searchAlertsGetInitialState()
};

export default (state = initialState, action) => {
  switch (action.type) {
    // tabs
    case FETCH_TABS_REQUEST:
    case FETCH_TABS_SUCCESS:
    case FETCH_TABS_FAILURE:
    case SET_ACTIVE_TAB:
      return {
        ...state,
        tabs: tabs(state.tabs, action)
      };

    // FETCH_DEFAULT_SETTINGS
    case FETCH_DEFAULT_SETTINGS_REQUEST:
    case FETCH_DEFAULT_SETTINGS_SUCCESS:
    case FETCH_DEFAULT_SETTINGS_FAILURE:
    case SET_DEFAULT_SETTINGS_REQUEST:
    case SET_DEFAULT_SETTINGS_SUCCESS:
    case SET_DEFAULT_SETTINGS_FAILURE:
      return {
        ...state,
        defaultSettings: defaultSettings(state.defaultSettings, action)
      };

    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_REQUEST:
    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUCCESS:
    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_FAILURE:
    case SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_REQUEST:
    case SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_SUCCESS:
    case SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_FAILURE:
    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_REQUEST:
    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_SUCCESS:
    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_FAILURE:
    case TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_OPEN:
    case TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_SETTINGS_POPUP:
      return {
        ...state,
        oddsAlertsSelectedPermanent: oddsAlertsSelectedPermanent(state.oddsAlertsSelectedPermanent, action)
      };

    case FETCH_SEARCHFIELD_LIST_REQUEST:
    case FETCH_SEARCHFIELD_LIST_SUCCESS:
    case FETCH_SEARCHFIELD_LIST_FAILURE:
    case FETCH_SEARCH_COUNTRIES_REQUEST:
    case FETCH_SEARCH_COUNTRIES_SUCCESS:
    case FETCH_SEARCH_COUNTRIES_FAILURE:
    case FETCH_SEARCH_RESULT_LEAGUES_REQUEST:
    case FETCH_SEARCH_RESULT_LEAGUES_SUCCESS:
    case FETCH_SEARCH_RESULT_LEAGUES_FAILURE:
    case FETCH_SEARCH_RESULT_TEAMS_REQUEST:
    case FETCH_SEARCH_RESULT_TEAMS_SUCCESS:
    case FETCH_SEARCH_RESULT_TEAMS_FAILURE:
    case SET_CHECKED_SEARCH_RESULT_ITEM_REQUEST:
    case SET_CHECKED_SEARCH_RESULT_ITEM_SUCCESS:
    case SET_CHECKED_SEARCH_RESULT_ITEM_FAILURE:
    case SET_ODDS_ALERT_SEARCH_PARAMETERS:
    case TOGGLE_OPEN_ODDS_ALERT_SEARCH_RESULT:
    case TOGGLE_ODDS_ALERT_SEARCH_RESULT_SETTINGS_POPUP:
      return {
        ...state,
        searchAlerts: searchAlerts(state.searchAlerts, action)
      }

    default:
      return state;
  }
};
