import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Wrapper from './styles';
import MessagesIcon from '../../assets/images/ic-messages';
import OpenMessagesIcon from '../../assets/images/web-ic-envelope-norm-open';
import RightColumn from '../../containers/Messages/RightColumn';
import ComposeRightColumn from '../../containers/Messages/ComposeRightColumn';

const Messages = ({
  groups,
  showMessages,
  activeGroup,
  showCompose,
  handleFilterChange,
  closeModal,
}) => {
  const handleShowMessages= (groupId) => () => {
    showMessages(groupId)
    closeModal()
  }

  const filterChange = (e) => {
    handleFilterChange(e)
    closeModal()
  }

  return (
    <Wrapper>
      <div className="left-column">
        <select onChange={filterChange} className="left-header" defaultValue="inbox">
          <option value="all">All</option>
          <option value="inbox">Inbox</option>
          <option value="sent">Sent</option>
        </select>
        <div className="group-list">
          <ul>
            {groups.map(g => (
              <li
                key={`group-${g.id}`}
                onClick={handleShowMessages(g.id)}
                className={classNames({ active: activeGroup === g.id, unread: !!g.hasNewMessages })}
              >
                {g.hasNew ? <MessagesIcon /> : <OpenMessagesIcon />}
                {' '}
                {g.title}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {!showCompose ? <RightColumn /> : <ComposeRightColumn />}
    </Wrapper>
  );
};

Messages.propTypes = {
  groups: PropTypes.array.isRequired,
  showMessages: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  showCompose: PropTypes.bool.isRequired,
  activeGroup: PropTypes.number
};

Messages.defaultProps = {
  activeGroup: null
};

export default Messages;
