import React from 'react';

import FooterLinkItem from '../../containers/FooterLinkItem';
import Wrapper from './styles';

const UnauthFooter = () => (
  <Wrapper>
    <nav>
      <FooterLinkItem to="/about-us-unauth" title="About Us" linkClass="footer-link" />
      <FooterLinkItem to="/contact-us-unauth" title="Contact Us" linkClass="footer-link" />
      <FooterLinkItem to="/terms-unauth" title="Terms & Conditions" linkClass="footer-link" />
    </nav>
  </Wrapper>
);

export default UnauthFooter;
