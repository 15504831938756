import styled from 'styled-components';
import BellWhite from '../../assets/images/ic-bell-white.svg';
import fonts from '../../config/fonts';
import colors from '../../config/colors';

export default styled.div`
  height: 60px;
  background-color: #dde0e6;
  font-family: ${fonts.proximaNova};
  color: #38415f;
  padding: 0 0 0 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  padding-left: 220px;

  .like-text {
    text-decoration: none;
    color: #38415f;
  }

  & > div {
    display: flex;
    align-items: center;
    font-weight: 600;
    height: 100%;
    z-index: 99;
  }

  .blue-bell.active {
    margin-top: 0;

    // img {
    //   background-image: url(${BellWhite});
    // }
  }

  .inner-links {
    position: absolute;
    top: 60px;
    width: 100%;
    left: 0;
    flex-direction: column;
    background-color: #ffffff;
    box-shadow: 0 0 15px 0 rgba(48, 2, 104, 0.2);
    z-index: 9;
    display: none;

    a, .link {
      cursor: pointer;
      padding: 22px 16px;
      font-size: 16px;
      font-weight: bold;
      color: ${colors.secondaryBlue};
      text-decoration: none;
      position: relative;

      &:hover {
        background-color: rgba(74, 144, 226, 0.2);
      }

      &:active {
        background-color: #4a90e2;
        color: white;
      }

      :first-child:after {
        content: '';
        height: 1px;
        background-color: rgb(151, 151, 151, 0.19);
        display: block;
        width: calc(100% - 8px);
        bottom: 0;
        left: 4px;
        position: absolute;
      }
    }
  }

  .profile-link {
    position: relative;
    cursor: pointer;
    min-width: 100px;

    &:hover {
      .inner-links {
        display: flex;
      }
    }
  }

  a + .profile-link:before {
    position: absolute;
    content: '';
    display: inline-block;
    height: 23px;
    border: solid 1px #818fa9;
    left: 0;
  }

  .active + .profile-link:before,
  .profile-link.active:before,
  a:hover + .profile-link:before,
  .profile-link:hover:before {
    display: none;
  }
  
  .back-container {
    margin-right: 10px;
  }
  
  .send-btn,
    .btn-border {
      border-radius: 3px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      min-width: 171px;
      outline: none;
      text-align: center;
      vertical-align: middle;

      img {
        display: inline-block;
        margin-right: 4px;
        vertical-align: top;
      }
    }
    .send-btn {
    border: 0;
    cursor: pointer;
    min-width: 119px;
    height: 40px;
    background-image: linear-gradient(107deg, #8e70ee, #4a7ece);
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    font-size: 15px;
    color: #fff;
    font-weight: 300;
    margin-left: 24px;
    outline: none;
    z-index: 100;
    position: relative;

    &:before {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:after {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:active {
      &:after {
        opacity: 1;
      }
    }
  }
`;
