import React from 'react';

const BtnReplayIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#4A90E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 21.896L18.658 14v5.211c3.937-.264 6.934.924 8.99 3.564s3.173 4.887 3.352 6.74c-1.958-2.846-4.244-4.453-6.86-4.82-2.614-.367-4.442-.367-5.482 0V30L12 21.896z"
      />
    </g>
  </svg>
);

export default BtnReplayIcon;
