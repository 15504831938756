import http from '@/services/http-service';
import moment from 'moment';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';
import { getLocalDate } from '@/helpers/timezoneConvertors';

export const GET_GENERAL_INFO = 'mystats/GET_GENERAL_INFO';
export const GET_GENERAL_INFO_FAIL = 'mystats/GET_GENERAL_INFO_FAIL';
export const GET_GENERAL_BETS_BY_LEAGUE = 'mystats/GET_GENERAL_BETS_BY_LEAGUE';
export const PRELOAD_SELECTS_GENERAL_BETS_BY_LEAGUE = 'mystats/PRELOAD_SELECTS_GENERAL_BETS_BY_LEAGUE';
export const GET_GENERAL_BETS_BY_LEAGUE_FAIL = 'mystats/GET_GENERAL_BETS_BY_LEAGUE_FAIL';
export const GET_PROFIT_CHART_DATA = 'mystats/GET_PROFIT_CHART_DATA';
export const GET_PROFIT_CHART_DATA_FAIL = 'mystats/GET_PROFIT_CHART_DATA_FAIL';
export const GET_PICKS_PIE_CHARTS_SUCCESS = 'mystats/GET_PICKS_PIE_CHARTS_SUCCESS';

export const SET_PROFIT_CHART_RANGE = 'mystats/SET_PROFIT_CHART_RANGE';

export const getGeneralInfo = () => async (dispatch, getState) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetProfilePageInfo');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetProfilePageInfo');

    const { auth: { utcOffset } } = getState();

    data.firstBet = data.firstBet 
      ? getLocalDate(data.firstBet, utcOffset, 'MMM DD, YYYY')
      : data.firstBet;
      
    data.lastBet = data.lastBet 
      ? getLocalDate(data.lastBet, utcOffset, 'MMM DD, YYYY')
      : data.lastBet;

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_GENERAL_INFO,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    dispatch({
      type: GET_GENERAL_INFO_FAIL,
      payload: error.message
    });
  }
};

export const preloadSelect = data => ({
  type: PRELOAD_SELECTS_GENERAL_BETS_BY_LEAGUE,
  payload: data,
});

export const getGeneralBetsByLeague = () => async (dispatch, getState) => {
  const state = getState();
  const params = {
    gamesFilter: state.form.betsForm.values.onlyLive,
    betsFilter: state.form.betsForm.values.onlyBet,
    timesFilter: state.form.betsForm.values.onlyDate
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetProfileBetsByLeague');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetProfileBetsByLeague', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_GENERAL_BETS_BY_LEAGUE,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    dispatch({
      type: GET_GENERAL_BETS_BY_LEAGUE_FAIL,
      payload: error.message
    });
  }
};

export const getProfitChartData = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GeProfileUnitsByDate');
      trace.start();
    }

    const { data: { graphUnits, error }} = await http.post('/GeProfileUnitsByDate');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_PROFIT_CHART_DATA,
      payload: graphUnits
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    dispatch({
      type: GET_PROFIT_CHART_DATA_FAIL,
      payload: error.message
    });
  }
};

export const setProfitChartRange = (startDate, endDate) => (dispatch, getState) => {
  const { auth: { utcOffset } } = getState();
  
  const start = moment(startDate).utcOffset(utcOffset);
  const end = moment(endDate).utcOffset(utcOffset);

  dispatch({
    type: SET_PROFIT_CHART_RANGE,
    payload: [
      startDate ? start.toDate() : null, 
      endDate ? end.toDate() : null
    ]
  });
};

export const getPicksPieCharts = () => async dispatch => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetPicksPieCharts');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetPicksPieCharts');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_PICKS_PIE_CHARTS_SUCCESS,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    console.log(error);
  }
};
