import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styled';
import Modal from '../../containers/Modal';
import { ClipLoader } from 'react-spinners';

const WaitModal = ({ opened }) => (
  opened ? (
    <Wrapper>
      <Modal className="modal approve-modal " modalName="wait">
        <div className="title-wrapper">
          <div className="center">
            <ClipLoader
              sizeUnit="px"
              size={30}
              color="#123abc"
              loading
            />
          </div>
          <span className="modal-title">Please wait while checking your pick (up to 60 seconds)...</span>
        </div>
        <div>
          <span className="modal-text">Please note: your pick might rejected if game dropped, or if the score has been changed (goal) within 60 seconds from your submitting.</span>
        </div>
      </Modal>
    </Wrapper>
  ) : null
);

WaitModal.propTypes = {
  opened: PropTypes.bool.isRequired
};

export default WaitModal;
