import styled from 'styled-components';

export default styled.div`
  height: 60px;
  color: #38415f;
  padding: 0 20px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;

  & > div {
    display: flex;
    align-items: center;
    font-weight: 600;
    height: 100%;
  }

  nav {
    width: 368px;
    margin: 0 auto;
    text-align: center;
  }
`;
