import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { lifecycle, compose, withProps, withHandlers } from 'recompose';

import {
  getMailMessages,
  showMessages,
  setInboxFilter,
  getUnreadMsgsCount,
} from '@/store/actions/messages';
import { closeModal } from '@/store/actions/view';
import { getGroups } from '@/selectors/messages';
import Messages from '@/components/Messages';

const mapStateToProps = ({ messages }) => ({
  groups: getGroups(messages),
  activeGroup: messages.activeGroup,
  showCompose: messages.showCompose,
  inboxFilter: messages.inboxFilter,
  messages
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getMailMessages,
    showMessagesAction: showMessages,
    setInboxFilter,
    getUnreadMsgsCount,
    closeModal: () => {
      return closeModal('composeConfirm')
    },
  },
  dispatch
);

const wHandlers = withHandlers({
  handleFilterChange: props => (e) => {
    props.setInboxFilter(e.target.value);
  }
});

const wLifecycle = lifecycle({
  componentDidMount () {
    this.props.getMailMessages(true);
  },

  componentWillUnmount () {
    this.props.closeModal()
  }
});

const wProps = withProps(ownProps => ({
  showMessages: (groupId) => {
    ownProps.showMessagesAction(groupId);
  }
}));

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  wLifecycle,
  wProps,
  wHandlers
)(Messages);
