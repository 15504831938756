import styled from 'styled-components';

import colors from '../../config/colors';
import fonts from '../../config/fonts';

export default styled.div`
  font-family: ${fonts.proximaNova};
  padding: 40px 20px;

  h1 {
    font-weight: 600;
    font-size: 36px;
    color: ${colors.secondaryBlue};
    text-align: center;
    margin: 0;
  }

  h2 {
    font-size: 24px;
    color: ${colors.secondaryBlue};
    text-align: center;
    font-weight: 400;
    max-width: 919px;
    margin: 40px auto 60px;
  }

  a {
    color: ${colors.mainBlue};

    &:hover {
      color: #1d7bea;
    }

    &:active {
      color: #366fb1;
    }
  }

  .screens {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    img {
      box-shadow: 0 5px 12px 0 rgba(73, 42, 139, 0.37);
      display: block;
      width: 300px;
      height: auto;
      margin: 0 20px;
    }
  }
`;
