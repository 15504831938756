import React, { Fragment } from 'react';
import FinanceContainer from '../../containers/Finance';

const Finance = () => (
  <Fragment>
    <FinanceContainer />
  </Fragment>
);

export default Finance;
