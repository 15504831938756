import React, { Fragment } from 'react';

import Wrapper from './styles';
import AboutUsContainer from '../../assets/staticTexts/AboutUs';

const AboutUs = () => (
  <Fragment>
    <Wrapper>
      <AboutUsContainer />
    </Wrapper>
  </Fragment>
);

export default AboutUs;
