import React from 'react';

const ReportIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" viewBox="0 0 16 21">
    <g fill="none" fillRule="evenodd">
      <path fill="#FFB946" d="M14.031 2.019H2.016C1.169 2.019 0 2.815 0 4.211l.004 13.588c0 1.397 1.169 2.21 2.016 2.21h12.011c.847 0 1.954-.812 1.954-2.21V4.211c0-1.396-1.107-2.192-1.954-2.192zm-.748 16.086H2.573c-.447 0-.672-.464-.672-.727V4.775c0-.262.221-.727.668-.727h10.714c.447 0 .753.465.753.727l.004 12.603c0 .263-.31.727-.757.727zM9.568 7.518l-5.017 5.77c-.252.29-.024.9.269 1.155.293.255.955.417 1.206.128l5.016-5.77c.252-.29-.002-.922-.295-1.177-.292-.254-.927-.395-1.18-.106zm.843-5.592h1.119a.42.42 0 0 0 .418-.423V.454a.421.421 0 0 0-.418-.423h-1.119a.42.42 0 0 0-.418.423v1.049a.42.42 0 0 0 .418.423zm-3.024 0h1.149a.42.42 0 0 0 .418-.423V.423A.42.42 0 0 0 8.536 0H7.387a.42.42 0 0 0-.418.423v1.08a.42.42 0 0 0 .418.423zm-3.015.043h1.15a.42.42 0 0 0 .418-.423V.454a.421.421 0 0 0-.418-.423h-1.15a.42.42 0 0 0-.418.423v1.092a.42.42 0 0 0 .418.423z"/>
      <circle cx="8" cy="11" r="4" fillRule="nonzero" stroke="#FFB946" strokeWidth="2"/>
    </g>
  </svg>
);

export default ReportIcon;
