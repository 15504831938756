import http from '@/services/http-service';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';
import capitalize from 'lodash/capitalize';
import {change, clearFields, reset, submit} from 'redux-form';
import moment from 'moment';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';

import {CLOSE_MODAL, expandNewPick, OPEN_MODAL} from './view';
import {USER_FULL_MEMBER_LEVEL, USER_TRIAL_LEVEL} from '@/helpers/access';
import consoleError from '@/helpers/consoleError';
import {gamesFormatted} from '@/selectors/picks';
import {MARKET_TYPE_TODAY as MARKET_TODAY} from '@/config/constants'
import {prepareGamesInfo} from "@/helpers/gamesHelper";
import {getLeaguesData, getWebsocketData} from "@/helpers/websocket";
import store from "@/store/store";
import {FILTERED_OUT_LEAGUE_ID_ID, MAX_ID, MIN_ID, FILTER_ID} from "@/components/CreateNewPick";

export const GET_CREATE_PICK_PAGE_INFO_SUCCESS = 'picks/GET_CREATE_PICK_PAGE_INFO_SUCCESS';
export const GET_CREATE_PICK_PAGE_INFO_REJECTED = 'picks/GET_CREATE_PICK_PAGE_INFO_REJECTED';
export const CREATE_PICK_PAGE_LEAGUES_SUCCESS = 'picks/CREATE_PICK_PAGE_LEAGUES_SUCCESS';
export const CREATE_PICK_PAGE_GAMES_SUCCESS = 'picks/CREATE_PICK_PAGE_GAMES_SUCCESS';
export const CREATE_PICK_PAGE_LEAGUES_GAMES_REJECTED = 'picks/CREATE_PICK_PAGE_LEAGUES_GAMES_REJECTED';
export const SELECT_LEAGUE = 'picks/SELECT_LEAGUE';
export const DISABLE_PICKS_FORM = 'picks/DISABLE_PICKS_FORM';
export const ENABLE_PICKS_FORM = 'picks/ENABLE_PICKS_FORM';
export const GET_GRAPH_BY_PICK_REQUESTED = 'picks/GET_GRAPH_BY_PICK_REQUESTED';
export const GET_GRAPH_BY_PICK_SUCCESS = 'picks/GET_GRAPH_BY_PICK_SUCCESS';
export const GET_GRAPH_BY_PICK_REJECTED = 'picks/GET_GRAPH_BY_PICK_REJECTED';
export const CHANGE_LINE_ON_GRAPH = 'picks/CHANGE_LINE_ON_GRAPH';
export const GET_MY_PICKS_LAST_SUCCESS = 'picks/GET_MY_PICKS_LAST_SUCCESS';
export const GET_MY_PICKS_LAST_REJECTED = 'picks/GET_MY_PICKS_LAST_REJECTED';
export const GET_MY_PICKS_SUCCESS = 'picks/GET_MY_PICKS_SUCCESS';
export const GET_MY_PICKS_REJECTED = 'picks/GET_MY_PICKS_REJECTED';
export const CLEAR_GAMES = 'picks/CLEAR_GAMES';
export const SET_FILTER = 'picks/SET_FILTER';
export const INIT_WEBSOCKET_SUCCESS = 'picks/INIT_WEBSOCKET_SUCCESS';
export const CLOSE_WEBSOCKET_SUCCESS = 'picks/CLOSE_WEBSOCKET_SUCCESS';

const GAMES_API_UTC = 1

const createMinOdds = (game, bet, userLevel = USER_FULL_MEMBER_LEVEL) => {
  const mapBetKey = {
    home: 'odds1Hcap',
    away: 'odds2Hcap',
    over: 'odds1Total',
    under: 'odds2Total'
  };
  let downCents = userLevel === USER_TRIAL_LEVEL ? 0.02 : 0.1;
  const minOdds = Number((game[mapBetKey[bet]] - downCents).toFixed(2));
  if (minOdds < 1) {
    return 1;
  }
  return minOdds;
};

export const getCreatePickPageInfo = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetCreatePickPageInfo');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetCreatePickPageInfo');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_CREATE_PICK_PAGE_INFO_SUCCESS,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
    dispatch({
      type: GET_CREATE_PICK_PAGE_INFO_REJECTED
    });
  }
};

export const prefillOddsReaches = () => async (dispatch, getState) => {
  const state = getState();
  const profileData = get(state, 'alerts.defaultValues', {});
  dispatch(change('alertModalForm', 'oddsReaches', profileData.oddsAlertMoveMore));
};

export const getLeagues = () => async (dispatch, getState) => {
  const state = getState();
  const market = Number(get(state, 'form.leagueForm.values.marketType', MARKET_TODAY))
  const utcOffset = get(state, 'auth.utcOffset', GAMES_API_UTC)
  // const params = {
  //   sport: get(state, 'form.leagueForm.values.sport', 1),
  //   betGamePart: get(state, 'form.leagueForm.values.betGamePart', 0)
  // };

  // let trace;
  try {
    // if (process.env.REACT_APP_ENV === 'production') {
    //   trace = performance.trace('LeaguesGamesMetaData');
    //   trace.start();
    // }

    // const { data: { error, games }} = await http.post('/LeaguesGamesMetaData', params);
    // if (process.env.REACT_APP_ENV === 'production') {
    //   if (error.errorCode !== 0) {
    //     trace.putAttribute('errorCode', `${error.errorCode}`);
    //   }
    //   trace.stop();
    // }
    //
    // if (error.errorCode !== 0) {
    //   throw new ApiError(error.errorText, error.errorCode);
    // }

    // const preparedGames = MARKET_TYPE_LIVE !== +market ? games.filter(g => {
    //   const date = momenst(g.gameDate).add(Math.abs(utcOffset - GAMES_API_UTC), 'hours')
    //   return moment().isBefore(date)
    // }) : games
    // const preparedGames = filterGamesForMarket(games, market);
    const leaguesData = getLeaguesData()
    const localUserId = localStorage.getItem('userId')
    const idInWhiteList = (((localUserId >= MIN_ID) && (localUserId <= MAX_ID)) || (localUserId == FILTER_ID))

    const preparedGames = leaguesData
        .map(g => ({
          ...g,
          gameDate: moment.utc(g.gameDate).add(utcOffset - GAMES_API_UTC, 'hours').format('YYYY-MM-DDTHH:mm:ss'),
        }))
    const leagues = preparedGames.filter(v => idInWhiteList ? v: v.leagueId !== FILTERED_OUT_LEAGUE_ID_ID)
        .filter(v => v.market === market)
        .reduce((res, item) => {
          if (res.some(i => (i.leagueNameId === item.leagueNameId && i.market === item.market))) {
            return res;
          }
          return [...res, item];
        }, [])
      .sort((a, b) => {
        if (a.leagueName > b.leagueName) return 1;
        if (a.leagueName < b.leagueName) return -1;
        return 0;
      });

    const league = get(state, 'picks.selectedLeague', null)
    let leagueEvents = get(state, 'picks.leagueEvents', [])
    if (league) {
      leagueEvents = preparedGames
          .filter(v => v.leagueNameId === league.leagueNameId && v.market === league.market)
          .map(v => ({
            eventId: v.eventId,
            gameId: v.gameId
          }))
    }


    dispatch({
      type: CREATE_PICK_PAGE_LEAGUES_SUCCESS,
      payload: {
        leagues,
        leagueEvents,
        allGames: preparedGames,
      },
    });
  } catch (error) {
    // if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
    //   trace.putAttribute('error', error.message);
    //   trace.stop();
    // }

    console.warn(error);
    dispatch({
      type: CREATE_PICK_PAGE_LEAGUES_GAMES_REJECTED
    });
  }
};

export const selectLeague = league => async (dispatch, getState) => {
  let state = getState();
  const allGames = get(state, 'picks.allGames', [])
  const leagueEvents = allGames
      .filter(v => v.leagueNameId === league.leagueNameId && v.market === league.market)
      .map(v => ({
        eventId: v.eventId,
        gameId: v.gameId
      }))

  dispatch({
    type: SELECT_LEAGUE,
    payload: {
      league,
      leagueEvents: leagueEvents
    }
  });

  setGameLines(dispatch)
};

export const setGameLines = (dispatch, options = {}) => {
  const state = store.getState()
  const league = options.league || get(state, 'picks.selectedLeague', null)
  if (!league) return

  const leagueEvents = options.leagueEvents || get(state, 'picks.selectedLeagueEvents', [])
  const utcOffset = get(state, 'auth.utcOffset', GAMES_API_UTC);
  const websocketData = getWebsocketData()
  const {gamesWithOdds} = prepareGamesInfo({
    league, leagueEvents, websocketData, utcOffset
  })

  dispatch({
    type: CREATE_PICK_PAGE_GAMES_SUCCESS,
    payload: {
      games: gamesWithOdds
    }
  });

  handleOdds(gamesWithOdds, state, dispatch)
}

export const selectGame = (game, line, bet) => async (dispatch, getState) => {
  const state = getState();
  const sport = get(state, 'form.leagueForm.values.sport', 1);
  const leagueName = get(state, 'picks.selectedLeague.leagueName', '');
  const market = get(state, 'form.leagueForm.values.marketType', MARKET_TODAY);
  const fields = get(state, 'form.picksForm.values', {});
  const userLevel = state.user.account.userLevel;
  const utcOffset = Math.abs(state.auth.utcOffset - GAMES_API_UTC)
  dispatch(clearFields('picksForm', false, false, ...Object.keys(fields)));
  dispatch(change('picksForm', 'live', game.isLive));
  dispatch(change('picksForm', 'minuteIR', isNaN(game.minuteIR) ? game.minuteIR : `${game.minuteIR}'`));
  dispatch(change('picksForm', 'score', `${game.team1Score} - ${game.team2Score}`));
  dispatch(change('picksForm', 'date', moment(game.eventDateTime).add(utcOffset, 'hours').format('DD/MM/YY HH:mm')));
  dispatch(change('picksForm', 'sport', sport));
  dispatch(change('picksForm', 'team1', game.team1Name));
  dispatch(change('picksForm', 'team2', game.team2Name));
  dispatch(change('picksForm', 'league', leagueName));
  dispatch(change('picksForm', 'line', line));
  dispatch(change('picksForm', 'lineTotal', game.lineTotal));
  dispatch(change('picksForm', 'market', market));
  dispatch(change('picksForm', 'bet', bet));
  dispatch(change('picksForm', 'time', moment(game.eventDateTime).add(utcOffset, 'hours').format('HH:mm')));
  dispatch(change('picksForm', 'eventConnectionId', game.eventsConnectionId));
  dispatch(change('picksForm', 'gameId', game.gameId));
  dispatch(change('picksForm', 'betGamePart', game.betGamePart));
  dispatch(change('picksForm', 'minOdds', createMinOdds(game, bet, userLevel)));
  dispatch(change('picksForm', '__betId', `${game.gameId}|${bet}|${game.lineHcap}`));
  dispatch({
    type: DISABLE_PICKS_FORM,
  });

  // // sorry for that :(
  // document.querySelector('input[name="minOdds"]').focus();
};

export const clearPicksForm = event => async (dispatch, getState) => {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }

  const state = getState();
  const fields = get(state, 'form.picksForm.values', {});

  dispatch(clearFields('picksForm', false, false, ...Object.keys(fields)));
  dispatch({type: CLEAR_GAMES});
  dispatch({
    type: ENABLE_PICKS_FORM
  });
};

export const clearFormNotes = () => async dispatch => {
  dispatch(reset('picksForm'));
  dispatch({
    type: DISABLE_PICKS_FORM
  });
};

export const clearLeagueSearch = () => async (dispatch) => {
  dispatch({
    type: SELECT_LEAGUE,
    payload: {
      league: null,
      leagueEventIds: []
    },
  });
  dispatch(change('leagueForm', 'leagueSearch', ''));
};

export const submitPicksForm = () => async (dispatch) => {
  dispatch(submit('picksForm'));
};

const savePicksToStorage = ({ id, game, gameDate, league }) => {
  const localPick = {
    pickId: id,
    league,
    team1: game.team1,
    team2: game.team2,
    gameDate: moment(gameDate).format("DD/MM/YY HH:mm"),
    bet: `${capitalize(game.bet)} ${parseFloat(game.line)} ${game.minOdds}`,
    memberBet: {
      bet: capitalize(game.bet),
      fh: "",
      line: parseFloat(game.line),
      odds: game.minOdds,
    },
    traderBet: {
      bet: capitalize(game.bet),
      fh: "",
      line: parseFloat(game.line),
      odds: game.minOdds,
    },
    isLive: "",
    gameStatus: "Pending",
    betAmount: "",
    revenueRoi: "",
    isGraph: true,
  };

  const localPicks = JSON.parse(localStorage.getItem('localPicks'));

  const updatedPicks = localPicks !== null
    ? [...localPicks,  localPick]
    : [localPick];

  localStorage.setItem('localPicks', JSON.stringify(updatedPicks));

  return updatedPicks;
}

const getGame = ({ gameId, games, userLevel, selectedLeague }) => {
  const gamesList = games.filter(g => !!g.isCorners === !!selectedLeague.isCorners);
  return gamesFormatted(gamesList, userLevel).find(item => (
    item.gameId === gameId
  ));
}

let score = null;
export const createNewPick = game => async (dispatch, getState) => {
  selectLeague()
  const state = getState();
  const user = get(state, 'user.webtemplate.user', {});
  const games = get(state, 'picks.games', []);
  const selectedLeague = get(state, 'picks.selectedLeague', {});

  const updatedGame = getGame({
    gameId: game.gameId,
    games,
    userLevel: user.userlevel,
    selectedLeague
  });

  // const idInWhiteList = user.userId >= MIN_ID && user.userId <= MAX_ID;

  if (!score && user.userlevel === USER_TRIAL_LEVEL && game.live
    // && !idInWhiteList
    ) {
    score = game.score;
    dispatch({type: OPEN_MODAL, payload: 'wait'});
    setTimeout(() => {
      dispatch(submit('picksForm'));
      dispatch({type: CLOSE_MODAL, payload: 'wait'});
    }, 60000);
    return;
  }

  const updatedScores = `${updatedGame.team1Score} - ${updatedGame.team2Score}`

  if (score && updatedScores !== score) {
    dispatch({type: OPEN_MODAL, payload: 'refresh'});
    return;
  }
  score = null;

  const gameDate = !game.isLive
    ?  moment(game.date, 'DD/MM/YY HH:mm').utc().format()
    : undefined

  const params = {
    sportId: game.sport,
    homeTeam: game.team1,
    awayTeam: game.team2,
    leagueName: get(state, 'picks.selectedLeague.leagueName'),
    leagueId: get(state, 'picks.selectedLeague.leagueId'),
    leagueNameId: get(state, 'picks.selectedLeague.leagueNameId'),
    gameDate,
    isLive: game.live,
    // score: game.live ? `${game.minuteIR} ${game.score}` : null,
    score: game.live ? game.score : null,
    isUrgent: !!game.urgentBet,
    highValue: !!game.highValues,
    teamNews: !!game.teamNews,
    betGamePartId: game.betGamePart,
    bet: capitalize(game.bet),
    line: parseFloat(game.line),
    minOdds: game.minOdds,
    notes: game.notes,
    eventConnectionId: game.eventConnectionId,
    gameId: game.gameId,
    onHalf: !!game.isHalf,
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebCreatePick');
      trace.start();
    }
    const { data: { error }} = await http.post('/WebCreatePick', params);
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    const findBiggestId = (list) => {
      return list.reduce((maxId, item) => (
        maxId < item.pickId ? item.pickId : maxId
      ), 0);
    }

    const picksList = get(state, 'picks.list');

    const localPicks = savePicksToStorage({
      id: findBiggestId(picksList) + 1,
      game,
      gameDate,
      league: get(state, 'picks.selectedLeague.leagueName')
    });

    const picks = uniqBy([...picksList, ...localPicks], v => (
      [v.gameDate, v.team1, v.team2, v.memberBet.line, v.memberBet.odds].join()
    ))

    dispatch({
      type: GET_MY_PICKS_SUCCESS,
      payload: picks,
    });

    dispatch({
      type: GET_CREATE_PICK_PAGE_INFO_SUCCESS
    });

    const fields = get(state, 'form.picksForm.values', {});

    dispatch(clearFields('picksForm', false, false, ...Object.keys(fields)));
    dispatch({
      type: ENABLE_PICKS_FORM
    });

    if (user.userlevel === USER_TRIAL_LEVEL) {
      dispatch({type: OPEN_MODAL, payload: game.live ? 'success' : 'trialSuccess'});
    }
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_CREATE_PICK_PAGE_INFO_REJECTED
    });
  }
};

export const changeLineOnGraph = line => async (dispatch) => {
  dispatch({
    type: CHANGE_LINE_ON_GRAPH,
    payload: line,
  });
};

export const getMyPicksLast = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMyPicksLast');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetMyPicksLast');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MY_PICKS_LAST_SUCCESS,
      payload: {
        ...data,
        picksLast: uniqBy(data.picksLast, 'betText')
      }
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_MY_PICKS_LAST_REJECTED
    });
  }
};


let timer = null;
export const getMyPicks = (fromDate = null, toDate = null) => async (dispatch, getState) => {
  if (timer) {
    return
  }

  const params = { fromDate, toDate };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMyPicks');
      trace.start();
    }

    const { data: { picks, error }} = await http.post('/GetMyPicks', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    const { auth: { utcOffset } }  = getState();

    const picksList = picks.map(picks => ({
      ...picks,
      gameDate: moment(picks.gameDate, 'DD/MM/YYYY HH:mm')
        .add(utcOffset, 'hours')
        .format('DD/MM/YYYY HH:mm')
    }));

    localStorage.removeItem('localPicks');

    dispatch({
      type: GET_MY_PICKS_SUCCESS,
      payload: uniqBy(picksList, v => (
        [v.gameDate, v.team1, v.team2, v.memberBet.line, v.memberBet.odds].join()
      ))
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_MY_PICKS_REJECTED
    });
  }

  timer = setTimeout(() => {
    timer = null
  }, 30000);
};

export const getGraphByPick = request => async (dispatch) => {
  dispatch({
    type: GET_GRAPH_BY_PICK_REQUESTED,
  });

  const params = { ...request };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetGraphByPick');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetGraphByPick', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_GRAPH_BY_PICK_SUCCESS,
      payload: {
        ...data,
        bet: request.bet,
        line: data.lines.includes(data.redLine) ? data.redLine.toString() : request.line
      }
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_GRAPH_BY_PICK_REJECTED
    });
  }
};

export const setFilter = ({ name, value }) => dispatch => {
  dispatch({
    type: SET_FILTER,
    payload: { name, value }
  });
};

const handleOdds = (games, state, dispatch) => {
  const league = get(state, 'picks.selectedLeague', null)
  if (league && league.leagueId !== 0) {
    dispatch(expandNewPick());

    const __betId = get(state, 'form.picksForm.values.__betId');
    if (__betId) {
      const [ gameId, bet, line1 ] = __betId.split('|'); // eslint-disable-line no-unused-vars
      // const lineTotal = get(state, 'form.picksForm.values.lineTotal');
      let line = get(state, 'form.picksForm.values.line');
      line = bet === 'away' ? -line : line;
      const foundGame = games.find(game => (
          String(game.gameId) === gameId && (game.lineHcap === line || game.lineTotal === line)
      ));

      const modalRefreshOpened = get(state, 'view.modals.refresh');
      const modalWaitOpened = get(state, 'view.modals.wait');

      if (foundGame) {
        const userLevel = state.user.account.userLevel;
        const bet = get(state, 'form.picksForm.values.bet');
        const oldMinOdds = get(state, 'form.picksForm.values.minOdds');
        const newMinOdds = createMinOdds(foundGame, bet, userLevel);
        if (oldMinOdds !== newMinOdds) {
          const isActive = get(state, 'form.picksForm.fields.minOdds.active', false);
          const isTouched = get(state, 'form.picksForm.fields.minOdds.touched', false);
          if (!isActive && !isTouched) {
            dispatch(change('picksForm', 'minOdds', newMinOdds));
          }
        }
      } else if (!modalWaitOpened && !modalRefreshOpened) {
        dispatch({
          type: OPEN_MODAL,
          payload: 'autoFetch',
        });
      }
    }
  }
}
