import React from 'react';

export const renderOption = (from, to, fromZero) => {
  const options = [];

  for (let i = from; i < to; i++) {
    fromZero
      ? options.push(
        <option key={Date.now + i} value={i}>
          {i + 1}
        </option>,
      )
      : options.push(
        <option key={Date.now + i} value={i}>
          {i}
        </option>,
      );
  }
  return options;
};
