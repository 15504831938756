import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

import FormInput from '../FormInput';
import Wrapper from './styles';

const LoginForm = ({ handleSubmit, isLoginProcessing, errorMessage, loginErrorMessage }) => (
  <Wrapper>
    <h1 className="title">Welcome</h1>
    {localStorage.getItem('passwordToken') && (
      <p
        style={{
          color: '#50c14e',
          fontSize: 20,
          fontWeight: 'bold',
          fontFamily: 'proxima-nova',
          marginBottom: 35,
          marginTop: 0,
        }}
      >
        Please, log in to approve your email.
      </p>
    )}
    <p>
      <b>Dear guest,</b>
      &nbsp;Punteam is a tipsters ("Punters") center, and is only available for registered members.
      To learn more about Punteam&nbsp;
      <Link to="/about-us-unauth">click here.</Link>
    </p>

    <p>If you are a Punteam member, please login:</p>
    <form onSubmit={handleSubmit} noValidate>
      <Field
        name="userEmail"
        type="email"
        component={FormInput}
        placeholder="email@something.com"
        label="Email"
      />
      <Field name="userPassword" type="password" component={FormInput} label="Password" />
      {errorMessage && <div className="auth-error">{errorMessage}</div>}
      {loginErrorMessage && <div className="auth-error">{loginErrorMessage}</div>}
      <div>
        <button className="submit-btn" type="submit" disabled={isLoginProcessing}>
          <div>
            Log In
          </div>
          <ClipLoader
            className="position: absolute !important; left: 56%;"
            sizeUnit="px"
            size={25}
            color="white"
            loading={isLoginProcessing}
          />
        </button>
        <Link to="/restore-password" className="forgot-password-btn">
          I forgot my password
        </Link>
      </div>
    </form>
    <nav>
      <Link to="/about-us-unauth">About Us</Link>
      <Link to="/contact-us-unauth">Contact Us</Link>
      <Link to="/terms-unauth">Terms & Conditions</Link>
    </nav>
  </Wrapper>
);

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  isLoginProcessing: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};

export default LoginForm;
