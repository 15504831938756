import styled from 'styled-components';

import Modal from '../../containers/Modal';

export default styled(Modal)`
  .no-data-graph {
    font-size: 95px;
    opacity: 0.2;
    color: #818fa9;
    font-weight: bold;
    position: absolute;
    top: 86px;
    left: 50%;
    margin-left: -182px;
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -33px;
  }
`;
