import React from 'react';

const BellBlack = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#38415F"
        fillRule="nonzero"
        d="M7.779 18.528l.34-1.365c1.48.378 2.74.318 3.48.3 1.241-.03 2.437.063 3.457-.273l.43 1.338c-1.164.384-2.489.31-3.854.342-.891.021-2.26.064-3.853-.342z"
      />
      <path
        stroke="#38415F"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.362 18.082C5.693 17.629 3 16.13 3 14.352c0-1.002.854-1.915 2.255-2.602.055-.436.467-.784 1.227-3.39C7.557 4.68 9.129 3.034 11.85 3.034c2.721 0 4.69.84 5.637 5.328.555 2.626.847 3.273.935 3.548 1.218.666 1.948 1.516 1.948 2.442 0 1.78-2.693 3.278-6.362 3.73.07.228.109.47.109.721v.463a2.432 2.432 0 1 1-4.864 0v-.463c0-.25.038-.493.109-.72z"
      />
    </g>
  </svg>
);

export default BellBlack;
