/**
 * External dependencies
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';

/**
 * Internal dependencies
 */

class StatisticsError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hovered: false,
      hidden: false
    }
  }

  componentDidMount () {
    this.setState({ hidden: false });
  }

  componentWillUnmount () {
    this.setState({ hidden: true });
  }

  render() {
    const { error, customMessage } = this.props;
    const { hovered, hidden } = this.state;

    const errorClasses = classnames('statistics__error', {
      'hovered': hovered,
      'hidden': hidden
    });

    return (
      <div
        className={errorClasses}
        onMouseEnter={() => {
          this.setState({ hovered: true });
        }}
        onMouseLeave={() => {
          this.setState({ hovered: false })
        }}
      >
        <span
          className="close"
          onClick={ () => {
            this.setState({
              hidden: true
            });
          }}
        >Hide</span>
        <p className="custom-message">{ customMessage }</p>
        <p className="original-error">{ error.message }</p>
      </div>
    );
  }
};

const mapStateToProps = state => ({
  error: state.newStatistics.error
});

export default connect(mapStateToProps)(StatisticsError);
