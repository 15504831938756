import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuth = !!localStorage.getItem('authorizationKey');
  const isPublicRoute = [
    '/login',
    '/about-us-unauth',
    '/contact-us-unauth',
    '/terms-unauth',
    '/restore-password',
    '/how-it-works-unauth',
  ].indexOf(window.location.pathname) >= 0;

  const token = window.location.pathname.match(/\/approve-email\/([\w\d]+)/);
  if (!isAuth && token && token.length === 2) {
    localStorage.setItem('passwordToken', token[1]);
  }

  return (
    <Route
      {...rest}
      render={props => (isAuth ? <Component {...props} /> : isPublicRoute ? null : <Redirect to="/login" />)
      }
    />
  );
};

export default PrivateRoute;
