import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styled';
import Modal from '../../containers/Modal';

const RefreshModal = ({ opened }) => (
  opened ? (
    <Wrapper>
      <Modal className="modal approve-modal " modalName="refresh">
        <div className="title-wrapper">
          <span className="modal-title">Can't confirm pick, please send again</span>
        </div>
        <div className="center">
          <button className="reload send-btn" onClick={() => window.location.reload()}>Ok</button>
        </div>
      </Modal>
    </Wrapper>
  ) : null
);

RefreshModal.propTypes = {
  opened: PropTypes.bool.isRequired
};

export default RefreshModal;
