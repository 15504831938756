import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import EditProfile from '@/containers/Profile/EditProfile';
import GetTerms from '@/containers/Profile/GetTerms';

import Wrapper from './profile-styles';

const Profile = ({
  account,
  hasTerms
}) => {
  const isLoading = isEqual(account, {});
  const isShowGetTerms = !isLoading && (!hasTerms && account.userId !== null);

  return (
    <Wrapper>
      {
        isLoading ? <div className="block">Loading...</div> :
        isShowGetTerms ? <GetTerms /> : <EditProfile />
      }
    </Wrapper>
  );
};

Profile.propTypes = {
  account: PropTypes.object.isRequired,
  hasTerms: PropTypes.bool.isRequired
};

export default Profile;
