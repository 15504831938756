import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import Wrapper from './styles';

const defineds = {
  startOfLastWeek: moment().startOf('week').subtract(1, 'weeks'),
  endOfLastWeek: moment().endOf('week').subtract(1, 'weeks'),
  startOfLastMonth: moment().startOf('month').subtract(1, 'months'),
  endOfLastMonth: moment().endOf('month').subtract(1, 'months'),
  startOfLastYear: moment().startOf('year').subtract(1, 'years'),
  endOfLastYear: moment().endOf('year').subtract(1, 'years'),
};

const DateRange = ({
  range,
  onChange
}) => {
  const [startDate, setStartDate] = useState(range[0]);
  const [endDate, setEndDate] = useState(range[1]);
  const [focusedInput, setFocusedInput] = useState();

  const onDatesChange = ({ startDate, endDate }) => {
    const start = startDate ? startDate.toDate() : null;
    const end = endDate ? endDate.toDate() : null;

    setStartDate(start);
    setEndDate(end);

    if ((start && end) || (!start && !end)) {
      setFocusedInput(null);
      onChange(start, end);
    }
  };
  const reset = () => {
    onDatesChange({ startDate: null, endDate: null });
  };

  const presets = [
    {
      label: "Last week",
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek
    },
    {
      label: "Last month",
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth
    },
    {
      label: "Last year",
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear
    }
  ];

  const renderDatePresets = () => {
    return (
      <>
        {
          presets.map(({ label, startDate, endDate }) => {
            const isSelected = startDate.isSame(range[0], 'day') && endDate.isSame(range[1], 'day');
            return (
              <div key={label} className="presets-item">
                <button
                  className={`${isSelected ? 'is-selected' : ''}`}
                  type="button"
                  onClick={() => onDatesChange({ startDate, endDate })}
                >{label}</button>
              </div>
            );
          })
        }
      </>
    );
  };

  return (
    <Wrapper className="date-range">
      <DateRangePicker
        displayFormat="MM/DD/YY"
        startDate={startDate ? moment(startDate) : null}
        startDateId="startDateId"
        endDate={endDate ? moment(endDate) : null}
        endDateId="endDateId"
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={focusedInput => setFocusedInput(focusedInput)}
        renderCalendarInfo={renderDatePresets}
        calendarInfoPosition="before"
        hideKeyboardShortcutsPanel
        isOutsideRange={() => false}
      />
      <button type="button" onClick={reset} className="clear-date">x</button>
    </Wrapper>
  );
};

DateRange.propTypes = {
  range: PropTypes.array.isRequired,
  onChange: PropTypes.func
};

export default DateRange;
