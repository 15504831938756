import styled from 'styled-components';
import fonts from '../../config/fonts';

export default styled.div`
  &.date-range {
    .DateRangePickerInput {
      border-radius: 4px;
      border: solid 1px #e2e2e2;
      background: #fff;
      height: 32px;
    }
    .DateInput {
      background: none;
      width: 90px;
    }
    .DateInput_input {
      font: 13px ${fonts.proximaNova};
      color: #818fa9;
      cursor: pointer;
      border: 0;
      padding: 7px 10px;
      background-color: transparent;

      &::-webkit-input-placeholder {
        color: #818fa9;
      }
      &:-ms-input-placeholder {
        color: #818fa9;
      }
      &::placeholder {
        color: #818fa9;
      }
    }
    .DateRangePicker_picker,
    .DateInput_fang {
      margin-top: -24px;
    }
    .DateRangePickerInput_arrow_svg {
      fill: #818fa9;
    }

    .CalendarDay__selected_span {
      background: #4a90e2;
      color: white;
      border: 1px solid #3f78bb;
    }
    .CalendarDay__selected {
      background: #3f78bb;
      color: white;
      border: 1px solid #3f78bb;
    }
    .CalendarDay__hovered_span:hover,
    .CalendarDay__hovered_span {
      background: #4a90e2;
      color: white;
      border: 1px solid #3f78bb;
    }
    .DayPicker_calendarInfo__horizontal {
      padding: 10px 0 0;

      .presets-item {
        border-bottom: 1px solid #e4e7e7;

        button {
          display: block;
          width: 100%;
          padding: 10px;
          border: 0;
          cursor: pointer;
          text-align: left;
          background-color: transparent;
          color: #484848;
          outline: none;

          &.is-selected {
            background: #3f78bb;
            color: white;
          }
        }
      }

      & + .DayPicker_wrapper__horizontal {
        border-left: 1px solid #e4e7e7;
      }
    }

    .clear-date {
      width: 32px;
      height: 32px;
      border-radius: 4px;
      border: solid 1px #e2e2e2;
      background: #fff;
      color: #818fa9;
      font: 13px ${fonts.proximaNova};
      outline: none;
      cursor: pointer;
    }
  }
`;
