import React from 'react';
import PropTypes from 'prop-types';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
// import ReportIcon from '../../assets/images/ic-report';
import IconTrash from '@/assets/images/ic-trash.svg';
import CloseIcon from '@/assets/images/ic-close';
import WaitingIcon from '@/assets/images/ic-waiting';

const AllowancesPart = ({
  allowances,
  requests,
  setNewAllowanceRequest,
  getUnitsReportNewAllowanceRequests
}) => {
  const showConfirm = ({ countryId, leagueId }) => {
    confirmAlert({
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-modal">
            <div className="close-icon">
              <button type="button" onClick={onClose}>
                <CloseIcon />
              </button>
            </div>
            <div className="body">
              <p>Do you want to request removing this league <br/>from your units allowances?</p>
              <div className="buttons">
                <button className="button" onClick={onClose}>No</button>
                <button
                  className="button"
                  onClick={async () => {
                    await setNewAllowanceRequest([{ countryId, leagueId, forRemove: true, note: '' }]);
                    getUnitsReportNewAllowanceRequests();
                    onClose();
                  }}
                >
                  Yes, Delete it!
                </button>
              </div>
            </div>
          </div>
        );
      }
    });
  };

  return (
    <div className="allowances-part">
      <table cellPadding="0" cellSpacing="0">
        <thead>
          <tr>
            <th>% of Your Bets</th>
            <th>Shortcut</th>
            <th>League Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {allowances.map(allowance => {
            const { precentage, shortCut, leagueName, countryId, leagueId } = allowance;
            const isPending = requests.some(r => r.countryId === countryId && r.leagueId === leagueId && r.status.toLowerCase() === 'pending');
            return (
              <tr key={`${precentage}/${shortCut}`}>
                <td>{precentage}{`${Number(precentage) === 0 ? '' : '%'}`}</td>
                <td>{shortCut}</td>
                <td>{leagueName}</td>
                <td>
                  {
                    isPending ?
                    <WaitingIcon/> :
                    <button className="btn-remove-allowance" type="button" onClick={() => showConfirm(allowance)}><img src={IconTrash} alt="remove" /></button>
                  }
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

AllowancesPart.propTypes = {
  allowances: PropTypes.array.isRequired
};

export default AllowancesPart;
