/**
 * External dependencies
 */
import http from '@/services/http-service';

/**
 * Internal dependencies
 */

export const callBetAPI = async () => {
  const result = await http.post('/GetStatisticsGames')
    .then(response => response)
    .catch(err => err);

  return result.data;
};

// TODO: add error handling here
export const callResultsAPI = async (eventId) => {
  // place to edit for testing with mocked data
  const result = await http.post('/GetStatisticsResults', {
    event_id: eventId
  })
    .then(response => response )
    .catch(err => err);

  return result.data.result ? result.data.result.results : [];
};

export const callEventInPlayAPI = async (eventId) => {
  // place to edit for testing with mocked data
  const result = await http.post('/GetStatisticsEvents', {
    fi: eventId
  })
    .then(response => response)
    .catch(err => err);

    return result.data.result ? result.data.result.results : [];
  };
