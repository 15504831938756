import { change, reset } from 'redux-form';
import _get from 'lodash/get';
import http from '@/services/http-service';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';

import { OPEN_MODAL } from './view';

export const CONTACT_US_SEND_SUCCESS = 'contactus/CONTACT_US_SEND_SUCCESS';
export const CONTACT_US_SEND_FAIL = 'contactus/CONTACT_US_SEND_FAIL';
export const PRELOAD_CONTACT_FORM = 'contactus/PRELOAD_CONTACT_FORM';

export const preloadContactForm = () => async (dispatch, getState) => {
  const state = getState();
  const profileData = _get(state, 'user.account', {});

  dispatch(change('contactForm', 'firstname', profileData.firstName));
  dispatch(change('contactForm', 'lastname', profileData.lastName));
  dispatch(change('contactForm', 'phone', profileData.phone));
  dispatch(change('contactForm', 'email', profileData.email));
};
export const contactUs = ({
  firstname, lastname, email, phone, message
}) => async (dispatch) => {
  const data = {
    firstname,
    lastname,
    email,
    phone,
    message
  };

  let trace;

  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetContactUs');
      trace.start();
    }

    const { data: { error }} = await http.post('/SetContactUs', data);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0 && error.errorCode !== 6) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: CONTACT_US_SEND_SUCCESS
    });
    dispatch({
      type: OPEN_MODAL,
      payload: 'contact',
    });
    dispatch(reset('contactForm'));
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
    dispatch({
      type: CONTACT_US_SEND_FAIL,
      payload: error.message
    });
  }
};
