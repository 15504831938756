import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { lifecycle, compose, withProps } from 'recompose';

import PicksStats from '@/components/PicksStats';
import { getMyPicksLast } from '@/store/actions/picks';
import { getMyFinanceInfo } from '@/store/actions/finance';

const mapStateToProps = ({ picks: { stats }, finance: { myfinance: { balance, totalPayed }}}) => ({
  stats,
  balance,
  totalPayed: totalPayed ? totalPayed.split(' ')[0] : ''
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getMyPicksLast,
    getMyFinanceInfo
  },
  dispatch
);

let timer;
const props = withProps(ownProps => {

  const startAutoFetch = () => {
    ownProps.getMyPicksLast();
    ownProps.getMyFinanceInfo();
    timer = setTimeout(startAutoFetch, 30000); // refresh every 30 seconds
  };

  const stopAutoFetch = () => clearTimeout(timer);

  return {
    startAutoFetch,
    stopAutoFetch
  };
});

const lifecycleMethods = lifecycle({
  componentDidMount () {
    this.props.startAutoFetch();
  },

  componentWillUnmount () {
    this.props.stopAutoFetch();
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  props,
  lifecycleMethods
)(PicksStats);
