import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import PagesWrapper from '../../components/PagesWrapper';
import PrivateRoute from '../../components/PrivateRoute';
import MyStats from '../MyStats';
import ContactUs from '../ContactUs';
import AboutUs from '../AboutUs';
import DownloadApp from '../DownloadApp';
import MobileApp from '../MobileApp';
import Affiliates from '../Affiliates';
import Profile from '../Profile';
import Finance from '../Finance';
import HowItWorks from '../HowItWorks';
import TermsOfUse from '../TermsOfUse';
import Messages from '../Messages';
// import Statistics from '../Statistics';
import UnitsReport from '../UnitsReport';
import LastPicks from '../../containers/LastPicks';
import CreateNewPick from '../../containers/CreateNewPick';
import PicksStats from '../../containers/PicksStats';
import MyPicks from '../../containers/MyPicks';
import ApproveEmailModal from '../../containers/ApproveEmailModal';
// import NotificationWidget from '../../containers/NotificationWidget/NotificationWidget';
import FloatChat from '@/containers/Chat/FloatChat';
// import OddsAlerts from '../../containers/OddsAlerts/OddsAlerts';
import {connect} from "react-redux";
import {checkAccess, listOfSection} from "@/helpers/access";
import {compose} from "recompose";
import NewStatisticsPage from '../NewStatistics';

const Home = () => (
    <div className="content">
        <h1 className="title">My Picks</h1>
        <LastPicks/>
        <PicksStats/>
        <MyPicks/>
    </div>
);

const HomePage = ({ userLevel, hasTerms }) => {
    const accessData = checkAccess(userLevel, hasTerms);
    return (
        <PagesWrapper>
            {accessData[listOfSection.MyPicks] ?
                <CreateNewPick/> : null}

            {accessData[listOfSection.Affiliates] ?
                <>
                    {accessData[listOfSection.MyPicks] ?
                        <PrivateRoute exact path="/" component={Home}/>
                        : <PrivateRoute exact path="/" component={Affiliates}/>
                    }
                </>
                : hasTerms ? <PrivateRoute exact path="/" component={Home}/> : null}

            {accessData[listOfSection.MyStats] ?
                <PrivateRoute exact path="/my-stats" component={MyStats}/> : null}
            {accessData[listOfSection.Finance] ?
                <PrivateRoute exact path="/finance" component={Finance}/> : null}
            {accessData[listOfSection.Affiliates] ?
                <PrivateRoute exact path="/affiliates" component={Affiliates}/> : null}
            {accessData[listOfSection.UnitsReport] ?
                <PrivateRoute exact path="/units-report" component={UnitsReport}/> : null}
            {accessData[listOfSection.DownloadApp] ?
                <PrivateRoute exact path="/download-app" component={DownloadApp}/> : null}
            {accessData[listOfSection.MobileApp] ?
                <PrivateRoute exact path="/mobile-app" component={MobileApp}/> : null}
            {accessData[listOfSection.Messages] ?
                <PrivateRoute exact path="/messages" component={Messages}/> : null}
            {/*{accessData[listOfSection.Statistics]*/}
            {/*  ? <PrivateRoute exact path="/statistics" component={Statistics}/>*/}
            {/*  : null}*/}
            {accessData[listOfSection.Statistics]
              ? <PrivateRoute exact path="/new-statistics" component={NewStatisticsPage}/>
              : null}
            {/* {accessData[listOfSection.OddsAlert] ?
                <PrivateRoute exact path="/odds-alerts" component={OddsAlerts}/> : null} */}

            <PrivateRoute exact path="/about-us" component={AboutUs}/>
            <PrivateRoute exact path="/contact-us" component={ContactUs}/>
            <PrivateRoute exact path="/how-it-works" component={HowItWorks}/>
            <PrivateRoute exact path="/terms-of-use" component={TermsOfUse}/>

            <Route exact path="/approve-email/:token" component={ApproveEmailModal}/>

            <Switch>
                <PrivateRoute exact path="/edit-profile" component={Profile}/>
                { !hasTerms && <Redirect exact from="/" to="/edit-profile" /> }
            </Switch>
            {/* <NotificationWidget /> */}
            {accessData[listOfSection.ChatWithManager] ?
                <FloatChat
                    apiKey="manager"
                    theme="blue"
                    title="Chat with manager"
                    offsetY={-70}
                /> : null}

            {accessData[listOfSection.ChatWithTrader] ?
                <FloatChat
                    apiKey="trader"
                    theme="purple"
                    title="Chat with trader"
                    showDate={false}
                /> : null}

        </PagesWrapper>
    )
};

const mapStateToProps = ({ user, profile }) => ({
    userLevel: user.account.userLevel,
    hasTerms: profile.hasTerms
});


export default compose(
    connect(
        mapStateToProps
    )
)(HomePage);
