import React, { Fragment } from 'react';
import NewStatistics from '../../components/NewStatistics';

const NewStatisticsPage = () => (
  <Fragment>
    <NewStatistics />
  </Fragment>
);

export default NewStatisticsPage;
