import http from '@/services/http-service';
import { history } from '../store.js';
import { getToken, unsubscribe } from '@/services/firebase';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';
import { USER_FULL_MEMBER_LEVEL } from '@/helpers/access';

import { openModal } from './view';
import { getUserAccountInfo } from './user';
import { setHasTerms } from './profile';

import { stopAutoFetch as stopAutoFetchUnreadCountTrader } from './chat-with-trader';
import { stopAutoFetch as stopAutoFetchUnreadCountManager } from './chat-with-manager';

export const LOGIN_REQUEST = 'user/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE';

export const RESTORE_PASSWORD_REQUEST = 'user/RESTORE_PASSWORD_REQUEST';
export const RESTORE_PASSWORD_SUCCESS = 'user/RESTORE_PASSWORD_SUCCESS';
export const RESTORE_PASSWORD_FAILURE = 'user/RESTORE_PASSWORD_FAILURE';

export const LOGOUT = 'user/LOGOUT';

export const SET_UTC_OFFSET = 'user/SET_UTC_OFFSET';

/**
 * Authorization function
 * @async
 * @function
 * @param {object} user - User.
 * @param {string} user.userEmail - User email from input.
 * @param {string} user.userPassword - User password from input.
 */
export const logIn = ({ userEmail, userPassword }) => async dispatch => {
  dispatch({ type: LOGIN_REQUEST });

  let deviceToken = '';

  try {
    deviceToken = await getToken();
    console.log('%cdeviceToken', 'color:red', deviceToken);
  } catch (error) {
    console.warn(error);
  }
  let trace;

  try {
    const params = {
      userEmail,
      userPassword,
      deviceToken,
      notificationType: 2
    };
  
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: {
      error, authorizationKey, userId, tiltMode = true, userLevel
    }} = await http.post('/WebLogin', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0 && error.errorCode !== 6) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    localStorage.setItem('userId', userId);
    localStorage.setItem('authorizationKey', authorizationKey);
    localStorage.setItem('tiltMode', tiltMode ? '1' : '0');

    dispatch({
      type: LOGIN_SUCCESS,
      payload: { authorizationKey, userId, tiltMode: tiltMode ? '1' : '0' }
    });

    const passwordToken = localStorage.getItem('passwordToken');

    if (passwordToken) {
      history.push(`approve-email/${passwordToken}`);
      localStorage.removeItem('passwordToken');
    } else if (error.errorCode === 6 && userLevel === USER_FULL_MEMBER_LEVEL) {
      dispatch(setHasTerms(false));
      history.push('/edit-profile');
    } else {
      dispatch(setHasTerms(true));
      history.push('/');
    }

    dispatch(getUserAccountInfo());

  } catch (error) {
    console.error("Login error:", error)
    
    dispatch({
      type: LOGIN_FAILURE,
      payload: { errorMessage: error.message }
    });

    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
  }
};

/**
 * Logout function
 * @function
 */
export const logOut = () => async dispatch => {
  unsubscribe();
  localStorage.removeItem('userId');
  localStorage.removeItem('authorizationKey');
  localStorage.removeItem('tiltMode');
  stopAutoFetchUnreadCountTrader();
  stopAutoFetchUnreadCountManager();
  dispatch({ type: LOGOUT });
  history.push('/login');
};


export const setUtcOffset = value => dispatch => {
  dispatch({
    type: SET_UTC_OFFSET,
    payload: value
  });
};

/**
 * Sending the Restore password requset from the form
 * @async
 * @function
 * @param {object} user
 * @param {string} user.email - An email from input
 */
export const restorePassword = ({ email }) => async dispatch => {
  dispatch({ type: RESTORE_PASSWORD_REQUEST });
  const params = { email };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('ForgotPassword');
      trace.start();
    }

    const { data: { error }} = await http.post('/ForgotPassword', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({ type: RESTORE_PASSWORD_SUCCESS });
    dispatch(openModal('restore'));

  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn('catch', error);

    dispatch({
      type: RESTORE_PASSWORD_FAILURE,
      payload: { errorMessage: error.message }
    });
  }
};
