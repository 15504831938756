/**
 * Internal dependencies
 */
import { COLOR_KIT, setOfDefaultColors } from '@/utils/bet365/constants';

export const getColors = (teamStats) => {
  if (
    teamStats.length &&
    teamStats[0][COLOR_KIT] &&
    teamStats[1] &&
    teamStats[1][COLOR_KIT]
  ) {
    return {
      primaryColorHome: teamStats[0][COLOR_KIT].split(',')[0],
      secondaryColorHome:teamStats[0][COLOR_KIT].split(',')[1] || teamStats[0][COLOR_KIT].split(',')[0],
      primaryColorAway: teamStats[1][COLOR_KIT].split(',')[0],
      secondaryColorAway: teamStats[1][COLOR_KIT].split(',')[1] || teamStats[1][COLOR_KIT].split(',')[0]
    }
  } else {
    return setOfDefaultColors
  }
}
