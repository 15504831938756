import styled from 'styled-components';

import colors from '../../config/colors';
import fonts from '../../config/fonts';

export default styled.div`
  background-color: #fff;
  padding: 13px 19px 5px;
  width: 100%;
  color: ${colors.secondaryBlue};

  .bet-settings-checkbox-wrapper {
    position: relative;
    display: flex;
    margin-top: 0 !important;

    .picks-checkbox {
      margin: 0 5px 0 0 !important;
    }
  }

  .info-container {
    margin: 0px !important;

    img {
      width: 18px;
      height: 18px;
    }
  }

  .info-container:hover::before {
    content: '';
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 30px;
    left: 5px;
    background-color: rgba(0, 0, 0, .6);
    width: 300px;
    height: 60px;
    z-index: 9999;
    border-radius: 7px;
    padding: 6px;
    color: white;
    font-size: 14px;
  }

  .setting-highValues {
    .info-container:hover::before {
      content: "When you believe this bet is a very high value bet, much larger than usual situations/bets.";
    }
  }

  .setting-teamNews {
    .info-container:hover::before {
      content: "When you believe the news you've received about the game is new/recent and the market will drop soon due to this information.";
      height: 45px;
    }
  }

  .setting-urgentBet {
    .info-container:hover::before {
      content: "Check this box if you expect the market to drop soon due to any reason.";
      height: 45px;
    }
  }

  .title {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;

    img {
      margin-right: 10px;
    }
  }

  .note {
    padding: 20px;
    margin-top: 25px;
    background-color: #edeff5;
    border: 1px solid #dde0e6;

    a {
      color: #4A90E2;
    }
  }

  .search {
    position: relative;
    margin-top: 15px;

    input {
      width: 100%;
      height: 40px;
      border: 1px solid #dbdbdb;
      font-size: 14px;
      color: ${colors.secondaryBlue};
      border-radius: 4px;
      padding: 1px 13px;
      outline: none;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #818fa9;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #818fa9;
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #818fa9;
      }

      &:hover {
        border-color: rgba(74, 144, 226, 0.5);

        & + button g {
          stroke: #a5c8f1;
        }
      }

      &:focus,
      &:active {
        border-color: ${colors.thirdBlue};

        & + .arrow g {
          stroke: ${colors.thirdBlue};
        }

        & + .arrow + .prediction {
          visibility: visible;
          z-index: 99;
          opacity: 1;
        }
      }
    }

    .arrow {
      height: 40px;
      line-height: 40px;
      background: rgba(0, 0, 0, 0);
      border: 0;
      position: absolute;
      padding: 0;
      right: 13px;
      top: 0;
      outline: none;
      pointer-events: none;

      svg {
        vertical-align: middle;
      }
    }
  }

  .prediction {
    max-height: 351px;
    overflow-y: scroll;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 40px;
    width: 100%;
    transition: visibility 0.4s ease;
    z-index: 9999;


    .is-live-marker {
      display: inline-block;
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: #ff606e;
      margin-right: 4px;
      margin-bottom: 1px;
      padding:0;
    }

    .group-title {
        font-size: 14px;
        font-weight: bold;
        margin-left: 5px;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        height: 46px;
        line-height: 46px;
        padding: 0 9px;
        position: relative;
        cursor: pointer;
        font-size: 14px;

        &:after {
          content: '';
          height: 1px;
          width: calc(100% - 18px);
          background-color: rgba(151, 151, 151, 0.22);
          position: absolute;
          left: 9px;
          bottom: 0;
        }

        span {
          padding: 0 3px;
        }
      }
    }
  }

  .selects {
    margin-top: 25px;
  }

  .type-of-game {
    margin-right: 50px;

    @media screen and (max-width: 794px) {
      margin-bottom: 15px;
    }
  }

  .market-day {
    span,
    select {
      margin-right: 15px;
    }

    span {
      font-size: 14px;
      font-family: ${fonts.proximaNova};
      font-weight: 600;
    }
  }

  .alerts-form {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin-bottom: 20px;
    margin-top: 20px;

    svg {
      margin-right: 15px;
    }

    input[name=oddsReaches] {
      padding: 1px 13px;
    }


    select {
      min-width: 66px !important;
      display: block;
      margin: 0 15px;
    font-family: ${fonts.proximaNova};
    font-weight: 600;
    color: ${colors.secondaryBlue};
    outline: none;
    appearance: none;
    height: 32px;
    min-width: 135px;
    border: solid 1px #dbdbdb;
    background-color: #fff;
    border-radius: 0;
    font-size: 14px;
    padding: 1px 13px;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 9px);
    background-position-y: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23818FA9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");

    &:hover {
      border-color: #a5c8f1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23A5C8F1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    &:active,
    &:focus {
      border-color: #4a90e2;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='15' viewBox='0 0 7 15'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%234A90E2' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 5l2.5-3L6 5M1 10l2.5 3L6 10'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

    input {
      width: 66px;
      height: 32px;
      border: 1px solid #dbdbdb;
      margin: 0 15px;
      font-family: ${fonts.proximaNova};
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #38415f;
    }

    button {
      height: 36px;
      margin-left: 10px;
    }
  }

  .expand {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;

    span {
      margin-right: 15px;
      font-size: 12px;
      color: ${colors.lightBlue};
      font-weight: 600;
    }

    button {
      background-color: rgba(0, 0, 0, 0);
      border: 0;
      display: flex;
      align-items: center;
      outline: none;
      cursor: pointer;

      &:hover {
        span {
          text-decoration: underline;
          color: ${colors.thirdBlue};
        }

        g {
          stroke: ${colors.thirdBlue};
        }
      }

      &:active,
      &:focus {
        span {
          text-decoration: underline;
          color: ${colors.mainBlue};
        }

        g {
          stroke: ${colors.mainBlue};
        }
      }
    }
  }

  .new-pick-more {
    border-top: 2px solid #dde0e6;
    margin: 5px -19px 0;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;

    .selected-league {
      flex: 0 100%;
      padding-left: 5px;
      font-weight: bold;
      margin-bottom: 10px;
      font-size: 20px;
    }
  }

  .new-pick-left {
    flex: 0 0 calc(65% - 20px);
    max-width: calc(65% - 20px);
    padding-right: 20px;
  }

  .picks-table {
    height: 241px;

    tbody {
      max-height: 231px;
      position: relative;
      font-size: 14px;
      line-height: 2em;

      td {
        padding: 5px 10px;
      }
    }

    thead th {
      padding-bottom: 8px;
    }

    .under-total,
    .over-total {
      display: inline-block;
      min-width: 70px;
      display: flex;
      padding-right: 5px;

      .right {
        display: inline-block;
        margin-left: auto;
        padding-left: 5px;
      }
    }

    .expand-btn {
        background-color: transparent;
        border: none;
        outline: none;
    }

    .expand-img {
        height: 26px;
        width: 26px;
    }

    .teams-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .teams {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .game-date {
      width: 20%;

      .islive-indicator {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #b70000;
        margin: 0 5px 0 0;

        animation-name: pulselivedot;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }
    .game-teams {
        width: 40%;
    }
    .game-events {
        width: 15%
    }

    tbody tr.limit-row > td {
      border-top: 1px solid black;
    }
    tbody tr.first-child > td {
      border-top: none;
    }
  }

  .picks-form-wrapper {
    background-color: #edeff5;
    border: 1px solid #dde0e6;
    margin-top: 23px;
    padding: 5px 0 10px 19px;
  }

  .picks-form {
    display: flex;
    flex-wrap: wrap;
    //max-width: 600px;

    .bet-settings-row {
      flex-wrap: wrap;
      align-items: center;

      .additional-settings-row {
        display: flex;
        align-items: center;
        margin-top: 0 !important;
      }
    }

    & > .form-row  {
      display: flex;
      width: 100%;

      div {
        margin-top: 13px;
        margin-right: 12px;
      }

      .pick-team-wrapper {
        flex: 0 50%;
      }

      .wrapper-pick-league {
        width: 100%;
      }
    }

    input,
    select,
    span {
      border: 1px solid #edeff5;
    }

    input[type='checkbox'] {
      width: 19px;
      height: 19px;
      top: 0;
      position: absolute;

      & + span {
        width: 19px;
        height: 19px;
        top: 0;

        &:after {
          width: 19px;
          height: 19px;
        }
      }
    }

    select {
      min-width: 95px;
    }

    input[type='text'] {
      min-width: 67px;
      padding: 1px 5px;

      &.pick-score {
        min-width: 60px;
        text-align: center;
      }
      &.minute-ir {
        min-width: 48px;
        text-align: center;
      }

      &.pick-input-mid {
        min-width: 76px;
      }

      &.pick-notes {
        min-width: 286px;
        width: 90%;
      }

      &.pick-date {
        min-width: 70px;
        text-align: center;
      }

      &.pick-league {
        width: 100%;
      }
      &.pick-team {
        width: 100%;
      }
    }

    .clear-data-wrapper {
      flex-basis: 100%;
      margin-bottom: 0;
      margin-top: 5px;
    }

    a {
      color: ${colors.thirdBlue};
      font-size: 12px;
      font-weight: bold;

      &:hover {
        color: ${colors.azul};
      }

      &:active {
        color: ${colors.mainBlue};
      }
    }
  }

  .send-btn-wrapper {
    text-align: right;
    margin-top: 15px;
  }

  .send-btn {
    min-width: 135px;
    height: 40px;
    background-image: linear-gradient(107deg, #8e70ee, #4a7ece);
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    font-size: 15px;
    color: #fff;
    border: none;
    font-weight: 300;

    &:hover {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:active,
    &:focus {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
      outline: none;
    }
  }



  .new-pick-right {
    flex: 0 0 35%;
    max-width: 35%;
  }

  .chat-wrapper {
	  height: 100%;
	  display: flex;
    flex-direction: column;
   }
  .chat-title {
    margin: 0 0 5px;
    font-size: 15px;
    line-height: 20px;
  }
  .chat {
  	flex: 1 1 auto;
  	display: flex;
  	flex-direction: column;
  	border: 1px solid #dde0e6;
  	background-color: #edeff5;
  }
  .chat-body {
  	flex: 1 1 auto;
  	max-height: 90vh;
  	padding: 20px 15px 0;
  	overflow-y: auto;
  }
  .chat-item {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-bottom: 15px;
  }
  .chat-item:last-child {
    margin-bottom: 0;
  }
  .chat-item-author {
    flex: 0 0 35px;
    max-width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    background-color: #ffffff;
    border-radius: 50%;
  }
  .chat-item-author img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    object-fit: contain;
  }
  .chat-item-body {
		flex: 0 1 auto;
		max-width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .chat-item-message {
		position: relative;
		margin-bottom: 5px;
		padding: 15px 20px;
		background-color: #ffffff;
		border-radius: 3px;
		font-size: 14px;
		line-height: 1.5;
	}
	.chat-item-message:last-child {
		margin-bottom: 0;
	}
	.chat-item-message:last-child:before {
		position: absolute;
		left: 0;
		bottom: 0;
		transform: translateX(-100%);
		display: block;
		content: '';
		width: 0;
		height: 0;
		border-bottom: 6px solid #ffffff;
		border-left: 6px solid transparent;
		border-right: none;
	}

  .chat-item_own {
    flex-direction: row-reverse;
  }
  .chat-item_own .chat-item-author {
    margin-right: 0;
    margin-left: 10px;
  }
  .chat-item_own .chat-item-message:last-child:before {
    left: auto;
    right: 0;
		transform: translateX(100%);
		border-right: 6px solid transparent;
		border-left: none;
  }
  .chat-item_own .chat-item-body {
    align-items: flex-end;
  }
  .chat-date {
    margin: 15px 0;
    padding: 10px;
    border-bottom: 1px solid #dde0e6;
    text-align: center;
    font-weight: bold;
  }

  .chat-action {
  	padding: 15px 20px 20px;
  }
  .chat-form {
  	display: flex;
  }
  .chat-input {
  	flex: 1 1 auto;
  	height: 40px;
  	padding: 15px;
  	background: #ffffff;
  	border: none;
  	font-size: 14px;
  	color: #646464;
  }
  .chat-btn {
  	flex: 0 0 135px;
  }
  .btn {
  	display: inline-flex;
  	align-items: center;
  	justify-content: center;
  	height: 40px;
  	padding: 5px 15px;
  	background-image: linear-gradient(-101deg, #4e7dd0 0%, #8a71ec 100%);
  	border: none;
  	text-align: center;
  	font-size: 14px;
  	line-height: 1.5;
  	font-weight: 400;
  	color: #ffffff;
  	cursor: pointer;
  }
  .btn:hover, .btn:active, .btn:focus {
  	background-image: linear-gradient(105deg,#7b57ee,#306ece);
  }

  .pick-modal {
    color: ${colors.secondaryBlue};
    margin-left: 200px;

    .content-wrapper {
      display: flex;
      align-items: center;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      display: inline-block;
      margin-right: 140px;
    }

    .title-wrapper {
      display: flex;
      align-items: center;

      label {
        margin-right: 8px;
        font-family: ${fonts.proximaNova};
        font-size: 14px;
      }

      select {
        min-width: 66px;
      }
    }

    .select-wrapper {
      display: flex;
      align-items: center;
    }

    .zoom-text {
      font-size: 12px;
      font-weight: 600;
      color: #9b9b9b;
      font-family: ${fonts.proximaNova};
    }
  }

  .picks-checkbox {
    label {
      margin-top: 0;
    }
  }

  .hide {
    display: none;
  }

  .picks-form-wrapper,
  .picks-table {
    min-width: 532px;

    @media screen and (max-width: 1439px) {
      margin-right: 0;
      margin-left: 0;
      margin-top: 0;
      min-width: 100%;
      margin-bottom: 20px;
    }
  }

  label[for='pick-over'] {
    text-transform: capitalize;
    font-weight: 600;
  }

  .picks-table {
    svg {
      cursor: pointer;
    }

    span {
      &:hover {
        svg rect {
          fill: ${colors.thirdBlue};
          fill-opacity: 0.2;
        }
      }

      &:active {
        svg {
          rect {
            fill-opacity: 1;
          }

          path {
            fill: ${colors.white};

            &[stroke-linecap='round'] {
              stroke: ${colors.white};
            }
          }
        }
      }
    }
  }

  .no-data-text {
    display: block;
    font-size: 24px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px !important;
  }

  @keyframes pulselivedot {
    0% {
      background-color: #b70000;
    }
    50% {
      background-color: #ff3131;
    }
    100% {
      background-color: #b70000;
    }
  }
`;
