import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';

import PieChart from './PieChart';

import GeneralInfo from './GeneralInfo';
import BetsByLeagues from './BetsByLeagues';

const MyStats = ({
  mystats: {
    general,
    betsByLeague,
    chartError,
    betsError,
    generalError,
    profitChartRange,
    pieCharts: {
      pieLeaguesByUnits,
      pieByMarket,
      pieByBetType,
      pieByHandicup,
      pieByTotal
    }
  },
  picks: {
    stats: {
      totalBet,
      totalRoi,
      totalWin
    }
  },
  getGeneralBetsByLeague,
  getProfitChartData,
  getGeneralInfo,
  options,
  loading,
  setProfitChartRange
}) => {
  return (
    <Wrapper>
      <div className="block">
        <div className="pie-charts">
          <PieChart
            title="Top 20 leagues"
            totalBet={totalBet || '0'}
            totalRoi={totalRoi}
            totalWin={totalWin}
            data={pieLeaguesByUnits}
            nameKey="lig"
            labelFormat="<b>{point.name}</b>"
            labelDistance={10}
            labelY={-5}
          />
        </div>
        <div className="pie-charts">
          <PieChart
            title="By handicup"
            description={
              <div className="center">
                <b>Minus:</b> -0.5 and less<br/>
                <b>Plus:</b> +0.5 and up<br/>
                <b>Neutral:</b> -0.25, 0, +0.25
              </div>
            }
            data={(pieByHandicup || []).map(point => {
              let color = undefined;
              switch (point.hcGroup.toLowerCase()) {
                case 'plus':
                  color = '#228B22';
                  break;
                case 'minus':
                  color = '#DC143C';
                  break;
                case 'neutral':
                  color = '#FFD700';
                  break;
                // no default
              }
              return { ...point, color };
            })}
            nameKey="hcGroup"
            labelFormat="<b>{point.name}</b><br/>{point.y}<br/>{point.unitsWon}<br/>{point.roi}<br/>"
          />
          <PieChart
            title="By markets"
            data={pieByMarket}
            nameKey="market"
            labelFormat="<b>{point.name}</b><br/>{point.y}<br/>{point.unitsWon}<br/>{point.roi}<br/>"
          />
          <PieChart
            title="By bet type"
            data={pieByBetType}
            nameKey="bet"
            labelFormat="<b>{point.name}</b><br/>{point.y}<br/>{point.unitsWon}<br/>{point.roi}<br/>"
          />
          <PieChart
            title="By totals"
            data={pieByTotal}
            nameKey="bet"
            labelFormat="<b>{point.name}</b><br/>{point.y}<br/>{point.unitsWon}<br/>{point.roi}<br/>"
          />
        </div>
      </div>

      <GeneralInfo
        loading={loading}
        general={general}
        reloadChart={getProfitChartData}
        reloadGeneral={getGeneralInfo}
        generalError={generalError}
        chartError={chartError}
        options={options}
        range={profitChartRange}
        setRange={setProfitChartRange}
      />
      <BetsByLeagues
        betsByLeague={betsByLeague}
        betsError={betsError}
        generalError={generalError}
        general={general}
        reloadBets={getGeneralBetsByLeague}
        onChange={getGeneralBetsByLeague}
      />
    </Wrapper>
  );
};

MyStats.propTypes = {
  mystats: PropTypes.object.isRequired,
  getGeneralBetsByLeague: PropTypes.func.isRequired,
  getProfitChartData: PropTypes.func.isRequired,
  getGeneralInfo: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

export default MyStats;
