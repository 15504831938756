import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import { reduxForm } from 'redux-form';

import {
  getMemberUnitsReportCountries,
  getMemberUnitsReportLeaguesByCountry,
  setNewAllowanceRequest,
  setNewAllowancesAllLeaguesRequest,
  getUnitsReportNewAllowanceRequests,
  getSearchLeagues,
} from '../../store/actions/report';
import { closeModal } from '../../store/actions/view';

import newAllowancesModal from '../../components/UnitsReport/NewAllowancesModal';

const mapStateToProps = ({ report }) => ({
  countries: report.countries,
  leagues: report.leagues,
  requests: report.requests,
  searchResults: report.searchResults,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getMemberUnitsReportCountries,
  getMemberUnitsReportLeaguesByCountry,
  setNewAllowanceRequest,
  setNewAllowancesAllLeaguesRequest,
  getUnitsReportNewAllowanceRequests,
  getSearchLeagues,
  closeModal,
}, dispatch);

const cLifecycle = lifecycle({

});

const form = reduxForm({
  form: 'newAllowanceForm',
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  cLifecycle,
  form,
)(newAllowancesModal);
