import React, { Fragment } from 'react';
import MyStatsContainer from '../../containers/MyStats';

const MyStats = () => (
  <Fragment>
    <MyStatsContainer />
  </Fragment>
);

export default MyStats;
