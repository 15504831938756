import React from "react";

import Wrapper from "../OldSiteButton/styles";

const MobileSiteButton = () => {
  return (
    <Wrapper>
      <div className="back-container">
        <a href={process.env.REACT_APP_MOBILE_APP}>
          <button className="send-btn" type="button">
            <span className="text-btn">Go to mobile site</span>
          </button>
        </a>
      </div>
    </Wrapper>
  );
};

export default MobileSiteButton;
