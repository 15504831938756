import styled from 'styled-components';

export default styled.div`
  margin-top: 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 35px;

  .item {
    min-width: 250px;
    width: calc(20% - 32px);
    height: 140px;
    border-radius: 5px;
    background-image: linear-gradient(299deg, #4a7ece, #8e70ee);
    background-color: #8e70ee;
    box-shadow: 0 5px 12px 0 rgba(73, 42, 139, 0.37);
    position: relative;
    margin: 0 5px 40px;

    @media (max-width: 1339px) {
      width: calc(50% - 10px);
    }

    @media (max-width: 819px) {
      width: 100%;
    }

    img {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    &:nth-child(1) {
      margin-left: 0;
    }

    &:nth-child(2) {
      background-image: linear-gradient(119deg, #ff875e, #fe67ad);
      background-color: #fe67ad;

      @media (max-width: 1339px) {
        margin-right: 0;
      }

      @media (max-width: 819px) {
        margin-left: 0;
      }
    }

    &:nth-child(3) {
      background-image: linear-gradient(119deg, #ee70e9, #8363f9);
      background-color: #8363f9;

      @media (max-width: 1339px) {
        margin-left: 0;
      }
    }

    &:nth-child(4) {
      background-image: linear-gradient(299deg, #3870e7, #4ac3ce);
      background-color: #4ac3ce;
      margin-right: 0;

      @media (max-width: 819px) {
        margin-left: 0;
      }

      img {
        bottom: -16px;
        right: -6px;
      }
    }

    &:nth-child(2),
    &:nth-child(3) {
      img {
        right: -11px;
        bottom: -16px;
      }
    }

    span.title {
      font-size: 24px;
      font-weight: 600;
      color: #fff;
      margin: 18px 0 0 17px;
      display: block;
    }

    span.result {
      display: block;
      font-size: 36px;
      font-weight: 600;
      color: #fff;
      margin: 20px 0 0 24px;
    }
  }
`;
