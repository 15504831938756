import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './styles';
import MyMembersBet from './MyMembersBet';
import MyRecommendedMembers from './MyRecommendedMembers';

const Affiliates = ({ myMembersBet, myAffiliateInfo, myRecommendedMembers, webtemplate }) => (
  <Wrapper>
    {webtemplate.user && webtemplate.user.userlevel === 0 ? (
      <MyMembersBet
        myMembersBet={myMembersBet}
        myAffiliateInfo={myAffiliateInfo}
      />
    ) : (
      <span />
    )}
    {webtemplate.user
    && webtemplate.user.userlevel !== 0
    && myMembersBet.affiliateMembers
    && myMembersBet.affiliateMembers.length !== 0 ? (
        <MyMembersBet
          myMembersBet={myMembersBet}
          myAffiliateInfo={myAffiliateInfo}
        />
      ) : (
        <span />
      )}

    <MyRecommendedMembers
      myRecommendedMembers={myRecommendedMembers}
      myMembersBet={myMembersBet}
    />
  </Wrapper>
);

Affiliates.propTypes = {
  myMembersBet: PropTypes.object.isRequired,
  myRecommendedMembers: PropTypes.object.isRequired,
  webtemplate: PropTypes.object.isRequired,
};

export default Affiliates;
