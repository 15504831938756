import styled from 'styled-components';
import loginImage from '../../assets/images/login-image.jpg';

export default styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;

  .block {
    width: 50%;
    background-image: url(${loginImage});
    background-size: cover;
  }

  .block img {
    display: block;
    width: 50%;
    object-fit: contain;
    margin: 10% auto 0;
  }

  .auth-additional-actions {
    display: flex;
    justify-content: space-between;
  }

  .old-site-button-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .left-side-container {
    width: 50%;
    position: relative;
    top: 10px
  }

  @media screen and (max-width: 602px) {
    position: relative;

    .block {
      opacity: 0.5;
      width: 100%;
      position: absolute;
      height: 100vh;
    }

    nav {
      left: 28%;
    }
  }
`;
