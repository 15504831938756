import axios from 'axios';
import consoleError from '@/helpers/consoleError';

// const bet365 = axios.create({
//   baseURL: '/bet365',
//   params: {
//     token: '34404-OeNC8LzzEjs7v9'
//   }
// });

let autofetchTimer;
const timeout = 200000000000000;

export const SET_ACTIVE_SPORT_ID = 'statistics/SET_ACTIVE_SPORT_ID';
export const SET_SPORTS = 'statistics/SET_SPORTS';
export const SET_LEAGUES = 'statistics/SET_LEAGUES';
export const SET_EVENTS = 'statistics/SET_EVENTS';

export const SET_WATCH_EVENT = 'statistics/SET_WATCH_EVENT';
export const SET_EVENT_STATS = 'statistics/SET_EVENT_STATS';

export const SET_ACTIVE_TAB = 'statistics/SET_ACTIVE_TAB';

export const EVENTS_IN_PLAY_REQUEST = 'statistics/EVENTS_IN_PLAY_REQUEST';
export const EVENTS_IN_PLAY_SUCCESS = 'statistics/EVENTS_IN_PLAY_SUCCESS';
export const EVENTS_IN_PLAY_FAILURE = 'statistics/EVENTS_IN_PLAY_FAILURE';

export const EVENTS_UPCOMING_REQUEST = 'statistics/EVENTS_UPCOMING_REQUEST';
export const EVENTS_UPCOMING_SUCCESS = 'statistics/EVENTS_UPCOMING_SUCCESS';
export const EVENTS_UPCOMING_FAILURE = 'statistics/EVENTS_UPCOMING_FAILURE';

export const fetchInPlay = () => async dispatch => {
  try {
    const { data: { results: [ results ], success }} = await axios.get('_fake_api/bet365_inplay.json');
    // const { data: { results: [ results ], success }} = await bet365.get('/inplay');

    if (!success) {
      throw new Error('ERROR fetchInPlay');
    }

    const sports = results.filter(p => p.type === 'CL');
    const soccerID = sports.find(s => s.NA === 'Soccer').ID;
    const leagues = results.filter(p => p.type === 'CT' && p.ID === soccerID);
    const events = results.filter(p => p.type === 'EV' && leagues.some(c => p.CT === c.NA));

    console.log('%cfetchInPlay', 'color:lime', leagues, events);

    dispatch({
      type: SET_ACTIVE_SPORT_ID,
      payload: soccerID
    });
    dispatch({
      type: SET_SPORTS,
      payload: sports
    });
    dispatch({
      type: SET_LEAGUES,
      payload: leagues
    });
    dispatch({
      type: SET_EVENTS,
      payload: events
    });

    dispatch(setWatchEvent(events[0].FI)); // TODO: remove it
  } catch (error) {
    consoleError(error);
  }
};

export const setWatchEvent = event_id => dispatch => {
  dispatch({
    type: SET_WATCH_EVENT,
    payload: event_id
  });
  dispatch(getEventStats());
};

export const getEventStats = () => async (dispatch, getState) => {
  clearTimeout(autofetchTimer);

  const { statistics: { watchEventID: event_id }} = getState();

  if (!event_id) return;

  try {
    const { data: { results: [ results ], success }} = await axios.get('_fake_api/bet365_result.json');
    // const { data: { results: [ results ], success }} = await bet365.get('/result', {
    //   params: {
    //     event_id
    //   }
    // });

    if (!success) {
      throw new Error('ERROR fetchEventStat');
    }

    console.log('%cfetchEventStat', 'color:lime', results);

    dispatch({
      type: SET_EVENT_STATS,
      payload: {
        event_id,
        data: results
      }
    });
  } catch (error) {
    consoleError(error);
  } finally {
    autofetchTimer = setTimeout(() => {
      dispatch(getEventStats());
    }, timeout);
  }
};

export const deleteWatchEvent = () => async dispatch => {
  dispatch({
    type: SET_WATCH_EVENT,
    payload: ''
  });
};

export const setActiveTab = tabValue => (dispatch, getState) => {
  const { statistics: { tabs: { values }}} = getState();
  const isExistTab = values.find(value => value.value === tabValue);

  if (isExistTab) {
    dispatch({
      type: SET_ACTIVE_TAB,
      payload: tabValue
    });
  } else {
    console.warn(`Tab "${tabValue}" does not exist`);
  }
};

export const fetchEventsInPlay = () => async dispatch => {
  dispatch({
    type: EVENTS_IN_PLAY_REQUEST
  });

  try {
    const { data: { results }} = await axios.get('_fake_api/events_inplay.json');
    dispatch({
      type: EVENTS_IN_PLAY_SUCCESS,
      payload: results
    });
  } catch (error) {
    console.warn(error);

    dispatch({
      type: EVENTS_IN_PLAY_FAILURE,
      payload: { name: error.name, message: error.message }
    });
  }
};

export const fetchEventsUpcoming = () => async dispatch => {
  dispatch({
    type: EVENTS_UPCOMING_REQUEST
  });

  try {
    const { data: { results }} = await axios.get('_fake_api/events_upcoming.json');
    dispatch({
      type: EVENTS_UPCOMING_SUCCESS,
      payload: results
    });
  } catch (error) {
    console.warn(error);

    dispatch({
      type: EVENTS_UPCOMING_FAILURE,
      payload: { name: error.name, message: error.message }
    });
  }
};
