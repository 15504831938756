import styled from 'styled-components';

export default styled.div`
  position: fixed !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);

  .modal {
    background-color: #fff;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e6e6e6;
    width: 927px;
    position: relative;
    padding: 15px;
  }

  .close-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;

    button {
      border: 0;
      background-color: rgba(0, 0, 0, 0);
      cursor: pointer;
      padding: 0;
      width: 24px;
      height: 24px;
    }

    &:hover g {
      stroke: #4a90e2;
    }

    &:active g,
    &:focus g {
      fill: #4a90e2;

      & > g {
        stroke: #fff;
      }
    }
  }
`;
