import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Wrapper from './styled';
import Modal from '../../containers/Modal';

const ApproveEmailModal = ({ closeWithRedirect }) => (
  <Wrapper>
    <Modal withRedirect={closeWithRedirect} className="approve-modal" modalName="approve">
      <div className="title-wrapper">
        <span className="modal-title">Thank You!</span>
      </div>
      <div>
        <span className="modal-text">Your email was confirmed!</span>
        <Link className="modal-link" to="/">
          Home
        </Link>
      </div>
    </Modal>
  </Wrapper>
);

ApproveEmailModal.propTypes = {
  closeWithRedirect: PropTypes.func.isRequired,
};

export default ApproveEmailModal;
