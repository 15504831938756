import React from 'react';

const Help = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <linearGradient id="a" x1="0%" x2="203.647%" y1="0%" y2="242.466%">
        <stop offset="0%" stopColor="#6952B3" />
        <stop offset="100%" stopColor="#2558A8" />
      </linearGradient>
      <linearGradient id="b" x1="26.149%" y1="-179.63%" y2="100%">
        <stop offset="0%" stopColor="#6952B3" />
        <stop offset="100%" stopColor="#2558A8" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="#FFF">
      <path
        strokeWidth="2"
        d="M12 1C5.952 1 1 5.952 1 12s4.952 11 11 11 11-4.952 11-11S18.048 1 12 1z"
      />
      <g fill="#FFF" fillRule="evenodd" strokeWidth="0" transform="translate(-0.1 0)">
        <path d="M8.3 10c.2-2 1.5-3.5 3.7-3.5 2.8 0 3.7 1.8 3.7 2.9 0 2.9-2.5 2.5-2.7 4.3V15h-2v-1c.2-2.6 2.4-2.8 2.3-4.4 0-.9-.5-1.3-1.3-1.3-1 0-1.5.8-1.5 1.7H8.3zm4.7 6v2h-2v-2h2z" />
      </g>
    </g>
  </svg>
);

export default Help;
