/**
 * External dependencies
 */

import { connect } from 'react-redux';
import React, { Component } from 'react';

/**
 * Internal dependencies
 */
// import ChartDonut from '@/components/charts/ChartDonut';
import ChartLine from '@/components/charts/ChartLine';
//import Field from '../../assets/images/football-field-soccer-background-green-lawn-court-create-game-sport-138450465.jpg';
import NoImage from '../../assets/images/no-image.png';
import formatTime from '@/utils/format-time';
import moment from 'moment';
// import {
//   Corner,
//   Cards,
//   Goal,
//   Shot,
//   Penalty,
//   Shirts
// } from '@/components/NewStatistics/icons';
import { 
  //  getShots,
  getScores,
  getColors
  } from '@/utils/bet365';
import { setOfDefaultColors, TEAM } from '@/utils/bet365/constants';
import { getSelectedMatch } from '@/store/actions/statistics-actions/statistics-actions';
// import EventsFilter from "@/components/NewStatistics/Events-filter";

class SelectedMatch extends Component  {
  state = {
    tm: 0,
    ts: 0,
    currentDate: new Date(),
    markedDate: moment().format("DD-MM-YYYY"),
  };

  componentDidMount() {
    const { selectedEvent, idOfSelectedEvent } = this.props;

    if (selectedEvent && idOfSelectedEvent) {
      this.autoUpdate = setInterval(this.matchInterval, 15000);
      if (selectedEvent.timer) this.setTimer(selectedEvent.timer);
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.selectedEvent.timer && nextProps.selectedEvent.timer !== this.props.selectedEvent.timer) {
      this.setTimer(nextProps.selectedEvent.timer);
    }
  }

  componentWillUnmount () {
    clearInterval(this.autoUpdate);
    clearInterval(this.timer);
    this.autoUpdate = null;
    this.timer = null;
  }

  matchInterval = () => {
    const { idOfSelectedEvent, selectMatch } = this.props;
    selectMatch(idOfSelectedEvent);
  };

  setTimer = (timer) => {
    this.setState(timer);
    clearInterval(this.timer);
    if (timer.ts === 0 && [0, 45, 90].includes(timer.tm)) return;
    this.timer = setInterval(this.timerInterval, 1000);
  };

  timerInterval = () => {
    const {ts, tm} = this.state;
    this.setState({
      ts: ts < 59 ? ts + 1 : 0,
      tm: ts < 59 ? tm : tm + 1,
    });
  };

  render () {
    const { 
      // filteredTime,
      selectedEvent,
    } = this.props;
  
    const { 
      home,
      away,
      league,
      ss,
      timer,
      time,
      stats,
      selectedEventStats = []
      // filteredHistory,
    } = selectedEvent;

    if (!stats) {
      return (
        <div className="statistics__selected-match selected-match">
          <div className="selected-match__content">
            <div className="header">
              <h2>Match stats</h2>
            </div>
            <div className="selected-match__players">
              There is no data now.
            </div>
          </div>
        </div>
      )
    }

    const {
      possession_rt,
      attacks,
      dangerous_attacks,
      on_target,
      off_target,
      corners,
      yellowcards,
      redcards,
      penalties,
      goals
    } = stats;

    // const {
    //   filteredCorners,
    //   filteredYellowcards,
    //   filteredRedcards,
    //   filteredPenalties,
    //   filteredGoals
    // } = filteredHistory;
    const teamStats = (selectedEventStats[0] || []).filter(item => item["type"] === TEAM);

    let {
      primaryColorHome,
      secondaryColorHome,
      primaryColorAway,
      secondaryColorAway
    } = getColors(teamStats);

    if (
      !primaryColorHome.length
      || !secondaryColorHome
      || !primaryColorAway
      || !secondaryColorAway
    ) {
      primaryColorHome = setOfDefaultColors.primaryColorHome;
      secondaryColorHome = setOfDefaultColors.secondaryColorHome;
      primaryColorAway = setOfDefaultColors.primaryColorAway;
      secondaryColorAway = setOfDefaultColors.secondaryColorAway;
    }

    // const { homeShots, awayShots } = getShots(on_target, off_target);
    const formattedTime = timer && formatTime(this.state.tm, this.state.ts);
    const { homeScore, awayScore } = getScores(ss);

    return (
      <div className="statistics__selected-match selected-match">
        <div className="selected-match__content">
          <div className="header">
            <h2>Match stats</h2>
          </div>
          <div className="selected-match__players">
            <div className="selected-match__players__header">
              <button className="btn-live"><i className="icon-circle"></i>Live</button>
              <span className="title-league">{ league.name }</span>
            </div>
            <div className="players-and-count">
              {
                (home.name && away.name)
                  && <div className="teams">
                    <div className="home-player">
                      <div className="home-player-name-container">
                        <span className="home-player-name">{ home.name }</span>
                      </div>
                      <div className="team-logo-and-score">
                        <div className="image">
                            {
                                home.image_id
                                    ? <img src={`https://assets.b365api.com/images/team/b/${home.image_id}.png`} alt={home.image_id} />
                                    : <img className="no-image" src={ NoImage } alt="noimage" />
                            }
                        </div>
                        <span className="home-player-score"> { homeScore } </span>
                      </div>
                        {/*<span*/}
                          {/*className="color"*/}
                          {/*style={{ background: primaryColorHome }}*/}
                        {/*></span>*/}
                    </div>
                    <div className="vs">vs</div>
                    <div className="away-player">
                      <div className="team-logo-and-score">
                        <span className="away-player-score"> { awayScore } </span>
                        <div className="image">
                            {
                                away.image_id
                                    ? <img src={`https://assets.b365api.com/images/team/b/${away.image_id}.png`} alt={away.image_id} />
                                    : <img className="no-image" src={ NoImage } alt="noimage" />
                            }
                        </div>
                      </div>
                      <div className="away-player-name-container">
                        <span className="away-player-name">{ away.name }</span>
                      </div>
                      {/*<span*/}
                        {/*className="color"*/}
                        {/*style={{ background: primaryColorAway }}*/}
                      {/*></span>*/}
                    </div>
                </div>
              }
              <div className="date">
                  { moment(time * 1000).format("D MMM YYYY") }
              </div>
              {
                  formattedTime
                  && <div className="current-time">
                      { formattedTime }
                  </div>
              }
            </div>
          </div>
          {/* <React.Fragment>
            <p>Slide to select time frame</p>

            <EventsFilter
              selectedEvent={this.props.selectedEvent}
            />

            <div className="selected-match__players-stats">
              <div className="selected-match__players-stats__header stats-line">
                <div className="stats-line-title">
                  <span className="league-name">{ league.name }</span>
                </div>

                <div className="stats-line-rating">
                  {
                    filteredCorners
                    && <div className="header-stat corners-icon">
                      <span>Corners</span>
                    </div>
                  }
                  {
                    filteredYellowcards
                    && <div className="header-stat yellow-cards-icon">
                      <span>Yellow</span>
                    </div>
                  }
                  {
                    filteredRedcards
                    && <div className="header-stat red-cards-icon">
                      <span>Red</span>
                    </div>
                  }
                  {
                    filteredPenalties
                    && <div className="header-stat penalties-icon">
                      <span>Penalty</span>
                    </div>
                  }
                  {
                    filteredGoals
                    && <div className="header-stat goals-icon">
                      <span>Goals</span>
                    </div>
                  }
                </div>
              </div>


              <div className="selected-match__players-stats__header stats-content">
                <div className="date-timer">
                  {
                    filteredTime
                    && <div className="current-time">
                      {filteredTime[0] + 'min-' + filteredTime[1] + 'min'}
                    </div>
                  }
                  <span className="date">{ moment(time * 1000).format("D MMMM") }</span>
                </div>

                <div className="team-logo">
                  <div className="image-wrap">
                    <div className="image">
                      {
                        home.image_id
                          ? <img src={`https://assets.b365api.com/images/team/b/${home.image_id}.png`} alt={home.image_id} />
                          : <img className="no-image" src={ NoImage } alt="noimage" />
                      }
                    </div>
                    <div className="image">
                      {
                        away.image_id
                          ? <img src={`https://assets.b365api.com/images/team/b/${away.image_id}.png`} alt={away.image_id} />
                          : <img className="no-image" src={ NoImage } alt="noimage" />
                      }
                    </div>
                  </div>
                  <div className="team-name-content">
                    <div className="team-name home">{ home.name }</div>
                    <div className="team-name away">{ away.name }</div>
                  </div>
                </div>


                <div className="selected-match__players-stats__table">
                  <div className="selected-match__players-stats__home stats-line stats-line-counter">
                    <div className="goals">
                      {
                        filteredGoals
                        && <div className={`stat ${filteredGoals[0] > filteredGoals[1] ? 'stat-bold' : ''}`}>{ filteredGoals[0] }</div>
                      }
                      {
                        filteredGoals
                        && <div className={`stat ${filteredGoals[1] > filteredGoals[0] ? 'stat-bold' : ''}`}>{ filteredGoals[1] }</div>
                      }
                    </div>
                    <div className="col">
                      <div className="col-wrap">
                        {
                          filteredCorners
                          && <div className={`stat corners ${filteredCorners[0] > filteredCorners[1] ? 'stat-bold' : ''}`}>{ filteredCorners[0] }</div>
                        }
                        {
                          filteredCorners
                          && <div className={`stat corners ${filteredCorners[1] > filteredCorners[0] ? 'stat-bold' : ''}`}>{ filteredCorners[1] }</div>
                        }
                      </div>
                    </div>

                    <div className="col">
                      <div className="col-wrap">
                        {
                          filteredYellowcards
                          && <div className={`stat yellow-cards ${filteredYellowcards[0] > filteredYellowcards[1] ? 'stat-bold' : ''}`}>{ filteredYellowcards[0] }</div>
                        }
                        {
                          filteredYellowcards
                          && <div className={`stat yellow-cards ${filteredYellowcards[1] > filteredYellowcards[0] ? 'stat-bold' : ''}`}>{ filteredYellowcards[1] }</div>
                        }
                      </div>
                    </div>

                    <div className="col">
                      <div className="col-wrap">
                        {
                          filteredRedcards
                          && <div className={`stat red-cards ${filteredRedcards[0] > filteredRedcards[1] ? 'stat-bold' : ''}`}>{ filteredRedcards[0] }</div>
                        }
                        {
                          filteredRedcards
                          && <div className={`stat red-cards ${filteredRedcards[1] > filteredRedcards[0] ? 'stat-bold' : ''}`}>{ filteredRedcards[1] }</div>
                        }
                      </div>
                    </div>

                    <div className="col">
                      <div className="col-wrap">
                        {
                          filteredPenalties
                          && <div className={`stat penalties ${filteredPenalties[0] > filteredPenalties[1] ? 'stat-bold' : ''}`}>{ filteredPenalties[0] }</div>
                        }
                        {
                          filteredPenalties
                          && <div className={`stat penalties ${filteredPenalties[1] > filteredPenalties[0] ? 'stat-bold' : ''}`}>{ filteredPenalties[1] }</div>
                        }
                      </div>
                    </div>

                    <div className="col">
                      <div className="col-wrap">
                        {
                          filteredGoals
                          && <div className={`stat ${filteredGoals[0] > filteredGoals[1] ? 'stat-bold' : ''}`}>{ filteredGoals[0] }</div>
                        }
                        {
                          filteredGoals
                          && <div className={`stat ${filteredGoals[1] > filteredGoals[0] ? 'stat-bold' : ''}`}>{ filteredGoals[1] }</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment> */}

          <div className="teams-logo">
            <div className="team-logo-home">
              <div className="image">
                  {
                      home.image_id
                          ? <img src={`https://assets.b365api.com/images/team/b/${home.image_id}.png`} alt={home.image_id} />
                          : <img className="no-image" src={ NoImage } alt="noimage" />
                  }
                  <span
                  className="color"
                  style={{ background: primaryColorHome }}
                  ></span>
              </div>
              <span className="away-player-name">{ home.name }</span>
            </div>
            <div className="team-logo-away">
              <div className="image">
                  {
                      away.image_id
                          ? <img src={`https://assets.b365api.com/images/team/b/${away.image_id}.png`} alt={away.image_id} />
                          : <img className="no-image" src={ NoImage } alt="noimage" />
                  }
                  <span
                  className="color"
                  style={{ background: primaryColorAway }}
                  ></span>
              </div>
              <span className="away-player-name">{ away.name }</span>
            </div>
          </div>

          <div className="selected-match__graph-statistics">
            {
              attacks
                && <div className="selected-match__graph-statistics__shots">
                <div className="on-target">
                  <div className="title">Attacks</div>
                  <div className="home">{ attacks[0] }</div>
                  {/*<ChartDonut*/}
                    {/*d1={+attacks[0]}*/}
                    {/*d2={+attacks[1]}*/}
                    {/*width="60%"*/}
                    {/*firstColor={primaryColorHome}*/}
                    {/*secondColor={primaryColorAway}*/}
                  {/*/>*/}
                  <ChartLine
                      className="chart-line"
                      d1={+attacks[0]}
                      d2={+attacks[1]}
                      firstColor={primaryColorHome}
                      secondColor={primaryColorAway}
                  />
                  <div className="away">{ attacks[1] }</div>
                </div>
              </div>
            }
            {
              dangerous_attacks
                && <div className="selected-match__graph-statistics__shots">
                <div className="on-target">
                  <div className="title">Dangerous attacks</div>
                  <div className="home">{ dangerous_attacks[0] }</div>
                  {/*<ChartDonut*/}
                    {/*d1={+dangerous_attacks[0]}*/}
                    {/*d2={+dangerous_attacks[1]}*/}
                    {/*firstColor={primaryColorHome}*/}
                    {/*secondColor={primaryColorAway}*/}
                  {/*/>*/}
                  <ChartLine
                      className="chart-line"
                      d1={+dangerous_attacks[0]}
                      d2={+dangerous_attacks[1]}
                      firstColor={primaryColorHome}
                      secondColor={primaryColorAway}
                  />
                  <div className="away">{ dangerous_attacks[1] }</div>
                </div>
              </div>
            }
            {
              possession_rt
               && <div className="selected-match__graph-statistics__shots">
                <div className="on-target">
                  <div className="title">Possession %</div>
                  <div className="home">{ possession_rt[0] }</div>
                  {/*<ChartDonut*/}
                    {/*d1={+possession_rt[0]}*/}
                    {/*d2={+possession_rt[1]}*/}
                    {/*firstColor={primaryColorHome}*/}
                    {/*secondColor={primaryColorAway}*/}
                  {/*/>*/}
                  <ChartLine
                      className="chart-line"
                      d1={+possession_rt[0]}
                      d2={+possession_rt[1]}
                      firstColor={primaryColorHome}
                      secondColor={primaryColorAway}
                  />
                  <div className="away">{ possession_rt[1] }</div>
                </div>
              </div>
            }
            {
              (on_target && off_target)
                && <div className="selected-match__graph-statistics__shots">
                  <div className="on-target">
                    <div className="title">On target</div>
                    <div className="home">{ on_target[0] }</div>
                    <ChartLine
                      className="chart-line"
                      d1={ +on_target[0] }
                      d2={ +on_target[1] }
                      firstColor={primaryColorHome}
                      secondColor={primaryColorAway}
                    />
                    <div className="away">{ on_target[1] }</div>
                  </div>
                  <div className="off-target">
                    <div className="title">Off target</div>
                    <div className="home">{ off_target[0] }</div>
                    <ChartLine
                      className="chart-line"
                      d1={ +off_target[0] }
                      d2={ +off_target[1] }
                      firstColor={primaryColorHome}
                      secondColor={primaryColorAway}
                    />
                    <div className="away">{ off_target[1] }</div>
                  </div>
                </div>
            }
          </div>

          <p>Total results</p>


          <div className="selected-match__players-stats">
            <div className="selected-match__players-stats__header stats-line">
              {/*{*/}
                {/*formattedTime*/}
                  {/*&& <div className="header-stat time">*/}
                  {/*{ formattedTime }*/}
                {/*</div>*/}
              {/*}*/}

              <div className="stats-line-title">
                <span className="league-name">{ league.name }</span>
              </div>

              <div className="stats-line-rating">
                {
                  corners
                    && <div className="header-stat corners-icon">
                    {/*<Corner/>*/}
                    <span>Corners</span>
                  </div>
                }
                {
                  yellowcards
                    && <div className="header-stat yellow-cards-icon">
                    {/*<Cards/>*/}
                    <span>Yellow</span>
                  </div>
                }
                {
                  redcards
                    && <div className="header-stat red-cards-icon">
                    {/*<Cards red/>*/}
                    <span>Red</span>
                  </div>
                }
                {
                  penalties
                    && <div className="header-stat penalties-icon">
                    {/*<Penalty/>*/}
                    <span>Penalty</span>
                  </div>
                }
                {/*{*/}
                  {/*(on_target && off_target)*/}
                    {/*&& <div className="header-stat shots-icon">*/}
                    {/*/!*<Shot/>*!/*/}
                    {/*<span>Race</span>*/}
                  {/*</div>*/}
                {/*}*/}
                {
                  goals
                    && <div className="header-stat goals-icon">
                    {/*<Goal/>*/}
                    <span>Goals</span>
                  </div>
                }
              </div>
            </div>


            <div className="selected-match__players-stats__header stats-content">
              <div className="date-timer">
                  {
                      formattedTime
                      && <div className="current-time">
                          { formattedTime }
                      </div>
                  }
                  <span className="date">{ moment(time * 1000).format("D MMMM") }</span>
              </div>

              <div className="team-logo">
                {/*<div className="t-shirt">*/}
                  {/*<Shirts home*/}
                          {/*primaryColor={ primaryColorHome }*/}
                          {/*secondaryColor={ secondaryColorHome }*/}
                  {/*/>*/}
                {/*</div>*/}
                <div className="image-wrap">
                    <div className="image">
                    {
                        home.image_id
                            ? <img src={`https://assets.b365api.com/images/team/b/${home.image_id}.png`} alt={home.image_id} />
                            : <img className="no-image" src={ NoImage } alt="noimage" />
                    }
                    </div>
                    <div className="image">
                    {
                        away.image_id
                            ? <img src={`https://assets.b365api.com/images/team/b/${away.image_id}.png`} alt={away.image_id} />
                            : <img className="no-image" src={ NoImage } alt="noimage" />
                    }
                    </div>
                </div>
                <div className="team-name-content">
                  <div className="team-name home">{ home.name }</div>
                  <div className="team-name away">{ away.name }</div>
                </div>
              </div>

              <div className="selected-match__players-stats__table">
                <div className="selected-match__players-stats__home stats-line stats-line-counter">
                  <div className="goals">
                      {
                          goals
                          && <div className={`stat ${goals[0] > goals[1] ? 'stat-bold' : ''}`}>{ goals[0] }</div>
                      }
                      {
                          goals
                          && <div className={`stat ${goals[1] > goals[0] ? 'stat-bold' : ''}`}>{ goals[1] }</div>
                      }
                  </div>
                  <div className="col">
                    <div className="col-wrap">
                    {
                      corners
                        && <div className={`stat corners ${corners[0] > corners[1] ? 'stat-bold' : ''}`}>{ corners[0] }</div>
                    }
                    {
                        corners
                        && <div className={`stat corners ${corners[1] > corners[0] ? 'stat-bold' : ''}`}>{ corners[1] }</div>
                    }
                    </div>
                  </div>

                  <div className="col">
                    <div className="col-wrap">
                    {
                      yellowcards
                        && <div className={`stat yellow-cards ${yellowcards[0] > yellowcards[1] ? 'stat-bold' : ''}`}>{ yellowcards[0] }</div>
                    }
                    {
                        yellowcards
                        && <div className={`stat yellow-cards ${yellowcards[1] > yellowcards[0] ? 'stat-bold' : ''}`}>{ yellowcards[1] }</div>
                    }
                    </div>
                  </div>

                  <div className="col">
                    <div className="col-wrap">
                    {
                      redcards
                        && <div className={`stat red-cards ${redcards[0] > redcards[1] ? 'stat-bold' : ''}`}>{ redcards[0] }</div>
                    }
                    {
                        redcards
                        && <div className={`stat red-cards ${redcards[1] > redcards[0] ? 'stat-bold' : ''}`}>{ redcards[1] }</div>
                    }
                    </div>
                  </div>

                  <div className="col">
                    <div className="col-wrap">
                    {
                      penalties
                        && <div className={`stat penalties ${penalties[0] > penalties[1] ? 'stat-bold' : ''}`}>{ penalties[0] }</div>
                    }
                    {
                        penalties
                        && <div className={`stat penalties ${penalties[1] > penalties[0] ? 'stat-bold' : ''}`}>{ penalties[1] }</div>
                    }
                    </div>
                  </div>

                  {/*<div className="col">*/}
                  {/*  <div className="col-wrap">*/}
                  {/*  {*/}
                  {/*    (on_target && off_target)*/}
                  {/*      && <div className={`stat shots ${homeShots > awayShots ? 'stat-bold' : ''}`}>{ homeShots }</div>*/}
                  {/*  }*/}
                  {/*  {*/}
                  {/*      (on_target && off_target)*/}
                  {/*      && <div className={`stat shots ${awayShots > homeShots ? 'stat-bold' : ''}`}>{ awayShots }</div>*/}
                  {/*  }*/}
                  {/*  </div>*/}
                  {/*</div>*/}

                  <div className="col">
                    <div className="col-wrap">
                    {
                      goals
                        && <div className={`stat ${goals[0] > goals[1] ? 'stat-bold' : ''}`}>{ goals[0] }</div>
                    }
                    {
                        goals
                        && <div className={`stat ${goals[1] > goals[0] ? 'stat-bold' : ''}`}>{ goals[1] }</div>
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedEvent: state.newStatistics.selectedEvent,
  filteredTime: state.newStatistics.filteredTime,
  idOfSelectedEvent: state.newStatistics.idOfSelectedEvent
});

const mapDispatchToProps = dispatch => ({
  selectMatch: eventId => dispatch(getSelectedMatch(eventId))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectedMatch);
