import styled from 'styled-components';
import colors from '../../config/colors';
import fonts from '../../config/fonts';

export default styled.div`
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #e6e6e6;
  padding: 27px;


  .table {
    thead th {
      padding: 0 13px 15px;
    }

    tbody td {
      padding: 0 15px;
    }

    tbody td > div {
      flex-wrap: wrap;
    }

    tbody div > span {
      min-width: 40px;
    }

    .message-empty {
      text-align: center;
      font-size: 20px;
    }
  }

  .ReactTable {
    border: 0;
    color: #38415f;

    .rt-thead {
      font-family: ${fonts.proximaNova};
      font-size: 14px;
      &.-header {
        box-shadow: none;
      }

      .rt-th {
        text-align: left;
        padding: 0 13px 15px;
        font-weight: bold;
        border: 0;

        &.-cursor-pointer {
          position: relative;

          &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            right: 5px;
            top: 0;
            border-width: 4px;
            border-style: solid;
            border-color: transparent transparent black transparent;
          }
          &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            right: 5px;
            top: 9px;
            border-width: 4px;
            border-style: solid;
            border-color: black transparent transparent transparent;
          }

          &.-sort-asc::after{
            display: none
          }
          &.-sort-desc::before{
            display: none
          }
          &.-sort-asc,
          &.-sort-desc {
            box-shadow: none;
          }
        }
      }
    }

    .rt-tbody {
      font-size: 12px;
      font-weight: 600;
      border: 1px solid #dde0e6;

      .rt-tr-group {
        border: 0;
      }

      .rt-tr {
        align-items: center;

        &.-odd {
          background: #edeff5;
        }
        &.-even {
          background: #fbfbfb;
        }

      }

      .rt-td {
        padding: 3px 15px;
        white-space: normal;
        border: 0;

        > div {
          flex-wrap: wrap;
        }

        div > span {
          min-width: 40px;
        }
      }
    }

    .message-empty {
      text-align: center;
      font-size: 20px;
    }
  }

  .pick-modal {
    color: ${colors.secondaryBlue};
    margin-left: 200px;

    .content-wrapper {
      display: flex;
      align-items: center;
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      display: inline-block;
      margin-right: 140px;
    }

    .title-wrapper {
      display: flex;
      align-items: center;

      label {
        margin-right: 8px;
        font-size: 14px;
      }

      select {
        min-width: 66px;
      }
    }

    .select-wrapper {
      display: flex;
      align-items: center;
    }

    .zoom-text {
      font-size: 12px;
      font-weight: 600;
      color: #9b9b9b;
    }
  }

  label[for='pick-over'] {
    text-transform: capitalize;
    font-weight: 600;
  }

  .open-graph {
    span {
      cursor: pointer;

      &:hover {
        svg rect {
          fill: ${colors.thirdBlue};
          fill-opacity: 0.2;
        }
      }

      &:active {
        svg {
          rect {
            fill-opacity: 1;
          }

          path {
            fill: ${colors.white};

            &[stroke-linecap='round'] {
              stroke: ${colors.white};
            }
          }
        }
      }
    }
  }

  .filter {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-content: stretch;
    align-items: flex-start;
    margin-bottom: 25px;

    .filter-cell {
      margin-right: 15px;
    }

    select {
      padding: 5px;
      width: 145px;
      height: 32px;
      border-radius: 4px;
      background-color: #ffffff;
      border: solid 1px #e2e2e2;
      font: 13px ${fonts.proximaNova};
      color: #818fa9;
      cursor: pointer;
    }

    label.checkbox {
      color: #818fa9;
      font: 13px ${fonts.proximaNova};
      display: inline-block;
      height: 32px;
      padding: 5px 6px 5px 5px;
      border-radius: 4px;
      background-color: #ffffff;
      border: solid 1px #e2e2e2;
    }
  }
`;
