import styled from 'styled-components';
import fonts from '../../config/fonts';
import colors from '../../config/colors';

export default styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;

  #paymentsForm,
  .row-wrapper {
    display: flex;
    flex-direction: row;
  }

  .payments-form {
    padding: 20px 26px !important;
  }

  #paymentsForm {
    margin-top: 20px;
    margin-bottom: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .payment-button-wrapper {
    justify-content: flex-end !important;
  }

  .payment-button-wrapper button {
    width: 200px;
    height: 40px;
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    background-image: linear-gradient(101deg, #8e70ee, #4a7ece);
    border: none;
    color: white;
    font-size: 16px;
    cursor: pointer;
    position: relative;

    &:before {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:after {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:active {
      &:after {
        opacity: 1;
      }
    }

    &:active,
    &:focus {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
      outline: none;
    }
  }

  .expand {
    float: right;
  }

  .expand button {
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    display: flex;
    align-items: center;
    outline: none;
    cursor: pointer;
    padding: 0;

    span {
      color: ${colors.lightBlue};
    }

    &:hover {
      span {
        color: ${colors.thirdBlue};
      }

      g {
        stroke: ${colors.thirdBlue};
      }
    }

    &:active,
    &:focus {
      span {
        color: ${colors.mainBlue};
        text-decoration: underline;
      }

      g {
        stroke: ${colors.mainBlue};
      }
    }
  }

  .expand span {
    margin-right: 15px;
    font-size: 12px;
    color: #818fa9;
    font-weight: 600;

    &:hover {
      color: #4a90e2;
      text-decoration: underline;
    }
  }

  #paymentsForm > div {
    width: auto;
    display: flex;
    justify-content: flex-start;
    position: relative;
    align-items: center;
  }

  .form-element {
    display: flex;
    align-items: center;
  }

  #paymentsForm span,
  #paymentsForm label {
    // width: 100%;
    display: inline;
    margin-right: 20px;
  }

  .form-error {
    position: absolute;
    top: 32px;
    right: 18px;
    font-size: 12px;
    color: red;
    margin-bottom: 0;
    margin-right: 0 !important;
    margin-top: 5px;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  textarea::placeholder,
  input::placeholder {
    color: #818fa9;
  }

  textarea:focus::placeholder,
  input:focus::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  select:focus {
    outline: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 1px solid #4a90e2;
    -webkit-text-fill-color: #38415f;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
  }

  #paymentsForm > div {
    width: 28%;
    display: flex;
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
  }

  #paymentsForm input,
  #paymentsForm select {
    padding: 5px;
    width: 200px;
    height: 32px;
    margin-right: 15px;
    border-radius: 3px;
    background-color: #ffffff;
    border: solid 1px #e2e2e2;
    font-size: 13px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #818fa9;
    cursor: pointer;
  }

  .payments-form .title {
    display: inline;
    position: relative;
    top: 3px;
    margin-top: 0;
    margin-left: 0;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
  }

  .wrapper {
    flex-grow: 1;
    flex-basis: 400px;
  }

  .wrapper:last-child {
    margin-left: 30px;
  }

  .block {
    background-color: #ffffff;
    margin-bottom: 20px;
    flex-grow: 1;
    padding: 25px;
    border-radius: 5px;
    border: solid 1px #e6e6e6;
  }
  .title {
    font-family: ${fonts.proximaNova};
    margin-top: 0;
    margin-bottom: 13px;
    margin-left: 25px;
    color: #38415f;
    font-size: 24px;
    font-weight: 600;
  }

  .color-block {
    position: relative;
    width: 100%;
    min-height: 120px;
    border-radius: 5px;
    color: #ffffff;
    font-family: ${fonts.proximaNova};
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .color-block img {
    position: absolute;
    top: 0;
    right: 0;
  }

  .blue {
    background-image: linear-gradient(to right, #64a7f6, #4a90e2);
  }

  .violet {
    background-image: linear-gradient(to right, #8e70ee, #4a7ece);
  }

  .value-wrapper {
    display: flex;
  }

  .value {
    // flex-grow: 1;
    padding-right: 20px;
    font-weight: 100;
    font-size: 52px;
    border-right: 1px solid #ffffff;
  }

  .value-description {
    // flex-grow: 1;
    padding-left: 20px;
    display: flex;
    align-items: center;
  }

  .value-description p {
    font-size: 18px;
    font-weight: 100;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    margin: 0;
  }

  .value-description p span {
    font-weight: 600;
  }

  i {
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: #38415f;
  }

  .total {
    font-size: 15px;
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 15px 0 0 0;
  }

  .table-title {
    font-family: ${fonts.proximaNova};
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.61;
    letter-spacing: normal;
    color: #38415f;
  }

  .table-block {
    display: flex;
    margin-bottom: 5px;
  }

  .table-block > div {
    font-size: 16px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
  }

  .table-block.revenue-table {
    margin-top: 35px;
  }

  .revenue-table.details td {
    font-size: 13px !important;
  }

  .revenue-table.details.revenues td {
    width: 40% !important;
  }

  .table-block.revenue-table > div {
    width: 50%;
  }

  .table-block.payments-table > div {
    width: 23%;
  }

  .table-block > div {
    padding-left: 15px;
  }

  table {
    width: 100%;
    text-align: left;
  }

  .tableTitles-block {
    display: flex;
  }

  .tableTitles-block div {
    width: 16%;
    padding: 8px;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #38415f;
    display: flex;
  }

  thead,
  tr {
    width: 100%;
    display: flex;
  }

  table {
    border: 1px solid #dde0e6;
    border-spacing: 0;
    font-size: 15px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #38415f;
  }

  .affiliate {
    margin-top: 30px;
  }

  .revenue-table td {
    font-size: 15px;
    width: 50%;
    padding: 15px;
  }

  .revenue-table .total-row {
    font-weight: bold;
  }

  th,
  td {
    width: 16%;
    padding: 8px;
    font-size: 12px;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: #38415f;
    display: flex;
  }

  thead tr {
    background-color: white !important;
  }

  thead td {
    font-size: 14px;
  }

  tr {
    background-color: #fbfbfb;
  }

  tr:nth-child(2n + 1) {
    background-color: #edeff5;
  }

  tr.marked {
    background-color: #d1d7e9;
  }

  tr.marked td {
    font-weight: bold;
  }

  .color {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .wrapperHidden {
    display: none !important;
  }

  .block-with-tables {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .table-1 {
    order: 1;
    width: 49%;
    padding-top: 23px;
  }
  .table-2 {
    order: 3;
    padding-left: 24px;
    width: 100%;
  }
  .table-3 {
    order: 2;
    width: 49%;
  }

  @media screen and (min-width: 1920px) {
    #paymentsForm > div {
      width: 28%;
    }

    .payment-button-wrapper {
      width: 11% !important;
    }
  }

  @media screen and (max-width: 1920px) {
    #paymentsForm > div {
      width: 24%;
    }
  }

  @media screen and (max-width: 1680px) {
    #paymentsForm {
      justify-content: flex-start;
    }
    .payment-button-wrapper {
      width: 100% !important;
      margin-top: 20px;
    }
    #paymentsForm > div {
      width: 28%;
    }
  }

  @media screen and (max-width: 1600px) {
    .payment-button-wrapper {
      width: 15% !important;
      margin-top: 0;
    }
  }

  @media screen and (width: 1536px) {
    .color-block {
      height: 145px;
    }
  }

  @media screen and (width: 1440px) {
    .color-block {
      height: 160px;
    }
    .form-element {
      label {
        margin-left: 26px;
      }

      input {
        margin: 0 !important;
      }
    }
  }

  @media screen and (max-width: 1450px) {
    #paymentsForm > div {
      width: 34%;
    }

    #paymentsForm > div:first-child {
      width: 34%;
    }

    .table-block.payments-table > div {
      width: 20%;
    }

    #paymentsForm {
      justify-content: space-between;
    }

    #paymentsForm > div:nth-child(3) {
      width: 31%;

      label {
        margin-right: 27px;
      }
    }

    .revenue-table.details.revenues td {
      width: 17.5% !important;
    }

    #paymentsForm span {
      display: block;
      height: 20px;
    }
    .payment-button-wrapper {
      width: 100% !important;
      margin-top: 24px;
    }
  }

  @media screen and (max-width: 1370px) {
    #paymentsForm span {
      height: 40px;
      width: 70px;
    }
    #walletEmail {
      margin: 0 !important;
    }
  }

  @media screen and (width: 1366px) {
    #paymentsForm>div: nth-child(3) label {
      margin-right: 31px;
    }
  }

  @media screen and (width: 1280px) {
    .color-block {
      height: 200px;
    }
  }

  @media screen and (max-width: 1285px) {
    #paymentsForm span {
      display: flex;
    }

    .amount {
      width: 23% !important;
    }

    .date {
      width: 52% !important;
    }

    .start-date {
      width: 60% !important;
    }
  }

  @media screen and (max-width: 1025px) {
    .value {
      border-right: none;
    }
    #paymentsForm {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: flex-start;

      div {
        &:first-child {
          // margin-bottom: 14px;
        }
      }
    }

    #paymentsForm input,
    #paymentsForm select {
      width: 100%;
      margin-right: 0;
    }

    .payment-button-wrapper {
      margin-bottom: 0 !important;
      margin-top: 15px !important;
    }

    label[for=walletEmail] {
      width: 140px !important;
      margin-right: 43px !important;
    }

    .row-wrapper {
      justify-content: space-between;
    }

    .wrapper {
      width: 368px !important;
      // flex-grow: unset;
      flex-basis: unset;

      &:last-child {
        margin-left: 20px;
      }
    }

    .payments-table {
      div {
        font-size: 14px;
      }
    }

    .value-wrapper {
      flex-direction: column;

      .value-description {
        padding: 0;
      }
    }

    .revenue-table {
      td {
        padding: 13px;
      }
    }

    #paymentsForm > div {
      margin-bottom: 10px;
    }

    #paymentsForm div {
      width: 100% !important;
    }

    #paymentsForm span {
      width: 170px !important;
      height: 20px;
    }

    div.amount {
      width: 45% !important;
    }

    .table-3 {
    .revenue-table td {
      padding: 8px 8px 8px 15px;
      display: flex;
      align-items: center;
    }

    #paymentsForm label {
      width: 185px;
    }

    #paymentsForm input {
      margin-left: 10px;
      width: 100% !important;
    }

    #paymentsForm select {
      margin-left: 10px;
      width: 98% !important;
    }

    .value-wrapper {
      flex-direction: column;
    }

    .value {
      border: none;
    }

    .value-description {
      padding-left: 0;
    }

    .block {
      padding: 12px;
    }

    .wrapper:first-child {
      margin-right: 0;
    }

    #paymentsForm > div:nth-child(3) {
      label {
        margin-right: 20px;
      }
    }
  }
`;
