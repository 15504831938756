import {
  GET_GENERAL_INFO,
  GET_GENERAL_INFO_FAIL,
  GET_GENERAL_BETS_BY_LEAGUE,
  GET_GENERAL_BETS_BY_LEAGUE_FAIL,
  GET_PROFIT_CHART_DATA,
  GET_PROFIT_CHART_DATA_FAIL,
  PRELOAD_SELECTS_GENERAL_BETS_BY_LEAGUE,
  SET_PROFIT_CHART_RANGE,
  GET_PICKS_PIE_CHARTS_SUCCESS
} from '../actions/mystats';

const initialState = {
  general: {},
  betsByLeague: {},
  profitChartRange: [null, null],
  profitChart: [],
  pieCharts: [],
  chartError: '',
  betsError: '',
  generalError: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_GENERAL_INFO:
      return {
        ...state,
        general: action.payload,
        generalError: '',
      };
    case GET_GENERAL_INFO_FAIL:
      return { ...state, generalError: action.payload };
    case PRELOAD_SELECTS_GENERAL_BETS_BY_LEAGUE:
      return { ...state, data: action.payload };
    case GET_GENERAL_BETS_BY_LEAGUE:
      return {
        ...state,
        betsByLeague: action.payload,
        betsError: '',
      };
    case GET_GENERAL_BETS_BY_LEAGUE_FAIL:
      return { ...state, betsError: action.payload };
    case GET_PROFIT_CHART_DATA:
      return { ...state, profitChart: action.payload, chartError: '' };
    case GET_PROFIT_CHART_DATA_FAIL:
      return { ...state, chartError: action.payload };
    case SET_PROFIT_CHART_RANGE:
      return { ...state, profitChartRange: action.payload };
    case GET_PICKS_PIE_CHARTS_SUCCESS:
      return { ...state, pieCharts: action.payload };
    default:
      return state;
  }
};
