import React from 'react';

const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" stroke="#366FB1" strokeWidth="2" transform="translate(4 4)">
      <circle cx="8" cy="8" r="9" />
      <g strokeLinecap="round">
        <path d="M5 5l6 6M11 5l-6 6" />
      </g>
    </g>
  </svg>
);

export default CloseIcon;
