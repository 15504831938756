import { lifecycle, compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getUserAccountInfo, getWebTemplate } from '../store/actions/user';
import InitialLoading from '../components/InitialLoading';

const lifecycleMethod = lifecycle({
  componentDidMount() {
    if (this.props.auth.userId) {
      this.props.getWebTemplate();
      this.props.getUserAccountInfo();
    }
  },
});

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getUserAccountInfo,
    getWebTemplate,
  },
  dispatch
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycleMethod,
)(InitialLoading);
