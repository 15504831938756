import {
  GET_LAST_CHAT_MESSAGES_REQUEST,
  GET_LAST_CHAT_MESSAGES_SUCCESS,
  GET_LAST_CHAT_MESSAGES_FAILURE,
  SET_CHAT_MESSAGES,
  SET_OPENED_CHAT_ID,
  SEND_MESSAGE_REQUEST,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILURE,
  TOGGLE_FLOAT_CHAT,
  SET_UNREAD_COUNT_MESSAGE
} from '@/store/actions/chat-with-trader';

const initialState = {
  lastChatId: 0,
  messages: [],
  errorMessage: null,
  isMessagesLoading: false,
  isMessageSending: false,
  isOpenFloatChat: false,
  openedChatIds: [],
  unReadMsgsCount: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LAST_CHAT_MESSAGES_REQUEST:
      return {
        ...state,
        errorMessage: null,
        isMessagesLoading: true
      };

    case GET_LAST_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        errorMessage: null,
        isMessagesLoading: false,
        messages: action.payload.messages,
        lastChatId: action.payload.lastChatId
      };

    case GET_LAST_CHAT_MESSAGES_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
        isMessagesLoading: false
      };

    case SET_CHAT_MESSAGES:
      return {
        ...state,
        messages: action.payload
      };

    case SET_OPENED_CHAT_ID:
      return {
        ...state,
        openedChatIds: action.payload
      };

    case SEND_MESSAGE_REQUEST:
      return {
        ...state,
        isMessageSending: true
      };

    case SEND_MESSAGE_SUCCESS:
    case SEND_MESSAGE_FAILURE:
      return {
        ...state,
        isMessageSending: false
      };

    case TOGGLE_FLOAT_CHAT:
      return {
        ...state,
        isOpenFloatChat: action.payload
      }

    case SET_UNREAD_COUNT_MESSAGE:
      return {
        ...state,
        unReadMsgsCount: action.payload
      }

    default:
      return state;
  }
};
