import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AutoFetchModal from '../components/AutoFetchModal';
import { clearFormNotes } from '../store/actions/picks';

const mapStateToProps = state => {
  const { autoFetch, refresh, wait } = state.view.modals;
  return ({
    opened: !refresh && !wait && autoFetch
  })
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    clearFormNotes,
  },
  dispatch,
);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(AutoFetchModal);
