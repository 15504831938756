import styled from 'styled-components';
import fonts from '../../../config/fonts';
import colors from '../../../config/colors';

export default styled.div`
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  color: ${colors.secondaryBlue};
  background-color: ${colors.white};

  strong {
    font-weight: 600;
  }

  .div-info {
    display: inline-block;
    text-align: center;
  }

  .title-page {
    margin: 0 0 53px;
    font-weight: bold;
    font-size: 52px;
    padding: 10px 0 0 0;
  }

  .info-page {
    display: inline-block;
    font-size: 0;
    max-width: 1300px;
    padding: 60px 65px 32px 0;
    width: 100%;
  }

  .left-block {
    display: inline-block;
    padding: 0 20px;
    text-align: center;
    vertical-align: top;
    width: 40%;

    .block-img {
      max-width: 100%;

      img {
        display: inline-block;
        max-width: 251.3px;
        width: 100%;
      }
    }
  }

  .right-block {
    display: inline-block;
    padding-left: 10px;
    vertical-align: top;
    width: 60%;

    a,
    p,
    ul li,
    ol li {
      color: ${colors.secondaryBlue};
      font-family: ${fonts.proximaNova};
      font-size: 18px;
      line-height: 1.67;
      margin: 0;
    }

    a {
      box-shadow: none;
      color: ${colors.lightBlue_2};
      font-weight: 600;
      outline: none;
      transition: 0.7s;

      &:hover {
        color: ${colors.azul};
      }

      &:focus,
      &:active {
        color: ${colors.mainBlue};
      }
    }
  }

  @media screen and (max-width: 768px) {
    .info-page {
      display: block;
      margin: 0 auto;
      padding: 30px 20px;
    }

    .title-page {
      font-size: 42px;
      margin-bottom: 40px;
      padding-top: 0;
      text-align: left;
    }

    .left-block {
      text-align: left;
      padding: 0 20px;
      width: 100%;

      .block-img {
        display: none;
      }
    }

    .right-block {
      text-align: left;
      padding: 0 20px;
      width: 100%;

      p,
      ul li,
      ol li,
      a {
        font-size: 15px;
        line-height: 1.45;
      }
    }
  }
`;
