import React from "react";
import { isMobile } from 'react-device-detect';
import LoginForm from "../../containers/LoginForm";
// import OldSiteButton from "../../components/OldSiteButton";
import MobileSiteButton from "../../components/MobileSiteButton";
import logo from "../../assets/images/logo.svg";

import Wrapper from "./styles";

const Login = () => (
  <Wrapper>
    <div className="left-side-container">
      <div className={!isMobile ? "old-site-button-wrapper" : "auth-additional-actions"}>
        {isMobile && (
          <MobileSiteButton />
        )}
        {/* <OldSiteButton /> */}
      </div> 
      <LoginForm />
    </div>
    <div className="block">
      <img src={logo} alt="logo" />
    </div>
  </Wrapper>
);

export default Login;
