import moment from 'moment';
import http from '@/services/http-service';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';
import { getLocalTime, getLocalDate } from '@/helpers/timezoneConvertors';

export const GET_LAST_CHAT_MESSAGES_REQUEST = 'chat-with-manager/GET_LAST_CHAT_MESSAGES_REQUEST';
export const GET_LAST_CHAT_MESSAGES_SUCCESS = 'chat-with-manager/GET_LAST_CHAT_MESSAGES_SUCCESS';
export const GET_LAST_CHAT_MESSAGES_FAILURE = 'chat-with-manager/GET_LAST_CHAT_MESSAGES_FAILURE';

export const SET_CHAT_MESSAGES = 'chat-with-manager/SET_CHAT_MESSAGES';

export const SET_OPENED_CHAT_ID = 'chat-with-manager/SET_OPENED_CHAT_ID';

export const TOGGLE_FLOAT_CHAT = 'chat-with-manager/TOGGLE_FLOAT_CHAT';

export const SEND_MESSAGE_REQUEST = 'chat-with-manager/SEND_MESSAGE_REQUEST';
export const SEND_MESSAGE_SUCCESS = 'chat-with-manager/SEND_MESSAGE_SUCCESS';
export const SEND_MESSAGE_FAILURE = 'chat-with-manager/SEND_MESSAGE_FAILURE';

export const SET_UNREAD_COUNT_MESSAGE = 'chat-with-manager/SET_UNREAD_COUNT_MESSAGE';

let autofetchTimer;
const timeout = 500;
let startId = 0;

export const getLastMessages = (_lastChatId = 0) => async (dispatch, getState) => {
  // console.log('%cgetLastMessagesManager', 'background:coral;color:black;padding:5px');

  clearTimeout(autofetchTimer);
  dispatch({ type: GET_LAST_CHAT_MESSAGES_REQUEST });

  const { auth: { utcOffset }, chatWithManager: { messages: oldMessages }} = getState();
  const params = { lastChatId: _lastChatId };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetLastChatMessagesMemory');
      trace.start();
    }
    const { data: { lastChatId, chatMessages, error }} = await http.post('/GetLastChatMessagesMemory', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    const oldMessagesCopy = [...oldMessages];

    chatMessages
      .filter(message => message.isManager)
      .forEach(message => {
        const isNewMessage = oldMessagesCopy.every(m => m.id !== message.id);
        if (isNewMessage) {
          const today = moment().format('dddd, DD/MM/YY');
          const yesterday = moment().subtract(1, 'days').format('dddd, DD/MM/YY');
          const date = moment(message.messageDate, 'DD/MM/YYYY').format('dddd, DD/MM/YY');
          oldMessagesCopy.push({
            ...message,
            messageTime: getLocalTime(message.messageTime, utcOffset, 'HH:mm'),
            messageDate: getLocalDate(message.messageDate, utcOffset, 'DD/MM/YYYY'),
            _formatedDate: date === today ? 'Today' : (date === yesterday ? 'Yesterday' : date)
          });
        }
      });

    dispatch({
      type: GET_LAST_CHAT_MESSAGES_SUCCESS,
      payload: {
        lastChatId,
        messages: oldMessagesCopy
      }
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
    dispatch({
      type: GET_LAST_CHAT_MESSAGES_FAILURE,
      payload: error.message
    });
  } finally {
    const { chatWithManager: { openedChatIds }} = getState();
    if (openedChatIds.length) {
      autofetchTimer = setTimeout(() => {
        dispatch(getLastMessages());
      }, timeout);
    }
  }
};

export const sendMessage = (message = '') => async (dispatch, getState) => {
  const { chatWithManager: { isMessageSending }} = getState();

  if (!message || isMessageSending) return;

  dispatch({ type: SEND_MESSAGE_REQUEST });

  const params = { message, isManager: true };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetChatMessages');
      trace.start();
    }

    const { data: { error }} = await http.post('/SetChatMessages', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({ type: SEND_MESSAGE_SUCCESS });
    dispatch(getLastMessages());
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
    dispatch({ type: SEND_MESSAGE_FAILURE });
  }
};

export const setAsRead = message => async (dispatch, getState) => {
  if (!message.isNew) {
    return;
  }

  const params = { ids: message.id, isChat: true };

  let trace;

  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetReadChatMessage');
      trace.start();
    }

    const { data: { error }} = await http.post('/SetReadChatMessage', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch(getUnReadMsgsCount());

    const { chatWithManager: { messages }} = getState();
    const idx = messages.findIndex(m => m.id === message.id);

    if (idx !== -1) {
      dispatch({
        type: SET_CHAT_MESSAGES,
        payload: [
          ...messages.slice(0, idx),
          {
            ...messages[idx],
            isNew: false
          },
          ...messages.slice(idx + 1)
        ]
      });
    }
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
  }
};

export const toggleFloatChat = state => (dispatch, getState) => {
  const { chatWithManager: { isOpenFloatChat }} = getState();
  let payload = !isOpenFloatChat;

  if (state !== undefined) {
    payload = !!state;
  }

  dispatch({
    type: TOGGLE_FLOAT_CHAT,
    payload
  });
};

export const addOpenedChat = () => async (dispatch, getState) => {
  const id = ++startId;
  const { chatWithManager: { openedChatIds }} = getState();

  if (!openedChatIds.includes(id)) {
    dispatch({ type: SET_OPENED_CHAT_ID, payload: [ ...openedChatIds, id ] });
  }
  return id;
};

export const removeOpenedChat = id => async (dispatch, getState) => {
  const { chatWithManager: { openedChatIds }} = getState();

  dispatch({ type: SET_OPENED_CHAT_ID, payload: openedChatIds.filter(_id => _id !== id) });
};

let autofetchUnreadCount;
export const getUnReadMsgsCount = () => async (dispatch) => {
  clearTimeout(autofetchUnreadCount);

  let trace;

  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetCountUnReadChatMessages');
      trace.start();
    }

    const { data: { unReadCountManager, error }} = await http.post('/GetCountUnReadChatMessages');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }
    dispatch({
      type: SET_UNREAD_COUNT_MESSAGE,
      payload: unReadCountManager
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
  } finally {
    autofetchUnreadCount = setTimeout(() => {
      dispatch(getUnReadMsgsCount());
    }, 5000);
  }
};

export const stopAutoFetch = () => {
  clearTimeout(autofetchUnreadCount);
};
