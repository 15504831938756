import styled from 'styled-components';
import fonts from '../../config/fonts';

export default styled.div`
  font-family: ${fonts.proximaNova};

  & > div {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }
  .closed {
    display: none;
  }

  .info-modal {
    width: 590px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .title-wrapper {
    margin-top: 30px;
    margin-bottom: 25px;
    text-align: center;
  }

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #366fb1;
  }

  .modal-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #38415f;
    display: block;
    margin-bottom: 22px;
    text-align: center;
  }
`;
