import styled from 'styled-components';
import colors from '../../config/colors';
import fonts from '../../config/fonts';

export default styled.div`
  .ReactTable {
    border: none;
    border-bottom: solid 1px ${colors.lightGray_2};
    border-collapse: collapse;
    border-spacing: 0;
    min-width: 100%;

    .rt-thead .rt-th {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #38415f;
      padding: 6px 15px;
      border: none;

      &.-cursor-pointer {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          right: 5px;
          top: 6px;
          border-width: 4px;
          border-style: solid;
          border-color: transparent transparent black transparent;
        }
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          right: 5px;
          top: 15px;
          border-width: 4px;
          border-style: solid;
          border-color: black transparent transparent transparent;
        }

        &.-sort-asc::after{
          display: none
        }
        &.-sort-desc::before{
          display: none
        }
        &.-sort-asc,
        &.-sort-desc {
          box-shadow: none;
        }
      }
    }

    .rt-tbody {
      border: 1px solid #dde0e6;

      .rt-tr-group {
        .rt-td:first-child {
          border-left: solid 1px ${colors.lightGray_2};
        }
        .rt-td:last-child {
          border-right: solid 1px ${colors.lightGray_2};
        }
      }

      .rt-tr-group:first-child {
        .rt-td {
          border-top: solid 1px ${colors.lightGray_2};
        }
      }

      .rt-tr-group:nth-child(odd) {
        .rt-td {
          background-color: ${colors.lightGray_2};
        }
      }

      .rt-tr-group.red {
        .rt-td {
          background-color: ${colors.red_2};
        }
      }

      .rt-tr-group {
        border: none;
      }

      .rt-td {
        background-color: ${colors.lightGray_7};
        border: 0;
        color: ${colors.secondaryBlue};
        font-family: ${fonts.proximaNova};
        font-size: 16px;
        font-weight: 600;
        line-height: 2.88;
        padding: 0 10px;
        white-space: nowrap;

        &:first-child {
          padding-left: 20px;
        }

        &:last-child {
          padding-right: 32px;
          text-align: right;
          width: 5%;
        }

        svg {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }
      }

      /* .rt-tr.-odd {
        background-color: #edeff5;
      } */
    }

    .rt-th {
      border: 0;
      color: ${colors.secondaryBlue};
      font-family: ${fonts.proximaNova};
      font-size: 15px;
      font-weight: 600;
      line-height: 1.1;
      padding-bottom: 20px;
      padding-left: 10px;
      padding-right: 10px;
      text-align: left;
      white-space: nowrap;

      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 22px;
        text-align: right;
        width: 5%;
      }

      &:nth-child(3) {
        width: 30%;
      }
    }
  }
`;
