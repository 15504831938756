import React from 'react';
import moment from 'moment';

import capitalize from '@/utils/capitalize';
import Table from '../ui/Table';
import StatsIcon from '@/assets/images/btn-graph-norm';

const filterPicks = (picks = [], params) => {
  const {
    filterByRuning,
    filterByLive,
    filterByStatus,
    allStatuses,
  } = params;

  let res = [...picks];

  if (filterByLive === 'live') {
    res = res.filter(p => p.isLive);
  }

  if (filterByLive === 'pregame') {
    res = res.filter(p => !p.isLive);
  }

  if (filterByStatus || filterByStatus === '') {
    res = res.filter(p => {
      const gameStatus = p.gameStatus.toLowerCase();
      if (!filterByStatus) {
        return true;
      }

      if (filterByStatus === 'rejected') {
        return !allStatuses.includes(gameStatus);
      }

      return gameStatus === filterByStatus;
    });
  }

  if (filterByRuning) {
    res = res.filter(p => p.gameStatus.toLowerCase().startsWith('running'));
  }

  // res = res.sort((a, b) => {
  //   const momentA = moment(a.gameDate, 'DD/MM/YYYY HH:mm');
  //   const momentB = moment(b.gameDate, 'DD/MM/YYYY HH:mm');
  //   return momentB.diff(momentA);
  // });

  res.sort((a, b) => b.pickId - a.pickId);

  return res;
};

class PicksTable extends React.Component {
  shouldComponentUpdate(nextProps) {
    const {
      picks,
      filterByRuning,
      filterByLive,
      filterByStatus
    } = this.props;

    const sameRunning = filterByRuning === nextProps.filterByRuning;
    const sameLive = filterByLive === nextProps.filterByLive;
    const sameStatus = filterByStatus === nextProps.filterByStatus;

    const options = [sameRunning, sameLive, sameStatus];
    const hasDifference = options.some(item => !item);

    if (hasDifference) {
      return true;
    }

    if (nextProps.picks.length === picks.length) {
      const sameData = JSON.stringify(nextProps.picks) === JSON.stringify(picks);
      if (sameData) {
        return false;
      }
    }

    return true;
  }

  handleGraphClick = (data) => () => {
    const { onGraphClick } = this.props;
    onGraphClick(data);
  }

  renderTableBody = () => {
    const {
      picks,
      filterByRuning,
      filterByLive,
      filterByStatus,
      allStatuses
    } = this.props;

    const picksList = filterPicks(picks, {
      filterByRuning,
      filterByLive,
      filterByStatus,
      allStatuses,
    });

    if (!picksList.length) {
      return (
        <tr>
          <td
            colSpan={11}
            className="message-empty"
          >
            Data is empty
          </td>
        </tr>
      )
    }

    return (
      picksList.map(p => (
        <tr key={`pick-${p.pickId}`}>
          <td>{p.league}</td>
          <td>
            <span>{p.team1}</span>
            <span>{p.team2}</span>
          </td>
          <td>
            {p.gameDate && (
              moment(p.gameDate, 'DD/MM/YYYY HH:mm').format('DD/MM/YY HH:mm')
            )}
          </td>
          <td dangerouslySetInnerHTML={{ __html: p.liveHalf }} />
          <td>
            <s>{capitalize(p.memberBet.bet)}</s>
            <br/>{capitalize(p.traderBet.bet)}
          </td>
          <td>
            <s>{p.memberBet.line}</s>
            <br/>{p.traderBet.line}
          </td>
          <td>
            <s>{p.memberBet.odds}</s>
            <br/>{p.traderBet.odds}
          </td>
          <td>{p.gameStatus}</td>
          <td>{p.betAmount}</td>
          <td>{p.revenueRoi}</td>
          <td className="open-graph">
            <span
              role="main"
              onClick={this.handleGraphClick({ pickId: p.pickId, bet: p.bet[0] })}
            >
              <StatsIcon />
            </span>
          </td>
        </tr>
      ))
    );
  };

  render () {
    return (
      <Table className="table">
        <thead>
          <tr>
            <th>League</th>
            <th>Teams</th>
            <th>Game Date</th>
            <th>Live/Half</th>
            <th>Bet</th>
            <th>Line</th>
            <th>Odds</th>
            <th>Status</th>
            <th>Bet Amount</th>
            <th>P&L</th>
            <th>Graph</th>
          </tr>
        </thead>
        <tbody>
          {this.renderTableBody()}
        </tbody>
      </Table>
    );
  };
}


export default PicksTable;
