import styled from 'styled-components';
import HelpSelected from '../../assets/images/ic-help-selected.svg';
import UserSelected from '../../assets/images/web-ic-user-selected.svg';
import colors from '../../config/colors';
import fonts from '../../config/fonts';

export default styled.div`
  width: 200px;
  min-width: 200px;
  background-image: linear-gradient(349deg, #8e70ee, #4a7ece);
  margin-top: -60px;
  min-height: 100%;
  font-family: ${fonts.proximaNova};

  .logo-container {
    display: flex;
    height: 60px;
    align-items: center;
    background-color: ${colors.mainBlue};
    padding-left: 17px;
  }

  ul {
    margin: 0;
    list-style: none;
    padding: 0;
    margin-bottom: 25px;
  }

  .trader-status {
    margin: 25px 15px 0;
    padding: 6px;
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    background-color: ${colors.mainBlue};
    line-height: 18px;
  }

  .status {
    width: 8px;
    height: 8px;
    background-color: ${colors.leafyGreen};
    border: 2px solid #5bdb59;
    border-radius: 50%;
    display: inline-block;
  }

  .recommend-user {
    width: 172px;
    height: 49px;
    border-radius: 5px;
    box-shadow: 0 2px 7px 0 rgba(0, 70, 151, 0.87);
    background-image: linear-gradient(286deg, #3870e7, #4ac3ce);
    border: solid 1px #ffffff;
    display: flex;
    margin-top: 20px;
    margin-left: 13px;
    color: #fff;
    font-size: 15px;
    text-decoration: none;
    align-items: center;
    text-align: left;
    font-weight: 600;
    z-index: 100;
    position: relative;

    &:before {
      background-image: linear-gradient(286deg, #0049e4, #02c2d4);
    }

    &:after {
      background-image: linear-gradient(286deg, #2b56b4, #45b4be);
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 47px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
      border-radius: 4px;
    }

    &:hover {
      outline: none;
      &:before {
        opacity: 1;
      }
    }

    &:active {
      outline: none;
      &:after {
        opacity: 1;
      }
    }

    &:focus {
      outline: none;
    }

    img {
      margin-left: 5px;
      margin-right: 10px;
    }

    span {
      font-family: ${fonts.proximaNova};
    }
  }

  .content-wrapper {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 60px);

    @media screen and (max-height: 730px) {
      justify-content: flex-start;
    }
  }

  .sidebar-links {
    display: flex;
    flex-direction: column;
    padding: 10px 25px 36px;
    position: sticky;
    top: calc(100vh - 146px);
    z-index: 100;

    @media (min-height: 731px) {
      position: fixed;
      bottom: 0;
      top: initial;
    }

    img {
      cursor: pointer;
    }

    .help,
    .profile {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .profile-selected,
    .help-selected {
      background-repeat: no-repeat;
      border: 1px solid #fff;
      border-radius: 50%;

      &:hover {
        border: none;
      }
    }

    .profile {
      background-repeat: no-repeat !important;

      &:hover {
        background-position: center;
        g {
          path {
            fill: #fff;
            fill-opacity: 0.246;
          }
        }
      }

      &:active {
        background-position: center;
        g {
          stroke-width: 2;

          path {
            fill: #fff;

            fill-opacity: 1;
          }

          g {
            circle {
              stroke: url(#a);
            }
            path {
              stroke: url(#b);
            }
          }
        }
      }
    }

    .help {
      background-repeat: no-repeat !important;
      &:hover {
        background-position: center;
        svg {
          g {
            path:first-child {
              fill: #fff;
              fill-opacity: 0.246;
              d: path('M12 1C5.952 1 1 5.952 1 12s4.952 11 11 11 11-4.952 11-11S18.048 1 12 1z');
              stroke-width: 2;
              stroke: #fff;
            }
            path:last-child {
              stroke: none;
              fill: #fff;
              fill-opacity: 1;
              d: path(
                'M8.3 10c.2-2 1.5-3.5 3.7-3.5 2.8 0 3.7 1.8 3.7 2.9 0 2.9-2.5 2.5-2.7 4.3V15h-2v-1c.2-2.6 2.4-2.8 2.3-4.4 0-.9-.5-1.3-1.3-1.3-1 0-1.5.8-1.5 1.7H8.3zm4.7 6v2h-2v-2h2z'
              );
            }
          }
        }
      }

      &:active {
        background-position: center;
        g {
          path:first-child {
            stroke-width: 2;
            stroke: #fff;
            fill: #fff;
            fill-opacity: 1;
            d: path('M11 0c6.05 0 11 4.95 11 11s-4.95 11-11 11S0 17.05 0 11 4.95 0 11 0z');
          }

          path:last-child {
            stroke: none;
            fill: #8971ec;
            fill-opacity: 1;
            d: path(
              'M7.3 9c.2-2 1.5-3.5 3.7-3.5 2.8 0 3.7 1.8 3.7 2.9 0 2.9-2.5 2.5-2.7 4.3V14h-2v-1c.2-2.6 2.4-2.8 2.3-4.4 0-.9-.5-1.3-1.3-1.3-1 0-1.5.8-1.5 1.7H7.3zM12 15v2h-2v-2z'
            );
          }
        }
      }
    }

    .profile-selected {
      background-image: url(${UserSelected});
      border: 2px;

      &:hover {
        background-image: url(${UserSelected});
      }
    }

    .help-selected {
      background-image: url(${HelpSelected});

      &:hover {
        background-image: url(${HelpSelected});
        border: 1px solid #fff !important;
      }
    }

    .links-opened {
      display: flex !important;
    }

    .inner-links {
      width: 140px;
      position: absolute;
      background: white;
      flex-direction: column;
      left: 35px;
      bottom: -15px;
      display: none;
      background-color: #ffffff;
      box-shadow: 0 0 15px 0 rgba(48, 2, 104, 0.2);
      z-index: 9999;

      &:before {
        content: '';
        position: absolute;
        right: 122px;
        top: 165px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 17px 17px 17px;
        border-color: transparent transparent #ffffff transparent;
        transform: rotate(-90deg);
      }

      a, .link {
        cursor: pointer;
        padding: 15px;
        font-size: 16px;
        font-weight: bold;
        color: ${colors.secondaryBlue};
        text-decoration: none;
        position: relative;

        &:hover {
          background-color: rgba(74, 144, 226, 0.2);
        }

        &:active {
          background-color: #4a90e2;
          color: white;
        }

        :first-child:after {
          content: '';
          height: 1px;
          background-color: rgb(151, 151, 151, 0.19);
          display: block;
          width: calc(100% - 8px);
          bottom: 0;
          left: 4px;
          position: absolute;
        }

        :nth-child(2):after {
          content: '';
          height: 1px;
          background-color: rgb(151, 151, 151, 0.19);
          display: block;
          width: calc(100% - 8px);
          bottom: 0;
          left: 4px;
          position: absolute;
        }

        :nth-child(3):after {
          content: '';
          height: 1px;
          background-color: rgb(151, 151, 151, 0.19);
          display: block;
          width: calc(100% - 8px);
          bottom: 0;
          left: 4px;
          position: absolute;
        }
      }
    }

    .profile-link {
      bottom: -10px !important;

      &:before {
        top: 65px !important;
      }
    }

    div {
      position: relative;
      margin-top: 26px;
      width: 35px;

      &:first-child {
        margin-top: 10px;
      }

      // &:hover .inner-links {
      //   display: flex;
      // }
    }
  }

  .recommend-modal-thank {
    width: 540px !important;
    padding-left: 30px;
    padding-right: 30px;
  }

  .thank-wrapper > div {
    background: rgba(0, 0, 0, 0.36);
    z-index: 999;
  }

  .title-wrapper-thank {
    margin-top: 48px;
    margin-bottom: 40px;
    text-align: center;
  }

  .modal-title-thank {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #366fb1;
  }

  .modal-text-thank {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #38415f;
    display: block;
    margin-bottom: 43px;
  }

  .modal-link-thank {
    display: block;
    margin: 0 auto;
    width: 50px;
    margin-bottom: 40px;
    border: none;
    background-color: rgba(255, 255, 255, 0);
    padding: 0;
    text-decoration: underline;
    cursor: pointer;

    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a90e2;
  }
`;
