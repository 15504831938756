import {
  getOddsAlertsInitialState as getInitialState,
  transformOddsAlertsSelectedPermanentData,
  transformOddsAlertsProcessing
} from './_utils';

import {
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_REQUEST,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUCCESS,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_FAILURE,

  SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_REQUEST,
  SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_SUCCESS,
  SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_FAILURE,

  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_REQUEST,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_SUCCESS,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_FAILURE,

  TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_OPEN,
  TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_SETTINGS_POPUP
} from '../../actions/odds-alerts';

export {
  getInitialState,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_REQUEST,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUCCESS,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_FAILURE,

  SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_REQUEST,
  SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_SUCCESS,
  SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_FAILURE,

  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_REQUEST,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_SUCCESS,
  FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_FAILURE,

  TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_OPEN,
  TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_SETTINGS_POPUP
};

const toggleSubItems = (items, id) => {
  return items.map(item => {
    return {
      ...item,
      groupItems: {
        ...item.groupItems,
        isOpen: item.id === id && !item.groupItems.isOpen
      }
    };
  });
};

const toggleSettingsPopup = (items, { id }) => {
  return items.map(item => {
    if (item.isGroup) {
      return {
        ...item,
        groupItems: {
          ...item.groupItems,
          data: item.groupItems.data.map(groupItem => ({
            ...groupItem,
            isShowSettingsPopup: groupItem.id === id && !groupItem.isShowSettingsPopup
          }))
        },
        isShowSettingsPopup: item.id === id && !item.isShowSettingsPopup
      }
    }
    return {
      ...item,
      isShowSettingsPopup: item.id === id && !item.isShowSettingsPopup
    };
  });
};

const loadSubItemsRequest = (items, { id }) => {
  return items.map(item => {
    if (item.id === id) {
      return {
        ...item,
        groupItems: {
          ...item.groupItems,
          error: null,
          isLoading: true
        }
      };
    }
    return item;
  });
};

const loadSubItemsSuccess = (items, { id, data }) => {
  return items.map(item => {
    if (item.id === id) {
      return {
        ...item,
        groupItems: {
          ...item.groupItems,
          data: transformOddsAlertsSelectedPermanentData(item.groupItems.data, data, {
            groupName: 'oddsAlertsSelectedPermanent',
            isDisabled: true
          }),
          error: null,
          isLoading: false
        }
      };
    }
    return item;
  });
};

const loadSubItemsFailure = (items, { id, error }) => {
  return items.map(item => {
    if (item.id === id) {
      return {
        ...item,
        groupItems: {
          ...item.groupItems,
          error,
          isLoading: false
        }
      };
    }
    return item;
  });
};

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUCCESS:
      return {
        ...state,
        data: transformOddsAlertsSelectedPermanentData(state.data, action.payload, {
          groupName: 'oddsAlertsSelectedPermanent'
        }),
        isLoading: false,
        error: null
      };

    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_REQUEST:
      return {
        ...state,
        data: transformOddsAlertsProcessing(state.data, action.payload.id, true)
      };

    case SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_SUCCESS:
    case SET_ODDS_ALERTS_SELECTED_PERMANENT_ITEM_FAILURE:
      return {
        ...state,
        data: transformOddsAlertsProcessing(state.data, action.payload.id, false)
      };

    case TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_OPEN:
      return {
        ...state,
        data: toggleSubItems(state.data, action.payload)
      };

    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_REQUEST:
      return {
        ...state,
        data: loadSubItemsRequest(state.data, action.payload)
      };

    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_SUCCESS:
      return {
        ...state,
        data: loadSubItemsSuccess(state.data, action.payload)
      };
    case FETCH_ODDS_ALERTS_SELECTED_PERMANENT_SUBITEMS_FAILURE:
      return {
        ...state,
        data: loadSubItemsFailure(state.data, action.payload)
      };

    case TOGGLE_ODDS_ALERT_SELECTED_PERMANENT_SETTINGS_POPUP:
      return {
        ...state,
        data: toggleSettingsPopup(state.data, action.payload)
      }

    default:
      return state;
  }
}
