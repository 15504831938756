/**
  External dependencies
 */
import { takeEvery, put, call } from 'redux-saga/effects';

/**
 * Internal dependencies
 */
import {
  setEventsInPlay,
  failedEventsInPlay,
  getLeaguesList
} from '../actions/statistics-actions/statistics-actions';
import {
  GET_EVENTS_IN_PLAY,
} from '@/store/actions/statistics-actions/constants';
import { callBetAPI } from '../../utils/bet365';




function* handleUpcomingGamesLoading() {
  const gamesInPlay = yield call(callBetAPI);

  if (gamesInPlay.error.errorCode) {
    yield put(failedEventsInPlay(gamesInPlay.error));
  } else {
    try {
      yield put(setEventsInPlay(gamesInPlay.result.results[0]));
      yield put(getLeaguesList({ gamesInPlay: gamesInPlay.result.results[0] }));
    } catch (error) {
      yield put(failedEventsInPlay(error));
    }
  }
};

export function* WatchInPlayGamesLoading() {
  yield takeEvery(GET_EVENTS_IN_PLAY, handleUpcomingGamesLoading);
};
