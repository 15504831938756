import styled from 'styled-components';
import fonts from '../../config/fonts';

export default styled.div`
  font-family: ${fonts.proximaNova};
  
  .center {
    display: flex;
    justify-content: center;
  }

  & > div {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99;
  }

  .bet-settings-modal {
    width: 550px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .bet-settings-checkboxes-container {
    display: flex;
    align-items: center;
    margin: 20px 0;
    justify-content: space-around;
  }

  .bet-settings-modal .checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .notes-input-container {
    display: flex;
    justify-content: center;
  }

  .notes-input-container div {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 13px;
    width: 71%;
  }
  
  .notes-input-container input {
    width: 100%;
  }

  .notes-input-container label {
    font-size: 16px;
    margin-right: 5px;
  }

  .bet-settings-checkbox {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }

  .title-wrapper {
    margin-bottom: 25px;
    text-align: center;
  }

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #366fb1;
  }
`;
