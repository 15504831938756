import { reduxForm, reset } from 'redux-form';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as ChatTrader from '@/store/actions/chat-with-trader';
import * as ChatManager from '@/store/actions/chat-with-manager';

import { StaticChat } from '@/components/Chat';

const form = reduxForm({
  form: 'chatForm'
});

const mapStateToProps = ({
  chatWithTrader,
  chatWithManager
}) => ({
  chatWithTrader,
  chatWithManager
});

const mapDispatchToProps = dispatch => ({
  dispatch
});

const mergeProps = ({ chatWithTrader, chatWithManager }, { dispatch }, ownProps) => {
  const ChatActions = ownProps.apiKey === 'manager' ? ChatManager : ChatTrader;
  const ChatProps = ownProps.apiKey === 'manager' ? chatWithManager : chatWithTrader;

  return {
    ...ownProps,
    type: 'static',
    messages: ChatProps.messages,
    lastChatId: ChatProps.lastChatId,
    isOpened: ChatProps.isOpenFloatChat,
    onSubmit: (values, dispatch, { sendMessage }) => {
      const message = values.message ? values.message.trim() : '';
      if (message) {
        sendMessage(message);
        dispatch(reset('chatForm'));
      }
    },
    toggle: () => {
      if (ownProps.apiKey === 'manager') {
        dispatch(ChatTrader.toggleFloatChat(false));
      } else {
        dispatch(ChatManager.toggleFloatChat(false));
      }
      dispatch(ChatActions.toggleFloatChat());
    },
    ...bindActionCreators({
      getLastMessages: ChatActions.getLastMessages,
      sendMessage: ChatActions.sendMessage,
      setAsRead: ChatActions.setAsRead,
      addOpenedChat: ChatActions.addOpenedChat,
      removeOpenedChat: ChatActions.removeOpenedChat,
      getUnReadMsgsCount: ChatActions.getUnReadMsgsCount
    }, dispatch)
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
  ),
  form
)(StaticChat);
