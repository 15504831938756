import http from '@/services/http-service';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';
import { getLocalDate } from '@/helpers/timezoneConvertors';

export const GET_MY_FINANCE_SUCCESS = 'finance/GET_MY_FINANCE_SUCCESS';
export const GET_MY_FINANCE_FAIL = 'finance/GET_MY_FINANCE_FAIL';
export const GET_MY_AFFILIATE_FINANCE_SUCCESS = 'finance/GET_MY_AFFILIATE_FINANCE_SUCCESS';
export const GET_MY_AFFILIATE_FINANCE_FAIL = 'finance/GET_MY_AFFILIATE_FINANCE_FAIL';
export const PRELOAD_PAYMENT_DETAILS = 'user/PRELOAD_PAYMENT_DETAILS';
export const SET_PAYMENT_DETAILS_SUCCESS = 'user/SET_PAYMENT_DETAILS_SUCCESS';
export const SET_PAYMENT_DETAILS_FAIL = 'user/SET_PAYMENT_DETAILS_FAIL';

export const preloadPaymentDetails = data => ({
  type: PRELOAD_PAYMENT_DETAILS,
  payload: data,
});

export const setPaymentsDetails = () => async (dispatch, getState) => {
  const state = getState();
  const params = {
    paymentMethod: state.form.paymentsForm.values.paymentMethod,
    paymentCurrency: state.form.paymentsForm.values.paymentCurrency,
    walletEmail: state.form.paymentsForm.values.walletEmail
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetPaymentMethodDetails');
      trace.start();
    }

    const { data: { error }} = await http.post('/SetPaymentMethodDetails', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }
    dispatch({
      type: SET_PAYMENT_DETAILS_SUCCESS
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: SET_PAYMENT_DETAILS_FAIL,
      payload: error.message
    });
  }
};

export const getMyFinanceInfo = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMyFinancePageInfo');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetMyFinancePageInfo');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MY_FINANCE_SUCCESS,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    console.log(error);
    dispatch({
      type: GET_MY_FINANCE_FAIL,
      payload: error.message
    });
  }
};

export const getMyAffiliateInfo = () => async (dispatch, getState) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMyAffiliateFinancePageInfo');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetMyAffiliateFinancePageInfo');

    const { auth: { utcOffset } } = getState();
  
    const preparedData = {
      ...data,
      affiliateCard: {
        ...data.affiliateCard,
        nextPayment: data.affiliateCard.nextPayment
          ? getLocalDate(data.affiliateCard.nextPayment, utcOffset, 'MMM DD, YYYY') 
          : data.affiliateCard.nextPayment
      },
      affiliateRevenues:data.affiliateRevenues.map(item => ({
          ...item,
          startDate: item.startDate
            ?  getLocalDate(item.startDate, utcOffset, 'MMM DD, YYYY') 
            :  item.startDate
      })),
    };

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MY_AFFILIATE_FINANCE_SUCCESS,
      payload: preparedData
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    console.warn(error);
    dispatch({
      type: GET_MY_AFFILIATE_FINANCE_FAIL,
      payload: error.message
    });
  }
};
