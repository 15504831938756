import React from 'react';

const User = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <defs>
      <linearGradient id="a" x1="0%" x2="203.647%" y1="0%" y2="242.466%">
        <stop offset="0%" stopColor="#6952B3" />
        <stop offset="100%" stopColor="#2558A8" />
      </linearGradient>
      <linearGradient id="b" x1="26.149%" y1="-179.63%" y2="100%">
        <stop offset="0%" stopColor="#6952B3" />
        <stop offset="100%" stopColor="#2558A8" />
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd" stroke="#FFF">
      <path
        strokeWidth="2"
        d="M12 1C5.952 1 1 5.952 1 12s4.952 11 11 11 11-4.952 11-11S18.048 1 12 1z"
      />
      <g strokeWidth="2" transform="translate(5.25 5.25)">
        <circle cx="6.375" cy="2.625" r="2.625" />
        <path
          strokeLinecap="round"
          d="M0 12.738c1.212-2.995 3.353-4.493 6.423-4.493s5.212 1.498 6.424 4.493"
        />
      </g>
    </g>
  </svg>
);

export default User;
