import http from '@/services/http-service';
import { change } from 'redux-form';
import _get from 'lodash/get';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';

export const GET_MEMBER_ODDSALERTS_PERMANENT_SUCCESS = 'alerts/GET_MEMBER_ODDSALERTS_PERMANENT_SUCCESS';
export const GET_MEMBER_ODDSALERTS_PERMANENT_FAIL = 'alerts/GET_MEMBER_ODDSALERTS_PERMANENT_FAIL';
export const GET_MEMBER_ODDSALERTS_TEAMS_BY_LEAGUE_PERMANENT_SUCCESS = 'alerts/GET_MEMBER_ODDSALERTS_TEAMS_BY_LEAGUE_PERMANENT_SUCCESS';
export const GET_MEMBER_ODDSALERTS_TEAMS_BY_LEAGUE_PERMANENT_FAIL = 'alerts/GET_MEMBER_ODDSALERTS_TEAMS_BY_LEAGUE_PERMANENT_FAIL';
export const SET_ODDS_ALERTS_LEAGUE_SUCCESS = 'alerts/SET_ODDS_ALERTS_LEAGUE_SUCCESS';
export const SET_ODDS_ALERTS_LEAGUE_FAIL = 'alerts/SET_ODDS_ALERTS_LEAGUE_FAIL';
export const GET_MEMBER_UNITS_REPORT_COUNTRIES_SUCCESS = 'alerts/GET_MEMBER_UNITS_REPORT_COUNTRIES_SUCCESS';
export const GET_MEMBER_UNITS_REPORT_COUNTRIES_FAIL = 'alerts/GET_MEMBER_UNITS_REPORT_COUNTRIES_FAIL';
export const GET_ODDSALERT_LEAGUES_BY_COUNTRY_SUCCESS = 'alerts/GET_ODDSALERT_LEAGUES_BY_COUNTRY_SUCCESS';
export const GET_ODDSALERT_LEAGUES_BY_COUNTRY_FAIL = 'alerts/GET_ODDSALERT_LEAGUES_BY_COUNTRY_FAIL';
export const GET_ODDSALERT_TEAMS_BY_LEAGUE_SUCCESS = 'alerts/GET_ODDSALERT_TEAMS_BY_LEAGUE_SUCCESS';
export const GET_ODDSALERT_TEAMS_BY_LEAGUE_FAIL = 'alerts/GET_ODDSALERT_TEAMS_BY_LEAGUE_FAIL';
export const GET_MEMBER_ODDSALERT_GAMES_TEMPORARY_SUCCESS = 'alerts/GET_MEMBER_ODDSALERT_GAMES_TEMPORARY_SUCCESS';
export const GET_MEMBER_ODDSALERT_GAMES_TEMPORARY_FAIL = 'alerts/GET_MEMBER_ODDSALERT_GAMES_TEMPORARY_FAIL';
export const SET_ODDS_ALERTS_GAME_SUCCESS = 'alerts/SET_ODDS_ALERTS_GAME_SUCCESS';
export const SET_ODDS_ALERTS_GAME_FAIL = 'alerts/SET_ODDS_ALERTS_GAME_FAIL';
export const SET_ODDS_ALERTS_TEAM_SUCCESS = 'alerts/SET_ODDS_ALERTS_TEAM_SUCCESS';
export const SET_ODDS_ALERTS_TEAM_FAIL = 'alerts/SET_ODDS_ALERTS_TEAM_FAIL';
export const COUNTRY_ID = 'alerts/COUNTRY_ID';
export const RESET_TEAMS_LIST = 'alerts/RESET_TEAMS_LIST';
export const OPEN_SUBMENU = 'alerts/OPEN_SUBMENU';
export const OPEN_TOOLTIP = 'alerts/OPEN_TOOLTIP';
export const OPEN_SUB_TOOLTIP = 'alerts/OPEN_SUB_TOOLTIP';
export const GET_SEARCH_RESULTS_SUCCESS = 'alerts/GET_SEARCH_RESULTS_SUCCESS';
export const GET_SEARCH_RESULTS_FAIL = 'alerts/GET_SEARCH_RESULTS_FAIL';
export const SET_COUNTRY_ID = 'alerts/SET_COUNTRY_ID';
export const TOGGLE_COUNTRIES = 'alerts/TOGGLE_COUNTRIES';
export const SET_COUNTRY_DETAILS = 'alerts/SET_COUNTRY_DETAILS';
export const SET_GAME_ID = 'alerts/SET_GAME_ID';
export const SET_ODDS_REACHES = 'alerts/SET_ODDS_REACHES';
export const SET_LINES = 'alerts/SET_LINES';
export const GET_ALERTS_DEFAULTS_SUCCESS = 'alerts/GET_ALERTS_DEFAULTS_SUCCESS';
export const GET_ALERTS_DEFAULTS_FAIL = 'alerts/GET_ALERTS_DEFAULTS_FAIL';
export const SET_ALERTS_DEFAULTS = 'alerts/SET_ALERTS_DEFAULTS';
export const START_LOADING = 'alerts/START_LOADING';
export const STOP_LOADING = 'alerts/STOP_LOADING';

export const toggleLoading = isLoading => async (dispatch) => {
  if (isLoading) {
    dispatch({
      type: START_LOADING,
    });
  } else {
    dispatch({
      type: STOP_LOADING,
    });
  }
};
export const setAlertsDefaults = (isNewGameOpen, oddsMoveMore) => async (dispatch) => {
  const params = {
    isNewGameOpen,
    oddsMoveMore
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error }} = await http.post('/SetMemberOddsAlertDefaults', params);
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_ALERTS_DEFAULTS
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
  }
};

export const setGameId = (gameId, line) => async (dispatch) => {
  dispatch({
    type: SET_GAME_ID,
    id: gameId,
    line,
  });
};

/**
 * Preload default values in form under the alerts table (for all group of alerts)
 * @async
 * @function
 */
export const preloadDefaults = () => async (dispatch, getState) => {
  const state = getState();
  const profileData = _get(state, 'alerts.defaultValues', {});
  dispatch(change('oddsAlertsForm', 'isNewGameOpen1', profileData.isNewGameOpen));
  dispatch(change('oddsAlertsForm', 'oddsAlertMoveMore', profileData.oddsAlertMoveMore));
  dispatch(change('oddsAlertsForm', 'oddsAlertMove', true));
};

export const preloadTooltip = (isNewGameOpen, oddsAlertMoveMore) => async (dispatch) => {
  dispatch(change('oddsAlertsForm', 'isNewGameOpen_tooltip', isNewGameOpen));
  dispatch(change('oddsAlertsForm', 'oddsAlertMoveMore_tooltip', oddsAlertMoveMore));
  dispatch(change('oddsAlertsForm', 'oddsAlertMove_tooltip', true));
};

export const setOddsReaches = reaches => async (dispatch) => {
  dispatch({
    type: SET_ODDS_REACHES,
    payload: reaches,
  });
};

export const setLines = lines => async (dispatch) => {
  dispatch({
    type: SET_LINES,
    payload: lines,
  });
};

export const openSubMenu = (leagueId, position) => async (dispatch) => {
  dispatch({
    type: OPEN_SUBMENU,
    id: leagueId,
    position,
  });
};

export const setCountryDetails = (name, code, id) => async (dispatch) => {
  dispatch({
    type: SET_COUNTRY_DETAILS,
    name,
    code,
    id,
  });
};

export const toggleCountries = () => async (dispatch) => {
  dispatch({
    type: TOGGLE_COUNTRIES,
  });
};

/**
 * Set country id to reducer
 * @async
 * @function
 * @param {number} id
 */
export const setCountryId = id => async (dispatch) => {
  dispatch({
    type: SET_COUNTRY_ID,
    id,
  });
};

export const changeCountryInSelect = countryId => async (dispatch) => {
  dispatch(change('oddsAlertsForm', 'countryId', countryId));
};

export const openTooltip = (leagueId, position) => async (dispatch) => {
  dispatch({
    type: OPEN_TOOLTIP,
    id: leagueId,
    position,
  });
};

export const openSubTooltip = teamId => async (dispatch) => {
  dispatch({
    type: OPEN_SUB_TOOLTIP,
    id: teamId,
  });
};

/**
 * Removing old data from reducer before new data loaded
 * @async
 * @function
 */
export const resetTeamList = () => async (dispatch) => {
  dispatch({
    type: RESET_TEAMS_LIST,
  });
};

/**
 * Getting default values for form under the alerts table (for all group of alerts)
 * @async
 * @function
 */
export const getAlertsDefaults = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error, ...data }} = await http.post('/GetMemberOddsAlertsDefaults');
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_ALERTS_DEFAULTS_SUCCESS,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);

    dispatch({
      type: GET_ALERTS_DEFAULTS_FAIL,
      payload: error.message
    });
  }
};

/**
 * Getting the search results, when searching by teams
 * @async
 * @function
 * @param {string} searchCriteria - Value from input
 */
export const getSearchResutls = searchCriteria => async dispatch => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error, teams }} = await http.post('/GetOddsAlertSearchTeams', {
      searchCriteria
    });
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_SEARCH_RESULTS_SUCCESS,
      payload: teams
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: GET_SEARCH_RESULTS_FAIL,
      payload: error.message
    });
  }
};

/**
 * Getting the list of existing alerts
 * @async
 * @function
 */
export const getMemberOddsAlertPermanent = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error, ...data }} = await http.post('/GetMemberOddsAlertPermanent');
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MEMBER_ODDSALERTS_PERMANENT_SUCCESS,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: GET_MEMBER_ODDSALERTS_PERMANENT_FAIL,
      payload: error.message
    });
  }
};

/**
 * Getting the list of teams in selected league
 * @async
 * @function
 * @param {number} leagueId - The ID of the league
 * @param {number} countryId - The ID of the country
 */
export const getSelectedLeagueTeams = (countryId, leagueId) => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }

    const { data: { error, alerts }} = await http.post('/GetMemberOddsAlertsTeamsByLeaguePermanent', {
      countryId,
      leagueId
    });
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MEMBER_ODDSALERTS_TEAMS_BY_LEAGUE_PERMANENT_SUCCESS,
      payload: alerts
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: GET_MEMBER_ODDSALERTS_TEAMS_BY_LEAGUE_PERMANENT_FAIL,
      payload: error.message
    });
  }
};

/**
 * Getting the list of countries
 * @async
 * @function
 */
export const getMemberUnitsReportCountries = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error, countries }} = await http.post('/GetMemberUnitsReportCountries');
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MEMBER_UNITS_REPORT_COUNTRIES_SUCCESS,
      payload: countries
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: GET_MEMBER_UNITS_REPORT_COUNTRIES_FAIL,
      payload: error.message
    });
  }
};

/**
 * Getting the list of leagues by country
 * @async
 * @function
 * @param {number} countryId - The ID of selected country
 */
export const getOddsAlertsLeaguesByCountry = countryId => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error, leagues }} = await http.post('/GetOddsAlertLeaguesByCountry', {
      countryId
    });
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_ODDSALERT_LEAGUES_BY_COUNTRY_SUCCESS,
      payload: leagues
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: GET_ODDSALERT_LEAGUES_BY_COUNTRY_FAIL,
      payload: error.message
    });
  }
};

/**
 * Getting the list of teams by selected league
 * @async
 * @function
 * @param {number} countryId - The ID of selected country
 * @param {number} leagueId - The ID of selected league
 */
export const getOddsAlertsTeamsByLeague = (countryId, leagueId) => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error, teams }} = await http.post('/GetOddsAlertsTeamsByLeague', {
      countryId,
      leagueId
    });
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_ODDSALERT_TEAMS_BY_LEAGUE_SUCCESS,
      payload: teams
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: GET_ODDSALERT_TEAMS_BY_LEAGUE_FAIL,
      payload: error.message
    });
  }
};

/**
 * Getting the list of alerts on games
 * @async
 * @function
 */
export const getMemberOddsAlertGamesTemporary = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error, alerts }} = await http.post('/GetMemberOddsAlertGamesTemporary');
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MEMBER_ODDSALERT_GAMES_TEMPORARY_SUCCESS,
      payload: alerts
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: GET_MEMBER_ODDSALERT_GAMES_TEMPORARY_FAIL,
      payload: error.message
    });
  }
};

/**
 * Add/Edit/Remove alerts for league
 * @async
 * @function
 * @param {number} leagueId - The ID of the league
 * @param {number} countryId - The ID of the country
 * @param {boolean} isNewGameOpen
 * @param {number} oddsMoveMore
 * @param {number} typeAction - 1: Add, 2:Edit, 3: Remove
 */
export const setOddsAlertLeague = (
  countryId,
  leagueId,
  isNewGameOpen,
  oddsMoveMore,
  typeAction,
) => async (dispatch, getState) => {
  const state = getState();
  const profileData = _get(state, 'alerts.defaultValues', {});
  const params = {
    countryId,
    leagueId,
    isNewGameOpen: typeAction === 1 ? profileData.isNewGameOpen : isNewGameOpen,
    oddsMoveMore: typeAction === 1 ? profileData.oddsAlertMoveMore : oddsMoveMore,
    typeAction
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error }} = await http.post('/SetOddsAlertsLeague', params);
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_ODDS_ALERTS_LEAGUE_SUCCESS
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: SET_ODDS_ALERTS_LEAGUE_FAIL,
      payload: error.message
    });
  }
};

/**
 * Add/Edit/Remove alerts for games
 * @async
 * @function
 * @param {number} gameId - The ID of the game
 * @param {string} bet - Bet
 * @param {number} line - Line
 * @param {number} oddsReaches
 * @param {number} typeAction - 1: Add, 2:Edit, 3: Remove
 */
export const setAlertGame = (gameId, bet, line, oddsReaches, typeAction) => async (dispatch) => {
  const params = {
    gameId,
    bet,
    line,
    oddsReaches: oddsReaches || '0',
    typeAction
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error }} = await http.post('/SetOddsAlertsGame', params);
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_ODDS_ALERTS_GAME_SUCCESS
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: SET_ODDS_ALERTS_GAME_FAIL,
      payload: error.message
    });
  }
};

/**
 * Add/Edit/Remove alerts for teams
 * @async
 * @function
 * @param {number} countryId - The ID of the country
 * @param {number} leagueId - The ID of the league
 * @param {number} teamId - The ID of the team
 * @param {boolean} isNewGameOpen
 * @param {number} oddsMoveMore
 * @param {number} typeAction - 1: Add, 2:Edit, 3: Remove
 */
export const setAlertTeam = (
  countryId,
  leagueId,
  teamId,
  isNewGameOpen,
  oddsMoveMore,
  typeAction,
) => async (dispatch, getState) => {
  const state = getState();
  const profileData = _get(state, 'alerts.defaultValues', {});
  const params = {
    countryId,
    leagueId,
    teamId,
    isNewGameOpen: isNewGameOpen || profileData.isNewGameOpen,
    oddsMoveMore: oddsMoveMore || profileData.oddsAlertMoveMore,
    typeAction
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('WebLogin');
      trace.start();
    }
    const { data: { error }} = await http.post('/SetOddsAlertsTeam', params);
    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_ODDS_ALERTS_TEAM_SUCCESS
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: SET_ODDS_ALERTS_TEAM_FAIL,
      payload: error.message
    });
  }
};
