import React, { Fragment } from 'react';

import Wrapper from './styles';
import HowItWorksContainer from '../../assets/staticTexts/HowItWorks';

const HowItWorks = () => (
  <Fragment>
    <Wrapper>
      <HowItWorksContainer />
    </Wrapper>
  </Fragment>
);

export default HowItWorks;
