import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import { openModal, closeModal } from '../../store/actions/view';
import UnitsReport from '../../components/UnitsReport';
import { UNITS, ALLOWANCES } from '../../config/constants';

const mapStateToProps = ({ report, view }) => ({
  unitTab: report.tab === UNITS,
  allowancesTab: report.tab === ALLOWANCES,
  modalOpened: report.modal.opened,
  allowancesModalIsOpened: view.modals.allowances,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  openModal,
  closeModal
}, dispatch);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(UnitsReport);
