import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';
import { changeUnitTab } from '@/store/actions/report';

import SwitcherTop from '@/components/SwitcherTop';
import { UNITS, ALLOWANCES } from '@/config/constants';

const mapStateToProps = ({ report }) => ({
  unitTab: report.tab === UNITS,
  allowancesTab: report.tab === ALLOWANCES
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    changeUnitTab
  },
  dispatch
);

const props = withProps(ownProps => ({
  activateUnitTab: (e) => {
    e.preventDefault();
    ownProps.changeUnitTab(UNITS);
  },
  activateAllowancesTab: (e) => {
    e.preventDefault();
    ownProps.changeUnitTab(ALLOWANCES);
  }
}));

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  props
)(SwitcherTop);
