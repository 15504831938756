import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle, withProps } from 'recompose';

import { recommendNewMember } from '@/store/actions/user';
import { openModal, closeModal } from '@/store/actions/view';
import { logOut } from '@/store/actions/auth';
import { getUnreadMsgsCount } from '@/store/actions/messages';

import Sidebar from '@/components/Sidebar';

const mapStateToProps = ({ view, user, messages, profile }) => ({
  recommendModalOpened: view.modals.recommend,
  recommendModalThankOpened: view.modals.recommendThank,
  unreadMessages: messages.unreadCount,
  userLevel:  user.account.userLevel,
  hasTerms: profile.hasTerms
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    logOut,
    recommendNewMember,
    getUnreadMsgsCount,
    openRecommendModal: () => openModal('recommend'),
    closeRecommendModal: () => closeModal('recommend'),
    closeRecommendThankModal: () => closeModal('recommendThank')
  },
  dispatch
);

let timer;
const props = withProps(ownProps => {

  const startAutoFetch = () => {
    ownProps.getUnreadMsgsCount();
    timer = setTimeout(startAutoFetch, 10000); // refresh every 10 seconds
  };

  const stopAutoFetch = () => clearTimeout(timer);

  return {
    startAutoFetch,
    stopAutoFetch
  };
});

const lifecycleMethods = lifecycle({
  componentDidMount () {
    this.props.startAutoFetch();
  },

  componentWillUnmount () {
    this.props.stopAutoFetch();
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  props,
  lifecycleMethods
)(Sidebar);
