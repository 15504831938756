import http from '@/services/http-service';
import { reset } from 'redux-form';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';

export const GET_LAST_CHAT_MESSAGES_SUCCESS = 'notification/GET_LAST_CHAT_MESSAGES_SUCCESS';
export const GET_LAST_CHAT_MESSAGES_FAIL = 'notification/GET_LAST_CHAT_MESSAGES_FAIL';
export const SET_CHAT_MESSAGES_SUCCESS = 'notification/SET_CHAT_MESSAGES_SUCCESS';
export const SET_CHAT_MESSAGES_FAIL = 'notification/SET_CHAT_MESSAGES_FAIL';
export const GET_LAST_CHAT_MESSAGES_AFTER_SET_SUCCESS = 'notification/GET_LAST_CHAT_MESSAGES_AFTER_SET_SUCCESS';
export const CHANGE_MESSAGE_STATUS_IS_NEW = 'notification/CHANGE_MESSAGE_STATUS_IS_NEW';
export const SET_NEW_MESSAGE = 'notification/SET_NEW_MESSAGE';
export const RESET_CHAT_MESSAGES_REDUCER = 'notification/RESET_CHAT_MESSAGES_REDUCER';

/**
 * Changing a message status from new to read, if isNew param presented and equals true
 * @async
 * @function
 * @param {number} chatMessageId - if from Chat then Chat id,
 * if from Mail messages,then Mail Message id.
 * @param {boolean} isChat - if from Chat then true, if from Mail messages ,then false..
 */
export const changeMessageStatus = (chatMessageId, isChat) => async (dispatch) => {
  const params = { chatMessageId, isChat: !!isChat };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetChatMessagesIsNew');
      trace.start();
    }

    const { data: { error }} = await http.post('/SetChatMessagesIsNew', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: CHANGE_MESSAGE_STATUS_IS_NEW
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    console.warn(error);
  }
};

/**
 * Fetching the messages for Support from an API
 * @async
 * @function
 * @param {number} lastChatId - For initial request equals - 0,
 * for next requests - from data object (same name of param inside).
 * @param {boolean} isResetReducer - For detection of needs reducer clean
 * @return {object} - Object with requested data.
 */
export const getLastChatMessages = (lastChatId, isResetReducer) => async (dispatch) => {
  const params = { lastChatId: lastChatId || 0 };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetLastChatMessages');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetLastChatMessages', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    if (isResetReducer) {
      dispatch({
        type: RESET_CHAT_MESSAGES_REDUCER,
      });
      dispatch({
        type: GET_LAST_CHAT_MESSAGES_SUCCESS,
        payload: data
      });
    } else {
      dispatch({
        type: GET_LAST_CHAT_MESSAGES_SUCCESS,
        payload: data
      });
    }
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
    dispatch({
      type: GET_LAST_CHAT_MESSAGES_FAIL,
      payload: error.message
    });
  }
};

/**
 * Set new messages on Support
 * @async
 * @function
 */
export const setChatMessages = () => async (dispatch, getState) => {
  const state = getState();
  const params = { message: state.form.chatForm.values.message || '' };

  let trace1;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace1 = performance.trace('SetChatMessages');
      trace1.start();
    }

    const { data: { error: error1, ...data1 }} = await http.post('/SetChatMessages', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error1.errorCode !== 0) {
        trace1.putAttribute('errorCode', `${error1.errorCode}`);
      }
      trace1.stop();
    }

    if (error1.errorCode !== 0) {
      throw new ApiError(error1.errorText, error1.errorCode);
    }

    dispatch({
      type: SET_CHAT_MESSAGES_SUCCESS,
      payload: data1
    });
    dispatch(reset('chatForm'));

    let trace2;
    try {
      if (process.env.REACT_APP_ENV === 'production') {
        trace2 = performance.trace('GetLastChatMessages');
        trace2.start();
      }

      const { data: { error: error2, ...data2 }} = await http.post('/GetLastChatMessages', { lastChatId: 0 });

      if (process.env.REACT_APP_ENV === 'production') {
        if (error2.errorCode !== 0) {
          trace2.putAttribute('errorCode', `${error2.errorCode}`);
        }
        trace2.stop();
      }

      if (error2.errorCode !== 0) {
        throw new ApiError(error2.errorText, error2.errorCode);
      }

      dispatch({
        type: GET_LAST_CHAT_MESSAGES_AFTER_SET_SUCCESS,
        payload: data2
      });
      setTimeout(() => {
        const messageBody = document.querySelector('.chat-box');
        if (messageBody) {
          messageBody.scrollTop = messageBody.scrollHeight
        }
      }, 500);
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production' && trace2['state'] === 2) {
        trace2.putAttribute('error', error.message);
        trace2.stop();
      }
      console.warn(error);
      dispatch({
        type: GET_LAST_CHAT_MESSAGES_FAIL,
        payload: error.message
      });
    }
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace1['state'] === 2) {
      trace1.putAttribute('error', error.message);
      trace1.stop();
    }
    console.warn(error);
    dispatch({
      type: SET_CHAT_MESSAGES_FAIL,
      payload: error.message
    });
  }
};
