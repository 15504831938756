import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import HeaderLinkItem from '../components/HeaderLinkItem';

const mapStateToProps = ({ user }) => ({
  alertsCount: user.webtemplate.oddsAlersCount,
});

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    null,
  ),
)(HeaderLinkItem);
