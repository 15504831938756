import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import InitialLoading from './containers/InitialLoading';

import Home from './containers/Home';
import UnauthAboutUs from './pages/UnauthAboutUs';
import UnauthTermsOfUse from './pages/UnauthTermsOfUse';
import UnauthContactUs from './pages/UnauthContactUs';
import UnauthHowItWorks from './pages/UnauthHowItWorks';
import Login from './pages/Login';
import RestorePassword from './pages/RestorePassword';
import PrivateRoute from './components/PrivateRoute';
// import Statistics from './components/Statistics';
// import MaintenanceAnnouncement from './pages/MaintenanceAnnouncement';

const App = () => {
  const isAuth = !!localStorage.getItem('authorizationKey');
  return (
    <>
      <InitialLoading />

      <Switch>
        <Route path="/login">
          {isAuth ? <Redirect to="/" /> : <Login />}
        </Route>
        <Route path="/restore-password" component={RestorePassword} />
        <Route path="/about-us-unauth" component={UnauthAboutUs} />
        <Route path="/terms-unauth" component={UnauthTermsOfUse} />
        <Route path="/contact-us-unauth" component={UnauthContactUs} />
        <Route path="/how-it-works-unauth" component={UnauthHowItWorks} />
        {/* <Route path="/announcement" component={MaintenanceAnnouncement} /> */}
        {/*<Route path="/statistics" component={Statistics} />*/}

        <PrivateRoute path="/" component={Home} />
      </Switch>
    </>
  );
};

export default App;
