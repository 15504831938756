import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classNames from 'classnames';

import HelpSelected from '../../assets/images/ic-help-selected.svg';
import Checkbox from '../ui/form/Checkbox';
import SlideToggle from '../ui/form/SlideToggle';
import Input from '../ui/form/Input';
import Select from '../ui/form/Select';
import {checkAccess, listOfSection} from "@/helpers/access";

const PicksForm = ({
  dates,
  sports,
  bets,
  marketTypes,
  clearFormNotes,
  formDisabled,
  handleSubmit,
  isLive,
  userLevel
}) => {
  const accessData = checkAccess(userLevel);
  return (
    <form className="picks-form" onSubmit={handleSubmit}>
      <div className="form-row">
        <Field
          component={Checkbox}
          label="Live"
          name="live"
          id="pick-live"
          disabled={formDisabled}
          wrapperClass="picks-checkbox"
        />
        <Field
          component={Input}
          label="Time"
          name="minuteIR"
          id="pick-minute-ir"
          disabled={formDisabled}
          inputClass="minute-ir"
          wrapperClass={classNames({ hide: !isLive })}
        />
        <Field
          component={Input}
          label="Score"
          name="score"
          id="pick-score"
          disabled={formDisabled}
          inputClass="pick-score"
          wrapperClass={classNames({ hide: !isLive })}
        />
        <Field
          component={Input}
          label="Date"
          name="date"
          id="pick-date"
          // options={dates}
          addBlank
          disabled={formDisabled}
          inputClass="pick-date"
          wrapperClass={classNames({ hide: isLive })}
        />
        <Field
          component="input"
          type="hidden"
          label="Sport"
          name="sport"
          // id="pick-sport"
          // options={sports}
          // addBlank={sports.length > 1}
          // disabled={formDisabled || sports.length <= 1}
        />
        <Field
          component={Input}
          label="League"
          name="league"
          id="pick-league"
          inputClass="pick-league"
          wrapperClass="wrapper-pick-league"
          disabled={formDisabled}
        />
      </div>
      <div className="form-row">
        <Field
          component={Input}
          label="Team 1"
          name="team1"
          id="pick-team1"
          inputClass="pick-team"
          disabled={formDisabled}
          wrapperClass="pick-team-wrapper"
        />
        <Field
          component={Input}
          label="Team 2"
          name="team2"
          id="pick-team2"
          inputClass="pick-team"
          disabled={formDisabled}
          wrapperClass="pick-team-wrapper"
        />
      </div>
      <div className="form-row">
        <Field
          component={Select}
          label="Market"
          name="market"
          id="pick-market"
          options={marketTypes}
          disabled={formDisabled}
          addBlank
        />
        <Field
            component={Select}
            label="Bet"
            name="bet"
            id="pick-bet"
            options={bets}
            disabled={formDisabled}
            addBlank />
        <Field
            component={Input}
            label="Line"
            name="line"
            inputClass="pick-input-mid"
            id="pick-line"
            disabled={formDisabled}/>

        <Field
          component={Input}
          label="Min. Odds"
          name="minOdds"
          inputClass="pick-input-mid"
          id="pick-minOdds"
          disabled={!accessData[listOfSection.BetsOddsControl]}
        />
        <Field
          component={Input}
          label="Time"
          name="time"
          inputClass="pick-input-mid"
          id="pick-time"
          disabled={formDisabled}
          wrapperClass={classNames({ hide: isLive })}
        />
      </div>
      <div className="form-row bet-settings-row">
        <Field component={Input} label="Notes" name="notes" inputClass="pick-notes" id="pick-notes" />
        <div className="additional-settings-row">
        <div className="setting-urgentBet bet-settings-checkbox-wrapper">
            <Field
              className="checkbox-field"
              component={SlideToggle}
              label="Urgent Bet"
              name="urgentBet"
              id="pick-urgentBet"
              wrapperClass="bet-settings-checkbox picks-checkbox"
              disabled={!accessData[listOfSection.ReasonOddsControl]}
            />
            <div className="info-container">
              <img alt="help selected" src={HelpSelected} />
            </div>
          </div>
          <div className="setting-highValues bet-settings-checkbox-wrapper">
            <Field
              component={SlideToggle}
              label="High Values"
              name="highValues"
              id="pick-highValues"
              wrapperClass="bet-settings-checkbox picks-checkbox"
              disabled={!accessData[listOfSection.ReasonOddsControl]}
            />
            <div className="info-container">
              <img alt="help selected" src={HelpSelected} />
            </div>
          </div>
          <div className="setting-teamNews bet-settings-checkbox-wrapper">
            <Field
              className="checkbox-field"
              component={SlideToggle}
              label="Team News"
              name="teamNews"
              id="pick-teamNews"
              wrapperClass="bet-settings-checkbox picks-checkbox"
              disabled={!accessData[listOfSection.ReasonOddsControl]}
            />
            <div className="info-container">
              <img alt="help selected" src={HelpSelected} />
            </div>
          </div>
        </div>
      </div>
      <Field component="input" type="hidden" name="eventConnectionId" />
      <Field component="input" type="hidden" name="gameId" />
      <Field component="input" type="hidden" name="betGamePart" />
      <div className="clear-data-wrapper">
        <a
          href="/"
          onClick={e => {
            e.preventDefault();
            clearFormNotes();
          }}
        >
          Clear Data
        </a>
      </div>
    </form>
  );
};

PicksForm.propTypes = {
  dates: PropTypes.array.isRequired,
  sports: PropTypes.array.isRequired,
  bets: PropTypes.array.isRequired,
  marketTypes: PropTypes.array.isRequired,
  clearFormNotes: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  formDisabled: PropTypes.bool.isRequired,
  isLive: PropTypes.bool.isRequired,
};

export default PicksForm;
