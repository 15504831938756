import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from '../../config/colors';

export default styled(Link)`
  height: auto;
  font-size: 12px;
  color: ${colors.lightBlue};
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  outline: none;
  text-align: center;
  text-decoration: none;
  transition: 0.7s;
  padding: 0 13px;
  border-left: 1px solid #818fa9;

  &:hover {
    text-decoration: underline;
    color: #1d7bea;
  }

  &:first-child {
    border-left: none;
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &.active {
    font-weight: 700;
    color: ${colors.mainBlue};
    text-decoration: underline;
  }
`;
