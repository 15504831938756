import axios from 'axios';
import _get from 'lodash/get';
import _isObject from 'lodash/isObject';
import { isMobile } from 'react-device-detect';
import moment from 'moment';

import { logOut, setUtcOffset } from '@/store/actions/auth';
import { setHasTerms } from '@/store/actions/profile';
import store from '@/store/store';

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});


const dates = {};
const savedDatesKey = 'limitedRequests';
try {
  let savedDates = JSON.parse(localStorage.getItem(savedDatesKey));
  if (_isObject(savedDates)) {
    Object.keys(savedDates).forEach(key => {
      dates[key] = new Date(savedDates[key]);
    });
  }
} catch (error) {}

http.interceptors.request.use(request => {
  // const isPicks = request.url.match(/\/(GetMyPicks)\/?$/gi);
  // if (isPicks) {
  //   const now = moment();
  //   const [ point ] = isPicks;
  //   if (point && dates[point]) {
  //     const delta = now.diff(dates[point]);
  //     if (delta < 20000) {
  //       throw new axios.Cancel(`Operation "${point}" canceled by limit 3 times per minute.`);
  //     }
  //   }
  //   dates[point] = now;
  //   localStorage.setItem(savedDatesKey, JSON.stringify(dates));
  // }

  const authorizationKey = localStorage.getItem('authorizationKey');
  const userId = localStorage.getItem('userId');
  const isAuthenticated = !!(authorizationKey && userId);
  const isPublic = (/\/(WebLogin|ForgotPassword)\/?$/gi).test(request.url);

  if (!isPublic && !isAuthenticated) {
    store.dispatch(logOut());
    return request;
  }

  const utcOffset = moment().utcOffset() / 60
  if (localStorage.getItem('utcOffset') !== `${utcOffset}`) {
    localStorage.setItem('utcOffset', `${utcOffset}`);
    store.dispatch(setUtcOffset({ utcOffset }));
  }

  if (!isPublic) {
    switch (request.method.toLowerCase()) {
      case 'post':
      case 'put':
      case 'patch':
        // add automaticaly userId and authorizationKey to request
        request.data = {
          ...(request.data || {}),
          userId,
          authorizationKey,
          deviceType: isMobile ? 2 : 3 // 2 for mobile web, 3 for desktop web
        }
      // no default
    }
  }

  //  request.url = 'maintenance';
   return request
});

http.interceptors.response.use(res => {
  const errorCode = _get(res, 'data.error.errorCode', 0);
  switch (errorCode) {
    case 7:
    case 8:
      store.dispatch(logOut());
      break;

    case 6:
      store.dispatch(setHasTerms(false));
      break;

    // no default
  }

  return res;

// }, error => {
//   if ((!error.response || error.response.status >= 500)) {
//     const isPicks = new RegExp(/GetMyPicks|GetMyPicksLast/).test(error.message)
//     if (!isPicks) {
//       history.push('/announcement')
//     }
//   }

//   return Promise.reject(error)
});

export default http;