import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, lifecycle, withProps } from 'recompose';
import moment from 'moment';

import MyStats from '../components/MyStats';
import {
  getGeneralInfo,
  getGeneralBetsByLeague,
  getProfitChartData,
  preloadSelect,
  setProfitChartRange,
  getPicksPieCharts
} from '../store/actions/mystats';
import {
  getMyPicks,
  getMyPicksLast
} from '../store/actions/picks';

const prefilledData = {
  onlyLive: '0',
  onlyBet: '0',
  onlyDate: '99'
};

const initMystats = lifecycle({
  async componentDidMount() {
    await this.props.preloadSelect(prefilledData);
    this.props.getMyPicks();
    this.props.getMyPicksLast();
    this.props.getProfitChartData();
    this.props.getGeneralInfo();
    this.props.getGeneralBetsByLeague({});
    this.props.getPicksPieCharts();
  }
});

const mapStateToProps = ({ mystats, picks, form }) => ({
  mystats,
  picks,
  profitChart: mystats.profitChart,
  initialValues: mystats.data,
  formValues: form.betsForm ? form.betsForm.values : {}
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getGeneralInfo,
    getGeneralBetsByLeague,
    getProfitChartData,
    getPicksPieCharts,
    preloadSelect,
    getMyPicks,
    getMyPicksLast,
    setProfitChartRange
  },
  dispatch
);

const propsChart = withProps((props) => {
  let series = [];
  if (props.profitChart) {
    series = [
      {
        name: 'Thing requests',
        findNearestPointBy: 'xy',
        turboThreshold: 30000,
        type: 'area',
        data: props.profitChart.map(data => ({
          x: Date.parse(data.gameDate),
          y: data.unitsWon
        }))
      }
    ];
  }

  if (!series.length) {
    series = [
      {
        name: 'Thing requests',
        findNearestPointBy: 'xy',
        turboThreshold: 30000,
        step: true,
        color: 'rgba(0,0,0,0)',
        enableMouseTracking: false,
        data: [
          [1543622400000, 1.13],
          [1544054400000, 2.06],
          [1544486400000, 2.09],
          [1544918400000, 2.14],
          [1545350400000, 2.21],
          [1545782400000, 2.13],
          [1546214400000, 2.06],
        ],
      },
    ];
  }
  return {
    options: {
      chart: {
        renderTo: 'ProfitGraph',
        // zoomType: 'x',
      },
      boost: {
        useGPUTranslations: true,
      },
      title: {
        text: 'Profit',
        align: 'left',
      },
      accessibility: {
        describeSingleSeries: true,
      },
      legend: {
        enabled: true,
        align: 'left',
        verticalAlign: 'top',
        layout: 'horizontal',
        x: -5,
        y: -15,
        navigation: {
          enabled: false,
        },
        symbolWidth: 8,
        symbolHeight: 8,
        symbolPadding: 8,
        itemCheckboxStyle: {
          height: '6px',
        },
        itemStyle: {
          color: '#9b9b9b',
          fontWeight: '100',
          fontFamily: 'proxima-nova, Georgia, sans-serif',
          cursor: 'default',
          appearance: 'none',
        },
        itemHoverStyle: {
          color: '#9b9b9b',
        },
      },
      tooltip: {
        shared: true,
        backgroundColor: '#fff',
        borderColor: '#d1d7e9',
        borderRadius: 5,
        useHTML: true,
        shadow: false,
        formatter() {
          return `<span style="font-family:proxima-nova;font-size:12px;color:#3c4144">${moment(
            this.x,
          ).format(
            'DD/MM/YY',
          )}</span><br/><span style="font-family:proxima-nova;font-size:12px;color:#3c4144">Request: ${
            this.y
          }</span>`;
        },
      },
      xAxis: [
        {
          type: 'datetime',
          labels: {
            style: {
              fontFamily: 'proxima-nova',
              fontSize: '12px',
              color: '#8f9ea6',
            },
          },
          tickPixelInterval: 140,
        },
      ],
      yAxis: [
        {
          title: {
            text: '',
          },
          labels: {
            overflow: 'justify',
            style: {
              fontFamily: 'proxima-nova',
              fontSize: '12px',
              fontWeight: '600',
              color: '#888888',
            },
          },
          gridLineColor: '#dedede',
          gridLineDashStyle: 'dash',
          tickPixelInterval: 21,
        },
      ],
      plotOptions: {
        area: {
          fillOpacity: 0.12,
          fillColor: {
            linearGradient: [0, 0, 0, 300],
            stops: [[0, 'rgba(255, 185, 70, 0.7)'], [1, 'rgba(255, 185, 70, 0)']],
          },
          lineColor: '#ffb946',
          lineWidth: 3,
          marker: {
            fillColor: 'white',
            lineColor: 'rgb(255, 185, 70)',
            lineWidth: 3,
            radius: 0,
            enabled: false,
            states: {
              hover: {
                lineWidthPlus: 0,
                enabled: true,
                radius: 6,
              },
            },
          },
          shadow: false,
          states: {
            hover: {
              lineWidth: 3,
            },
          },
          threshold: null,
          showInLegend: false
        },
      },
      series
    }
  };
});

const form = reduxForm({
  form: 'betsForm'
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  form,
  initMystats,
  propsChart
)(MyStats);
