import styled from 'styled-components';

export default styled.div`
  .back-container {
    margin-right: 10px;
  }
  
  .send-btn,
    .btn-border {
      border-radius: 3px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      min-width: 171px;
      outline: none;
      text-align: center;
      vertical-align: middle;

      img {
        display: inline-block;
        margin-right: 4px;
        vertical-align: top;
      }
    }
    .send-btn {
    border: 0;
    cursor: pointer;
    min-width: 119px;
    height: 40px;
    background-image: linear-gradient(107deg, #8e70ee, #4a7ece);
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    font-size: 15px;
    color: #fff;
    font-weight: 300;
    margin-left: 24px;
    outline: none;
    z-index: 100;
    position: relative;

    &:before {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:after {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:active {
      &:after {
        opacity: 1;
      }
    }
  }
`;
