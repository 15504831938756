import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, withProps } from 'recompose';

import PicksTable from '../../components/CreateNewPick/PicksTable';
import { openModal } from '../../store/actions/view';
import { selectGame, getGraphByPick, prefillOddsReaches } from '../../store/actions/picks';
import { setGameId } from '../../store/actions/oddsalerts';
import { gamesFormatted, fullfillGamesTable } from '../../selectors/picks';

const mapStateToProps = ({ auth, picks, user }) => {
  const games = picks.games && picks.games.length ? picks.games.filter(g => !!g.isCorners === !!picks.selectedLeague.isCorners) : []
  const userLevel = user.account.userLevel;
    return {
      utcOffset: auth.utcOffset,
     games: fullfillGamesTable(gamesFormatted(games, userLevel)),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    openModal,
    selectGame,
    getGraphByPick,
    setGameId,
    prefillOddsReaches,
  },
  dispatch,
);

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const wProps = withProps(ownProps => ({
  openPickModal: (g, bet, line) => {
    ownProps.openModal('pick');
    ownProps.setGameId(g.gameId, line);
    ownProps.prefillOddsReaches();
    console.log('OpenPicksModal ', line);
    ownProps.getGraphByPick({
      eventsConnectionId: g.eventsConnectionId,
      betGamePart: g.betGamePart,
      bet,
      line,
    });
  },
}));

export default compose(
  connected,
  wProps,
)(PicksTable);
