import React from 'react';
import classnames from 'classnames';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment';

const MyRecommendedMembers = ({ myRecommendedMembers }) => {
  const tableData = myRecommendedMembers.recommendedMembers || [];
  const defaultPageSize = 20;
  return (
    <>
      <h1 className="main-title">My Recommended Members</h1>
      <div className="block">
        <ReactTable
          className="recomended-table"
          pageSize={undefined}
          defaultPageSize={defaultPageSize}
          resizable={false}
          minRows={2}
          showPagination={tableData.length > defaultPageSize}
          data={tableData}
          columns={[
            {
              Header: 'Members Name',
              accessor: 'memberName',
              sortable: false,
              minWidth: 100
            },
            {
              Header: 'Email',
              accessor: 'email',
              sortable: false,
              minWidth: 150
            },
            {
              Header: 'Date Added',
              accessor: 'dateAdded',
              sortMethod: (a, b) => {
                const momentA = moment(a, 'MMM D, YY');
                const momentB = moment(b, 'MMM D, YY');
                return momentA.diff(momentB);
              },
              minWidth: 120,
              maxWidth: 120
            },
            {
              Header: 'Status',
              accessor: 'status',
              Cell: data => (
                <span className={classnames({
                  active: data.original.statusId === 1,
                  waiting: data.original.statusId === 0,
                  rejected: data.original.statusId === 2
                })}>{data.value}</span>
              ),
              minWidth: 150,
              maxWidth: 200
            }
          ]}
        />
      </div>
    </>
  );
};

export default MyRecommendedMembers;
