import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import traderIcon from '@/assets/images/ic-trader.svg';
import chatIconDots from '@/assets/images/ic-chat-dots.svg';
import sendIcon from '@/assets/images/ic-send.svg';
import Icon from './Icon';

import ChatBody from './ChatBody';
import Wrapper from './styled';

class FloatChat extends Component {
  render () {
    const {
      type,
      theme,
      title,
      toggle,
      isOpened,
      messages,
      handleSubmit,
      getLastMessages,
      // setTimmerAutofetch,
      addOpenedChat,
      removeOpenedChat,
      lastChatId,
      setAsRead,
      offsetX,
      offsetY,
      showDate,
      unReadMsgsCount
    } = this.props;

    return (
      <Wrapper
        className={`chat chat-type-${type} chat-theme-${theme}`}
        style={{transform: `translate3d(${offsetX}px, ${offsetY}px, 0)`}}
      >
        <div className="chat-btn-toggle" onClick={() => toggle()}>
          {/* <span className="notification-count">10</span> */}
          {unReadMsgsCount ? <span className="notification-count">{unReadMsgsCount}</span> : null}
          <img src={traderIcon} alt=""/>
          <Icon className="title" text={title} />
        </div>
        {
          isOpened &&
          <div className="chat-inner">
            <div className="chat-header" onClick={() => toggle()}>
              <span className="chat-header-icon">
                <img src={chatIconDots} alt=""/>
              </span>
              <h3 className="chat-header-title">{title}</h3>
              <span className="chat-header-status"></span>
            </div>
            <div className="chat-body">
              <div className="chat-wrapper">
                <ChatBody
                  showDate={showDate}
                  messages={messages}
                  getLastMessages={getLastMessages}
                  addOpenedChat={addOpenedChat}
                  removeOpenedChat={removeOpenedChat}
                  setAsRead={setAsRead}
                  lastChatId={lastChatId}
                />

                <div className="chat-action">
                  <form className="chat-form" noValidate onSubmit={handleSubmit}>
                    <Field
                      name="message"
                      type="text"
                      component="input"
                      className="chat-input"
                      autoFocus
                      autoComplete="off"
                      placeholder="Write your message"
                    />
                    <button className="chat-btn" type="submit">
                      <img src={sendIcon} alt=""/>
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        }
      </Wrapper>
    );
  }

  static defaultProps = {
    type: 'float',
    theme: 'blue',
    offsetY: 0,
    offsetX: 0,
    title: ''
  };

  static propTypes = {
    type: PropTypes.oneOf(['float', 'static']),
    theme: PropTypes.oneOf(['blue', 'purple']),
    offsetY: PropTypes.number,
    offsetX: PropTypes.number,
    toggle: PropTypes.func.isRequired,
    isOpened: PropTypes.bool.isRequired,
    messages: PropTypes.arrayOf(PropTypes.object).isRequired,
    sendMessage: PropTypes.func.isRequired,
    title: PropTypes.string,
    setAsRead: PropTypes.func.isRequired,
    lastChatId: PropTypes.number.isRequired,
    addOpenedChat: PropTypes.func.isRequired,
    removeOpenedChat: PropTypes.func.isRequired
  };
};

export default FloatChat;
