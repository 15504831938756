import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import classnames from 'classnames';
import FormInput from '../FormInput';

const ChangePassword = ({
  hide,
  handleSubmit,
  oldVisible,
  newVisible,
  retypeVisible,
  onClickRetype,
  onClickOld,
  onClickNew,
}) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="change-pass">
      <h3>Change Password</h3>
      <div className="block-info">
        <div className="field-wrapper">
          <Field
            name="oldPassword"
            type={oldVisible ? 'text' : 'password'}
            component={FormInput}
            label="Current Password"
          />
          <div
            className={classnames({ eye: true, 'eye-active': oldVisible })}
            onClick={() => {
              onClickOld();
              setTimeout(onClickOld, 1000);
            }}
          />
        </div>
        <div className="block-text" />
      </div>
      <div className="block-info">
        <div className="field-wrapper">
          <Field
            name="newPassword"
            type={newVisible ? 'text' : 'password'}
            component={FormInput}
            label="New Password"
          />
          <div
            className={classnames({ eye: true, 'eye-active': newVisible })}
            onClick={() => {
              onClickNew();
              setTimeout(onClickNew, 1000);
            }}
          />
        </div>
        <div className="block-text" />
      </div>
      <div className="block-info">
        <div className="field-wrapper">
          <Field
            name="retypeNewPassword"
            type={retypeVisible ? 'text' : 'password'}
            component={FormInput}
            label="Retype New Password"
          />
          <div
            className={classnames({ eye: true, eye2: true, 'eye-active': retypeVisible })}
            onClick={() => {
              onClickRetype();
              setTimeout(onClickRetype, 1000);
            }}
          />
        </div>
        <div className="block-text" />
      </div>
      <div className="block-info block-info-btn">
        <div className="block-label" />
        <div className="block-input">
          <button type="submit" className="color-btn">
            Change Password
          </button>
        </div>
        <div className="block-text">
          <button type="button" onClick={hide} className="link-btn link-btn-2">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
);

ChangePassword.propTypes = {
  hide: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  oldVisible: PropTypes.bool.isRequired,
  newVisible: PropTypes.bool.isRequired,
  retypeVisible: PropTypes.bool.isRequired,
  onClickRetype: PropTypes.func.isRequired,
  onClickOld: PropTypes.func.isRequired,
  onClickNew: PropTypes.func.isRequired,
};

export default ChangePassword;
