import React from 'react';

const SearchIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd" stroke="#818FA9" strokeWidth="2" transform="translate(1 1)">
      <circle cx="8" cy="8" r="8" />
      <path strokeLinecap="round" d="M14 14l4 4" />
    </g>
  </svg>
);

export default SearchIcon;
