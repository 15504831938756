import { reduxForm } from 'redux-form';
import { compose, withProps } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ReportModal from '../../components/UnitsReport/ReportModal';
import {
  changeReportModalPosition, closeReportModal, sendReport, sendMemberUnitsAllowancesReport,
} from '../../store/actions/report';

const mapStateToProps = ({ report }) => ({
  modalX: report.modal.x,
  modalY: report.modal.y,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    changeReportModalPosition,
    closeReportModal,
    sendReport,
    sendMemberUnitsAllowancesReport,
  },
  dispatch,
);

const form = reduxForm({
  form: 'reportForm',
});

const props = withProps(ownProps => ({
  onSubmit: ownProps.allowanceReport
    ? ownProps.sendMemberUnitsAllowancesReport
    : ownProps.sendReport,
}));

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  props,
  form,
)(ReportModal);
