import styled from 'styled-components';

import colors from '../../config/colors';

export default styled.div`
  padding: 40px 20px;

  h1 {
    font-weight: 600;
    font-size: 36px;
    color: ${colors.secondaryBlue};
    text-align: center;
    margin: 0;
  }

  h2 {
    font-size: 24px;
    color: ${colors.secondaryBlue};
    text-align: center;
    font-weight: 400;
    max-width: 919px;
    margin: 40px auto 60px;
  }

  .app {
    box-shadow: 0 5px 12px 0 rgba(73, 42, 139, 0.37);
    padding: 22px 22px 40px;
    width: 428px;
    height: 200px;
    color: #fff;
    font-size: 36px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    & > img {
      position: absolute;
      right: 5px;
      bottom: 10px;
    }

    a {
      border-radius: 5px;
      border: solid 2px #ffffff;
      background-color: rgba(0, 0, 0, 0);
      width: 229px;
      height: 70px;
      font-size: 24px;
      color: #fff;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0 34px;
      justify-content: space-between;
    }
  }

  .ios-app {
    background-image: linear-gradient(115deg, #ee70e9, #8363f9);
    margin-right: 45px;

    a {
      border: solid 2px #ffffff;
    }

    @media (max-width: 1185px) {
      margin-right: 0;
      width: 100%;
      margin-bottom: 15px;
    }
  }

  .android-app {
    background-image: linear-gradient(295deg, #3870e7, #4ac3ce);
    margin-left: 45px;

    a {
      border: solid 2px #ffffff;
    }

    @media (max-width: 1185px) {
      margin-left: 0;
      width: 100%;
    }
  }

  .apps-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .note {
    background-color: #fff;
    padding: 19px 23px;
    margin-top: 60px;
    color: ${colors.secondaryBlue};
    font-size: 14px;

    a {
      color: ${colors.mainBlue};

      &:hover {
        color: #1d7bea;
      }

      &:active {
        color: #366fb1;
      }
    }
  }

  ol {
    padding-left: 14px;
    margin-bottom: 40px;
    margin-top: 0;
  }

  p {
    margin-bottom: 40px;

    &:last-child,
    &.how-to {
      margin-bottom: 0;
    }
  }
`;
