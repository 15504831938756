import React from 'react';
import PropTypes from 'prop-types';
import Wrapper from './styles';

class SlideToggle extends React.PureComponent {
  render () {
    const {
      input,
      label,
      id,
      disabled,
      wrapperClass,
      labelClassName,
    } = this.props

    return (
      <Wrapper className={wrapperClass}>
        <input
          hidden
          type="checkbox"
          id={id}
          disabled={disabled}
          checked={input.value}
          onChange={input.onChange}
        />
        <label htmlFor={id} className={`slide-toggle-label ${labelClassName}`}>
          <div className="slide-toggle-title">
            {label}
          </div>
          <div
             className={
              `slide-toggle
              ${input.value
                ? 'slide-toggle_on'
                : 'slide-toggle_off'
            }`}
          >
            <div
              className={
                `slide-toggle__switcher
                ${input.value
                  ? 'slide-toggle__switcher_on'
                  : 'slide-toggle__switcher_off'
              }`}
            />
          </div>
        </label>
      </Wrapper>
    );
  }
}

SlideToggle.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  wrapperClass: PropTypes.string,
};

SlideToggle.defaultProps = {
  disabled: false,
  wrapperClass: null,
};

export default SlideToggle;
