/**
 * External dependencies
 */
import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

/**
 * Internal dependencies
 */
import Styled from './styles';
import {
  getEventsInPlay,
  getSelectedMatch
} from '@/store/actions/statistics-actions/statistics-actions';
import { getEvents } from '@/utils/bet365/get-events';
import { NAME, EVENT_ID } from '@/utils/bet365/constants';
import SelectedMatch from './Selected-match';
import MatchHistory from './Match-history';
import StatisticsError from '@/components/NewStatistics/Error';
import { Loader } from '@/components/NewStatistics/Loader';

class NewStatistics extends Component {
  constructor (props) {
    super(props);

    this.state = {
      currentEvent: null,
      initialGameSet: false
    }
  }

  componentDidMount () {
    const { getEventsInPlay } = this.props;
    this.props.getEventsInPlay();
    this.leaguesTimer = setInterval(() => {
      getEventsInPlay();
    }, 15000);
  }

  componentDidUpdate(){
    const { newStatistics } = this.props;

    if(newStatistics.pending === false) {
      const games = newStatistics.games;
      const leagues = newStatistics.leagues;

      if(games.length > 0 && leagues.length > 0 && !this.state.initialGameSet) {
        const league = leagues[0];
        this.handleEventSelection(getEvents({league, games})[0]);
        this.setState({initialGameSet: true});
      }
    }
  }

  componentWillUnmount () {
    clearInterval(this.leaguesTimer);
  }

  handleEventSelection = (game = {}) => {
    clearInterval(this.leaguesTimer);
    this.props.selectMatch(game[EVENT_ID]);
  }

  render() {
    const {
      pending,
      selectedEvent,
      leagues,
      idOfSelectedEvent,
      games,
      error
    } = this.props.newStatistics;
    return (
      <Styled className="statistics content-container">
        {error ? (
          <StatisticsError
            customMessage={"Sorry, we cant get games in play at the moment. "
              + "Please contact support or try again in a few minutes."}
          />
        ) : (
          <div className="statistics__columns">
            <div className="statistics__list statistics__sidebar">
              <div className="statistics__list__header">
                <h2>Soccer</h2>
              </div>
              <div className="statistics__list-content">
                <ul>
                  {pending
                    ? <Loader/>
                    : leagues && leagues.map((league, i) => (
                        getEvents({ league, games }).length > 0 && (
                          <li key={`${league}-${i}`} className='league'>
                            <span className="list-item">
                              {league}
                            </span>
                            <ul className='events-list'>
                              {getEvents({ league, games }).map((game, index) => (
                                <li
                                  key={game[EVENT_ID]}
                                  className={
                                    classnames('event list-item', {
                                    'selected-event': game[EVENT_ID]
                                      && idOfSelectedEvent
                                      && game[EVENT_ID] === idOfSelectedEvent
                                    })
                                  }
                                  onClick={() => this.handleEventSelection(game)}
                                >
                                  <span>{game[NAME]}</span>
                                </li>
                              ))}
                            </ul>
                          </li>
                        )
                    ))
                  }
                </ul>
              </div>
            </div>
            <div className="statistics__selected-match-wrapper">
              {selectedEvent.pending && <Loader/>}
              {selectedEvent && selectedEvent.home && <SelectedMatch/>}
            </div>
            <div className="statistics__history-wrapper">
              {selectedEvent && selectedEvent.home && <MatchHistory/>}
            </div>
          </div>
        )}
      </Styled>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getEventsInPlay: () => dispatch(getEventsInPlay()),
  selectMatch: eventId => dispatch(getSelectedMatch(eventId))
});

const mapStateToProps =  state => ({
  newStatistics: state.newStatistics,
  idOfSelectedEvent: state.newStatistics.idOfSelectedEvent
});

export default connect(mapStateToProps, mapDispatchToProps)(NewStatistics);
