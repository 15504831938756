import styled from 'styled-components';
import arrowImg from '../../assets/images/ic-arrow-back-norm.svg';
import arrowImgHover from '../../assets/images/ic-arrow-back-hover.svg';
import arrowImgSelected from '../../assets/images/ic-arrow-back-selected.svg';

export default styled.div`
  width: 564px;
  margin: 0 auto;
  position: relative;

  & > div {
    width: 413px;
    margin: 0 auto;
    position: relative;
  }

  input {
    width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
    height: 30px;
    border: none;
    border-bottom: 1px solid #4a90e2;
    color: #38415f;
  }

  a {
    color: #4a90e2;
  }

  label {
    font-size: 20px;
    font-weight: 700;
    color: #38415f;
  }

  .title {
    color: #4a90e2;
    text-align: center;
    font-size: 64px;
    font-weight: 200;
    margin-top: 0;
    margin-bottom: 70px;
  }

  p {
    font-size: 18px;
    text-align: center;
    color: #38415f;
  }

  form {
    margin-top: 143px;
  }

  form div {
    position: relative;
  }

  form span {
    position: absolute;
    right: 0;
    top: 6px;
    color: red;
  }

  nav {
    width: 368px;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }

  nav a {
    font-size: 12px;
    color: #818fa9;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 0 13px;
    border-left: 1px solid #818fa9;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: #1d7bea;
    }

    &:active {
      color: #366fb1;
      text-decoration: underline;
    }
  }

  nav a:first-child {
    border-left: none;
    padding-left: 0;
  }

  nav a:last-child {
    padding-right: 0;
  }

  button {
    cursor: pointer;
    font-size: 15px;
  }

  .link-back {
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #818fa9;
    border: none;
    background: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
    padding-left: 0;
    margin-top: 105px;
    padding-left: 15px;
    text-decoration: none;
    display: flex;
    align-items: center;
    height: 13px;

    &:focus {
      outline: none;
    }

    &:hover {
      color: #1d7bea;
      text-decoration: underline;

      .back-image {
        background-image: url(${arrowImgHover});
      }
    }

    &:active {
      color: #366fb1;
      text-decoration: underline;

      .back-image {
        background-image: url(${arrowImgSelected});
      }
    }

    .back-image {
      width: 7px;
      height: 13px;
      margin-right: 9px;
      background-image: url(${arrowImg});
    }
  }

  .submit-btn {
    width: 100%;
    height: 40px;
    padding: 10px;
    background-image: linear-gradient(to right, #8e70ee, #4a7ece);
    border: none;
    color: white;
    margin-bottom: 20px;
    margin-top: 40px;
    position: relative;
    font-size: 15px;
    z-index: 100;

    &:before {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:after {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:active {
      &:after {
        opacity: 1;
      }
    }

    &:focus {
      outline: none;
    }
  }
  .darken-wrapper > div {
    background-color: rgba(0, 0, 0, 0.36);
  }
  .restore-modal {
    width: 500px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .title-wrapper {
    margin-top: 30px;
    margin-bottom: 35px;
    text-align: center;
  }

  button:focus,
  textarea:focus,
  input:focus {
    outline: none;
  }

  textarea::placeholder,
  input::placeholder {
    color: #818fa9;
  }

  textarea:focus::placeholder,
  input:focus::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 1px solid #4a90e2;
    -webkit-text-fill-color: #38415f;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
  }

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #366fb1;
  }

  .modal-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #38415f;
    display: block;
    margin-bottom: 42px;
  }

  .modal-link {
    display: block;
    margin: 0 auto;
    width: 50px;
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a90e2;

    &:hover {
      color: #1d7bea;
    }

    &:active {
      color: #366fb1;
    }
  }

  .form-error {
    font-size: 13px !important;
    position: absolute;
    right: -124px;
    top: 7px;
    color: #ff5766;
    width: 117px;
    height: 20px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .red-error {
    label {
      color: #ff5766;
    }

    textarea {
      border-color: #ff5766;
    }
    input {
      border-bottom-color: #ff5766;
    }
  }

  .restore-error {
    position: static;
  }

  @media screen and (max-width: 1024px) {
    .link-back {
      margin-left: 70px;
      margin-top: 70px;
    }
  }

  @media screen and (max-width: 840px) {
    padding-left: 50px;
    padding-right: 50px;

    nav {
      left: 15%;
    }
  }

  @media screen and (max-height: 810px) {
    form {
      margin-top: 90px;
    }
  }

  @media screen and (max-height: 700px) {
    .title {
      margin-bottom: 30px;
    }
  }

  @media screen and (max-width: 602px) {
    z-index: 999;
    width: 100%;
    position: absolute;
    height: 100vh;
    padding: 0 50px;

    input {
      background: transparent;
    }
  }
`;
