import React from 'react';

const WaitingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd">
      <g stroke="#FFB946" strokeWidth="2">
        <circle cx="14" cy="14" r="14" transform="translate(1 1)" />
        <path strokeLinecap="round" d="M9.406 9h11.789M9.406 22h11.789" />
        <path d="M11.323 9c-.43.99-.43 1.82 0 2.49.732 1.139 2.047 2.309 3.944 3.51 1.958-1.04 3.41-2.21 4.353-3.51.507-.7.507-1.53 0-2.49h-8.297z" />
        <path d="M11.323 21c-.43-.99-.43-1.82 0-2.49.732-1.139 2.047-2.309 3.944-3.51 1.958 1.04 3.41 2.21 4.353 3.51.507.7.507 1.53 0 2.49h-8.297z" />
      </g>
      <path fill="#FFB946" d="M13 12h5v2h-5z" />
    </g>
  </svg>
);

export default WaitingIcon;
