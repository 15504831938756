import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

// import ReportIcon from '../../assets/images/ic-report';
import PlusIcon from '../../assets/images/ic-plus';
import MinusIcon from '../../assets/images/ic-minus';
import DayReportRow from './DayReportRow';

class ReportRow extends Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    openReportModal: PropTypes.func.isRequired,
  };

  state = {
    opened: false,
  };

  toggleRow = () => {
    this.setState(({ opened }) => ({ opened: !opened }));
  };

  render() {
    const { opened } = this.state;
    const { report, openReportModal } = this.props;

    return (
      <Fragment>
        <tr className={classNames({ 'tr-table': true, open: opened, 'cursor-pointer': true })}>
          <td className="td-table" onClick={this.toggleRow}>
            {opened ? <MinusIcon /> : <PlusIcon />}
            {moment(report.startDate).format('DD/MM/YY')}
          </td>
          <td className="td-table" onClick={this.toggleRow}>
            {moment(report.endDate).format('DD/MM/YY')}
          </td>
          <td className="td-table" onClick={this.toggleRow}>
            {report.unitsAvailible}
          </td>
          <td className="td-table" onClick={this.toggleRow}>
            {report.unitsUsed}
          </td>
          <td className="td-table" onClick={this.toggleRow}>
            {report.fixturesAvailible}
          </td>
          {/* <td className="td-table">
            <div
              onClick={e => openReportModal(e, report, 'week')}
              style={{ display: 'inline-block' }}
              role="main"
            >
              <ReportIcon />
            </div>
          </td> */}
        </tr>
        <tr className="tr-table tr-table-colspan">
          <td colSpan={7}>
            <table cellSpacing="0">
              <thead>
                <tr className="title-table">
                  <th className="td-table">Day</th>
                  <th className="td-table">Units Avilable</th>
                  <th className="td-table">Units Used</th>
                  <th className="td-table">Units Used</th>
                  <th className="td-table">Fixtures Avilable</th>
                  {/* <th className="td-table">Report</th> */}
                </tr>
              </thead>

              <tbody>
                {report.unitsReportDays.map(day => (
                  <DayReportRow
                    key={`${report.startDate}_${day.dayName}`}
                    day={day}
                    openReportModal={openReportModal}
                    week={report}
                  />
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
}

export default ReportRow;
