import styled from 'styled-components';

import fonts from '@/config/fonts';
import colors from '@/config/colors';
import showPassImg from '@/assets/images/ic-eye.svg';
import showPassImgHover from '@/assets/images/ic-eye-hover.svg';
import showPassImgSelected from '@/assets/images/ic-eye-selected.svg';

export default styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.proximaNova};

  .profile-image-settings {
    padding-top: 15px;
  }

  .profile-image.disabled {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .block {
    background-color: white;
    margin: 20px;
    font-size: 0;
    padding: 43px 25px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e6e6e6;
  }

  .title-user {
    color: ${colors.secondaryBlue};
    font-family: ${fonts.proximaNova};
    font-size: 52px;
    font-weight: 600;
    line-height: 1;
    margin: 0;
  }

  .left-block {
    display: inline-block;
    text-align: right;
    width: 33.3%;
    vertical-align: top;

    .block-img {
      max-width: 100%;
      padding-left: 30px;
      padding-top: 53px;
      min-width: 320px;

      img {
        max-width: 250px;
        width: 100%;
        margin-right: 50px;
      }
    }
  }

  .right-block {
    display: inline-block;
    width: 66.6%;
    vertical-align: top;

    .top-block {
      .block-label {
        color: ${colors.red};
      }

      .block-text {
        padding-top: 0;
        width: 33.3%;
      }

      .left-info {
        display: inline-block;
        width: 66.6%;
        vertical-align: top;
      }

      .block-label, .block-input {
        width: 50%;
      }
    }
  }

  .block-info {
    margin-bottom: 15px;

    &.block-info-mb0 {
      margin-bottom: 0;
    }
  }

  .link-btn {
    color: ${colors.lightBlue_2};
    float: right;
    font-family: ${fonts.proximaNova};
    font-size: 13px;
    line-height: 1;
    outline: none;
    transition: 0.7s;
    background: white;
    outline: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #1d7bea;
      text-decoration: underline;
    }

    &:focus,
    &:active {
      outline: none;
      color: #366fb1;
      text-decoration: underline;
    }

    &.link-btn-2 {
      font-size: 18px;
      font-weight: 600;
      float: left;
      border: none;
      background: rgba(255,255,255,0);
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .block-label, label {
    display: inline-block;
    color: ${colors.secondaryBlue};
    font-family: ${fonts.proximaNova};
    font-size: 13px;
    font-weight: 600;
    line-height: 30px;
    padding-right: 24px;
    text-align: right;
    width: 33.3%;
    vertical-align: top;
  }

  //Edits by Vlad
  input:disabled {
    background: white;
  }
  .red-error {
    label {
      color: #ff5766;
    }
  }
  .required {
      .form-error {
        display: none !important;
      }
    }
  .form-element {
    display: flex;
    justify-content: flex-end;

    div {
      width: 300px;
      position: relative;
    }

    .form-error {
      color: red;
      font-size: 13px;
      position: absolute;
      left: 310px;
      top: 7px;
      display: block;
      width: 200px;
    }
  }

  .block-info.error {
    input {
      border-color: ${colors.red} !important;
    }
  }

  .all-info {

    .block-info {
      display: flex;
      justify-content: flex-end;

      & > div:first-child {
        width: 66.5%;
      }
    }

    .block-info-btn {
      & > div:first-child {
        width: auto;
      }
    }

    select {
      width: 30%;
      height: 30px;
      border: 1px solid #dbdbdb;
      font-size: 13px;
      border-radius: 2px;
      color: #000;
      padding: 7px 11px;
      outline: none;
      -webkit-transition: 0.7s;
      transition: 0.7s;
    }

    .block-input-selects {
      display: flex;
      justify-content: space-between;
    }

    .block-checkboxes {
      display: flex;
      width: 300px;

      label {
        width: auto;
        padding-right: 10px;
      }
    }

    #country,
    #yearOfBirth,
    #monthOfBirth,
    #dayOfBirth {
      width: 30%; padding: 0;
      font-family: ${fonts.proximaNova};
      color: ${colors.black};
      outline: none;
      appearance: none;
      height: 32px;
      border: solid 1px #dbdbdb;
      background-color: #fff;
      border-radius: 0;
      font-size: 13px;
      padding: 1px 13px;
      background-repeat: no-repeat;
      background-position-x: calc(100% - 9px);
      background-position-y: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23818FA9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");

      &:hover {
        border-color: #a5c8f1;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23A5C8F1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");
      }

      &:active,
      &:focus {
        border-color: #4a90e2;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='15' viewBox='0 0 7 15'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%234A90E2' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 5l2.5-3L6 5M1 10l2.5 3L6 10'/%3E%3C/g%3E%3C/svg%3E%0A");
      }
    }
    label[for=countryId] {
      width: auto;
    }

    .form-error {
    font-size: 13px !important;
    position: absolute;
    right: -125px;
    top: -5px;
    color: #ff5766;
    width: 117px;
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .red-error {
    label {
      color: #ff5766;
    }

    textarea {
      border-color: #ff5766;
    }
    input {
      border-bottom-color: #ff5766;
    }
  }

    .block-input,
    .block-input-selects {
      width: 300px;
    }

    .check-success,
    .check-error {
      font-size: 12px;
      width: 300px;
      float: right;
      padding-top: 3px;
    }

    .check-success {
      color: ${colors.leafyGreen};
    }

    .check-error {
      color: ${colors.red};
    }

    #country {
      width: 300px;
    }

    .closed {
      display: none;
    }

    .opened {
      display: block;
    }
  }

  //END edits

  input {
      width: 100%;
      height: 30px;
      border: 1px solid  ${colors.lightGray_1};
      font-size: 13px;
      border-radius: 2px;
      color: ${colors.black};
      padding: 7px 11px;
      outline: none;
      transition: 0.7s;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${colors.lightBlue};
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${colors.lightBlue};
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${colors.lightBlue};
      }

      &:hover {
        border-color: rgba(74, 144, 226, 0.5);
      }

      &:focus,
      &:active {
        border-color: ${colors.thirdBlue};

        & + button g {
          stroke: ${colors.thirdBlue};
        }

        & + button + .prediction {
          visibility: visible;
          z-index: 99;
          opacity: 1;
        }
      }

      &.error {
        border-color: ${colors.red};
      }

    }

  textarea::placeholder,
  input::placeholder {
    color: #818fa9;
  }

  textarea:focus::placeholder,
  input:focus::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  .block-input {
    display: inline-block;
    width: 33.3%;
    vertical-align: top;

    input {
      width: 100%;
      height: 30px;
      border: 1px solid  ${colors.lightGray_1};
      font-size: 13px;
      border-radius: 2px;
      color: ${colors.black};
      padding: 7px 11px;
      outline: none;
      transition: 0.7s;

      &::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${colors.lightBlue};
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${colors.lightBlue};
      }

      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${colors.lightBlue};
      }

      &:hover {
        border-color: rgba(74, 144, 226, 0.5);
      }

      &:focus,
      &:active {
        border-color: ${colors.thirdBlue};

        & + button g {
          stroke: ${colors.thirdBlue};
        }

        & + button + .prediction {
          visibility: visible;
          z-index: 99;
          opacity: 1;
        }
      }

      &.error {
        border-color: ${colors.red};
      }

    }
  }
  .block-text {
    display: inline-block;
    padding-left: 23px;
    padding-top: 6px;
    width: 33.3%;
    vertical-align: top;

    .error-text, p, h4 {
      font-family: ${fonts.proximaNova};
      font-size: 13px;
      color: ${colors.secondaryBlue};
      margin: 0 0 13px;
    }
    .error-text {
      color: ${colors.red};
      display: inline-block;
      font-weight: 600;
    }
    h4 {
      font-weight: 600;
    }
  }

  .color-btn {
    background-image: linear-gradient(98deg, #8e70ee, #4a7ece);
    border: 0;
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    color: ${colors.white};
    cursor: pointer;
    font-family: ${fonts.proximaNova};
    height: 40px;
    outline: none;
    transition: 0.7s;
    width: 100%;
    font-size: 15px;
    z-index: 100;
    position: relative;

    &:before {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:after {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:active {
      &:after {
        opacity: 1;
      }
    }

    &[disabled] {
      cursor: default;
      opacity: 0.5;
    }
  }


  .no-margin {
    display: none;
    margin: 0;
  }

  .color-border-btn {
    background: linear-gradient(103deg, #8e70ee, #4a7ece);;
    box-shadow: 0 0 8px 0 rgba(54, 111, 177, 0.11);
    border-radius: 5px;
    color: ${colors.mainBlue};
    display: inline-block;
    font-family: ${fonts.proximaNova};
    font-size: 13px;
    font-weight: 600;
    outline: none;
    padding: 1px;
    text-decoration: none;
    cursor: pointer;
    border: 1px solid white;
    width: 100%;

    span {
      z-index: 100;
      position: relative;
    }


    span:before {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    span:after {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }

    span:before,
    span:after {
      content: '';
      width: 100%;
      height: 42px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
      color: ${colors.white} !important;
    }

    &:hover {
      color: white;
      span:before {
        opacity: 1;
      }
    }

    &:active {
      span:after {
        opacity: 1;
      }
    }

  // &:hover {
  //   span{
  //     background-image: linear-gradient(105deg, #7b57ee, #306ece);
  //     color: ${colors.white};
  //     transition: 0.4s
  //   }

  // }

  // &:active,
  // &:focus {
  //   span {
  //     background-image: linear-gradient(105deg, #6952b3, #2558a8);
  //     background-color: ${colors.transparent};
  //     color: ${colors.white};
  //   }
  // }
}
.color-border-btn span {
  background: #fff;
  display: block;
  padding: 1em 3em;
  border-radius: 3px;
}

  .change-pass {
    padding-top: 24px;

    h3 {
      font-size: 18px;
      text-align: right;
      width: 40%;
      color: #38415f;
    }
    .field-wrapper {
      position: relative;

      .eye {
        position: absolute;
        top: 3px;
        right: 8px;
        cursor: pointer;
        background-image: url(${showPassImg});
        width: 24px;
        height: 24px;

        &:hover {
          background-image: url(${showPassImgHover});
        }

        &:active {
          background-image: url(${showPassImgSelected});
        }
      }
      .eye-active {
          background-image: url(${showPassImgSelected});
      }

      img {
        position: absolute;
        top: 3px;
        right: 8px;
        cursor: pointer;
      }
    }
  }

  .block-checkboxes {
    display: inline-block;
    width: 33.3%;
    vertical-align: top;
  }

  .checkmark {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;
    background-color: ${colors.white};
    border: 1px solid ${colors.lightGray_1};

    &:hover {
      width: 24px;
      height: 24px;
      border: solid 1px #bfd0e5;
      background-color: white;
    }
  }

  /* On mouse-over, add a grey background color */
  .label-checkbox:hover input ~ .checkmark {
    background-color: ${colors.white};
  }

  /* When the checkbox is checked, add a blue background */
  .label-checkbox input:checked ~ .checkmark {
    background-color: ${colors.white};
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .label-checkbox input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .label-checkbox .checkmark:after {
    left: 7px;
    top: 2px;
    width: 6px;
    height: 12px;
    border: solid ${colors.lightBlue_2};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .all-text-info {
      color: ${colors.secondaryBlue};
      font-family: ${fonts.proximaNova};
      font-size: 13px;
      font-weight: 600;
  }

  .label-checkbox {
    padding-right: 20px;
    padding-left: 30px;
    position: relative;
    line-height: 24px;
    margin-bottom: 15px;
    height: 24px;
    display: inline-block;
    max-width: 33.3%;
    vertical-align: top;

    input {
      height: 0;
      width: 0;
      opacity: 0;
    }
  }

  .block-input-selects {
    .block-select {
      display: inline-block;
      margin-right: 28px;
      margin-bottom: 15px;
      width: calc(33.3% - 19px);
      vertical-align: top;

      select {
        border-radius: 2px;
        height: 30px;
        width: 100%;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    .change-pass {
      h3 {
      width: 33%;
      }
    }
  }

  @media screen and (max-width: 1310px) {
    .right-block {
      width: 60%;

      #country {
        width: 233px;
      }
    }
    .left-block{
      padding-bottom: 40px;
      padding-right: 40px;
      width:  40%;
      text-align: center;

      .block-img {
        text-align: center;
      }
    }

    .change-pass {
      h3 {
      width: 33%;
      }
    }
  }

  @media screen and (max-width: 1025px) {
    .form-element {
      align-items: center;
    }

    .email-error {
      .error-text {
        position: absolute;
      }

      .link-btn {
        margin-left: 85px;
      }
    }

    label[for=countryId] {
      padding-top: 7px;
    }

    label[for=nativeLanguage] {
      padding-right: 19px;
    }

    label[for=favouriteTeam] {
      padding-right: 21px;
    }

    .block-label {
      padding-right: 20px;
    }

    .email-error {
      padding: 0;
      padding-left: 20px;
      display: flex;
      align-items: flex-start;

      .error-text {
        width: 130px;
        margin: 0;
      }

      .link-btn {
        padding-top: 6px;
      }

    }

    .block-label,
    label {
      line-height: 1.23;
    }

    .block-input-selects {
      width: 220px !important;
    }

    #country {
      width: 49% !important;
    }

    .eye2 {
      top: 10px !important;
    }

    .change-pass {
      .block-info {
        &::last-child {
          .field-wrapper .eye {
            top: 10px;
          }
        }

      }
    }

    .left-block .block-img img {
      max-width: 200px;
    }
  }

  @media screen and (max-width: 1160px) {
    .right-block,
    .left-block {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    .title-user {
      font-size: 42px;
      text-align: left;
    }
    .block-label {
      text-align: left;
      margin-bottom: 5px;
      width: 100%;
    }
    .block-input, .block-checkboxes {
      width: 100%;
    }
    .block-text {
      padding-left: 0;
      padding-top: 0;
      margin-top: 5px;
      width: 100%;
    }
    .right-block {
      width: 100%;

      .top-block {
        margin-bottom: 20px;

        .left-info, .block-text {
          width: 100%;
        }
        .block-label {
          width: 33.3%;
        }
        .block-input {
          width: 66.6%;
        }
      }
    }
    .link-btn.link-btn-2 {
      float: right;
      margin-top: 20px;
    }
  }

  }
`;
