import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import BtnTrashIcon from '../../assets/images/ic-btn-trash';

const MessageForm = ({ handleSubmit, closeReply }) => (
  <div className="answer-block">
    <form onSubmit={handleSubmit}>
      <Field component="textarea" autoFocus name="reply" />
      <div className="bottom-block">
        <button className="send-btn" type="submit">
          Send
        </button>
        <div className="btn" onClick={closeReply} role="main">
          {' '}
          <BtnTrashIcon />
          {' '}
        </div>
      </div>
    </form>
  </div>
);

MessageForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  closeReply: PropTypes.func.isRequired,
};

export default MessageForm;
