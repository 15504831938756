import {compose, lifecycle} from "recompose";
import {connect} from "react-redux";
import Home from "@/pages/Home";
import {closeWebsocket, initWebsocket} from "@/helpers/websocket";
import {getLeagues} from "@/store/actions/picks";
import {bindActionCreators} from "redux";

const lifecycleMethod = lifecycle({
    componentDidMount() {
        initWebsocket(this.props.dispatch, this.props.getLeagues)
    },

    componentWillUnmount() {
        closeWebsocket();
    }
});

const mapStateToProps = ({ picks, auth }) => ({ auth, picks });

const mapDispatchToProps = dispatch => ({
    ...bindActionCreators(
        {
            getLeagues,
        },
        dispatch,
    ),
    dispatch
});

export default compose(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    ),
    lifecycleMethod
)(Home);
