import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ReportIcon from '../../assets/images/ic-report';

const GameReportRow = ({
  game, week, day, openReportModal,
}) => (
  <tr className="tr-table">
    <td className="td-table">{game.league}</td>
    <td className="td-table">{game.teams}</td>
    <td className="td-table">{moment(game.gameDate).format('DD/MM/YY | HH:mm')}</td>
    <td className="td-table">{game.bet}</td>
    {/* <td className="td-table">{game.coveragePercent}</td> */}
    <td className="td-table">{game.isMarketOpen ? 'Yes' : 'No'}</td>
    <td className="td-table">
      <div
        onClick={e => openReportModal(e, week, 'day', day, game)}
        style={{ display: 'inline-block' }}
        role="main"
      >
        <ReportIcon />
      </div>
    </td>
  </tr>
);

GameReportRow.propTypes = {
  game: PropTypes.object.isRequired,
  week: PropTypes.object.isRequired,
  day: PropTypes.object.isRequired,
  openReportModal: PropTypes.func.isRequired,
};

export default GameReportRow;
