export const USER_AFFILIATE_LEVEL = 0;
export const USER_TRIAL_LEVEL = 1;
export const USER_PAID_LEVEL = 2;
export const USER_FULL_MEMBER_LEVEL = 3;

/**
 * List of sections
 * @type {{MyStats: string, DownloadApp: string, MobileApp: string, Messages: string, MyPicks: string, ChatWithTrader: string, Finance: string, UnitsReport: string, Affiliates: string, ChatWithManager: string, OddsAlert: string, ReasonOddsControl: string, FinancePrivate: string, BetsOddsControl: string, Down2CentBetsOdds: string, Statistics: string}}
 */
export const listOfSection = {
    MyPicks: 'MyPicks',
    MyStats: 'MyStats',
    Finance: 'Finance',
    UnitsReport: 'UnitsReport',
    Affiliates: 'Affiliates',
    DownloadApp: 'DownloadApp',
    MobileApp: 'MobileApp',
    Messages: 'Messages',
    ChatWithManager: 'ChatWithManager',
    ChatWithTrader: 'ChatWithTrader',
    OddsAlert: 'OddsAlert',
    ReasonOddsControl: 'ReasonOddsControl',
    FinancePrivate: 'FinancePrivate',
    BetsOddsControl: 'BetsOddsControl',
    Down2CentBetsOdds: 'Down2CentBetsOdds',
    Statistics: 'Statistics'
};

/**
 * List on sections with rules, that allow access
 * @type {{[p: string]: number[]}}
 */
const allowAccessToSections = {
    [listOfSection.MyPicks]: [USER_TRIAL_LEVEL, USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.MyStats]: [USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.Finance]: [USER_AFFILIATE_LEVEL, USER_TRIAL_LEVEL, USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.UnitsReport]: [USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.Affiliates]: [USER_AFFILIATE_LEVEL, USER_TRIAL_LEVEL, USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.DownloadApp]: [USER_TRIAL_LEVEL, USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.MobileApp]: [USER_TRIAL_LEVEL, USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.Messages]: [USER_AFFILIATE_LEVEL, USER_TRIAL_LEVEL, USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.ChatWithManager]: [USER_AFFILIATE_LEVEL, USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.ChatWithTrader]: [USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.OddsAlert]: [USER_TRIAL_LEVEL, USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.BetsOddsControl]: [USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.ReasonOddsControl]: [USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.FinancePrivate]: [USER_AFFILIATE_LEVEL, USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL],
    [listOfSection.Down2CentBetsOdds]: [USER_TRIAL_LEVEL],
    [listOfSection.Statistics]: [USER_PAID_LEVEL, USER_FULL_MEMBER_LEVEL]
};

/**
 * Check access by user level
 * @param userLevel
 * @returns {{}}
 */
export const checkAccess = (userLevel, hasTerms = true) => {
    const response = {};

    for (let props in allowAccessToSections) {
        response[props] = allowAccessToSections[props].includes(userLevel) && hasTerms;
    }

    return response;
}
