import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import PlusIcon from '../../assets/images/ic-plus';
import MinusIcon from '../../assets/images/ic-minus';
// import ReportIcon from '../../assets/images/ic-report';
import GameReportRow from './GameReportRow';

class DayReportRow extends Component {
  static propTypes = {
    day: PropTypes.object.isRequired,
    week: PropTypes.object.isRequired,
    openReportModal: PropTypes.func.isRequired,
  };

  state = {
    opened: false,
  };

  toggleRow = () => {
    this.setState(({ opened }) => ({ opened: !opened }));
  };

  render() {
    const { opened } = this.state;
    const { day, openReportModal, week } = this.props;

    return (
      <Fragment>
        <tr className={classNames({ 'tr-table': true, open: opened, 'cursor-pointer': true })}>
          <td className="td-table" onClick={this.toggleRow}>
            {opened ? <MinusIcon /> : <PlusIcon />}
            {day.dayName}
          </td>
          <td className="td-table" onClick={this.toggleRow}>
            {day.unitsAvailible}
          </td>
          <td className="td-table" onClick={this.toggleRow}>
            {day.unitsUsed}
          </td>
          <td className="td-table" onClick={this.toggleRow}>
            {day.unitsUsed}
          </td>
          <td className="td-table" onClick={this.toggleRow}>
            {day.fixturesAvailible}
          </td>
          {/* <td className="td-table">
            <div
              onClick={e => openReportModal(e, week, 'day', day)}
              style={{ display: 'inline-block' }}
              role="main"
            >
              <ReportIcon />
            </div>
          </td> */}
        </tr>

        <tr className="tr-table tr-table-colspan">
          <td colSpan={7}>
            <table cellSpacing="0">
              <thead>
                <tr className="title-table">
                  <th className="td-table">League</th>
                  <th className="td-table">Teams</th>
                  <th className="td-table">Game Date</th>
                  <th className="td-table">Bet</th>
                  {/* <th className="td-table">Coverage %</th> */}
                  <th className="td-table">
                    Market
                    <br />
                    Open?
                  </th>
                  <th className="td-table">Report</th>
                </tr>
              </thead>

              <tbody>
                {day.unitsReportGames.map(game => (
                  <GameReportRow
                    key={`${game.gameDate}_${game.teams}`}
                    game={game}
                    week={week}
                    day={day}
                    openReportModal={openReportModal}
                  />
                ))}
              </tbody>
            </table>
          </td>
        </tr>
      </Fragment>
    );
  }
}

export default DayReportRow;
