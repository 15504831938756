import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react'

import { injectGlobal } from 'styled-components';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import App from './App';
import store, { history, persistor } from './store/store.js';
import sentryConfig from "./config/sentry";
import { unregister } from './registerServiceWorker';
import fonts from './config/fonts';
import './assets/styles/highcharts.css';
import './assets/styles/scrollbar.css';
import {WebNotifications} from "@/services/notification";

injectGlobal`
  body {
    padding: 0;
    margin: 0;
    font-family: ${fonts.proximaNova}
  }

  #root {
    min-height: 100vh;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }

  .apexcharts-tooltip {
    border-radius: 5px !important;
    box-shadow: 0 0 14px 0 rgba(23, 0, 73, 0.21) !important;
    background-color: #ffffff !important;
    border: solid 1px #d1d7e9 !important;
  }

  .apexcharts-tooltip.light .apexcharts-tooltip-title {
    background-color: #fff !important;
    font-size: 12px !important;
    font-weight: 600 !important;
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }

  .apexcharts-tooltip-series-group.active .apexcharts-tooltip-marker {
    width: 7px !important;
    height: 7px !important;
    margin-right: 8px !important;
  }

  .apexcharts-tooltip-text {
    font-size: 13px !important;
    font-weight: 600  !important;
  }

  .apexcharts-tooltip-text-value, .apexcharts-tooltip-text-z-value {
    margin-left: 0 !important;
  }
`;

if (sentryConfig.enabled) {
  Sentry.init({
    dsn: sentryConfig.dns,
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
    autoSessionTracking: false, 
  });
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <App/>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

WebNotifications.init();
unregister();
