/**
 * External dependencies
 */
import React from 'react';
import { connect } from 'react-redux';

/**
 * Internal dependencies
 */
import {
  Corner,
  Cards,
  Goal,
  Penalty
} from '@/components/NewStatistics/icons';

const ICONS = {
  CORNER: Corner,
  YELLOW_CARD: Cards,
  RED_CARD: () => <Cards red />,
  GOAL: Goal,
  PENALTY: Penalty
};

const MatchHistory = props => {
  const { history } = props;

  return (
    <div className="statistics__match-history">
      <div className="header">
        <h2>Match events</h2>
      </div>
      <div className="statistics__match-history__content">
        <ul className="statistics__match-history__list">
          {history.map(({ text, type, id }) => {
            const Icon = ICONS[type] || React.Fragment;
            return (
              <li key={id} className='list-item'>
                {type && (
                  <span className='event-icon'>
                    <Icon />
                  </span>
                )}
                <span className='text'>
                  {text
                }</span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  history: state.newStatistics.selectedEvent.history
});

export default connect(mapStateToProps)(MatchHistory);
