export const getScores = (score) => {
  try {
    const homeScore =
      score
        .substring(0, score.indexOf('-'))
        .replace('-', '');

    const awayScore =
      score
        .substring(score.indexOf('-'), score.length)
        .replace('-', '');

    return {
      homeScore,
      awayScore
    };
  } catch (e) {
    return {
      homeScore: 0,
      awayScore: 0
    };
  }
};
