import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';

import Modal from '../../containers/Modal';

import FormInput from '../FormInput';

import Wrapper from './styles';

class ContactForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    pristine: PropTypes.bool.isRequired,
    invalid: PropTypes.bool.isRequired,
    contactModalOpened: PropTypes.bool.isRequired,
    closeContactModal: PropTypes.func.isRequired,
    preloadContactForm: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { preloadContactForm } = this.props;
    preloadContactForm();
  }

  render() {
    const {
      handleSubmit,
      submitting,
      contactModalOpened,
      closeContactModal,
      pristine,
      invalid,
    } = this.props;
    return (
      <Wrapper>
        <form noValidate>
          <Field
            name="firstname"
            type="text"
            component={FormInput}
            label="First Name*"
            placeholder="First Name"
          />
          <Field
            name="lastname"
            type="text"
            component={FormInput}
            label="Last Name*"
            placeholder="Last Name"
          />
          <Field
            name="email"
            type="email"
            component={FormInput}
            label="Email*"
            placeholder="Email"
          />
          <Field
            name="phone"
            type="text"
            component={FormInput}
            label="Phone*"
            placeholder="Phone"
          />
          <Field
            name="message"
            textarea="true"
            component={FormInput}
            label="Message*"
            placeholder="Message"
          />
          <div>
            <button
              className="contact-submit"
              onClick={handleSubmit}
              type="button"
              disabled={invalid || pristine || submitting}
            >
              Send
            </button>
          </div>

          {contactModalOpened && (
            <Modal className="contact-modal" modalName="contact">
              <div className="title-wrapper">
                <span className="modal-title">Thank You!</span>
              </div>
              <div>
                <span className="modal-text">
                  Your message has been sent to us!
                  <br />
                  We will review your email and if needed will reply in the shortest possible time
                </span>
                <Link onClick={() => closeContactModal()} className="modal-link" to="/">
                  Home
                </Link>
              </div>
            </Modal>
          )}
        </form>
      </Wrapper>
    );
  }
}

export default ContactForm;
