import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { lifecycle, compose, withProps } from 'recompose';
import moment from 'moment';

import MyPicks from '../components/MyPicks';
import { getMyPicks, getGraphByPick, changeLineOnGraph } from '../store/actions/picks';
import { handicapGraph } from '../selectors/picks';
import { openModal } from '../store/actions/view';

const mapStateToProps = ({ picks, view }) => {
  const dates = picks.filter.date.value || [];
  const [from, to] = dates

  const picksList = !dates.includes(null) && dates.length === 2
    ? picks.list.filter(item => {
      const gameDate = moment(item.gameDate, 'DD/MM/YYYY HH:mm')
      return gameDate.isBetween(from, to, 'day') || gameDate.isSame(to, 'day')
    })
    : picks.list;

  return ({
    picks: picksList,
    filterByRuning: picks.filter.isRunning.value,
    filterByLive: picks.filter.market.value,
    filterByStatus: picks.filter.status ? picks.filter.status.value : '',
    allStatuses: (picks.filter.status ? picks.filter.status.values : []).map(option => option.value),
    pickModalOpened: view.modals.myPicksGame,
    graphData: picks.graphData,
    ...handicapGraph(picks.graphData),
    graphLoading: picks.graphLoading,
  })
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getMyPicks,
    getGraphByPick,
    openPickModal: () => openModal('myPicksGame'),
    changeLineOnGraph,
  },
  dispatch,
);

const rLifecycle = lifecycle({
  componentDidMount() {
    this.props.getMyPicks();
  },
});

const rConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const rProps = withProps(ownProps => ({
  onGraphClick: (data) => {
    ownProps.openPickModal();
    ownProps.getGraphByPick(data);
  },
}));

export default compose(
  rConnect,
  rLifecycle,
  rProps,
)(MyPicks);
