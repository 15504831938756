import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import RightColumn from '@/components/Messages/RightColumn';
import { getMessages, getSubject } from '@/selectors/messages';
import { expandAllMessages, showComposeMessage } from '@/store/actions/messages';
import { closeModal } from '../../store/actions/view';

const mapStateToProps = ({ messages }) => ({
  messages: getMessages(messages),
  subject: getSubject(messages),
  expand: getMessages(messages).length !== messages.openedMessages.length,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    expandAllMessages,
    showComposeMessage,
    closeModal: () => {
      return closeModal('composeConfirm')
    },
  },
  dispatch,
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(RightColumn);
