import styled from 'styled-components';
import colors from '../../config/colors';

export default styled.div`
   .statistics {
   
    &__columns {
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      
      .loader {
        text-align: center;
      }
    }

    &__list {
      width: 20%;
      overflow-y: scroll;
      padding-bottom: 10px;
      background: ${colors.white}
      border-radius: 5px;
      border: 1px solid ${colors.lightGray};
      padding: 16px 25px 25px;
      max-height: 839px;
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }
        
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #D6DAE1;
      }
      
      &__header {
        background: ${colors.white};
        color: white;
        margin-bottom: 8px;
        
        h2 {
          margin: 0;
          font-weight: 600;
          font-size: 18px;
          color: ${colors.black_3};
        }
      }
    }

    &__list-content ul {
      list-style: none;
      margin: 0;
      padding: 0;
        
      .league {
        margin-bottom: 9px;
        border: 1px solid ${colors.lightGray_2};
        
        &:last-child{
          margin-bottom: 0;
        }
        & > .list-item {
          font-weight: bold;
          display: block;
          font-size: 13px;
          background: ${colors.lightGray_3};
          color: ${colors.black_3}
        } 
      }
      
      .list-item {
        padding: 16px 15px;
      
        > span {
          line-height: 20px;
          font-weight: 600;
          font-size: 13px;
          color: ${colors.black_3}
        }  
        
        &.selected-event {
          font-weight: bold;
          color: ${colors.white}
          background: ${colors.thirdBlue}
          pointer-events: none;
          > span{
            color: ${colors.white}
          }
        }
      }

      .events-list {
        padding: 12px 15px;
        font-weight: normal;
        background: ${colors.lightGray_7};
        .list-item{
            padding: 5px 15px;
            margin: 0 -15px;
            &:hover {
              cursor: pointer;
              > span{
                color: ${colors.thirdBlue}
              }
            }
        }
      }
    }


    &__selected-match-wrapper {
      width: 60%;
    }

    &__selected-match {
      animation: fade-in 1s;
      padding: 0 20px;
        
      .selected-match__players {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: ${colors.lightGray_3};
        border: 1px solid ${colors.lightGray_2};
        padding: 16px 24px 24px;
        margin-bottom: 24px;
        box-sizing: border-box;
        
        &__header{
          width: 100%;
          margin-bottom: 10px;
          position: relative;
        }    
        .players-and-count {
          position: relative;
          
          .teams{
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;
            align-items: flex-start;
            
            .vs{
              font-weight: bold;
              font-size: 16px;
              color: #9BA4B7;
              margin: 0 30px;
              margin-top: 43px;
            }
            
          }
         
          .home-player, .away-player { 
            font-weight: bold;
            font-size: 18px;
            display: flex;
            align-items: center;
            width: 50%;
           
            .image{
                background: ${colors.white};
                border-radius: 50%;
                padding: 15px;
                margin-right: 30px;
                
                img{
                    display: block;
                    width: 74px;
                }
            }
            
            &-score{
              font-weight: bold;
              font-size: 48px;
              color: ${colors.black_3};
              display: block;
            }
            
            &-name{
              font-weight: bold;
              font-size: 17px;
              color: ${colors.black_3};
              max-width: 130px;
              display: block;
              width: 100%;
              text-align: right;
              // margin-right: 30px;
              word-break: break-word;
            }

            &-name-container{
              margin-right: 30px;
            }
            
            .color {
              content: '';
              position: absolute;
              width: 20px;
              height: 8px;
              top: 55%;
              transform: translateY(-50%);
            }
          }
          
          .away-player{
              &-score{
                margin-right: 30px;
              }
              &-name{
                margin-right: 0;
                text-align: left;
              }
          }
          
          .date{
            color: #9BA4B7;
            font-weight: 600;
            font-size: 13px;
            text-align: center;
            margin-bottom: 10px;
          }
          
          .home-player { 
            right: calc(50% + 40px);
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .color {
              left: calc(100% + 14px);
            }
          }
          .away-player { 
            left: calc(50% + 40px);
            display: flex;
            align-items: center;
            // justify-content: flex-start;

            .color {
              right: calc(100% + 14px);
            }
          }
          
          .field-image {
            width: 50%;
            min-width: 280px;
            margin: 40px auto 0;
            position: relative;
            
            img {
              max-width: 100%;
            }
            
            .home-player-score, .away-player-score {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              font-size: 72px;
              font-weight: bold;
              color: white;
            }
            
            .home-player-score {
              left: 28%;
              transform: translate(-50%, -50%);
            }
            .away-player-score {
              right: 28%;
              transform: translate(50%, -50%);
            }
          }          
        }
        
        .btn-live{
          box-shadow: 0px 10px 30px rgba(187, 192, 206, 0.3);
          border-radius: 10px;
          width: 71px;
          height: 32px;
          text-align: center;
          line-height: 32px;
          font-weight: bold;
          font-size: 13px;
          color: ${colors.white};
          background: linear-gradient(90deg, #63A6F5 0%, #4C91E3 100%);
          text-transform: uppercase;
          border: none;
          position: relative;
          
          .icon-circle{
            background: ${colors.white};
            border: none;
            border-radius: 50%;
            height: 8px;
            width: 8px;
            display: block;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 10px;
          }
        }
        .title-league{
          font-weight: bold;
          font-size: 13px;
          color: ${colors.black_3};
          text-align: center;
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          top: 55%;
          transform: translateY(-50%);
        }
        .current-time {
          font-weight: bold;
          border-radius: 10px;
          border: 1px solid ${colors.lightBlue_7};
          box-sizing: border-box;
          width: 90px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          font-size: 21px;
          margin: 0 auto;
          color: ${colors.black_3};
        }
      }
      
      .selected-match__graph-statistics {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .donut-graph {
            width: 30%;
            margin: 10px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            
            .title {
              width: 100%
              text-align: center;
            }
        }

        &__shots {
           width: 100%;
           margin: 0 auto;
           
          .on-target, .off-target {
            display: flex;    
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;
            position: relative;
            margin-bottom: 25px;
            
            .title {
              width: 100%;
              font-weight: bold;
              font-size: 13px;
              color: ${colors.black_3};
              opacity: 0.5;
            }
            
            .chart-line {
              flex-grow: 1;
              max-width: 100%;
              border-radius: 10px;
              > div{
                border-radius: 10px 0 0 10px;
                position: relative;
                z-index: 1;
                &:after{
                    content: "";
                    position: absolute;
                    display: block;
                    width: 10px;
                    height: 100%;
                    top: 0;
                    right: 0;
                    z-index: -1;
                    background: ${colors.white};
                    -webkit-transform-origin: bottom left;
                    -ms-transform-origin: bottom left;
                    transform-origin: bottom left;
                    -ms-transform: skew(-40deg, 0deg);
                    -webkit-transform: skew(-40deg, 0deg);
                    transform: skew(-40deg, 0deg);
                }
              }
            }
            .home, .away {
              position: absolute;
              font-weight: bold;
              font-size: 13px;
              color: ${colors.black_3};
            }
            .home {
              left: 10px;
            }
            .away {
              right: 10px;
            }
          }
        }
      }

      .team-logo-and-score {
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: 1367px) {
        .home-player-name-container {
          margin-top: 5px !important;
          display: flex;
          width: 104px;
          justify-content: center;
          margin-right: 60px !important;
        }

        .home-player-name {
          text-align: center !important;
        }

        .home-player {
          flex-wrap: wrap-reverse;
        }

        .team-logo-and-score {
          width: 164px
        }

        .away-player-name-container {
          margin-top: 5px !important;
          display: flex;
          width: 104px;
          justify-content: center;
          margin-left: 58px;
        }

        .away-player-name {
          text-align: center !important;
        }

        .away-player {
          flex-wrap: wrap;

          .image {
            margin-right: 0 !important;
          }
        }
      }

      .teams-logo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 16px;
        
        .team-logo-home, .team-logo-away{
          display: flex;
          align-items: center;
        }
        
        .home-player-name {
          font-weight: bold;
          font-size: 13px;
          color: ${colors.black_3};
          text-align: left !important;
          margin-right: 15px;
        }

        .away-player-name{
          font-weight: bold;
          font-size: 13px;
          color: ${colors.black_3};
          text-align: right;
          margin-left: 15px;
        }
        
        .image{
            background: ${colors.white};
            border: 1px solid ${colors.lightGray_2};
            width: 44px;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            position: relative;
            margin-right: 16px;
    
            img{
                width: 24px;
                display: block;
                position: absolute;
                margin: 0 auto;
                left: 0;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            .color{
                display: block;
                width: 8px;
                height: 8px;
                position: absolute;
                right: 0;
                bottom: 4px;
                border-radius: 50%;
            }
        }
      }
      
      .selected-match__content{
        background: ${colors.white};
        border-radius: 5px;
        border: 1px solid ${colors.lightGray};
        padding: 16px 25px;
        
        .header {
          position: relative;
          color: ${colors.white};
          margin-bottom: 8px;
          animation: headerAppear .3s;
          
          h2 {
           margin: 0;
           font-weight: 600;
           font-size: 18px;
           color: ${colors.black_3};
          }
        }
      }
      .selected-match__players-stats {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 0 auto;

        .stats-line-title{
          display: flex;
          align-items: center;
        }
        .name{
          font-weight: 600;
          font-size: 13px;
          color: ${colors.black_3};
          opacity: 0.5;
        }
        .league-name{
          font-weight: 600;
          font-size: 13px;
          color: ${colors.black_3};
        }
        
        .stats-line-rating{
           display: flex;
           align-items: center;
           justify-content: flex-end;
        }
        
        .stats-content{
          background: ${colors.lightGray_3};
          border: 1px solid ${colors.lightGray_2};
          box-sizing: border-box;
          padding: 8px 16px;
          
          .date-timer{
            font-weight: bold;
            font-size: 13px;
            
            .current-time{
               color: ${colors.black_3};
               margin-bottom: 4px;
            }
            .date{
               color: ${colors.black_3};
               opacity: 0.5;
            }
          }
          .team-logo{
            display: flex;
            align-items: center;
            max-width: 250px;
            
            .image-wrap{
                display: flex;
                align-items: center;
                margin-right: 12px; 
            }
            .image{
                background: ${colors.white};
                border: 1px solid ${colors.lightGray_2};
                width: 32px;
                height: 32px;
                border-radius: 50%;
                position: relative;
                
                &:first-child{
                  margin-right: -5px;
                  z-index: 2;
                }
                
                img{
                    width: 18px;
                    display: block;
                    position: absolute;
                    margin: 0 auto;
                    left: 0;
                    right: 0;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }
            .team-name-content{
                .team-name{
                    font-weight: bold;
                    font-size: 13px;
                    margin-bottom: 4px;
                    &.home{
                        color: ${colors.black_3};
                    }
                    &.away{
                        color: ${colors.black_3};
                        opacity: 0.5;
                    }
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
          }
        }
        
        &__header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 12px;
          padding: 0 16px;
          align-items: center;

          .header-stat {
            min-width: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 12px;
            color: ${colors.black_3};
            opacity: 0.5;
            
            svg {
              width: 25px;
            }
            
            
            &.time {
              flex-grow: 1;
              display: flex;
              justify-content: flex-start;
              font-weight: bold;
              color: white;
            }
          }
        }
        
        .stats-line-counter{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width: 100%;
            
            .goals{
                position: relative;
                
                &:after{
                    content: "";
                    background: ${colors.lightGray_2};
                    height: 36px;
                    width: 1px;
                    position: absolute;
                    top: 0;
                    right: 0px;
                }
                .stat{
                    padding: 0 15px 0 0;
                    
                    &-bold{
                        opacity: 1;
                        position: relative;
                        
                        &:before{
                            content: "";
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-width: 4px 8px 4px 0;
                            border-color: transparent ${colors.black_3} transparent transparent;
                            position: absolute;
                            right: 0px;
                            top: 3px;
                        }
                    }
                }
            }
            .col{
               padding: 0 5px;
               
               &-wrap{
                   background: ${colors.white};
                   border: 1px solid ${colors.lightGray_2};
                   box-sizing: border-box;
                   border-radius: 6px;
                   width: 40px;
                   text-align: center;
                   padding: 4px;
               }
               &:last-child{
                padding-right: 0;
               }
            }
        }

        &__table {
          display: flex;
          flex-wrap: wrap;
          list-style: none;
          margin: 0;

          .stat {
              text-align: center;
              font-weight: 600;
              font-size: 13px;
              color: ${colors.black_3};
              opacity: 0.5;
              margin-bottom: 4px;
              &:last-child{
                margin-bottom: 0;
              }
              &-bold{
                opacity: 1;
              }
          }
        }
      }
    }

    &__history-wrapper {
      width: 20%;
    }
    
    &__match-history {
      height: 100%;
      padding-bottom: 10px;
      background: ${colors.white};
      border-radius: 5px;
      border: 1px solid ${colors.lightGray};
      padding: 16px 25px 25px;
      overflow-y: scroll;
      max-height: 839px;
      
      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
      }
        
      ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #D6DAE1;
      }
    
      .header {
        position: relative;
        color: ${colors.white};
        margin-bottom: 8px;
        animation: headerAppear .3s;
        
        h2 {
          margin: 0;
          font-weight: 600;
          font-size: 18px;
          color: ${colors.black_3};
        }
      }
      
      ul {
        list-style: none;
        animation: fade-in-delay 1s;
        background: ${colors.lightGray_3};
        border: 1px solid ${colors.lightGray_2};
        margin: 0;
        padding: 0;
        
        li {
          position: relative;
          margin-bottom: 0;
          color: ${colors.black_3};
          font-weight: 600;
          font-size: 13px;
          padding: 16px 16px 14px;
          display: flex;
          align-items: center;
          
          .event-icon {
            margin-right: 9px;
            width: 15px;
            
            svg {
              height: 17px;
              path{
                fill: ${colors.thirdBlue};
              }
            }
          }
          &:nth-child(2n){
            background: ${colors.lightGray_7};
          }
        }
      }
    }
    
    &__error {
      position: relative;
      width: 35%;
      margin: 0 auto;
      padding: 10px 15px;
      border-radius: 5px;
      background: ${colors.lightYellow_1};
      
      .close {
        position: absolute;
        top: 8px;
        right: 10px;
        background: ${colors.red_dark};
        color: white;
        padding: 5px 8px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 11px;
        border-radius: 3px;
        pointer-events: none;
        opacity: 0;
        transform: rotateX(90deg);
        transition: opacity .2s, transform .2s, background-color .2s ease-in-out;
      
        &:hover {
          cursor: pointer;
          background: ${colors.red}; 
        }
      }
      
      &.hovered {
        .close {
          transform: rotateX(0deg);
          opacity: 1;
          pointer-events: all;
        }
      }
      &.hidden {
        display: none;
      }
    }
  }
  
  @keyframes fade-in-delay {
    0%  { opacity: 0 };
    35%  { opacity: 0 };
    100% { opacity: 1 };
  }
  
  @keyframes fade-in {
    0%  { opacity: 0 };
    100% { opacity: 1 };
  }
  
   @keyframes headerAppear {
    0%  { top: -100px };
    100% { top: 0 };
  }
`;
