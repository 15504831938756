import { reduxForm } from 'redux-form';
import { withProps, compose } from 'recompose';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { contactUs, preloadContactForm } from '../store/actions/contactus';
import { openModal, closeModal } from '../store/actions/view';

import ContactForm from '../components/ContactForm';

const validate = (values) => {
  const errors = {};
  if (!values.firstname) {
    errors.firstname = 'Enter first name';
  }
  if (!values.lastname) {
    errors.lastname = 'Enter last name';
  }
  if (!values.email) {
    errors.email = 'Enter email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.phone) {
    errors.phone = 'Enter phone number';
  }
  if (!values.message) {
    errors.message = 'Enter message';
  }
  return errors;
};

const form = reduxForm({
  form: 'contactForm',
  validate,
});

const props = withProps(ownProps => ({
  onSubmit: ownProps.contactUs,
}));

const mapStateToProps = ({ view, formErrors, user }) => ({
  contactModalOpened: view.modals.contact,
  initialValues: formErrors.data,
  account: user.account,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    contactUs,
    preloadContactForm,
    openContactModal: () => openModal('contact'),
    closeContactModal: () => closeModal('contact'),
  },
  dispatch,
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),

  props,
  form,
)(ContactForm);
