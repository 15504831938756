import React from 'react';
import Wrapper from './styles';
import termsImage from '../../images/ic-terms.svg';

const TermsOfUse = () => (
  <Wrapper>
    <div className="info-page">
      <div className="left-block">
        <div className="div-info">
          <h1 className="title-page">Terms Of Use</h1>
          <div className="block-img">
            <img src={termsImage} alt="about us" />
          </div>
        </div>
      </div>
      <div className="right-block">
        <p>
          <strong>At PunTeam,</strong>
          &nbsp; we respect our tipsters’ privacy, all personal details, conversations and bets will
          be kept private. We have developed a secure website where all tipsters’ information is kept
          private at highest levels. Any information passed to us via
          {' '}
          <a href="http://PunTeam.com">PunTeam.com</a>
          {' '}
          will be only used by us and not shared to
          anyone outside of the&nbsp;
          <strong>PunTeam</strong>
          &nbsp;operation.
        </p>
        <p>
          Only with prior consent can tipsters share ideas and information between each other, with
          this beneficial partnership in places and all tipsters in agreement, tipsters will be able
          to see each other’s information via the&nbsp;
          <strong>PunTeam</strong>
          &nbsp;website at all times. Tipsters can opt-in and out of the information sharing scheme at
          anytime by contacting Administration.
        </p>
        <p>
          Confidentiality and transparency are core values for&nbsp;
          <strong>PunTeam</strong>
          , in a betting, business and personal sense and to damage these
          values would go against everything we believe in. All we expect in return from anyone who
          works with and within&nbsp;
          <strong>PunTeam</strong>
          &nbsp;is the same treatment and values.
        </p>
      </div>
    </div>
  </Wrapper>
);

export default TermsOfUse;
