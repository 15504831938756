import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styled';
import composeConfirmMessage from '../../assets/images/compose-confirm-message.png'

const ComposeConfimMessage = ({ opened }) => (
    <Wrapper>
      <div
        hidden={!opened}
        className="confirm-image"
        style={{ backgroundImage: `url(${composeConfirmMessage})` }}
      />
   </Wrapper>
);

ComposeConfimMessage.propTypes = {
  opened: PropTypes.bool.isRequired,
};

export default ComposeConfimMessage;
