import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import {MARKET_TYPE_LIVE, MARKET_TYPE_EARLY, MARKET_TYPE_TODAY} from '../../config/constants';

import ExpandIcon from '../../assets/images/ic-expand-single';
import Select from '../ui/form/Select';

class LeagueForm extends Component {
  static propTypes = {
    sports: PropTypes.array.isRequired,
    marketTypes: PropTypes.array.isRequired,
    betGameParts: PropTypes.array.isRequired,
    leagues: PropTypes.array.isRequired,
    selLeague: PropTypes.func.isRequired,
    onChangeLeagueParams: PropTypes.func.isRequired,
  };

  render() {
    const {
      sports,
      marketTypes,
      betGameParts,
      leagues,
      selLeague,
      searchResult,
      onChangeLeagueParams,
      leagueSearch,
    } = this.props;
    const marketTypeLabels = {
      [MARKET_TYPE_LIVE]: 'Live',
      [MARKET_TYPE_TODAY]: 'Today',
      [MARKET_TYPE_EARLY]: 'Early',
    };
    return (
      <form>
        <div className="selects">
          <Field
              wrapperClass="type-of-game"
              id="type-of-game"
              name="sport"
              component={Select}
              options={sports}
              onChange={onChangeLeagueParams}
          />
          <label htmlFor="market-day" className="market-day">
            <span>Market:</span>
            <Field
                wrapperClass="market"
                id="market-day"
                component={Select}
                options={marketTypes}
                name="marketType"
                onChange={onChangeLeagueParams}
            />
          </label>
          <Field
              id="lf-time"
              component={Select}
              options={betGameParts}
              name="betGamePart"
              onChange={onChangeLeagueParams}
          />
        </div>
        <div className="search">
          <Field type="text" component="input" placeholder="Select Your Game" name="leagueSearch" autoComplete="off" />
          <div className="arrow">
            <ExpandIcon />
          </div>
          <div className="prediction">
            {
              !leagueSearch || leagueSearch.length <= 2
                ?
                  <ul>
                    {leagues.map(l => (
                        <li key={`league_${l.leagueNameId}_${l.leagueName}`} onClick={() => selLeague(l)}>
                          <span>{l.leagueName}</span>
                        </li>
                    ))}
                  </ul>
                :
                  searchResult.map(group => (
                      <div className="search-list-part" key={group.title}>
                        <span className="group-title">{group.title}</span>
                        <ul>
                          {group.data.map((item, index) => {
                            let title = '';
                            if (group.isLeague) {
                              title = item.leagueName;
                            } else {
                              title = `${item.team1Name} - ${item.team2Name}`;

                              if (item.isCorners) {
                                title += ' - No. of Corners';
                              }
                            }
                            const { market } = item;
                            const isLiveMarker = <span className="is-live-marker"></span>;
                            return (
                                <li key={index} onClick={() => {
                                  selLeague(item)
                                }}>
                                  {market === MARKET_TYPE_LIVE && group.isGame && isLiveMarker/*eslint-disable-line eqeqeq*/}{title}
                                  {marketTypeLabels[market] && <strong> {marketTypeLabels[market]}</strong>}
                                </li>
                            );
                          })}
                        </ul>
                      </div>
                  ))
            }
          </div>
        </div>
      </form>
    );
  }
}

export default LeagueForm;
