import React from 'react';

const CollapseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd" stroke="#366FB1" strokeLinecap="round" strokeWidth="3">
      <path d="M6 18.331L12.198 13l5.794 5.331M6 11.331L12.198 6l5.794 5.331" />
    </g>
  </svg>
);

export default CollapseIcon;
