import React from 'react';

import UnauthPagesWrapper from '../../components/UnauthPagesWrapper';
import HowItWorksContainer from '../../assets/staticTexts/HowItWorks';
import Wrapper from './styles';

const UnauthTermsOfUse = () => (
  <UnauthPagesWrapper>
    <Wrapper>
      <HowItWorksContainer />
    </Wrapper>
  </UnauthPagesWrapper>
);

export default UnauthTermsOfUse;
