import styled from 'styled-components';

import colors from '../../config/colors';
import fonts from '../../config/fonts';
import DropDown from '../../assets/images/ic-drop-down.svg';


export default styled.div`
  margin: 20px;
  background: #fff;
  border-radius: 4px;
  font-size: 0;
  display: flex;
  border: 1px solid #e6e6e6;

  .left-column {
    background-color: #fbfbfb;
    vertical-align: top;
    width: 350px;
    border-radius: 4px;

    .left-header {
      width: 100%;
      height: 65px;
      display: flex;
      align-items: center;
      margin-top: 1px;
      border: none;
      border-bottom: 1px solid #dde0e6;
      font-size: 14px;
      font-weight: bold;
      color: ${colors.secondaryBlue};
      padding-left:14px;
      appearance: none;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position-x: calc(34%);
      background-position-y: center;
      background-image: url(${DropDown});

      &:hover {
        border-color: #a5c8f1;
      }

      &:active,
      &:focus {
        border-color: #4a90e2;
        outline: none;
      }
    }
  }

  .group-list {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        height: 50px;
        line-height: 50px;
        padding-left: 11px;
        font-size: 13px;
        font-weight: 600;
        color: ${colors.secondaryBlue};
        overflow: hidden;
        padding-right: 11px;
        cursor: pointer;

        &:hover {
          background-color: ${colors.fourthBlue};
        }

        &.active {
          background-color: ${colors.thirdBlue};
          color: #fff;

          g {
            stroke: #fff;
          }
        }

        &.unread {
          font-weight: 900;
        }

        svg {
          margin-right: 9px;
          vertical-align: text-top;
          position: relative;
          margin-top: -5px;
        }
      }
    }
  }

  .right-column {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 193px);
  }

  .right-header {
    border-bottom: 1px solid ${colors.lightGray_2};
    display: inline-block;
    font-size: 0;
    padding: 15px 11px 10px 30px;
    text-align: right;
    vertical-align: top;
    width: 100%;

    h3 {
      color: ${colors.secondaryBlue};
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      vertical-align: middle;
      white-space: nowrap;
      width: calc(100% - 390px);
    }

    .btn-border {
      border: 0;
      background: linear-gradient(103deg, #8e70ee, #4a7ece);
      box-shadow: 0 0 8px 0 rgba(54, 111, 177, 0.11);
      display: inline-block;
      height: 40px;
      margin-left: 24px;
      min-width: 135px;
      padding: 1px;

      span:not(.text-btn) {
        background-color: ${colors.white};
        border-radius: 2px;
        color: ${colors.mainBlue};
        display: inline-block;
        height: 38px;
        line-height: 38px;
        width: 100%;
        transition: all 0.3s ease;

        &:hover{
          background-image: linear-gradient(103deg, #7b57ee, #306ece);
          color: ${colors.white};
          img {
            filter: brightness(100);
          }
        }
        &:active{
          background-image: linear-gradient(103deg, #6952b3, #2558a8);
          color: ${colors.white};
          img {
            filter: brightness(100);
          }
        }
      }

      img {
        padding-top: 9px;
      }
    }

    .send-btn,
    .btn-border {
      border-radius: 3px;
      cursor: pointer;
      font-size: 13px;
      font-weight: 600;
      min-width: 171px;
      outline: none;
      text-align: center;
      vertical-align: middle;

      img {
        display: inline-block;
        margin-right: 4px;
        vertical-align: top;
      }
    }
  }

  .send-btn {
    border: 0;
    cursor: pointer;
    min-width: 119px;
    height: 40px;
    background-image: linear-gradient(107deg, #8e70ee, #4a7ece);
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    font-size: 15px;
    color: #fff;
    font-weight: 300;
    margin-left: 24px;
    outline: none;
    z-index: 100;
    position: relative;

    &:before {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:after {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:active {
      &:after {
        opacity: 1;
      }
    }
  }

  .top-block {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
  }

  .message-list {
    padding: 0 30px 25px;

    &.show-all {
      .main-block,
      .top-block .btn {
        display: inline-block;
      }

      .top-block {
        .title,
        .right-block {
          vertical-align: top;
        }
      }

      .message-item {
        padding-bottom: 26px;
        padding-top: 16px;

        .info-text {
          padding-right: 9px;
        }
      }
    }
  }
  .message-item {
    display: inline-block;
    padding-bottom: 6.5px;
    padding-top: 6.5px;
    width: 100%;
    position: relative;

    &:hover {
      .title{
        text-decoration: underline;
        color: ${colors.lightBlue_2};
      }
    }

    &:active {
      .title{
        color: ${colors.mainBlue};
      }
    }

    &:after {
      content: '';
      display: block;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAABCAYAAADeko4lAAAAAXNSR0IArs4c6QAAABdJREFUCB1jbOxf+Z+BCFBXEEaEKgYGAAbXA/4Rqc7WAAAAAElFTkSuQmCC');
      position: absolute;
      height: 1px;
      bottom: 0;
      width: 100%;
    }

    &.open {
      padding-bottom: 26px;

      .info-text {
        padding-right: 9px;
      }

      .main-block,
      .top-block .btn {
        display: inline-block;
      }

      .top-block {
        .title,
        .right-block {
          vertical-align: top;
        }
      }

      .message-item {
        padding-bottom: 10.5px;
        padding-top: 16px;
      }
    }

    &.answer-block-open {
      .answer-block {
        display: inline-block;
      }

      .info-text {
        padding-right: 0;
      }

      .top-block {
        .btn {
          display: none;
        }
        .right-block {
          padding-top: 0;
        }
      }
    }

    .main-block,
    .top-block .btn {
      display: none;
    }

    .main-block {
      margin-top: 5px;
      width: 100%;
    }

    .title {
      color: ${colors.secondaryBlue};
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.06;
      vertical-align: middle;
    }
    .text-block {
      color: ${colors.secondaryBlue};
      font-size: 16px;
      line-height: 1.06;
    }

    .btn {
      background: linear-gradient(135deg, #8e70ee, #4a7ece);
      border-radius: 3px;
      box-shadow: 0 0 8px 0 rgba(54, 111, 177, 0.11);
      cursor: pointer;
      display: inline-block;
      padding: 1px;
      vertical-align: top;
      transition: all 0.3s ease;

      &:active {
        background: linear-gradient(135deg, #6952b3, #2558a8),
          linear-gradient(135deg, #8e70ee, #4a7ece);
      }

      svg {
        background-color: ${colors.white};
        border-radius: 2px;
        height: 38px;
        width: 38px;
        transition: all 0.3s ease;

        path {
          transition: all 0.3s ease;
        }
      }

      &:hover,
      &:active {
        svg {
          background-color: ${colors.transparent};

          path {
            stroke: ${colors.white};
          }
        }
      }
    }

    .right-block {
      display: flex;
      text-align: right;
      vertical-align: middle;
    }
    .info-text {
      color: ${colors.lightBlue};
      display: inline-block;
      font-size: 11px;
      font-weight: 600;
      line-height: 1.55;
      padding-top: 2px;
      text-align: right;
      vertical-align: top;
    }

    .answer-block {
      display: none;
      padding-top: 26px;
      width: 100%;

      textarea {
        border: 0;
        border-top: 2px dashed ${colors.lightBlue};
        box-shadow: none;
        caret-color: ${colors.lightBlue_2};
        color: ${colors.secondaryBlue};
        display: inline-block;
        font-family: ${fonts.proximaNova};
        font-size: 18px;
        line-height: 1.55;
        max-height: 240px;
        min-height: 240px;
        max-width: 100%;
        min-width: 100%;
        outline: none;
        padding: 11px 0;
        resize: none;
      }

      .bottom-block {
        display: table;
        padding-bottom: 24.5px;
        padding-top: 9px;
        width: 100%;

        .btn {
          float: right;
        }

        .send-btn {
          margin-left: 0;
        }
      }
    }
  }

  .right-column-compose {
    .right-header {
      padding-right: 170px;
    }

    .block-message {
      padding: 0 30px 25px;
    }
    .answer-block {
      padding-top: 0;

      textarea {
        border-top: 0;
      }
    }

    .message-item {
      border-bottom: 0;
    }

    .search {
      position: relative;

      input {
        width: 100%;
        height: 40px;
        border: 1px solid ${colors.lightGray_5};
        font-size: 18px;
        color: ${colors.secondaryBlue};
        padding: 1px 13px;
        outline: none;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: ${colors.lightGray_5};
          opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: ${colors.lightGray_5};
        }

        &::-ms-input-placeholder {
          /* Microsoft Edge */
          color: ${colors.lightGray_5};
        }

        &:hover {
          border-color: rgba(74, 144, 226, 0.5);
        }

        &:focus,
        &:active {
          border-color: ${colors.thirdBlue};
        }
      }

      button {
        height: 40px;
        background: rgba(0, 0, 0, 0);
        border: 0;
        position: absolute;
        padding: 0;
        right: 13px;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .message-item {
      .btn {
        max-height: 30px;
        svg {
          height: 30px;
          width: 30px;
        }
      }

      .answer-block textarea {
        max-height: 140px;
        min-height: 140px;
      }
    }

    .right-header {
      h3 {
        width: calc(100% - 110px);
      }
      .send-btn,
      .btn {
        margin-left: 14px;
        min-width: 40px;
        .text-btn {
          display: none;
        }

        img {
          margin: 0;
        }
      }
    }

    .right-column-compose {
      .right-header {
        padding-right: 30px;
      }
    }

    .right-column-compose {
      .right-header {
        padding-right: 30px;
      }
    }
  }
`;
