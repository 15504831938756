import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Wrapper from './styles';
import CloseIcon from '../../assets/images/ic-close';

const Modal = ({
  children,
  className,
  modalName,
  closeModal,
  withRedirect,
  onCloseAction = () => {}
}) => {
  const handleClose = () => {
    if (withRedirect) {
      withRedirect(modalName)
    } else {
      closeModal(modalName)
    }

    onCloseAction()
  }

  return (
    <Wrapper>
      <div className={classNames({ modal: true, [className]: true })}>
        <div className="close-icon">
          <button
            type="button"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>
        {children}
      </div>
    </Wrapper>
  )
};

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  modalName: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  className: PropTypes.string,
  withRedirect: PropTypes.func,
  onCloseAction: PropTypes.func,
};

Modal.defaultProps = {
  className: null,
};

export default Modal;
