import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import StaticChat from '@/containers/Chat/StaticChat';
import { getLocalDate } from '@/helpers/timezoneConvertors';

import Wrapper from './styles';
import LeagueForm from '../../containers/CreateNewPick/LeagueForm';
import PicksTable from '../../containers/CreateNewPick/PicksTable';
import PicksForm from '../../containers/CreateNewPick/PicksForm';
import AutoFetchModal from '../../containers/AutoFetchModal';
import PlusIcon from '../../assets/images/plus-icon.svg';
import ExpandIcon from '../../assets/images/ic-expand';
import CollapseIcon from '../../assets/images/ic-collapse';
import PicksChart from '../PicksChart';
import {
  checkAccess,
  listOfSection,
  USER_FULL_MEMBER_LEVEL,
  USER_TRIAL_LEVEL
} from "@/helpers/access";
import WaitModal from '@/components/WaitModal';
import RefreshModal from '@/components/RefreshModal';
import SuccessModal from '@/components/SuccessModal';
import TrialSuccessModal from '@/components/TrialSuccessModal';
import BetSettingsModal from '@/components/BetSettingsModal';
import InfoModal from '@/components/InfoModal';

export const FILTER_ID = 2600
export const MIN_ID = 3630
export const MAX_ID = 3672
export const USER_LEVEL = 3
export const FILTERED_OUT_COMPETITION_ID = 32
export const FILTERED_OUT_LEAGUE_ID_ID = 247
const MONDAY = 1;
const LIVE = 2;
const mailTo = "mailto:&#101;&#108;&#105;&#097;&#118;&#064;&#112;&#117;&#110;&#116;&#101;&#097;&#109;&#046;&#099;&#111;&#109;"

class CreateNewPick extends React.PureComponent {
  state = {
    infoModalData: {
      className: '',
      renderTitle: () => {},
      renderContent: () => {}
    }
  }

  checkOnAdditionalSettings = () => {
    const {
      selectedLeague,
      picksFormValues = {},
    } = this.props;

    const { highValues, urgentBet, teamNews } = picksFormValues;

    const emptyBetSettings = !highValues && !urgentBet && !teamNews;
    const notLive = selectedLeague.market !== LIVE;
  
    return emptyBetSettings && notLive;
  }

  checkOnRemainingUnitsStatus = () => {
    const { picksList = [] } = this.props;

    const hasNotApprovedBets = picksList.some(pick => (
      ['in process', 'waiting'].includes(pick.gameStatus.toLowerCase())
    ));

    return !hasNotApprovedBets;
  }

  checkOnRemainingUnitsDate = () => { 
    const { picksFormValues = {}, utcOffset } = this.props;

    const convertedGameDate = getLocalDate(picksFormValues.date, utcOffset, 'DD/MM/YY HH:mm')
    const gameDate = moment(convertedGameDate, 'DD/MM/YY HH:mm');

    const today = moment().isoWeekday();
    const followingMonday = today < MONDAY
      ? moment().isoWeekday(MONDAY)
      : moment().add(1, 'weeks').isoWeekday(MONDAY);

    const gameBeforeMonday = gameDate.isBefore(followingMonday, 'day');

    return gameBeforeMonday;
  }

  handleOpenInfoModal = (data) => {
    const { openInfoModal } = this.props;

    this.setState({ infoModalData: data });
    openInfoModal();
  }
  handleSubmitPicksForm = () => {
    const {
      list,
      unitLeft,
      userId,
      userLevel,
      selectedLeague,
      picksFormValues = {},
      submitPicksForm,
      // openBetSettingsModal,
    } = this.props;

    if (userLevel === USER_FULL_MEMBER_LEVEL) {
      const remainingUnitsStatus = this.checkOnRemainingUnitsStatus();
      const remainingUnitsDate = this.checkOnRemainingUnitsDate();
  
      if (unitLeft <= 0 && remainingUnitsStatus && remainingUnitsDate) {
        this.handleOpenInfoModal({
          renderTitle: () => (
            <span>Sorry, You have no units remaining for this week.</span>
          ),
          renderContent: () => (
            <p>
              For more information please visit <a href='/units-report'>units report tab</a>
            </p>
          )
        });
      
        return;
      }

      // const shouldCheckBetSettings = this.checkOnAdditionalSettings();
      // if (true) {
      //   openBetSettingsModal();
      //   return;
      // }
    }

    const idInWhiteList = (userId >= MIN_ID && userId <= MAX_ID) || userId === FILTER_ID

    if (userLevel === USER_TRIAL_LEVEL && !idInWhiteList) {
      const { date, league, team1, team2 } = picksFormValues;
      const isSameBet = list.some(bet => (
        date === bet.gameDate
          && league === bet.league
          && bet.team1 === team1
          && bet.team2 === team2
      ));

      if (isSameBet) {
        this.handleOpenInfoModal({
          renderTitle: () => (
            <span>Sorry, you're only allowed one pick per game</span>
          )
        });
        return;
      }
    }

    // if (userLevel === USER_TRIAL_LEVEL || userLevel === USER_FULL_MEMBER_LEVEL) {
    //   const { minuteIR: gameTime, live } = picksFormValues;

    //   if (live && parseInt(gameTime, 10) > 75 && parseInt(gameTime, 10) < 89) {
    //     this.handleOpenInfoModal({
    //       renderTitle: () => (
    //         <p>No bets allowed after the 75<sup>th</sup> minute</p>
    //       )
    //     });
    //     return;
    //   }
    // }

    submitPicksForm(selectedLeague.market, userLevel);
  }

  handleSubmitModal = () => {
    const {
      submitPicksForm,
      closeBetSettingsModal
    } = this.props;

    submitPicksForm();
    closeBetSettingsModal();
  }

  render() {
    const {
      expandNewPick,
      collapseNewPick,
      collapsed,
      options,
      pickModalOpened,
      graphData,
      gameId,
      oddsReaches,
      line,
      setLines,
      setAlertGame,
      setOddsReaches,
      getWebTemplate,
      getMemberOddsAlertGamesTemporary,
      lines,
      changeLineOnGraph,
      graphLoading,
      userLevel,
      trader = {},
      selectedLeague,
      tiltMode,
      waitModalOpened,
      refreshModalOpened,
      trialSuccessModalOpened,
      successModalOpened,
      betSettingsModalOpened,
      closeBetSettingsModal,
      picksFormValues = {},
      infoModalOpened
    } = this.props;

    const { infoModalData } = this.state;

    const accessData = checkAccess(userLevel);

    return (
      <Wrapper>
        <div className="title">
          <img src={PlusIcon} alt="new pick" />
          New Pick
        </div>
        <LeagueForm />
        <div className="expand">
          <button type="button" onClick={collapsed ? expandNewPick : collapseNewPick}>
            <span>
              {collapsed ? 'Expand' : 'Collapse'}
            </span>
            {collapsed ? <ExpandIcon /> : <CollapseIcon />}
          </button>
        </div>
        <div
          className={classNames({ 
            'new-pick-more': true,
            'new-pick-more-opened': collapsed
          })}
        >
          {!collapsed && (
            <>
              {selectedLeague && !tiltMode && (
                <div className="selected-league">
                  {selectedLeague.leagueName}
                </div>
              )}
              <div className="new-pick-left">
                {parseInt(tiltMode, 10) === 1 ? (
                  <div className="note">
                    <p>Your account has been temporarily blocked.</p>
                    <p>
                      Please talk with Eliav, either via <a href={mailTo}>eliav@punteam.com</a>, 
                      Inner Messaging System or Skype and Telegram at Eliav_Punteam.
                    </p>
                  </div>
                ) : (
                  <>
                    <PicksTable />
                    <div className="picks-form-wrapper">
                      <PicksForm />
                    </div>
                    <div className="send-btn-wrapper">
                      <button type="button" className="send-btn" onClick={this.handleSubmitPicksForm}>
                        Send
                      </button>
                    </div>
                  </>
                )}
              </div>
              {accessData[listOfSection.ChatWithTrader] && (
                <div className="new-pick-right">
                  <StaticChat
                    apiKey="trader"
                    title="Chat with trader"
                    name={trader.currentTraderName}
                  />
                </div>
              )}
            </>
          )}
        </div>
        {pickModalOpened && (
          <PicksChart
            alertsForm
            disableSelect
            graphData={graphData}
            changeLineOnGraph={changeLineOnGraph}
            lines={lines}
            options={options}
            graphLoading={graphLoading}
            modalName="pick"
            line={line}
            gameId={gameId}
            setLines={setLines}
            setOddsReaches={setOddsReaches}
            setAlertGame={setAlertGame}
            getWebTemplate={getWebTemplate}
            getMemberOddsAlertGamesTemporary={getMemberOddsAlertGamesTemporary}
            oddsReaches={oddsReaches}
          />
        )}
        <AutoFetchModal>
          <div className="modal approve-modal">
            <div className="title-wrapper">
              <span className="modal-title">
                Line has changed
              </span>
            </div>
          </div>
        </AutoFetchModal>
        <WaitModal opened={waitModalOpened}/>
        <RefreshModal opened={refreshModalOpened}/>
        <SuccessModal opened={successModalOpened}/>
        <TrialSuccessModal opened={trialSuccessModalOpened} />
        <BetSettingsModal
          opened={betSettingsModalOpened}
          close={closeBetSettingsModal}
          submit={this.handleSubmitModal}
          notes={picksFormValues.notes}
        />
        <InfoModal
          opened={infoModalOpened}
          className={infoModalData.className}
          renderTitle={infoModalData.renderTitle}
          renderContent={infoModalData.renderContent}
        />
      </Wrapper>
    )
  }
};

CreateNewPick.propTypes = {
  expandNewPick: PropTypes.func.isRequired,
  collapseNewPick: PropTypes.func.isRequired,
  submitPicksForm: PropTypes.func.isRequired,
  collapsed: PropTypes.bool.isRequired,
  pickModalOpened: PropTypes.bool.isRequired,
  betSettingsModalOpened: PropTypes.bool.isRequired,
  waitModalOpened: PropTypes.bool.isRequired,
  successModalOpened: PropTypes.bool.isRequired,
  trialSuccessModalOpened: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  graphData: PropTypes.object.isRequired,
  gameId: PropTypes.number,
  oddsReaches: PropTypes.number,
  line: PropTypes.string,
  setLines: PropTypes.func.isRequired,
  setAlertGame: PropTypes.func.isRequired,
  setOddsReaches: PropTypes.func.isRequired,
  getMemberOddsAlertGamesTemporary: PropTypes.func.isRequired,
  getWebTemplate: PropTypes.func.isRequired,
  lines: PropTypes.array,
  openBetSettingsModal: PropTypes.func.isRequired,
  closeBetSettingsModal: PropTypes.func.isRequired,
  picksFormValues: PropTypes.object
};

CreateNewPick.defaultProps = {
  lines: [],
  line: 0,
  oddsReaches: 0,
  gameId: 0,
};

export default CreateNewPick;
