import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import DateRange from '@/components/DateRange';
import { setFilter } from '@/store/actions/picks';

const mapStateToProps = ({ picks }) => ({
  filter: picks.filter
});

const mapDispatchToProps = dispatch => bindActionCreators(
  { setFilter },
  dispatch
);

const Filter = ({
  filter: {
    market,
    date,
    isRunning,
    status = {},
  },
  setFilter
}) => {
  const onChange = (startDate, endDate) => {
    setFilter({
      name: 'date',
      value: [ startDate, endDate ]
    });
  };

  return (
    <div className="filter">
      <div className="filter-cell">
        <select
          value={market.value}
          onChange={e => setFilter({ name: 'market', value: e.target.value })}
        >
          {
            market.values.map(market => (
              <option key={market.value} value={market.value}>{market.label}</option>
            ))
          }
        </select>
      </div>

      <div className="filter-cell">
        <select
          value={status ? status.value : ''}
          onChange={e => setFilter({ name: 'status', value: e.target.value })}
        >
          {
            (status && Array.isArray(status.values) ? status.values : []).map((status = {}) => (
              <option key={status.value} value={status.value}>{status.label}</option>
            ))
          }
        </select>
      </div>

      <div className="filter-cell">
        <DateRange
          range={[ ...date.value ]}
          onChange={onChange}
        />
      </div>

      <div className="filter-cell">
        <label className="checkbox">
          <input
            type="checkbox"
            checked={isRunning.value}
            onChange={e => setFilter({ name: 'isRunning', value: e.target.checked })}
          />
          Is running
        </label>
      </div>
    </div>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filter);
