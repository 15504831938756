import React from 'react';

const Icon = ({ text = '', className = '' }) => {
  const textStyle = {
    fontSize: '10px',
    fontFamily: "'Montserrat', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    WebkitFontSmoothing: 'antialiased',
    MozOsxFontSmoothing: 'grayscale'
  };

	return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="60"
      height="60"
      viewBox="0 0 60 60"
    >
      <defs>
        <path id="criclePath" d="M 30, 30 m -22, 0 a 22,22 0 0,1 44,0 a 22,22 0 0,1 -44,0 "/>
      </defs>

      <g>
        <use xlinkHref="#criclePath" fill="none"/>
        <text fill="#000" style={textStyle}>
          <textPath xlinkHref="#criclePath">{text}</textPath>
        </text>
      </g>
    </svg>
	);
};

export default Icon;
