import styled from 'styled-components';

import fonts from '@/config/fonts';
import colors from '@/config/colors';

export default styled.div`
  font-family: ${fonts.proximaNova};

  .title-terms {
    margin: 0;
    color: ${colors.secondaryBlue};
    font-size: 30px;
    font-weight: 600;
    line-height: 1;
  }

  .text-terms {
    color: ${colors.secondaryBlue};
    font-size: 14px;
    line-height: 1.5em;
  }

  .choose-program {
    text-align: center;
    background-color: #ffffe7;
    border: 1px solid #d2d3cd;
    font-size: 14px;
    line-height: 1.5em;
    margin: 0 auto 30px;
    display: table;

    .title {
      background: ${colors.red};
      text-align: center;
      color: #fff;
    }

    .frame {
      padding: 20px;
    }

    p {
      margin: 0 0 1em;
    }

    label {
      display: inline-block;
      margin: 0 10px;
    }

    .btns-wrapper {
      padding: 20px 0 0;
    }
  }
`;
