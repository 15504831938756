import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getLocalTime, getLocalDate } from '@/helpers/timezoneConvertors';
import MessageForm from '@/containers/Messages/MessageForm';
import BtnReplayIcon from '@/assets/images/ic-btn-replay';

const MessageItem = ({
  message, opened, toggleMessage, replyMessage, replyOpened, utcOffset
}) => (
  <div
  className={classNames({ 'message-item': true, open: opened, 'answer-block-open': replyOpened })}
  >
    <div className="top-block" onClick={() => toggleMessage(message.id)} role="main">
      <div className="title">{message.from}</div>
      <div className="right-block">
        <div className="info-text">
          {getLocalDate(message.date, utcOffset, 'DD/MM/YYYY')}
          <br />
          {getLocalTime(message.time, utcOffset, 'HH:mm')}
        </div>
        <div
          className="btn"
          role="main"
          onClick={e => {
            e.stopPropagation();
            replyMessage();
          }}
        >
          {' '}
          <BtnReplayIcon />
          {' '}
        </div>
      </div>
    </div>
    <div className="main-block">
      <div className="text-block" dangerouslySetInnerHTML={{ __html: message.body }} />
      {replyOpened && <MessageForm />}
    </div>
  </div>
);

MessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  opened: PropTypes.bool.isRequired,
  replyOpened: PropTypes.bool.isRequired,
  toggleMessage: PropTypes.func.isRequired,
  replyMessage: PropTypes.func.isRequired,
};

export default MessageItem;
