import styled from 'styled-components';

import colors from '../../../../config/colors';
import fonts from '../../../../config/fonts';

export default styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;

  select {
    font-family: ${fonts.proximaNova};
    font-weight: 600;
    color: ${colors.secondaryBlue};
    outline: none;
    appearance: none;
    height: 32px;
    min-width: 135px;
    border: solid 1px #dbdbdb;
    background-color: #fff;
    border-radius: 0;
    font-size: 14px;
    padding: 1px 13px;
    background-repeat: no-repeat;
    background-position-x: calc(100% - 9px);
    background-position-y: center;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23818FA9' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");

    &:hover {
      border-color: #a5c8f1;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%23A5C8F1' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 3.77L3.5 1 6 3.77M1 9.23L3.5 12 6 9.23'/%3E%3C/g%3E%3C/svg%3E%0A");
    }

    &:active,
    &:focus {
      border-color: #4a90e2;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='7' height='15' viewBox='0 0 7 15'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%234A90E2' stroke-linecap='round' stroke-linejoin='round' stroke-width='2'%3E%3Cpath d='M1 5l2.5-3L6 5M1 10l2.5 3L6 10'/%3E%3C/g%3E%3C/svg%3E%0A");
    }
  }

  label {
    font-size: 11px;
    font-weight: 600;
    color: #646464;
    margin-top: 6px;
  }
`;
