import React from 'react';
import PropTypes from 'prop-types';

import ReportRow from './ReportRow';

const MainTable = ({ reports, openReportModal }) => {
  return (
    <div className="main-table">
      <table cellSpacing="0">
        <thead>
          <tr className="title-table">
            <th className="td-table">Weekly Start Date</th>
            <th className="td-table">Weekly End Date</th>
            <th className="td-table">Units Avilable</th>
            <th className="td-table">Units Used</th>
            <th className="td-table">Fixtures Avilable</th>
            {/* <th className="td-table">Report</th> */}
          </tr>
        </thead>

        <tbody>
          {reports.map(r => (
            <ReportRow report={r} key={r.startDate} openReportModal={openReportModal} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

MainTable.propTypes = {
  reports: PropTypes.array.isRequired,
  openReportModal: PropTypes.func.isRequired
};

export default MainTable;
