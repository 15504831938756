import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import Wrapper from './styles';

const SidebarLinkItem = ({
  to, icon, icon2, title, location, unreadMessages
}) => (
  <Wrapper className={classNames({ active: location.pathname === to })}>
    <Link to={to}>
      <img src={icon} alt={title} />
      {icon2
        && <img className="icon-new" src={icon2} alt={title}/>
      }
      {unreadMessages !== null
        && unreadMessages > 0 && <span className="unread-messages">{unreadMessages}</span>}
      {title}
    </Link>
  </Wrapper>
);

SidebarLinkItem.propTypes = {
  to: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  icon2: PropTypes.string,
  unreadMessages: PropTypes.number
};

SidebarLinkItem.defaultProps = {
  unreadMessages: null
};

export default SidebarLinkItem;
