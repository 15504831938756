import http from '@/services/http-service';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';
import { OPEN_MODAL } from './view';

export const GET_MAIL_MESSAGES = 'messages/GET_MAIL_MESSAGES';
export const SHOW_MESSAGES = 'messages/SHOW_MESSAGES';
export const TOGGLE_MESSAGE = 'messages/TOGGLE_MESSAGE';
export const EXPAND_ALL_MESSAGES = 'messages/EXPAND_ALL_MESSAGES';
export const SHOW_REPLY = 'messages/SHOW_REPLY';
export const SEND_MESSAGE = 'messages/SEND_MESSAGE';
export const CLOSE_REPLY = 'messages/CLOSE_REPLY';
export const SHOW_COMPOSE_MESSAGE = 'messages/SHOW_COMPOSE_MESSAGE';
export const CLOSE_COMPOSE_MESSAGE = 'messages/CLOSE_COMPOSE_MESSAGE';
export const SET_AS_READ_MESSAGES = 'messages/SET_AS_READ_MESSAGES';
export const SET_INBOX_FILTER = 'messages/SET_INBOX_FILTER';
export const CLEAR_CHOSEN_MESSAGE = 'messages/CLEAR_CHOSEN_MESSAGE';
export const SHOW_MESSAGES_FROM_WIDGET = 'messages/SHOW_MESSAGES_FROM_WIDGET';
export const SET_UNREAD_COUNT = 'messages/SET_UNREAD_COUNT';

export const getMailMessages = (showFirst = false) => async dispatch => {
  const params = { lastParentId: 0 };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetMailMessages');
      trace.start();
    }

    const { data: { messages, error }} = await http.post('/GetMailMessages', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MAIL_MESSAGES,
      payload: messages,
      showFirst
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    console.warn(error);
  }
};

export const getUnreadMsgsCount = () => async dispatch => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetUnreadMsgsCount');
      trace.start();
    }

    const { data: { countMess, error }} = await http.post('/GetUnreadMsgsCount');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_UNREAD_COUNT,
      payload: countMess
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    console.warn(error);
  }
};

export const showMessages = groupId => dispatch => {
  dispatch({
    type: SHOW_MESSAGES,
    payload: groupId
  });
};

export const showLastMessage = groupId => dispatch => {
  dispatch({
    type: SHOW_MESSAGES_FROM_WIDGET,
    payload: groupId
  });

  window.scrollTo(0, document.body.scrollHeight);
};

export const toggleMessage = id => dispatch => {
  dispatch({
    type: TOGGLE_MESSAGE,
    payload: id
  });
};

export const expandAllMessages = () => dispatch => {
  dispatch({
    type: EXPAND_ALL_MESSAGES
  });
};

export const showReply = id => dispatch => {
  dispatch({
    type: SHOW_REPLY,
    payload: id
  });
};

export const sendReply = (subject, body, parentId) => async dispatch => {
  const params = {
    subject,
    body,
    parentId: parentId || undefined
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetMailMessages');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/SetMailMessages', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SEND_MESSAGE,
      payload: data
    });

    dispatch({
      type: OPEN_MODAL,
      payload: 'composeConfirm'
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    dispatch({
      type: SEND_MESSAGE,
      payload: error.message
    });
  }
};

export const closeReply = () => dispatch => {
  dispatch({
    type: CLOSE_REPLY
  });
};

export const showComposeMessage = () => dispatch => {
  dispatch({
    type: SHOW_COMPOSE_MESSAGE
  });
};

export const closeComposeMessage = () => dispatch => {
  dispatch({
    type: CLOSE_COMPOSE_MESSAGE
  });
};

export const setReadChatMessage = messages => async dispatch => {
  const params = {
    ids: messages.join(),
    isChat: false
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetReadChatMessage');
      trace.start();
    }

    const { data: { error }} = await http.post('/SetReadChatMessage', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch(getUnreadMsgsCount());
    dispatch({
      type: SET_AS_READ_MESSAGES,
      payload: messages
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }
    console.warn(error);
    // dispatch({
    //   type: READ_MESSAGES
    // });
  }
};

export const setInboxFilter = filter => dispatch => {
  dispatch({
    type: SET_INBOX_FILTER,
    payload: filter
  });
};


