import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';

// import Confirmed from '../../assets/images/ic-confirmed.svg';
// import Waiting from '../../assets/images/ic-waiting.svg';
// import Rejected from '../../assets/images/ic-rejected.svg';
// import Confirmed from '@/assets/images/ic-v2-confirmed.png';

import Waiting from '@/assets/images/ic-v2-waiting.png';
import Pending from '@/assets/images/ic-v2-pending.png';
import Running from '@/assets/images/ic-v2-running.png';
import Rejected from '@/assets/images/ic-rejected.svg';

const icons = {
  1: Pending,
  2: Running,
  3: Waiting,
  4: Rejected
};

const LastPicks = ({ lastPicks }) => (
  <Wrapper>
    {lastPicks.map((lp, i) => (
      <div className="item" key={`${lp.betText}_${i}`}>
        <img src={icons[lp.statusType]} alt="status-icon" />
        {lp.betText}
      </div>
    ))}
  </Wrapper>
);

LastPicks.propTypes = {
  lastPicks: PropTypes.array.isRequired,
};

export default LastPicks;
