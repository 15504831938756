import React from 'react';
import Wrapper from './styles';
import aboutImage from '../../images/about-us.svg';

const AboutUs = () => (
  <Wrapper>
    <div className="info-page">
      <div className="left-block">
        <div className="div-info">
          <h1 className="title-page">About Us</h1>
          <div className="block-img">
            <img src={aboutImage} alt="about us" />
          </div>
        </div>
      </div>
      <div className="right-block">
        <p>
          We are a group of entrepreneurs, with success in technological and financial markets, a
          few years ago we looked into the&nbsp;
          <strong>sports betting market</strong>
          &nbsp;in order to take advantage of the value offered by the bookmakers.
        </p>
        <p>
          <strong>PunTeam</strong>
          &nbsp;is a project based around composing a team of experts, who will find value in there
          specialist leagues - collectively the experts will cover a range of leagues and sports.
        </p>
        <p>
          PunTeam has been set up as a long term venture. We give our members time and support
          to&nbsp;
          <strong>show the true profit they can achieve.</strong>
          &nbsp;Anyone can apply to us for a trial period - for this, you'll be required to input
          your bets into our system that will assess and track your results over a period of time.
        </p>
        <p>
          <strong>We're looking for anyone with a specialty</strong>
          &nbsp;- you do not have to be native, a 'big name' or have any previous records. We will
          assess everyone on the same basis and anyone with the ability - or potential - to make a
          profit will have a chance with PunTeam over the long term.
        </p>
      </div>
    </div>
  </Wrapper>
);
export default AboutUs;
