import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import {
  getMemberUnitsReportAllowances,
  getUnitsReportNewAllowanceRequests,
  setNewAllowanceRequest
} from '../../store/actions/report';

import AllowancesPart from '../../components/UnitsReport/AllowancesPart';

const mapStateToProps = ({ report: { allowances, requests }}) => ({
  allowances,
  requests
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getMemberUnitsReportAllowances,
    getUnitsReportNewAllowanceRequests,
    setNewAllowanceRequest
  },
  dispatch
);

const cLifecycle = lifecycle({
  componentDidMount() {
    this.props.getMemberUnitsReportAllowances();
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  cLifecycle
)(AllowancesPart);
