import get from 'lodash/get';
import {
  FETCH_SEARCHFIELD_LIST_REQUEST,
  FETCH_SEARCHFIELD_LIST_SUCCESS,
  FETCH_SEARCHFIELD_LIST_FAILURE,
  FETCH_SEARCH_COUNTRIES_REQUEST,
  FETCH_SEARCH_COUNTRIES_SUCCESS,
  FETCH_SEARCH_COUNTRIES_FAILURE,
  FETCH_SEARCH_RESULT_LEAGUES_REQUEST,
  FETCH_SEARCH_RESULT_LEAGUES_SUCCESS,
  FETCH_SEARCH_RESULT_LEAGUES_FAILURE,
  FETCH_SEARCH_RESULT_TEAMS_REQUEST,
  FETCH_SEARCH_RESULT_TEAMS_SUCCESS,
  FETCH_SEARCH_RESULT_TEAMS_FAILURE,

  SET_CHECKED_SEARCH_RESULT_ITEM_REQUEST,
  SET_CHECKED_SEARCH_RESULT_ITEM_SUCCESS,
  SET_CHECKED_SEARCH_RESULT_ITEM_FAILURE,

  TOGGLE_OPEN_ODDS_ALERT_SEARCH_RESULT,

  SET_ODDS_ALERT_SEARCH_PARAMETERS,
  TOGGLE_ODDS_ALERT_SEARCH_RESULT_SETTINGS_POPUP
} from '../../actions/odds-alerts';

export {
  FETCH_SEARCHFIELD_LIST_REQUEST,
  FETCH_SEARCHFIELD_LIST_SUCCESS,
  FETCH_SEARCHFIELD_LIST_FAILURE,
  FETCH_SEARCH_COUNTRIES_REQUEST,
  FETCH_SEARCH_COUNTRIES_SUCCESS,
  FETCH_SEARCH_COUNTRIES_FAILURE,
  FETCH_SEARCH_RESULT_LEAGUES_REQUEST,
  FETCH_SEARCH_RESULT_LEAGUES_SUCCESS,
  FETCH_SEARCH_RESULT_LEAGUES_FAILURE,
  FETCH_SEARCH_RESULT_TEAMS_REQUEST,
  FETCH_SEARCH_RESULT_TEAMS_SUCCESS,
  FETCH_SEARCH_RESULT_TEAMS_FAILURE,
  SET_CHECKED_SEARCH_RESULT_ITEM_REQUEST,
  SET_CHECKED_SEARCH_RESULT_ITEM_SUCCESS,
  SET_CHECKED_SEARCH_RESULT_ITEM_FAILURE,
  TOGGLE_OPEN_ODDS_ALERT_SEARCH_RESULT,
  SET_ODDS_ALERT_SEARCH_PARAMETERS,
  TOGGLE_ODDS_ALERT_SEARCH_RESULT_SETTINGS_POPUP
}

export const getInitialState = () => ({
  searchField: {
    data: [],
    selectedId: null,
    isLoading: false,
    error: null
  },
  countries: {
    data: [],
    selectedId: null,
    isLoading: false,
    error: null
  },
  result: {
    data: [],
    isLoading: false,
    error: null
  }
});

const transformResultLeaguesData = (prevData, { data, countryId = null }) => {
  console.log('%ctransformResultLeaguesData', 'color: lime', data);
  return data.map(item => {
    const id = `odd-alert/${item.name}/${item.leagueId}/${item.teamId || 0}`;
    const isGroup = item.leagueId > 0 && !item.teamId;
    const isLeague = item.teamId === undefined;
    const oldItem = prevData.find(item => item.id === id);
    const isPreselectedItemInGroup = !isGroup && item.statusCheckBox === 2;

    const modData = {
      ...item,
      id,
      isGroup,
      isLeague,
      countryId: item.countryId || countryId,
      isChecked: item.statusCheckBox === 1 || isPreselectedItemInGroup,
      inProcessing: false,
      isDisabled: isPreselectedItemInGroup,
      isShowSettingsPopup: oldItem ? oldItem.isShowSettingsPopup : false
    };
    if (isGroup) {
      if (oldItem) {
        modData.groupItems = {
          ...oldItem.groupItems
        };
      } else {
        modData.groupItems = {
          data: [],
          isOpen: false,
          isLoading: false,
          error: null
        };
      }
    }

    return modData;
  });
};

const transformFetchTeams = (leagues, { data: teams = null, id }, config) => {
  console.log('%ctransformResultTeamData', 'color: red', leagues, teams, id, config);
  return leagues.map(league => {
    if (league.id === id) {
      return {
        ...league,
        groupItems: {
          ...league.groupItems,
          ...config,
          data: teams ? transformResultLeaguesData(league.groupItems.data, { data: teams }) : league.groupItems.data
        }
      };
    }
    return league;
  });
};

const setSearchItem = (prevData, payload, config) => {
  return prevData.map(item => {
    if (item.id === payload.id) {
      return {
        ...item,
        inProcessing: config.inProcessing
      }
    }
    const data = get(item, 'groupItems.data', []);

    if (data.length) {
      return {
        ...item,
        groupItems: {
          ...item.groupItems,
          data: setSearchItem(item.groupItems.data, payload, config)
        }
      };
    }
    return item;
  });
};

const toggleIsOpen = (items, { id, isOpen }) => {
  return items.map(item => {
    return {
      ...item,
      groupItems: {
        ...item.groupItems,
        isOpen: item.id === id && (isOpen === undefined ? !item.groupItems.isOpen : isOpen)
      }
    };
  });
};

const toggleSettingsPopup = (items, payload) => {
  console.log('TODO: toggleSettingsPopup', items, payload);
  return items;
};

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case FETCH_SEARCHFIELD_LIST_REQUEST:
      return {
        ...state,
        searchField: {
          ...state.searchField,
          isLoading: true,
          error: null
        }
      };
    case FETCH_SEARCHFIELD_LIST_SUCCESS:
      return {
        ...state,
        searchField: {
          ...state.searchField,
          data: action.payload,
          isLoading: false,
          error: null
        }
      };
    case FETCH_SEARCHFIELD_LIST_FAILURE:
      return {
        ...state,
        searchField: {
          ...state.searchField,
          isLoading: false,
          error: action.payload
        }
      };

    case FETCH_SEARCH_COUNTRIES_REQUEST:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: false,
          error: null
        }
      };
    case FETCH_SEARCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          ...state.countries,
          data: action.payload,
          isLoading: false,
          error: null
        }
      };

    case FETCH_SEARCH_COUNTRIES_FAILURE:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: false,
          error: action.payload
        }
      };

    case SET_ODDS_ALERT_SEARCH_PARAMETERS:
      return {
        ...state,
        countries: {
          ...state.countries,
          selectedId: action.payload.countryId
        },
        searchField: {
          ...state.searchField,
          selectedId: action.payload.leagueId
        }
      };

    case FETCH_SEARCH_RESULT_LEAGUES_REQUEST:
      return {
        ...state,
        result: {
          ...state.result,
          isLoading: true,
          error: null
        }
      };
    case FETCH_SEARCH_RESULT_LEAGUES_SUCCESS:
      return {
        ...state,
        result: {
          ...state.result,
          data: transformResultLeaguesData(state.result.data, action.payload),
          isLoading: false,
          error: null
        }
      };

    case FETCH_SEARCH_RESULT_LEAGUES_FAILURE:
      return {
        ...state,
        result: {
          ...state.result,
          isLoading: false,
          error: action.payload
        }
      };

    case FETCH_SEARCH_RESULT_TEAMS_REQUEST:
      return {
        ...state,
        result: {
          ...state.result,
          data: transformFetchTeams(state.result.data, action.payload, { isLoading: true, error: false })
        }
      };
    case FETCH_SEARCH_RESULT_TEAMS_SUCCESS:
      return {
        ...state,
        result: {
          ...state.result,
          data: transformFetchTeams(state.result.data, action.payload, { isLoading: false, error: null })
        }
      };
    case FETCH_SEARCH_RESULT_TEAMS_FAILURE:
      return {
        ...state,
        result: {
          ...state.result,
          data: transformFetchTeams(state.result.data, action.payload, { isLoading: false, error: action.payload.error })
        }
      };

    case SET_CHECKED_SEARCH_RESULT_ITEM_REQUEST:
      return {
        ...state,
        result: {
          ...state.result,
          data: setSearchItem(state.result.data, action.payload, { inProcessing: true, error: null }),
        }
      };
    case SET_CHECKED_SEARCH_RESULT_ITEM_SUCCESS:
      return {
        ...state,
        result: {
          ...state.result,
          data: setSearchItem(state.result.data, action.payload, { inProcessing: false, error: null }),
        }
      };
    case SET_CHECKED_SEARCH_RESULT_ITEM_FAILURE:
      return {
        ...state,
        result: {
          ...state.result,
          data: setSearchItem(state.result.data, action.payload, { inProcessing: false, error: action.payload.error })
        }
      };

    case TOGGLE_OPEN_ODDS_ALERT_SEARCH_RESULT:
      return {
        ...state,
        result: {
          ...state.result,
          data: toggleIsOpen(state.result.data, action.payload)
        }
      };

    case TOGGLE_ODDS_ALERT_SEARCH_RESULT_SETTINGS_POPUP:
      return {
        ...state,
        result: {
          ...state.result,
          data: toggleSettingsPopup(state.result.data, action.payload)
        }
      };

    default:
      return state;
  }
};
