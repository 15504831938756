import styled from 'styled-components';
import colors from '../../config/colors';

export default styled.div`
  background-color: ${colors.lightGray_3};
  display: block;
  padding: 20px;

  main {
    border: solid 1px ${colors.lightGray};
    border-radius: 5px;
    // padding: 0 95px;
    background-color: ${colors.white};

    .info-container {
      // min-height: calc(100vh - 162px);
      min-height: calc(100vh - 185px);
    }
  }

  @media screen and (max-width: 768px) {
    main {
      padding: 0;
    }
  }
`;
