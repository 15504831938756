import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import CloseIcon from '../../assets/images/ic-close';

class ReportModal extends Component {
  static propTypes = {
    modalX: PropTypes.number.isRequired,
    modalY: PropTypes.number.isRequired,
    changeReportModalPosition: PropTypes.func.isRequired,
    closeReportModal: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { changeReportModalPosition } = this.props;
    window.addEventListener('resize', changeReportModalPosition);
  }

  componentWillUnmount() {
    const { changeReportModalPosition } = this.props;
    window.removeEventListener('resize', changeReportModalPosition);
  }

  render() {
    const {
      modalX, modalY, closeReportModal, handleSubmit,
    } = this.props;

    return (
      <div className="report-modal" style={{ left: modalX - 311, top: modalY - 184 }}>
        <div>
          Report an error
          <button type="button" onClick={closeReportModal}>
            <CloseIcon />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <Field component="textarea" name="reportText" placeholder="Write Your Report" />
          <button type="submit">Submit</button>
        </form>
      </div>
    );
  }
}

export default ReportModal;
