import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import htmlParser from 'html-react-parser';

import PersonBlue from '@/assets/images/ic-person-blue.svg';

export default class ChatBody extends Component {
  scrollToBottom = (behavior = 'auto') => {
    if (this.scrollBox) {
      this.scrollBox.scrollTo({
        top: this.scrollBox.scrollHeight,
        left: 0,
        // @ts-ignore
        behavior
      });
    }
  }

  componentDidMount () {
    (async () => {
      this.chatId = await this.props.addOpenedChat();
    })();
    this.props.getLastMessages();
    this.scrollToBottom();
  }

  componentWillUnmount () {
    this.props.removeOpenedChat(this.chatId);
  }

  componentDidUpdate ({ lastChatId }) {
    if (lastChatId !== this.props.lastChatId) {
      this.scrollToBottom(lastChatId === 0 ? 'auto' : 'smooth');
    }
  }

  onEnterMessageViewport = (message, isVisible) => {
    if (isVisible) {
      this.props.setAsRead(message);
    }
  };

  render () {
    const { messages, showDate } = this.props;

    return (
      <div className="chat-frame" ref={el => this.scrollBox = el}>
        {messages.map(message => {
          return (
            <VisibilitySensor
              key={message.id}
              onChange={isVisible => this.onEnterMessageViewport(message, isVisible)}
            >
              <div className={`chat-item ${message.isIncomming ? '' : 'chat-item_own'}`}>
                <div className="chat-item-author">
                  <img src={PersonBlue} alt=""/>
                </div>
                <div className="chat-item-body">
                  <div className="chat-item-message-date">
                    {`${message.senderName},
                    ${showDate ? message.messageDate : ''}
                    ${message.messageTime}`}
                  </div>
                  <div className="chat-item-message">
                    {htmlParser(message.message)}
                  </div>
                </div>
              </div>
            </VisibilitySensor>
          );
        })}
      </div>
    );
  }
}
