import React, {Fragment, Component} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'perfect-scrollbar';
import classnames from 'classnames';

import StatsIcon from '../../assets/images/ic-stats';
import Table from '../ui/Table';
import CollapseSvg from "@/assets/images/expand_less.svg";
import ExpandSvg from '@/assets/images/expand_more.svg';
import {FILTER_ID, MAX_ID, MIN_ID} from "@/components/CreateNewPick/index";

class PicksTable extends Component {
    static propTypes = {
        games: PropTypes.arrayOf(PropTypes.object).isRequired,
        openPickModal: PropTypes.func.isRequired,
        selectGame: PropTypes.func.isRequired,
    };

    tableBody = null;
    scroll = null;

    state = {
        closeEvent: {}
    };

    componentDidMount() {
        this.scroll = new PerfectScrollbar(this.tableBody, {
            minScrollbarLength: 66,
        });
    }

    componentDidUpdate() {
        this.scroll.update();
    }

    componentWillUnmount() {
        this.scroll.destroy();
    }

    showAdditionalData = (game) => {
        this.setState({
            closeEvent: {
                ...this.state.closeEvent,
                [game.gameId]: !this.state.closeEvent[game.gameId]
            }
        })
    }

    renderDate = (event) => {
        if (event.isLive) {
            let minuteIR = <span><span className="islive-indicator"></span> Live</span>;
            if (event.minuteIR) {
                minuteIR = <span>{event.minuteIR + '\''}</span>;
            }
            return <>{minuteIR} {event.team1Score !== null && event.team2Score !== null ? `${event.team1Score} - ${event.team2Score}` : ''}</>;
        }

        if (event.eventDateTime) {
            return moment.utc(event.eventDateTime)
                // .add(Math.abs(utcOffset - 1), 'hours')
                .format('DD/MM/YY [|] HH:mm');
        }

        return '';
    }

    renderAdditionalEvents = (games) => {
        const {openPickModal, selectGame} = this.props;
        if (!games.additionalEvents || !games.additionalEvents.length) return;
        return games.additionalEvents.map((g, i) => (
            <tr key={`game_${g.gameId}_${i}`}>
                <td className="game-date">{this.renderDate(g)}</td>
                <td className="game-teams">
                    <div className="teams-section">
                        <div className="teams">
                            <span><b>{g.team1Name}</b></span>
                            <span>{g.team2Name}</span>
                        </div>
                    </div>
                </td>
                <td className="game-events">
                    {g.hcap && g.hcap.odds1 !== null && g.hcap.odds2 !== null && (
                        <Fragment>
                            <div>
                                <span>{g.hcap.line1}</span>
                                <span className="odds" onClick={e => selectGame(g, g.hcap.line1, 'home')}>{g.hcap.odds1}</span>
                                <span onClick={() => openPickModal(g, 'home', g.hcap.line1)} role="main">
                                    <StatsIcon/>
                                </span>
                            </div>
                            <div>
                                <span>{g.hcap.line2}</span>
                                <span className="odds" onClick={e => selectGame(g, g.hcap.line2, 'away')}>{g.hcap.odds2}</span>
                                <span onClick={() => openPickModal(g, 'away', g.hcap.line2)} role="main">
                            <StatsIcon/>
                          </span>
                            </div>
                        </Fragment>
                    )}
                </td>
                <td className="game-events">
                    {g.total && g.total.odds1 !== null && g.total.odds2 !== null && (
                        <Fragment>
                            <div>
                                <span className="over-total">Over <span className="right">{g.total.line1}</span></span>
                                <span className="odds" onClick={e => selectGame(g, g.total.line1, 'over')}>{g.total.odds1}</span>
                                <span onClick={() => openPickModal(g, 'over', g.total.line1)} role="main">
                            <StatsIcon/>
                          </span>
                            </div>
                            <div>
                                <span className="under-total">Under <span
                                    className="right">{g.total.line2}</span></span>
                                <span className="odds" onClick={e => selectGame(g, g.total.line2, 'under')}>{g.total.odds2}</span>
                                <span onClick={() => openPickModal(g, 'under', g.total.line2)} role="main">
                            <StatsIcon/>
                          </span>
                            </div>
                        </Fragment>
                    )}
                </td>
            </tr>
        ));
    }

    render() {
        const {games, openPickModal, selectGame} = this.props;
        let lastGameId;
        const userId = localStorage.getItem('userId')
        const idInWhiteList = (((userId >= MIN_ID) && (userId <= MAX_ID)) || (userId == FILTER_ID))
        if (games.length) {
            lastGameId = games[0].gameId;
        }

        return (
            <Table className="picks-table">
                <thead>
                <tr>
                    <th className="game-date">Date/Time</th>
                    <th className="game-teams">Game</th>
                    <th className="game-events">Handicap</th>
                    <th className="game-events">Over/Under</th>
                </tr>
                </thead>
                <tbody ref={el => this.tableBody = el}>
                {
                    games.map((g, i) => {
                        let otherGame = lastGameId !== g.gameId;
                        if (otherGame) {
                            lastGameId = g.gameId;
                        }
                        return (
                            <Fragment key={`game_${g.gameId}_${i}`}>
                                <tr
                                    className={classnames({
                                        'limit-row': otherGame,
                                        'first-child': i === 0,
                                        'last-child': i === games.length - 1
                                    })}
                                >
                                    <td className="game-date">{this.renderDate(g)}</td>
                                    <td className="game-teams">
                                        <div className="teams-section">
                                            <div className="teams">
                                                <span><b>{g.team1Name}</b></span>
                                                <span>{g.team2Name}</span>
                                            </div>
                                            <div>
                                                {
                                                    g.eventDateTime ? <button className="expand-btn"
                                                                              onClick={() => this.showAdditionalData(g)}>
                                                        {
                                                            this.state.closeEvent[g.gameId]
                                                                ? <img className="expand-img" src={ExpandSvg} alt=""/>
                                                                : <img className="expand-img" src={CollapseSvg} alt=""/>
                                                        }
                                                    </button> : null
                                                }
                                            </div>
                                        </div>
                                    </td>
                                    <td className="game-events">
                                        {g.hcap && (
                                            <Fragment>
                                                <div>
                                                    <span>{g.hcap.line1}</span>
                                                    <span className="odds" onClick={e => selectGame(g, g.hcap.line1, 'home')}>{g.hcap.odds1}</span>
                                                    <span onClick={() => openPickModal(g, 'home', g.hcap.line1)}
                                                          role="main">
                            <StatsIcon/>
                          </span>
                                                </div>
                                                <div>
                                                    <span>{g.hcap.line2}</span>
                                                    <span className="odds" onClick={e => selectGame(g, g.hcap.line2, 'away')}>{g.hcap.odds2}</span>
                                                    <span onClick={() => openPickModal(g, 'away', g.hcap.line2)}
                                                          role="main">
                            <StatsIcon/>
                          </span>
                                                </div>
                                            </Fragment>
                                        )}
                                    </td>
                                    <td className="game-events">
                                        {g.total && !(idInWhiteList === false && g.isLive === true) && (
                                            <Fragment>
                                                <div>
                                                    <span className="over-total">Over <span
                                                        className="right">{g.total.line1}</span></span>
                                                    <span className="odds" onClick={e => selectGame(g, g.total.line1, 'over')}>{g.total.odds1}</span>
                                                    <span onClick={() => openPickModal(g, 'over', g.total.line1)}
                                                          role="main">
                            <StatsIcon/>
                          </span>
                                                </div>
                                                <div>
                                                    <span className="under-total">Under <span
                                                        className="right">{g.total.line2}</span></span>
                                                    <span className="odds" onClick={e => selectGame(g, g.total.line2, 'under')}>{g.total.odds2}</span>
                                                    <span onClick={() => openPickModal(g, 'under', g.total.line2)}
                                                          role="main">
                            <StatsIcon/>
                          </span>
                                                </div>
                                            </Fragment>
                                        )}
                                    </td>
                                </tr>
                                {
                                    this.state.closeEvent && this.state.closeEvent[g.gameId] && !idInWhiteList ? null : this.renderAdditionalEvents(g)
                                }
                            </Fragment>
                        )
                    })
                }
                </tbody>
            </Table>
        );
    }
}

export default PicksTable;
