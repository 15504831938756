import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Modal from '../components/Modal';
import { closeModal } from '../store/actions/view';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    closeModal,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modal);
