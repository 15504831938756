import styled, { keyframes } from 'styled-components';

const ovalAnimation = keyframes`
100% {
  transform: rotate(360deg);
}
`;

export default styled.div`
  width: 77px;
  height: 77px;
  position: relative;

  .oval {
    position: absolute;
    right: 2px;
    top: -2px;
    animation: ${ovalAnimation} 1s ease-in-out 0s infinite;
  }
`;
