import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { lifecycle, compose } from 'recompose';

import ApproveEmailModal from '../components/ApproveEmailModal';
import { closeWithRedirect } from '../store/actions/view';
import { setApprovalEmail } from '../store/actions/profile';

const lifecycleMethod = lifecycle({
  componentDidMount() {
    this.props.setApprovalEmail(this.props.match.params.token);
  },
});

const mapStateToProps = state => ({
  opened: state.view.modals.approve,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    closeWithRedirect,
    setApprovalEmail,
  },
  dispatch,
);

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  lifecycleMethod,
)(ApproveEmailModal);
