import { reduxForm } from 'redux-form';
import { withProps, compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

// import { logIn } from '../actions/user';
import { logIn } from '../store/actions/auth';

import LoginForm from '../components/Login/LoginForm';

const validate = (values) => {
  const errors = {};
  if (!values.userEmail) {
    errors.userEmail = 'Enter email';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.userEmail)) {
    errors.userEmail = 'Invalid email address';
  }
  if (!values.userPassword) {
    errors.userPassword = 'Enter password';
  } else if (values.userPassword.length < 6) {
    errors.userPassword = 'Must be min 6 symbols';
  }
  return errors;
};

const form = reduxForm({
  form: 'loginForm',
  validate
});

const props = withProps(ownProps => ({
  onSubmit: ownProps.logIn
}));

const mapDispatchToProps = dispatch => bindActionCreators({
  logIn
}, dispatch);

const mapStateToProps = ({ auth: { loginErrorMessage, isLoginProcessing }}) => ({
  errorMessage: loginErrorMessage,
  isLoginProcessing: isLoginProcessing
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  props,
  form
)(LoginForm);
