import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import GetTerms from '@/components/Profile/GetTerms';

import {
  getTerms,
  setTerms
} from '@/store/actions/profile';

const lifecycleMethod = lifecycle({
  async componentDidMount() {
    await this.props.getTerms();
  }
});

const mapStateToProps = ({ profile: { terms }}) => ({
  terms
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getTerms,
    setTerms
  },
  dispatch
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  lifecycleMethod
)(GetTerms);
