import React from 'react';
import PropTypes from 'prop-types';

const ChartLine = ({ d1, d2, firstColor, secondColor, ...props }) => {
  const total = d1 + d2;
  const p1 = (d1 === d2) ? 50 : 100 * d1 / total;

  return (
    <div style={{
      backgroundColor: secondColor,
      margin: '8px'
    }} {...props}>
      <div style={{
        backgroundColor: firstColor,
        height: '8px',
        width: `${p1}%`
      }}></div>
    </div>
  );
};

ChartLine.propTypes = {
  d1: PropTypes.number.isRequired,
  d2: PropTypes.number.isRequired
};

export default ChartLine;
