import {
  GET_EVENTS_IN_PLAY,
  GET_EVENTS_IN_PLAY_SUCCESS,
  GET_EVENTS_IN_PLAY_ERROR,
  GET_LEAGUES_LIST,
  GET_SELECTED_MATCH_RESULTS,
  GET_SELECTED_MATCH_RESULTS_SUCCESS,
  GET_SELECTED_MATCH_RESULTS_ERROR, GET_FILTERED_MATCH_RESULTS
} from '@/store/actions/statistics-actions/constants';

const initialState = {
  pending: false,
  error: null,
  games: {},
  leagues: [],
  selectedEvent: {
    pending: false,
    idOfSelectedEvent: null
  },
  filteredTime: []
};

export default (
  state = initialState,
  action
) => {
  const { type, payload } = action;
  switch (type) {
    case GET_EVENTS_IN_PLAY :
      return {
        ...state,
        // games: [],
        // pending: true,
        error: null
      }
    case GET_EVENTS_IN_PLAY_SUCCESS :
      return {
        ...state,
        games: payload,
        pending: false,
        error: null
      }
    case GET_EVENTS_IN_PLAY_ERROR :
      const { message } = payload;
      return {
        ...state,
        games: [],
        error: {
          message,
          ...payload
        },
        pending: false
      }
    case GET_LEAGUES_LIST :
      return {
        ...state,
        leagues: payload,
        error: null
      }
    case GET_SELECTED_MATCH_RESULTS :
      return {
        ...state,
        idOfSelectedEvent: payload,
        // selectedEvent: {
        //   pending: true,
        // },
        error: null
      }
    case GET_SELECTED_MATCH_RESULTS_SUCCESS :
      return {
        ...state,
        selectedEvent: {
          ...payload,
          pending: false
        },
        error: null
      }
    case GET_SELECTED_MATCH_RESULTS_ERROR :
      return {
        ...state,
        selectedEvent: {
          error: { ...payload },
          pending: false
        },
      }
    case GET_FILTERED_MATCH_RESULTS :
      return {
        ...state,
        filteredTime: payload,
        error: null
      }
    default:
      return state;
  }
};
