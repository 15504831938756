import styled from 'styled-components';

export default styled.div`
  .slide-toggle-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }

  .slide-toggle-title {
    margin-top: 0 !important;
    margin-right: 0 !important;
    font-size: 11px;
  }

  .slide-toggle {
    background: #dde0e6;
    min-width: 40px;
    padding: 2px;
    padding-left: 3px;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 !important;
    margin-left: 5px !important;

    &_on {
      background-color: #73e960;
    }

    &__switcher {
      width: 15px;
      height: 15px;
      position: relative;
      top: 0;
      border-radius: 50%;
      background: white;

      &_on {
        margin-right: 0 !important;
        margin-top: 0 !important;
        margin-left: auto;
      }

      &_off {
        margin-top: 0 !important;
        margin-right: auto;
      }
    }
  }
`;