import React from "react";
import htmlParser from "html-react-parser";

import { history } from "@/store/store";

import Wrapper from "./get-terms-styles";

class GetTerms extends React.Component {
  state = {
    planId: ''
  };

  handleSubmit = async e => {
    const { setTerms } = this.props;
    const { planId } = this.state;

    e.preventDefault();

    if (planId) {
      await setTerms(planId);
      history.push('/');
    }
  };

  handlePlanChange = chosenPlanId => () => {
    this.setState({
      planId: `${chosenPlanId}`
    });
  };

  renderTerms = () => {
    const { terms } = this.props;
    const { planId } = this.state;

    return (
      <>
        <form className="choose-program" onSubmit={this.handleSubmit}>
          <div className="title">IMPORTANT!</div>
          <div className="frame">
            <p>
              <strong>
                You must register to one of the plans described below
              </strong>
              <br />
              Please red the following terms adn choose your preferred plan:
            </p>
            {terms.programs.map(({ id, name }) => {
              return (
                <label key={id}>
                  <input
                    type="radio"
                    value={planId}
                    onChange={this.handlePlanChange(id)}
                  />
                  {name}
                </label>
              );
            })}
            <div className="btns-wrapper">
              <button
                disabled={!planId}
                type="submit"
              >
                Accept the terms
              </button>
            </div>
          </div>
        </form>
        <h1 className="title-terms">{terms.titleTerms}</h1>
        <div className="text-terms">{htmlParser(terms.textTerms)}</div>
      </>
    );
  };

  render() {
    const { terms } = this.props;

    const content = terms ? this.renderTerms() : "Loading...";

    return (
      <div className="block get-terms">
        <Wrapper>{content}</Wrapper>
      </div>
    );
  };
};

export default GetTerms;
