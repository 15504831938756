import styled from 'styled-components';

export default styled.div`
  width: 413px;
  margin: 0 auto;
  position: relative;

  input {
    width: 100%;
    font-size: 18px;
    margin-bottom: 20px;
    height: 30px;
    border: none;
    border-bottom: 1px solid #4a90e2;
    color: #38415f;
  }

  label[for='userPassword'] {
    font-size: 18px;
  }

  .login-spinner {
    color: wheat;
    position: absolute;
    left: 91%;
    bottom: 9%;
    z-index: 101;
  }

  button:focus,
  textarea:focus,
  input:focus {
    outline: none;
  }

  textarea::placeholder,
  input::placeholder {
    color: #818fa9;
  }

  textarea:focus::placeholder,
  input:focus::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 1px solid #4a90e2;
    -webkit-text-fill-color: #38415f;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
  }

  a {
    color: #4a90e2;
  }

  b {
  }

  label {
    font-size: 20px;
    font-weight: 700;
    color: #38415f;
  }

  .title {
    color: #4a90e2;
    text-align: center;
    font-size: 64px;
    font-weight: 200;
    margin-top: 140px;
    margin-bottom: 70px;
  }

  p {
    font-size: 18px;
    text-align: center;
    color: #38415f;
  }

  form {
    margin-top: 80px;
  }

  form div {
    position: relative;
  }

  form span {
    position: absolute;
    right: 0;
    top: 6px;
    color: red;
  }

  .form-error {
    font-size: 13px !important;
    position: absolute;
    right: -124px;
    top: 7px;
    color: #ff5766;
    width: 117px;
    height: 20px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .red-error {
    label {
      color: #ff5766;
    }

    textarea {
      border-color: #ff5766;
    }
    input {
      border-bottom-color: #ff5766;
    }
  }

  nav {
    width: 368px;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }

  .auth-error {
    color: red;
    text-align: center;
  }

  nav a {
    font-size: 12px;
    color: #818fa9;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    padding: 0 13px;
    border-left: 1px solid #818fa9;
    text-decoration: none;
  }

  nav a:hover {
    text-decoration: underline;
  }

  nav a:first-child {
    border-left: none;
    padding-left: 0;
  }

  nav a:last-child {
    padding-right: 0;
  }

  button {
    cursor: pointer;
    font-size: 15px;
  }

  .submit-btn {
    width: 100%;
    height: 40px;
    padding: 10px;
    background-image: linear-gradient(to right, #8e70ee, #4a7ece);
    border: none;
    color: white;
    margin-bottom: 20px;
    margin-top: 40px;
    font-size: 15px;
    z-index: 100;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:after {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:active {
      &:after {
        opacity: 1;
      }
    }

    &:focus {
      outline: none;
    }
  }

  a {
    &:hover {
      color: #1d7bea;
      text-decoration: underline;
    }

    &:focus,
    &:active {
      outline: none;
      color: #366fb1;
      text-decoration: underline;
    }
  }

  .forgot-password-btn {
    width: 100%;
    background: none;
    border: none;
    font-size: 14px;
    color: #4a90e2;
    text-decoration: underline;
    display: block;
    text-align: center;

    &:hover {
      color: #1d7bea;
      text-decoration: underline;
    }

    &:focus,
    &:active {
      outline: none;
      color: #366fb1;
      text-decoration: underline;
    }
  }

  @media screen and (max-width: 1281px) {
    .form-error {
      width: 25%;
      right: -115px;
      height: 20px;
      top: 10px;
    }
  }

  @media screen and (max-width: 1024px) {
    .form-error {
      width: 117px;
      right: -50px;
    }
  }

  @media screen and (max-height: 810px) {
    .title {
      margin-top: 60px;
      margin-bottom: 40px;
    }

    form {
      margin-top: 90px;
    }
  }

  @media screen and (max-height: 700px) {
    .title {
      margin-bottom: 30px;
    }
    form {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 602px) {
    z-index: 999;
    width: 100%;
    position: absolute;
    height: 100vh;
    padding: 0 50px;

    input {
      background: transparent;
    }
  }
`;
