import {
  FETCH_DEFAULT_SETTINGS_REQUEST,
  FETCH_DEFAULT_SETTINGS_SUCCESS,
  FETCH_DEFAULT_SETTINGS_FAILURE,

  SET_DEFAULT_SETTINGS_REQUEST,
  SET_DEFAULT_SETTINGS_SUCCESS,
  SET_DEFAULT_SETTINGS_FAILURE
} from '@/store/actions/odds-alerts';

export {
  FETCH_DEFAULT_SETTINGS_REQUEST,
  FETCH_DEFAULT_SETTINGS_SUCCESS,
  FETCH_DEFAULT_SETTINGS_FAILURE,

  SET_DEFAULT_SETTINGS_REQUEST,
  SET_DEFAULT_SETTINGS_SUCCESS,
  SET_DEFAULT_SETTINGS_FAILURE,
}

export const getInitialState = () => ({
  data: {},
  isLoading: false,
  error: null
});

const transformDefaultSettings = (prevData, newData = {}) => {
  return {
    ...prevData,
    ...newData,
    oddsAlertMove: true // TODO: oddsAlertMove prop does not exist on backend side
  };
};

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case FETCH_DEFAULT_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case FETCH_DEFAULT_SETTINGS_SUCCESS:
      return {
        ...state,
        data: transformDefaultSettings(action.payload),
        isLoading: false,
        error: null
      };

    case FETCH_DEFAULT_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case SET_DEFAULT_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case SET_DEFAULT_SETTINGS_SUCCESS:
      return {
        ...state,
        data: transformDefaultSettings(state.data, action.payload),
        isLoading: false,
        error: null
      };

    case SET_DEFAULT_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    default:
      return state;
  }
}
