import {
  GET_LAST_CHAT_MESSAGES_SUCCESS,
  GET_LAST_CHAT_MESSAGES_FAIL,
  SET_CHAT_MESSAGES_FAIL,
  SET_CHAT_MESSAGES_SUCCESS,
  GET_LAST_CHAT_MESSAGES_AFTER_SET_SUCCESS,
  SET_NEW_MESSAGE,
  RESET_CHAT_MESSAGES_REDUCER,
} from '../actions/notification';

const initialState = {
  newMessage: false,
  chatId: 0,
  messages: [],
  chatError: '',
  sendSupportError: '',
  sendMailError: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_NEW_MESSAGE:
      return { ...state, newMessage: action.payload };
    case GET_LAST_CHAT_MESSAGES_AFTER_SET_SUCCESS:
      return {
        ...state,
        messages: [...action.payload.chatMessages, ...state.messages],
      };
    case RESET_CHAT_MESSAGES_REDUCER:
      return {
        ...state,
        chatId: 0,
        messages: [],
      };
    case GET_LAST_CHAT_MESSAGES_SUCCESS:
      return {
        ...state,
        chatId: action.payload.lastChatId,
        messages: [...state.messages, ...action.payload.chatMessages],
      };
    case GET_LAST_CHAT_MESSAGES_FAIL:
      return { ...state, chatError: action.payload };

    case SET_CHAT_MESSAGES_SUCCESS: {
      return state;
    }
    case SET_CHAT_MESSAGES_FAIL: {
      return { ...state, sendSupportError: action.payload };
    }
    default:
      return state;
  }
};
