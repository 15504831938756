import styled from 'styled-components';
import fonts from '../../config/fonts';
import colors from '../../config/colors';

export default styled.div`
  form {
    max-width: 413px;
    // padding: 45px 60px 60px 63px;
    padding: 0;
    width: 100%;
  }

  label {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
  }

  button[type='submit'],
  .contact-submit {
    width: 100%;
    height: 40px;
    color: white;
    background-image: linear-gradient(to right, #8e70ee, #4a7ece);
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    border: none;
    cursor: pointer;
    outline: none;
    padding: 10px;
    margin-top: 45px;
    font-size: 15px;
    z-index: 100;
    position: relative;

    &:before {
      background-image: linear-gradient(105deg, #7b57ee, #306ece);
    }

    &:after {
      background-image: linear-gradient(105deg, #6952b3, #2558a8);
    }

    &:before,
    &:after {
      content: '';
      width: 100%;
      height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      opacity: 0;
      z-index: -100;
      transition: opacity 0.3s ease-in;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }

    &:active {
      &:after {
        opacity: 1;
      }
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      opacity: 0.5;
      box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
      background-image: linear-gradient(105deg, #8e70ee, #4a7ece);
      cursor: default;
    }
  }

  textarea {
    border: 1px solid #4a90e2;
    height: 120px;
    margin-top: 10px;
    resize: none;
    width: 99%;
  }

  input {
    border: none;
    border-bottom: 1px solid #4a90e2;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  textarea::placeholder,
  input::placeholder {
    color: #818fa9;
  }

  div {
    position: relative;
  }

  .contact-modal {
    width: 500px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .title-wrapper {
    margin-top: 30px;
    margin-bottom: 35px;
    text-align: center;
  }

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #366fb1;
  }

  .modal-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #38415f;
    display: block;
    margin-bottom: 22px;
    text-align: center;
  }

  .modal-link {
    display: block;
    margin: 0 auto;
    width: 50px;
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a90e2;
  }

  textarea:focus,
  input:focus {
    outline: none;
  }

  textarea:focus::placeholder,
  input:focus::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border-bottom: 1px solid #4a90e2;
    -webkit-text-fill-color: #38415f;
    -webkit-box-shadow: inset 0 0 0 50px #fff;
  }

  .form-error {
    font-size: 13px !important;
    position: absolute;
    right: -135px;
    top: 13px;
    color: #ff5766;
    width: 127px;
    height: 22px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .textarea-error {
    top: 4px;
  }

  .red-error {
    & > div {
      overflow: visible !important;
    }

    label {
      color: #ff5766;
    }

    textarea {
      border-color: #ff5766;
      width: 98%;
    }
    input {
      border-bottom-color: #ff5766;
    }
  }

  textarea,
  input {
    width: 100%;
    font-family: ${fonts.proximaNova};
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${colors.secondaryBlue};
    outline: none;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: ${colors.lightBlue};
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: ${colors.lightBlue};
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: ${colors.lightBlue};
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: ${colors.lightBlue};
    }
  }

  textarea:focus::placeholder,
  input:focus::placeholder {
    color: rgba(0, 0, 0, 0);
  }

  @media screen and (max-width: 768px) {
    form {
      max-width: 100%;
    }
  }
`;
