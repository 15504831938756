import styled from 'styled-components';

import fonts from '../../../config/fonts';
import colors from '../../../config/colors';

export default styled.table`
  border-collapse: collapse;
  border-spacing: 0;
  flex: 1;
  color: ${colors.secondaryBlue};

  &,
  tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  thead {
    font-family: ${fonts.proximaNova};
    font-size: 14px;

    th {
      text-align: left;
      padding: 0 5px 5px;
    }
  }

  tbody {
    font-size: 12px;
    font-weight: 600;
    border: 1px solid #dde0e6;
    display: block;
    overflow: auto;

    tr {
      background-color: #edeff5;
      height: 46px;

      &:nth-child(even) {
        background-color: #fbfbfb;
      }
    }

    div,
    span {
      display: flex;
      align-items: center;
    }

    div {
      & > span,
      & > a {
        min-width: 35px;
      }
    }

    .odds, a {
      cursor: pointer;
      text-decoration: underline;
      font-weight: bold;

      color: ${colors.thirdBlue};
      &:hover {
        color: ${colors.azul};
      }
      &:active {
        color: ${colors.mainBlue};
      }
    }

    td {
      padding: 0 5px;
    }
  }
`;
