import styled from 'styled-components';
import colors from '../../config/colors';
import fonts from '../../config/fonts';

export default styled.div`
  display: flex;
  flex-direction: column;
  font-family: ${fonts.proximaNova};

  .main-title {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
    margin: 24px 20px 0 20px;
  }

  .block {
    background-color: white;
    margin: 20px;
    flex-grow: 1;
    padding: 20px;
    border-radius: 4px;
    background-color: #ffffff;
    border: solid 1px #e6e6e6;
  }

  .active {
    color: ${colors.leafyGreen};
  }
  .waiting {
    color: ${colors.secondaryBlue};
  }
  .rejected {
    color: ${colors.red};
  }

  .block-2 {
    display: flex;

    table {
      width: auto;
      flex-grow: 1;
      display: block;
    }

    tbody {
      display: block;
      border: 1px solid #dde0e6;
    }

    td {
      width: 20%;
    }

    table {
      width: 100%;
      text-align: left;
    }
    thead,
    tr {
      width: 100%;
      display: flex;
    }
  }

  .block-2 > div {
    width: 290px;
    padding: 13px;
    margin-left: 13px;
  }

  .recomended-table {
    border: none;

    .rt-thead .rt-th {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #38415f;
      padding: 6px 9px;
      border: none;

      &.-cursor-pointer {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          right: 5px;
          top: 6px;
          border-width: 4px;
          border-style: solid;
          border-color: transparent transparent black transparent;
        }
        &::after {
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          right: 5px;
          top: 15px;
          border-width: 4px;
          border-style: solid;
          border-color: black transparent transparent transparent;
        }

        &.-sort-asc::after{
          display: none
        }
        &.-sort-desc::before{
          display: none
        }
        &.-sort-asc,
        &.-sort-desc {
          box-shadow: none;
        }
      }
    }

    .rt-tbody {
      border: 1px solid #dde0e6;

      .rt-tr-group {
        border: none;
      }
      .rt-td {
        padding: 14px 5px 14px 9px;
        border: none;
        padding-left: 9px;
        font-size: 13px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.6em;
        letter-spacing: normal;
        color: #38415f;
      }
      .rt-tr.-odd {
        background-color: #edeff5;
      }
    }
  }

  .recomended-table tr {
    display: flex;
    justify-content: space-between;
  }

  .recomended-table td {
    width: 30%;
  }

  td.status-row {
    width: 200px;
    text-align: center;
  }

  .note,
  .note h5 {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
  }

  .note h5 {
    font-weight: 600;
    margin-top: 42px;
  }

  td.league {
    font-family: ${fonts.proximaNova};
    font-size: 11px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
  }

  .tableTitles-block {
    display: flex;
  }

  .tableTitles-block div {
    width: 16%;
    padding: 8px;
    font-family: ${fonts.proximaNova};
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    font-weight: 700;
    letter-spacing: normal;
    color: #38415f;
    display: flex;
  }

  table {
    border-collapse: collapse;
    min-width: 400px;

    th {
      text-align: left;
    }
  }

  th,
  td {
    padding-left: 9px;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 3.54;
    letter-spacing: normal;
    color: #38415f;

    &:last-child {
      padding-right: 9px;
    }
  }

  thead tr {
    margin-top: 30px;
    background-color: white !important;
  }

  thead td {
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
    padding-bottom: 6px;
  }

  tr {
    background-color: #fbfbfb;
  }

  tr:nth-child(2n + 1) {
    background-color: #edeff5;
  }

  tr.marked {
    background-color: #d1d7e9;
  }

  tr.marked td:first-child {
    border-left: 2px solid white;
    font-weight: bold;
  }

  tr.marked td {
    border-left: 2px solid white;
  }

  .table-summary {
    th {
      text-align: left;
      padding-right: 9px;
    }
    td {
      text-align: right;
    }
  }

  .block-section {
    margin: 0 0 25px;
  }
  .block-title {
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #38415f;
    margin: 0 0 10px;
  }

  .member-name {
    cursor: pointer;
    position: relative;

    &:hover {
      .tooltip {
        visibility: visible;
      }
    }

    .tooltip {
      position: absolute;
      top: -20px;
      padding: 10px;
      background: white;
      height: 30px;
      left: 20px;
      border-radius: 3px;
      visibility: hidden;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px #d6d6d6;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        position: absolute;
        left: 30px;
        top: 28px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 7px 7px 0 7px;
        border-color: #fff transparent transparent transparent;
      }
    }

    .email {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 3.54;
      letter-spacing: normal;
      color: #4a90e2;
      text-decoration: underline;

      &:hover {
        color: #1d7bea;
      }

      &:active {
        color: #366fb1;
      }

      img {
        margin-right: 10px;
      }
    }
  }

  .contact-modal {
    width: 500px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .back > div {
    background: rgba(0, 0, 0, 0.36);
    z-index: 999;
  }

  .title-wrapper {
    margin-top: 30px;
    margin-bottom: 25px;
    text-align: center;
  }

  .modal-title {
    font-size: 24px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #366fb1;
  }

  .modal-text .email {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #4a90e2;
  }

  .modal-text {
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #38415f;
    display: block;
    margin-bottom: 22px;
    text-align: center;
  }

  .modal-link {
    background: white;
    border: none;
    display: block;
    margin: 0 auto;
    margin-bottom: 11px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    background-image: linear-gradient(105deg, #8e70ee, #4a7ece);
    height: 40px;
    text-decoration: none;
    color: white;
    width: 171px;
    cursor: pointer;
  }

  .modal-link:hover {
    box-shadow: 0 3px 5px 0 rgba(54, 111, 177, 0.27);
    background-image: linear-gradient(105deg, #7b57ee, #306ece);
  }

  .modal-link:focus {
    outline: none;
    border: none;
  }
  @media screen and (max-width: 1440px) {
    .member-name {
      width: 25% !important;
      padding-bottom: 0px;

      a {
        height: 100%;
      }
      span {
        line-height: 1.5 !important;
      }
    }
  }
  @media screen and (max-width: 1366px) {
    .member-name {
      width: 25% !important;
      padding-bottom: 0;

      span {
        line-height: 1.7 !important;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    thead {
      .member-name {
        padding-top: 0 !important;
      }
    }

    tbody {
      td {
        display: flex;
        align-items: center;
      }
    }
    .block-2 {
      table {
        width: 100% !important;
      }
    }
    .status-row {
      text-align: left !important;
    }
    .general-block {
      height: auto;
    }
    .info-wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .info-block {
      width: 15%;
      margin-bottom: 10px;
    }

    .betinfo-block {
      justify-content: flex-start;
      margin-top: 20px;
    }

    .betinfo-block div {
      font-size: 30px;
      padding: 5px 10px;
    }

    select {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 1024px) {
    .member-name {
      width: 25% !important;
      padding-top: 0;
      padding-bottom: 0;

      span {
        line-height: 1.7 !important;
      }
    }
  }

  @media screen and (max-width: 800px) {
    .info-block {
      width: 30%;
      margin-bottom: 10px;
    }
  }
`;
