import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import DateRange from '@/components/DateRange';

const GeneralInfo = ({
  general,
  options,
  chartError,
  reloadChart,
  generalError,
  reloadGeneral,
  range,
  setRange
}) => {
  const filterRange = (series) => {
    let [start, end] = range;

    if (!start || !end) {
      return series;
    }

    start = Date.parse(start);
    end = Date.parse(end);

    return series.map(serie => {
      return {
        ...serie,
        data: serie.data.filter(point => point.x > start && point.x < end)
      }
    });
  };

  const onChange = (startDate, endDate) => {
    setRange(startDate, endDate)
  };

  return (
    <div className="block general-info">
      <p className="title">General Info.</p>
      <div className="general-block">
        {generalError ? (
          <div className="error">
            <p>Something went wrong...</p>
            <button type="button" onClick={reloadGeneral} className="reload-btn">
              Reload
            </button>
          </div>
        ) : (
          <div className="info-wrapper">
            <div className="info-block">
              <small>First Bet Date</small>
              <p>{general.firstBet}</p>
            </div>
            <div className="info-block">
              <small>Last Bet Date</small>
              <p>{general.lastBet}</p>
            </div>
            <div className="info-block">
              <small>Avg. Odds</small>
              <p>{general.avgOdds}</p>
            </div>
            <div className="info-block">
              <small>This month:</small>
              <p className="small-info">{general.thisMonth}</p>
            </div>
            <div className="info-block">
              <small>Last month:</small>
              <p className="small-info">{general.lastMonth}</p>
            </div>
            <div className="info-block">
              <small>Avg. month: </small>
              <p className="small-info">{general.avgMonth}</p>
            </div>
            <div className="info-block">
              <small>Total</small>
              <p className="small-info">{general.total}</p>
            </div>
          </div>
        )}
      </div>
      <div className="chart">
        {chartError ? (
          <div className="error">
            <p>Something went wrong...</p>
            <button type="button" onClick={reloadChart} className="reload-btn">
              Reload
            </button>
          </div>
        ) : (
          <span />
        )}

        <DateRange
          range={range}
          onChange={onChange}
        />

        <div className="chart">
          <div className="circle-info">
            <div className="circle" />
            <span>Thing requests</span>
          </div>
          <HighchartsReact highcharts={Highcharts} options={{...options, series: filterRange(options.series)}} />
        </div>
      </div>
    </div>
  );
};

GeneralInfo.propTypes = {
  general: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  chartError: PropTypes.string.isRequired,
  reloadChart: PropTypes.func.isRequired,
  generalError: PropTypes.string.isRequired,
  reloadGeneral: PropTypes.func.isRequired,
  loading: PropTypes.number
};

export default GeneralInfo;
