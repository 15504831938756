export const getOddsAlertsInitialState = () => ({
  data: [],
  isLoading: false,
  error: null
});

export const transformOddsAlertsSelectedPermanentData = (oldAlerts, alerts, { groupName, isDisabled = false }) => {
  return alerts.map(alert => {
    const isGroup = alert.leagueId > 0 && alert.teamId < 1;
    const isLeague = alert.teamId < 1;
    const id = `odd-alert/${alert.name}/${alert.leagueId}/${alert.teamId}`;

    const oldAlert = oldAlerts.find(oldAlert => oldAlert.id === id);

    const modAlert = {
      ...alert,
      id,
      isGroup,
      isDisabled,
      isLeague,
      isChecked: true,
      groupName,
      inProcessing: false,
      isShowSettingsPopup: oldAlert ? oldAlert.isShowSettingsPopup : false
    };

    if (isGroup) {
      if (oldAlert) {
        modAlert.groupItems = {
          ...oldAlert.groupItems
        };
      } else {
        modAlert.groupItems = {
          data: [],
          isOpen: false,
          isLoading: false,
          error: null
        };
      }
    }

    return modAlert;
  });
};

export const transformOddsAlertsProcessing = (alerts, id, inProcessing) => {
  return alerts.map(alert =>
    (alert.id === id ? { ...alert, inProcessing } : alert));
};

export const transformOddsAlertsTeams = (state, alert, isLoading) => {
  const alerts = state[alert.groupName];
  const data = alerts.data;
  const rootAlert = data.find(_alert => _alert.id === alert.id);

  console.log('%ctransformOddsAlertsTeams', 'color: red', alerts, rootAlert, alert);
  return { [alert.groupName]: { ...alerts, data: { ...data,  }}};
};