import React from 'react';

const StatsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd">
      <rect width="20" height="20" fillRule="nonzero" rx="1" />
      <path stroke="#C6CAD8" strokeLinecap="square" d="M3.5 15l13-9" />
      <g fill="#4A90E2">
        <path d="M4.58 9.146C3.092 8.978 2 8.414 2 7.745c0-.376.344-.718.909-.976.022-.163.188-.294.494-1.27.433-1.382.531-2.642 2.163-2.642 1.633 0 1.89.958 2.272 2.641.224.985.342 1.228.377 1.33.49.25.785.57.785.917 0 .67-1.093 1.233-2.58 1.4.04.106.06.219.06.337v.038a.98.98 0 0 1-1.96 0v-.038c0-.118.02-.231.06-.336z" />
        <path
          fillRule="nonzero"
          d="M4 8.897l.132-.397c.577.11 1.067.092 1.355.087.483-.008.949.018 1.346-.08l.167.39c-.453.111-.969.09-1.5.1-.347.005-.88.018-1.5-.1z"
        />
      </g>
      <path
        stroke="#4A90E2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M7 16.582l1.77-2.74 1.647 2.74 2.39-3.916 1.45 3.916 2.985-5.082"
      />
    </g>
  </svg>
);

export default StatsIcon;
