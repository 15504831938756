import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {compose, lifecycle, withProps} from 'recompose';
import {reduxForm} from 'redux-form';
import _ from 'lodash';
import moment from 'moment';

import PicksForm from '../../components/CreateNewPick/PicksForm';
import {
  clearFormNotes,
  clearPicksForm,
  createNewPick,
  DISABLE_PICKS_FORM,
  getMyPicksLast,
  selectLeague
} from '../../store/actions/picks';
import {getWebTemplate} from '../../store/actions/user';

const mapStateToProps = ({ picks, form: formState, user }) => ({
  sports: picks.sports,
  marketTypes: picks.marketTypes,
  formDisabled: picks.formDisabled,
  isLive: _.get(formState, 'picksForm.values.live', false),
  userLevel:  user.account.userLevel,
  leagueForm: formState.leagueForm,
  selectedLeague: picks.selectedLeague
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    clearPicksForm,
    createNewPick,
    selectLeague,
    clearFormNotes,
    getWebTemplate,
    getMyPicksLast
  },
  dispatch,
);

const connected = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const validate = (values) => {
  const errors = {};

  if (!values.sport) {
    errors.sport = 'Please select sport';
  }
  if (!values.league) {
    errors.league = 'Please type league name';
  }
  if (!values.team1) {
    errors.team1 = 'Please type both team names';
  }
  if (!values.team2) {
    errors.team2 = 'Please type both team names';
  }
  if (isNaN(values.line)) {
    errors.line = 'Please enter line';
  }
  if (!values.minOdds) {
    errors.line = 'Please enter minimum odds';
  }
  if (!values.date && values.isLive) {
    errors.date = 'Please select date';
  }
  if (!values.score && !values.isLive) {
    errors.score = 'Please set score';
  }
  if (!values.market) {
    errors.market = 'Please choose full time or 1st half';
  }
  if (!values.bet) {
    errors.bet = 'Please choose your bet';
  }

  return errors;
};

const form = reduxForm({
  form: 'picksForm',
  validate
});

let gamesIntervalId = null;

const props = withProps(ownProps => {
  //@todo there is not necessary, it just to display date in the past, when api will receive actual values please remove functionality with   ".subtract(subtractDates, 'day')"
  const subtractDates = 3;
  return {
    dates: _.chain(
        new Array(33).fill(1).map((d, i) => ({
          id:  moment()
              .subtract(subtractDates, 'day')
              .add(i, 'days')
              .format('DD/MM/YY'),
          name: moment()
              .subtract(subtractDates, 'day')
              .add(i, 'days')
              .format('DD/MM/YY'),
        })),
    ).value(),
    bets: [
      { id: 'home', name: 'Home' },
      { id: 'away', name: 'Away' },
      { id: 'draw', name: 'Draw' },
      { id: 'over', name: 'Over' },
      { id: 'under', name: 'Under' },
    ],
    onSubmit: async bet => {
      await ownProps.selectLeague(ownProps.selectedLeague);
      await ownProps.createNewPick(bet);
      await ownProps.getWebTemplate();
      await ownProps.getMyPicksLast();
    },
    onSubmitFail: (errors) => {
      console.log(errors);
      if (errors && typeof errors === 'object') {
        const [err] = Object.keys(errors || {});
        alert(errors[err]);
      }
    },
  }
});

const lifecycleMethod = lifecycle({
  componentWillReceiveProps(nextProps) {
  },

  componentDidMount() {
    this.props.dispatch({
      type: DISABLE_PICKS_FORM,
    });
  },

  componentWillUnmount() {
    if (gamesIntervalId) {
      clearInterval(gamesIntervalId);
    }
  }
});

export default compose(
  connected,
  props,
  form,
  lifecycleMethod
)(PicksForm);
