import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import myStatsReduser from './mystats';
import formErrorsReducer from './formErrors';
import financeReducer from './finance';
import viewReducer from './view';
import picksReducer from './picks';
import userReducer from './user';
import notificationReducer from './notification';
import profileReducer from './profile';
import messagesReducer from './messages';
import oddsalerts from './oddsalerts'; // old odds alerts refactoring
import oddsAlerts from './odds-alerts';
import reportReducer from './report';
import auth from './auth';
import chatWithTrader from './chat-with-trader';
import chatWithManager from './chat-with-manager';
import statistics from './statistics';
import inPlayReducer from './statistics-reducers/inPlayReducer'

export default combineReducers({
  form: formReducer,
  mystats: myStatsReduser,
  formErrors: formErrorsReducer,
  view: viewReducer,
  finance: financeReducer,
  picks: picksReducer,
  user: userReducer,
  notification: notificationReducer,
  profile: profileReducer,
  messages: messagesReducer,
  alerts: oddsalerts, // old odds alerts refactoring
  oddsAlerts,
  report: reportReducer,
  auth,
  chatWithTrader,
  chatWithManager,
  statistics,
  newStatistics: inPlayReducer
});
