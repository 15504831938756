import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';

import { getMemberUnitsReport, openReportModal } from '../../store/actions/report';

import MainTable from '../../components/UnitsReport/MainTable';

const mapStateToProps = ({ report }) => ({
  reports: report.reports,
  modalOpened: report.modal.opened
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getMemberUnitsReport,
    openReportModal
  },
  dispatch
);

const cLifecycle = lifecycle({
  componentDidMount() {
    this.props.getMemberUnitsReport();
  }
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  cLifecycle
)(MainTable);
