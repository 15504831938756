import React from 'react';
import PropTypes from 'prop-types';

import BtnEditWhiteIcon from '../../assets/images/ic-edit-white.svg';
import ExpandBlueIcon from '../../assets/images/ic-expand-blue.svg';
import CollapseIcon from '../../assets/images/ic-collapse.svg';
import MessageItem from '../../containers/Messages/MessageItem';
import ComposeConfimMessage from '../../containers/ComposeConfimMessage';

const RightColumn = ({
  subject,
  messages,
  expandAllMessages,
  expand,
  closeModal,
  showComposeMessage,
}) => {
  const handleExpandedAllMessages = () => {
    expandAllMessages()
    closeModal()
  }

  const handleShowComposeMessage = () => {
    showComposeMessage()
    closeModal()
  }

  return (
  <div className="right-column">
    <div className="right-header">
      <h3>{subject}</h3>
      {messages.length && (
        <button className="btn btn-border btn-expand" type="button" onClick={handleExpandedAllMessages}>
          <span>
            <img src={expand ? ExpandBlueIcon : CollapseIcon} alt="dropdown" />
            {' '}
            {expand ? (
              <span className="text-btn">Expand All</span>
            ) : (
              <span className="text-btn">Collapse All</span>
            )}
          </span>
        </button>
      )}
      <button className="send-btn" type="button" onClick={handleShowComposeMessage}>
        <img src={BtnEditWhiteIcon} alt="edit" />
        {' '}
        <span className="text-btn">Compose Message</span>
      </button>
    </div>

    <div className="message-list ">
      {messages.map(message => (
        <MessageItem message={message} key={`message_${message.id}`} />
      ))}
    </div>
    <ComposeConfimMessage />  
  </div>
);
}

RightColumn.propTypes = {
  messages: PropTypes.array.isRequired,
  expandAllMessages: PropTypes.func.isRequired,
  showComposeMessage: PropTypes.func.isRequired,
  expand: PropTypes.bool.isRequired,
  subject: PropTypes.string,
};

RightColumn.defaultProps = {
  subject: null,
};

export default RightColumn;
