import {
  SET_ACTIVE_TAB,
  FETCH_TABS_REQUEST,
  FETCH_TABS_SUCCESS,
  FETCH_TABS_FAILURE
} from '../../actions/odds-alerts';

export {
  SET_ACTIVE_TAB,
  FETCH_TABS_REQUEST,
  FETCH_TABS_SUCCESS,
  FETCH_TABS_FAILURE
}

const transformTabs = tabs => {
  let activeIndex = tabs.findIndex(tab => tab.isActive);
  if (activeIndex === -1) {
    activeIndex = 0;
  }

  return tabs.map((tab, index) => ({ ...tab, isActive: index === activeIndex }));
};

export const getInitialState = () => ({
  data: [],
  isLoading: false,
  error: null
});

export default (state = getInitialState(), action) => {
  switch (action.type) {
    case FETCH_TABS_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null
      }

    case FETCH_TABS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: transformTabs(action.payload)
      };

    case FETCH_TABS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case SET_ACTIVE_TAB:
      return {
        ...state,
        data: state.data.map(tab => ({ ...tab, isActive: tab.id === action.payload }))
      };

    default:
      return state;
  }
}
