import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Link } from 'react-router-dom';

import Modal from '../../containers/Modal';
import FormInput from '../FormInput';
import Wrapper from './styles';

const RestorePasswordForm = ({
  handleSubmit,
  submitting,
  restoreModalOpened,
  openRestoreModal,
  closeRestoreModal,
  errorMessage
}) => (
  <Wrapper>
    <Link to="/login" className="link-back" type="button">
      <div className="back-image" />
      Back
    </Link>
    <h1 className="title">Password Reminder</h1>
    <div>
      <p>
        Please enter your email address and we will send you the password to that address. Please
        use the same email address as registered in Punteam.
      </p>

      <form noValidate onSubmit={handleSubmit}>
        <Field
          name="email"
          type="email"
          component={FormInput}
          label="Email"
          placeholder="email@something.com"
        />
        {errorMessage && <span className="restore-error">{errorMessage}</span>}
        <div>
          <button className="submit-btn" type="submit" disabled={submitting}>
            Send
          </button>
        </div>
      </form>
    </div>
    <nav>
      <a href="/about-us-unauth">About Us</a>
      <a href="/contact-us-unauth">Contact Us</a>
      <a href="/terms-unauth">Terms & Conditions</a>
    </nav>

    {restoreModalOpened && (
      <div className="darken-wrapper">
        <Modal className="restore-modal" modalName="restore">
          <div className="title-wrapper">
            <span className="modal-title">Password sent!</span>
          </div>
          <div>
            <span className="modal-text">
              Your password has been sent to your email address. Please check your inbox (and
              potentially your junk folder) and try to login again.
            </span>
            <Link onClick={() => closeRestoreModal()} className="modal-link" to="/login">
              Home
            </Link>
          </div>
        </Modal>
      </div>
    )}
  </Wrapper>
);

RestorePasswordForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  restoreModalOpened: PropTypes.bool.isRequired,
  openRestoreModal: PropTypes.func.isRequired,
  errorMessage: PropTypes.string
};

export default RestorePasswordForm;
