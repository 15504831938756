import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import Wrapper from './styles';
// import BellBlue from '../../assets/images/ic-bell-blue.svg';
// import BellWhite from '../../assets/images/ic-bell-white.svg';
import PersonBlue from '../../assets/images/ic-person-blue.svg';
import PersonWhite from '../../assets/images/ic-person-white.svg';
import HeaderLinkItem from '../../containers/HeaderLinkItem';
// import OldSiteButton from '../../components/OldSiteButton';
import {checkAccess, listOfSection} from "@/helpers/access";

const Header = ({logOut, account, unitLeft, userLevel, utcOffset}) => {
    const accessData = checkAccess(userLevel);
    const utc = utcOffset > 0 ? `+${utcOffset}` : utcOffset
    return (
        <Wrapper>
            {
                accessData[listOfSection.Affiliates] && accessData[listOfSection.MyPicks] ?
                <Link to="/units-report" className="like-text">
                    You have&nbsp;{unitLeft} units remaining this week
                </Link>
                : <div></div>
            }
            <div>
                {/* {accessData[listOfSection.OddsAlert] ?
                    <HeaderLinkItem
                        to="/odds-alerts"
                        image={BellBlue}
                        hoveredImage={BellWhite}
                        title="Odds Alerts"
                        imageClass="blue-bell"
                    /> : null} */}
               {/* <OldSiteButton /> */}
               <span>
                    {`UTC Offset ( ${utc} )`}
               </span>
                <HeaderLinkItem
                    image={PersonBlue}
                    hoveredImage={PersonWhite}
                    title={
                        account && account.firstName && account.lastName
                            ? `${account.firstName} ${account.lastName}`
                            : 'User'
                    }
                    linkClass="profile-link"
                    disableLink
                >
                    <div className="inner-links">
                        <Link to="/edit-profile">Edit my profile</Link>
                        <span className="link" onClick={logOut}>Logout</span>
                    </div>
                </HeaderLinkItem>
            </div>
        </Wrapper>
    )
};

Header.propTypes = {
    logOut: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    unitLeft: PropTypes.number.isRequired
};

export default Header;
