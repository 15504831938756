import styled from 'styled-components';
import fonts from '../../config/fonts';

export default styled.div`
  font-family: ${fonts.proximaNova};

  & > div {
    background-color: white
    z-index: 99;
  }

  .confirm-image {
    width: 100%;
    height: 300px;
    background-repeat: no-repeat;
  }
`;
