import React from 'react';

const RejectedIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
    <g fill="none" fillRule="evenodd" stroke="#FF5766" strokeWidth="2">
      <circle cx="14" cy="14" r="14" transform="translate(1 1)" />
      <path strokeLinecap="square" d="M3.5 22.5l23-15" />
    </g>
  </svg>

);

export default RejectedIcon;
