import {
  GET_MEMBER_ODDSALERTS_PERMANENT_SUCCESS,
  GET_MEMBER_ODDSALERTS_PERMANENT_FAIL,
  GET_MEMBER_ODDSALERTS_TEAMS_BY_LEAGUE_PERMANENT_SUCCESS,
  GET_MEMBER_ODDSALERTS_TEAMS_BY_LEAGUE_PERMANENT_FAIL,
  GET_MEMBER_UNITS_REPORT_COUNTRIES_SUCCESS,
  GET_MEMBER_UNITS_REPORT_COUNTRIES_FAIL,
  GET_ODDSALERT_LEAGUES_BY_COUNTRY_SUCCESS,
  GET_ODDSALERT_LEAGUES_BY_COUNTRY_FAIL,
  GET_ODDSALERT_TEAMS_BY_LEAGUE_SUCCESS,
  GET_ODDSALERT_TEAMS_BY_LEAGUE_FAIL,
  GET_MEMBER_ODDSALERT_GAMES_TEMPORARY_SUCCESS,
  GET_MEMBER_ODDSALERT_GAMES_TEMPORARY_FAIL,
  SET_ODDS_ALERTS_LEAGUE_SUCCESS,
  SET_ODDS_ALERTS_LEAGUE_FAIL,
  SET_ODDS_ALERTS_GAME_SUCCESS,
  SET_ODDS_ALERTS_GAME_FAIL,
  SET_ODDS_ALERTS_TEAM_SUCCESS,
  SET_ODDS_ALERTS_TEAM_FAIL,
  COUNTRY_ID,
  RESET_TEAMS_LIST,
  OPEN_SUBMENU,
  OPEN_SUB_TOOLTIP,
  GET_SEARCH_RESULTS_SUCCESS,
  GET_SEARCH_RESULTS_FAIL,
  SET_COUNTRY_ID,
  TOGGLE_COUNTRIES,
  SET_COUNTRY_DETAILS,
  SET_GAME_ID,
  SET_LINES,
  SET_ODDS_REACHES,
  GET_ALERTS_DEFAULTS_SUCCESS,
  GET_ALERTS_DEFAULTS_FAIL,
  OPEN_TOOLTIP,
  START_LOADING,
  STOP_LOADING,
} from '../actions/oddsalerts';

const initialState = {
  search: [],
  searchError: '',
  currentAlerts: [],
  currentAlertsError: '',
  leagueTeamList: [],
  leagueTeamListError: '',
  setOddsAlertsLeagueError: '',
  countries: [],
  countriesError: '',
  leagues: [],
  leaguesError: '',
  teams: [],
  teamsError: '',
  gameAlerts: [],
  gameAlertsError: '',
  setAlertsGameError: '',
  setAlertsTeamError: '',
  submenuId: null,
  teamId: '',
  countriesVisible: false,
  countryId: null,
  gameId: null,
  leagueId: null,
  oddsReaches: null,
  line: null,
  selectedCountry: 'Select a country',
  selectedCode: '',
  defaultValues: {},
  defaultValuesError: '',
  subMenuPosition: '',
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case STOP_LOADING:
      return { ...state, isLoading: false };
    case GET_ALERTS_DEFAULTS_SUCCESS:
      return { ...state, defaultValues: action.payload };
    case GET_ALERTS_DEFAULTS_FAIL:
      return { ...state, defaultValuesError: action.payload };
    case SET_GAME_ID:
      return {
        ...state,
        gameId: action.id,
        line: action.line,
      };
    case SET_LINES:
      return {
        ...state,
        line: action.payload,
      };
    case SET_ODDS_REACHES:
      return {
        ...state,
        oddsReaches: action.payload,
      };
    case SET_COUNTRY_DETAILS:
      return {
        ...state,
        selectedCountry: action.name,
        selectedCode: action.code,
        countryId: action.id,
      };
    case TOGGLE_COUNTRIES:
      return { ...state, countriesVisible: !state.countriesVisible };
    case SET_COUNTRY_ID: {
      const country = state.countries.filter(id => id.id === action.id);
      return {
        ...state,
        countryId: action.id,
        selectedCountry: country[0].name,
        selectedCode: country[0].code,
      };
    }
    case GET_SEARCH_RESULTS_SUCCESS:
      return { ...state, search: action.payload };
    case GET_SEARCH_RESULTS_FAIL:
      return { ...state, searchError: action.payload };
    case OPEN_TOOLTIP:
      return {
        ...state,
        leagueId: state.leagueId ? null : +action.id,
        subMenuPosition: action.position,
      };
    case OPEN_SUB_TOOLTIP:
      return {
        ...state,
        teamId: state.teamId ? '' : action.id,
      };
    case OPEN_SUBMENU:
      return {
        ...state,
        submenuId: +action.id,
        subMenuPosition: action.position,
      };
    case RESET_TEAMS_LIST:
      return { ...state, leagueTeamList: [] };
    case COUNTRY_ID:
      return { ...state, countryId: action.payload };
    case GET_MEMBER_ODDSALERTS_PERMANENT_SUCCESS:
      return { ...state, currentAlerts: action.payload.alerts };
    case GET_MEMBER_ODDSALERTS_PERMANENT_FAIL:
      return { ...state, currentAlertsError: action.payload };
    case GET_MEMBER_ODDSALERTS_TEAMS_BY_LEAGUE_PERMANENT_SUCCESS:
      return { ...state, leagueTeamList: action.payload };
    case GET_MEMBER_ODDSALERTS_TEAMS_BY_LEAGUE_PERMANENT_FAIL:
      return { ...state, leagueTeamListError: action.payload };
    case GET_MEMBER_UNITS_REPORT_COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload };
    case GET_MEMBER_UNITS_REPORT_COUNTRIES_FAIL:
      return { ...state, countriesError: action.payload };
    case GET_ODDSALERT_LEAGUES_BY_COUNTRY_SUCCESS:
      return { ...state, leagues: action.payload };
    case GET_ODDSALERT_LEAGUES_BY_COUNTRY_FAIL:
      return { ...state, leaguesError: action.payload };
    case GET_ODDSALERT_TEAMS_BY_LEAGUE_SUCCESS:
      return { ...state, teams: action.payload };
    case GET_ODDSALERT_TEAMS_BY_LEAGUE_FAIL:
      return { ...state, teamsError: action.payload };
    case GET_MEMBER_ODDSALERT_GAMES_TEMPORARY_SUCCESS:
      return { ...state, gameAlerts: action.payload };
    case GET_MEMBER_ODDSALERT_GAMES_TEMPORARY_FAIL:
      return { ...state, gameAlertsError: action.payload };
    case SET_ODDS_ALERTS_LEAGUE_SUCCESS:
      return state;
    case SET_ODDS_ALERTS_LEAGUE_FAIL:
      return { ...state, setOddsAlertsLeagueError: action.payload };
    case SET_ODDS_ALERTS_GAME_SUCCESS:
      return state;
    case SET_ODDS_ALERTS_GAME_FAIL:
      return { ...state, setAlertsGameError: action.payload };
    case SET_ODDS_ALERTS_TEAM_SUCCESS:
      return state;
    case SET_ODDS_ALERTS_TEAM_FAIL:
      return { ...state, setAlertsTeamError: action.payload };
    default:
      return state;
  }
};
