import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Field } from 'redux-form';
import BellBlack from '../../assets/images/bell-black';

import Modal from './styles';
import Select from '../ui/form/Select';
import Loader from '../ui/Loader';

const PicksChart = ({
  graphData,
  changeLineOnGraph,
  lines,
  options,
  graphLoading,
  modalName,
  disableSelect,
  alertsForm,
  line,
  setLines,
  setOddsReaches,
  gameId,
  setAlertGame,
  getWebTemplate,
  getMemberOddsAlertGamesTemporary,
  oddsReaches,
}) => (
  <Modal className="pick-modal" modalName={modalName}>
    <div className="title-wrapper">
      <span className="title">{graphData.graphTitle}</span>
      <div className="select-wrapper">
        <label htmlFor="pick-over">{graphData.bet}</label>
        {!disableSelect ? (
          <Select
            id="pick-over"
            input={{ onChange: e => changeLineOnGraph(e.target.value) }}
            options={lines}
          />
        ) : (
          <Fragment>
            {' '}
            {graphData.line}
          </Fragment>
        )}
      </div>
    </div>
    {alertsForm && (
      <form className="alerts-form">
        <BellBlack />
        Alert me when OVER
        <Field onChange={e => setLines(e.target.value)} name="line" component="select">
          <option value={line} selected>
            {line}
          </option>
          <option value={+line + 0.25}>{+line + 0.25}</option>
          <option value={+line + 0.5}>{+line + 0.5}</option>
          <option value={+line + 0.75}>{+line + 0.75}</option>
        </Field>
        reaches odds of
        <Field
          component="input"
          type="number"
          lang="en-150"
          step="0.1"
          onBlur={e => setOddsReaches(e.target.value)}
          name="oddsReaches"
        />
        <button
          className="send-btn"
          type="button"
          onClick={async () => {
            await setAlertGame(gameId, graphData.bet, line, oddsReaches, 1);
            getMemberOddsAlertGamesTemporary();
            getWebTemplate();
          }}
        >
          Submit
        </button>
      </form>
    )}
    <div>
      <span className="zoom-text">
        You can select a portion of the graph with your mouse or finger, to zoom in to that portion.
      </span>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        type="line"
        height="300"
        id="handicapChart"
        allowChartUpdate
      />
      {!graphLoading && !options.series[0].enableMouseTracking && (
        <div className="no-data-graph">No Data</div>
      )}
      {graphLoading && <Loader />}
    </div>
  </Modal>
);

PicksChart.propTypes = {
  graphData: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  changeLineOnGraph: PropTypes.func.isRequired,
  lines: PropTypes.array.isRequired,
  graphLoading: PropTypes.bool.isRequired,
  modalName: PropTypes.string.isRequired,
  disableSelect: PropTypes.bool,
  alertsForm: PropTypes.bool,
  line: PropTypes.string.isRequired,
  setLines: PropTypes.func.isRequired,
  setOddsReaches: PropTypes.func.isRequired,
  gameId: PropTypes.number.isRequired,
  setAlertGame: PropTypes.func.isRequired,
  getWebTemplate: PropTypes.func.isRequired,
  getMemberOddsAlertGamesTemporary: PropTypes.func.isRequired,
  oddsReaches: PropTypes.number,
};

PicksChart.defaultProps = {
  disableSelect: false,
  alertsForm: false,
  oddsReaches: 0,
};

export default PicksChart;
