import styled from 'styled-components';

import colors from '../../config/colors';

export default styled.div`
  min-height: calc(100vh - 60px);
  display: flex;

  main {
    width: 100%;
    background-color: #edeff5;
    min-height: 111vh;
    height: 100%;

    & > div.content {
      padding: 25px 20px;
    }
  }

  h1.title {
    font-size: 24px;
    font-weight: 600;
    color: ${colors.secondaryBlue};
    margin: 0;
  }
  
  .content-container {
    padding: 20px;
  }
`;
