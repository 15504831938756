import styled from 'styled-components';

import Checkbox from '../Checkbox/styles';

export default styled(Checkbox)`
  input {
    opacity: 1;
    height: 28px;
    min-width: 135px;
    border: solid 1px #dbdbdb;
    background-color: #fff;
    border-radius: 0;
    font-size: 14px;
    padding: 1px 13px;

    &:hover {
      border-color: #a5c8f1;
    }

    &:active,
    &:focus {
      border-color: #4a90e2;
    }
  }
`;
