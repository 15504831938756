import { change } from 'redux-form';
import _get from 'lodash/get';
import http from '@/services/http-service';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';

import { getDay, getMonth, getYear } from '@/utils/dateFormatters';
import { getUserAccountInfo } from './user';

export const SET_PROFILE_INFO_SUCCESS = 'profile/SET_PROFILE_INFO_SUCCESS';
export const SET_PROFILE_INFO_FAIL = 'profile/SET_PROFILE_INFO_FAIL';
export const CHANGE_PASSWORD_SUCCESS = 'profile/CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'profile/CHANGE_PASSWORD_FAIL';
export const PRELOAD_PROFILE_FORM = 'profile/PRELOAD_PROFILE_FORM';
export const GET_COUNTRIES_LIST_SUCCESS = 'profile/GET_COUNTRIES_LIST_SUCCESS';
export const GET_COUNTRIES_LIST_FAIL = 'profile/GET_COUNTRIES_LIST_FAIL';
export const RESEND_EMAIL_SUCCESS = 'profile/RESEND_EMAIL_SUCCESS';
export const RESEND_EMAIL_FAIL = 'profile/RESEND_EMAIL_FAIL';
export const CHECK_EMAIL_CHANGE_SUCCESS = 'profile/CHECK_EMAIL_CHANGE_SUCCESS';
export const CHECK_EMAIL_CHANGE_FAIL = 'profile/CHECK_EMAIL_CHANGE_FAIL';
export const CHANGE_EMAIL_SUCCESS = 'profile/CHANGE_EMAIL_SUCCESS';
export const CHANGE_EMAIL_FAIL = 'profile/CHANGE_EMAIL_FAIL';
export const EMAIL_WAS_CONFIRMED = 'profile/EMAIL_WAS_CONFIRMED';
export const SET_HAS_TERMS = 'profile/SET_HAS_TERMS';
export const SET_DATA_TERMS = 'profile/SET_DATA_TERMS';

export const setApprovalEmail = request => async dispatch => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetApprovalEmail');
      trace.start();
    }

    const { data: { error }} = await http.get(`/SetApprovalEmail?request=${request}`);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: EMAIL_WAS_CONFIRMED
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
  }
};

export const setHasTerms = value => dispatch => {
  dispatch({
    type: SET_HAS_TERMS,
    payload: value
  });
};

export const preloadProfile = () => async (dispatch, getState) => {
  await dispatch(getUserAccountInfo());

  const state = getState();
  const profileData = _get(state, 'user.account', null);

  dispatch(change('setProfileForm', 'firstName', profileData.firstName));
  dispatch(change('setProfileForm', 'lastName', profileData.lastName));
  dispatch(change('setProfileForm', 'phone', profileData.phone));
  dispatch(change('setProfileForm', 'hasWhatsapp', profileData.hasWhatsapp));
  dispatch(change('setProfileForm', 'hasTelegram', profileData.hasTelegram));
  dispatch(change('setProfileForm', 'hasWeChat', profileData.hasWeChat));
  dispatch(change('setProfileForm', 'newEmail', profileData.email));
  dispatch(change('setProfileForm', 'skype', profileData.skype));
  dispatch(change('setProfileForm', 'countryId', profileData.countryId));
  dispatch(change('setProfileForm', 'addressLine1', profileData.addressLine1));
  dispatch(change('setProfileForm', 'addressLine2', profileData.addressLine2));
  dispatch(change('setProfileForm', 'city', profileData.city));
  dispatch(change('setProfileForm', 'zip', profileData.zip));
  dispatch(change('setProfileForm', 'nativeLanguage', profileData.nativeLanguage));
  dispatch(change('setProfileForm', 'favouriteTeam', profileData.favouriteTeam));
  dispatch(change('setProfileForm', 'dayOfBirth', getDay(profileData.birthDay)));
  dispatch(change('setProfileForm', 'monthOfBirth', getMonth(profileData.birthDay)));
  dispatch(change('setProfileForm', 'yearOfBirth', getYear(profileData.birthDay)));
};

export const checkEmail = () => async (dispatch, getState) => {
  const state = getState();
  const params = {
    newEmail: _get(state, 'form.setProfileForm.values.newEmail')
  };

  let trace1;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace1 = performance.trace('CheckChangeEmailByUser');
      trace1.start();
    }

    const { data: { error: error1 }} = await http.post('/CheckChangeEmailByUser', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error1.errorCode !== 0) {
        trace1.putAttribute('errorCode', `${error1.errorCode}`);
      }
      trace1.stop();
    }

    if (error1.errorCode !== 0) {
      throw new ApiError(error1.errorText, error1.errorCode);
    }

    dispatch({
      type: CHECK_EMAIL_CHANGE_SUCCESS
    });

    let trace2;
    try {
      if (process.env.REACT_APP_ENV === 'production') {
        trace2 = performance.trace('ChangeEmail');
        trace2.start();
      }

      const { data: { error: error2 }} = await http.post('/ChangeEmail', params);

      if (process.env.REACT_APP_ENV === 'production') {
        if (error2.errorCode !== 0) {
          trace2.putAttribute('errorCode', `${error2.errorCode}`);
        }
        trace2.stop();
      }

      if (error2.errorCode !== 0 && error2.errorCode !== 39) {
        throw new ApiError(error2.errorText, error2.errorCode);
      }

      dispatch({
        type: CHANGE_EMAIL_SUCCESS,
        payload: error2.errorText
      });
    } catch (error) {
      if (process.env.REACT_APP_ENV === 'production' && trace2['state'] === 2) {
        trace2.putAttribute('error', error.message);
        trace2.stop();
      }

      console.warn(error);
      dispatch({
        type: CHANGE_EMAIL_FAIL,
        payload: error.message
      });
    }
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace1['state'] === 2) {
      trace1.putAttribute('error', error.message);
      trace1.stop();
    }

    console.warn(error);
    dispatch({
      type: CHECK_EMAIL_CHANGE_FAIL,
      payload: error.message
    });
  }
};

export const resendEmailApproval = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('ResendChangeEmail');
      trace.start();
    }

    const { data: { error }} = await http.post('/ResendChangeEmail');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: RESEND_EMAIL_SUCCESS,
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
    dispatch({
      type: RESEND_EMAIL_FAIL,
      payload: error.message
    });
  }
};

export const changePassword = ({ oldPassword, newPassword }) => async (dispatch) => {
  const params = { oldPassword, newPassword };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('ChangePassword');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/ChangePassword', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: CHANGE_PASSWORD_SUCCESS,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
    dispatch({
      type: CHANGE_PASSWORD_FAIL,
      payload: error.message
    });
  }
};

export const getCountriesList = () => async dispatch => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetEditProfilePageInfo');
      trace.start();
    }

    const { data: { error, countries }} = await http.post('/GetEditProfilePageInfo');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_COUNTRIES_LIST_SUCCESS,
      payload: countries
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
    dispatch({
      type: GET_COUNTRIES_LIST_FAIL,
      payload: error.message
    });
  }
};

export const setProfileInfo = ({
  firstName,
  lastName,
  phone,
  hasWhatsapp,
  hasTelegram,
  hasWeChat,
  skype,
  countryId,
  addressLine1,
  addressLine2,
  city,
  zip,
  nativeLanguage,
  favouriteTeam,
  dayOfBirth,
  monthOfBirth,
  yearOfBirth
}) => async dispatch => {
  const params = {
    firstName: firstName || '',
    lastName: lastName || '',
    phone: phone || '',
    hasWhatsapp: !!hasWhatsapp,
    hasTelegram: !!hasTelegram,
    hasWeChat: !!hasWeChat,
    skype: skype || '',
    countryId,
    addressLine1,
    addressLine2: addressLine2 || '',
    city,
    zip,
    nativeLanguage,
    favouriteTeam,
    birthDay: `${yearOfBirth}-${+monthOfBirth + 1}-${dayOfBirth}T00:00:00`
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetEditProfileDetails');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/SetEditProfileDetails', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_PROFILE_INFO_SUCCESS,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
    dispatch({
      type: SET_PROFILE_INFO_FAIL,
      payload: error.message
    });
  }
};

export const getTerms = () => async dispatch => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetTerms');
      trace.start();
    }

    const { data: { error, ...terms }} = await http.post('/GetTerms');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0 && error.errorCode !== 6) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_DATA_TERMS,
      payload: terms
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
  }
};

export const setTerms = program => async dispatch => {
  const params = {
    typeAction: 'program',
    program
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetTerms');
      trace.start();
    }

    const { data: { error }} = await http.post('/SetTerms', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0 && error.errorCode !== 6) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch(setHasTerms(true));
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.warn(error);
  }
};
