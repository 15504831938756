import { reset } from 'redux-form';
import http from '@/services/http-service';
import ApiError from '@/utils/api-error';
import performance from '@/services/perfomance';

import { OPEN_MODAL, CLOSE_MODAL } from './view';
import consoleError from '@/helpers/consoleError';
import { getLocalDate } from '@/helpers/timezoneConvertors';

export const GET_ACCOUNT_INFO_SUCCESS = 'user/GET_ACCOUNT_INFO_SUCCESS';
export const GET_ACCOUNT_INFO_FAIL = 'user/GET_ACCOUNT_INFO_FAIL';
export const SET_RECOMMEND_NEW_USER_SUCCESS = 'user/SET_RECOMMEND_NEW_USER_SUCCESS';
export const SET_RECOMMEND_NEW_USER_FAIL = 'user/SET_RECOMMEND_NEW_USER_FAIL';
export const GET_MY_MEMBERS_BET_SUCCESS = 'user/GET_MY_MEMBERS_BET_SUCCESS';
export const GET_MY_MEMBERS_BET_FAIL = 'user/GET_MY_MEMBERS_BET_FAIL';
export const GET_MY_RECOMENDED_MEMBERS_SUCCESS = 'user/GET_MY_RECOMENDED_MEMBERS_SUCCESS';
export const GET_MY_RECOMENDED_MEMBERS_FAIL = 'user/GET_MY_RECOMENDED_MEMBERS_FAIL';
export const GET_WEB_TEMPLATE_SUCCESS = 'user/GET_WEB_TEMPLATE_SUCCESS';
export const GET_WEB_TEMPLATE_FAIL = 'user/GET_WEB_TEMPLATE_FAIL';

/**
 * Getting the user details
 * @async
 * @function
 * @returns {object}
 * @description Returned in the object value 'userlevel' - can be 4 types of users in system:
 * userlevel = 0 - Affiliate only
 * userlevel = 1 - Trial only
 * userlevel = 2 - Paid only
 * userlevel = 3 - Full member
 */
export const getWebTemplate = () => async dispatch => {
  const userId = localStorage.getItem('userId');
  const authorizationKey = localStorage.getItem('authorizationKey');
  const params = { userId, authorizationKey };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetWebTemplate');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetWebTemplate', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_WEB_TEMPLATE_SUCCESS,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: GET_WEB_TEMPLATE_FAIL,
      payload: error.errorText
    });
  }
};

/**
 * Getting the data for MyRecomendedMembers
 * @async
 * @function
 */
export const getMyRecomendedMembers = () => async (dispatch, getState) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetRecommendedUsers');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetRecommendedUsers');

    const { auth: { utcOffset } } = getState();

    const preparedData = {
      ...data,
      recommendedMembers: data.recommendedMembers.map(item => ({
        ...item,
        dateAdded: item.dateAdded
          ? getLocalDate(item.dateAdded, utcOffset, 'MMM DD, YYYY') 
          : item.dateAdded
      }))
    };

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MY_RECOMENDED_MEMBERS_SUCCESS,
      payload: preparedData
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: GET_MY_RECOMENDED_MEMBERS_FAIL,
      payload: error.message
    });
  }
};

/**
 * Getting the data for MyMembersBet
 * @async
 * @function
 */
export const getMyMembersBet = () => async (dispatch) => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetAffiliateCenter');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/GetAffiliateCenter');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_MY_MEMBERS_BET_SUCCESS,
      payload: data
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: GET_MY_MEMBERS_BET_FAIL,
      payload: error.message
    });
  }
};

/**
 * Setting Recommend New Member request
 * @async
 * @function
 * @param {object} prop
 * @param {string} prop.fullName
 * @param {string} prop.email
 * @param {string} prop.skype
 * @param {string} prop.countriesLeagues
 * @param {string} prop.whyYouRecommending
 * @param {boolean} prop.canContact
 * @param {boolean} prop.contacted
 */
export const recommendNewMember = ({
  fullName,
  email,
  skype,
  countriesLeagues,
  whyYouRecommending,
  canContact,
  contacted
}) => async (dispatch) => {
  const params = {
    fullName,
    email,
    skype,
    countriesLeagues,
    whyYouRecommending,
    canContact,
    contacted
  };

  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('SetRecomendedMember');
      trace.start();
    }

    const { data: { error, ...data }} = await http.post('/SetRecomendedMember', params);

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0) {
      throw new ApiError(error.errorText, error.errorCode);
    }

    dispatch({
      type: SET_RECOMMEND_NEW_USER_SUCCESS,
      payload: data
    });
    dispatch({
      type: CLOSE_MODAL,
      payload: 'recommend'
    });
    dispatch({
      type: OPEN_MODAL,
      payload: 'recommendThank'
    });
    dispatch(reset);
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    console.log(error);
    dispatch({
      type: SET_RECOMMEND_NEW_USER_FAIL,
      payload: error.message
    });
  }
};

/**
 * Getting the user account info
 * @async
 * @function
 */
export const getUserAccountInfo = () => async dispatch => {
  let trace;
  try {
    if (process.env.REACT_APP_ENV === 'production') {
      trace = performance.trace('GetEditProfileDetails');
      trace.start();
    }

    const { data: { error, ...account }} = await http.post('/GetEditProfileDetails');

    if (process.env.REACT_APP_ENV === 'production') {
      if (error.errorCode !== 0) {
        trace.putAttribute('errorCode', `${error.errorCode}`);
      }
      trace.stop();
    }

    if (error.errorCode !== 0 && error.errorCode !== 6) {
      throw new ApiError(error.exeptionMessage || error.errorText, error.errorCode);
    }

    dispatch({
      type: GET_ACCOUNT_INFO_SUCCESS,
      payload: account
    });
  } catch (error) {
    if (process.env.REACT_APP_ENV === 'production' && trace['state'] === 2) {
      trace.putAttribute('error', error.message);
      trace.stop();
    }

    consoleError(error);
    dispatch({
      type: GET_ACCOUNT_INFO_FAIL,
      payload: error.message
    });
  }
};
