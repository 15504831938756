import {
  EXPAND_NEW_PICK,
  COLLAPSE_NEW_PICK,
  OPEN_MODAL,
  CLOSE_MODAL,
  OPEN_TERMS,
  CLOSE_TERMS,
  OPEN_WIDGET,
  CLOSE_WIDGET,
  OPEN_CHAT,
  CLOSE_CHAT,
  SHOW_CHANGE_PASSWORD,
  HIDE_CHANGE_PASSWORD,
  TOGGLE_TAB
} from '../actions/view';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  newPickCollapsed: true,
  modals: {
    pick: false,
    myPicksGame: false,
    contact: false,
    recommend: false,
    recommendThank: false,
    restore: false,
    approve: true,
    allowances: false,
    autoFetch: false,
    composeConfirm: false,
    wait: false,
    refresh: false,
    success: false,
    trialSuccess: false,
    betSettings: false,
    pickRejected: false,
    infoModal: false
  },
  widget: {
    widgetOpened: false,
    chatOpened: false,
    groupId: '',
    chatData: {},
  },
  oddsalerts: {
    permanent: true,
    temporary: false,
  },
  toggleSubLeague: false,
  termsOpened: false,
  changePasswordVisible: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case OPEN_SUBLEAGUE:
    //   return {
    //     ...state,
    //     toggleSubLeague: true,
    //   };
    // case CLOSE_SUBLEAGUE:
    //   return {
    //     ...state,
    //     toggleSubLeague: false,
    //   };
    case TOGGLE_TAB:
      return {
        ...state,
        oddsalerts: { [action.payload]: true },
      };
    case SHOW_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordVisible: true,
      };
    case HIDE_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordVisible: false,
      };
    case EXPAND_NEW_PICK:
      return {
        ...state,
        newPickCollapsed: false,
      };
    case COLLAPSE_NEW_PICK:
      return {
        ...state,
        newPickCollapsed: true,
      };
    case OPEN_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload]: true,
        },
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload]: false,
        },
      };

    case OPEN_TERMS:
      return {
        ...state,
        termsOpened: true,
      };
    case CLOSE_TERMS:
      return {
        ...state,
        termsOpened: false,
      };
    case OPEN_WIDGET:
      return {
        ...state,
        widget: { widgetOpened: true },
      };
    case CLOSE_WIDGET:
      return {
        ...state,
        widget: { widgetOpened: false },
      };
    case OPEN_CHAT:
      return {
        ...state,
        widget: {
          widgetOpened: true,
          chatOpened: true,
          groupId: action.payload,
        },
      };
    case CLOSE_CHAT:
      return {
        ...state,
        widget: {
          widgetOpened: true,
          chatOpened: false,
          chatData: {},
        },
      };

    case LOCATION_CHANGE:
      const {location} = action.payload;
      if (location.pathname === '/' && location.hash === '#create-pick') {
        return {
          ...state,
          newPickCollapsed: false,
        };
      }

      return {
        ...state,
        newPickCollapsed: true,
      };
    default:
      return state;
  }
};
