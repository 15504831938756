import React, { Fragment } from 'react';

import TermsContainer from '../../assets/staticTexts/TermsOfUse';
import Wrapper from './styles';

const TermsOfUse = () => (
  <Fragment>
    <Wrapper>
      <TermsContainer />
    </Wrapper>
  </Fragment>
);

export default TermsOfUse;
