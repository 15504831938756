import React from 'react';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import fonts from '@/config/fonts';
import capitalize from '@/utils/capitalize';

const PieChart = ({
  title = '',
  totalBet = undefined,
  totalRoi = undefined,
  totalWin = undefined,
  data = [],
  labelFormat = '<b>{point.name}</b>',
  description = null,
  nameKey,
  labelDistance = 45,
  labelY = -20
}) => {
  const totalUnitsBet = totalBet === undefined ? data.reduce((res, item) => res + item.unitsBet, 0) : totalBet;
  const totalUnitsWon = totalWin === undefined ? data.reduce((res, item) => res + item.unitsWon, 0).toFixed(2) : totalWin;
  const totalROI = totalRoi === undefined ? (data.length ? 100 * totalUnitsWon / totalUnitsBet : 0).toFixed(2) : parseFloat(totalRoi);
  const subtitle = data.length ? `
    Units bets: <b>${totalUnitsBet}</b><br/>
    Units won: <b>${totalUnitsWon}</b><br/>
    ROI: <b>${totalROI}%</b>
  ` : '';
  const series = [
    {
      data: data.map(item => {
        const { [nameKey]: name, unitsBet, unitsWon, roi, totalBets, color } = item;
        return {
          name: capitalize(name),
          y: unitsBet,
          totalBets,
          unitsWon,
          roi,
          color
        };
      })
    }
  ];

  if (!data.length) {
    return (
      <div className="pie-chart empty-chart">
        <h2>{title}</h2>
        <p>Data is empty</p>
      </div>
    );
  }

  return (
    <div className="pie-chart">
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          chart: {
            type: 'pie',
            height: 500,
            width: 600,
            style: {
              margin: '0 auto'
            }
          },
          plotOptions: {
            pie: {
              allowPointSelect: false,
              cursor: 'pointer',
              showInLegend: false,
              dataLabels: {
                distance: labelDistance,
                enabled: true,
                y: labelY,
                format: labelFormat,
                style: {
                  color: 'contrast',
                  textOutline: '1px contrast',
                  fontSize: '13px',
                  fontWeight: 'normal',
                  fontFamily: fonts.proximaNova
                }
              },
              size: 300
            },
            series: {
              innerSize: '50%'
            }
          },
          title: {
            enabled: false,
            text: title,
            style: {
              color: '#3c4144',
              fontSize: '18px',
              fontWeight: 'bold',
              textOutline: '1px contrast',
              fontFamily: fonts.proximaNova
            }
          },
          // title: null,
          subtitle: {
            text: subtitle,
            align: 'center',
            verticalAlign: 'middle',
            y: 0,
            style: {
              fontSize: '16px',
              fontFamily: fonts.proximaNova,
              color: 'contrast',
              textOutline: '1px contrast'
            }
          },
          tooltip: {
            headerFormat: '<span style="font-size: 13px; font-weight: bold;">{point.key}</span><br/>',
            pointFormat: `
              Units bets: <b>{point.y}</b><br/>
              Units won: <b>{point.unitsWon}</b><br/>
              Percentage of total bets: <b>{point.totalBets}</b><br/>
              ROI: <b>{point.roi}</b>
            `,
            backgroundColor: '#fff'
          },
          series
        }}
      />
      {description ? <div className="description">{description}</div> : null}
    </div>
  );
}

PieChart.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  nameKey: PropTypes.string.isRequired,
  labelFormat: PropTypes.string
};

export default PieChart;