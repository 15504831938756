import styled from 'styled-components';

export default styled.div`
  &.chat-type-float {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10000;

    .chat-btn-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;
      position: relative;
      -webkit-touch-callout: none;
        -webkit-user-select: none;
         -khtml-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;

      .notification-count {
        position: absolute;
        z-index: 1;
        top: -17px;
        left: 100%;
        margin-left: -5px;
        min-width: 15px;
        height: 15px;
        padding: 0 3px;
        background: #dc0f00;
        border-radius: 7px;
        color: white;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
      }

      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
        width: 20px;
      }

      .title {
        position: absolute;
        top: -10px;
        left: -10px;
        width: 60px;
        height: 60px
      }
    }

    .chat-inner {
			position: absolute;
			bottom: 0;
			left: 0;
			transform: translateX(-100%);
			display: block;
			width: 280px;
			box-shadow: 0 10px 25px rgba(38, 26, 93, 0.18);
			border-radius: 15px 15px 0 0;
			background-color: #edeff5;
			overflow: hidden;
    }

		.chat-header {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 10px;
			font-size: 14px;
			font-weight: 400;
      color: #ffffff;
      cursor: pointer;
		}
		.chat-header-icon {
			flex: 0 0 15px;
			max-width: 15px;
			height: 15px;
			display: flex;
			align-items: center;
			justify-content: center;
      margin-right: 8px;

      img {
        max-width: 100%;
        max-height: 100%;
        height: auto;
      }
		}

		.chat-header-title {
		  margin: 0 10px 0 0;
		}
		.chat-header-status {
			flex: 0 0 10px;
			max-width: 10px;
			height: 2px;
			margin-left: auto;
			background-color: #ffffff;
		}

		.chat-wrapper {
      background-color: #edeff5;
    }
    .chat-frame {
      height: 290px;
      padding: 25px 10px;
      overflow-y: auto;
    }
    .chat-item {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: 15px;
    }
    .chat-item:last-child {
      margin-bottom: 0;
    }
    .chat-item-author {
      flex: 0 0 35px;
      max-width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background-color: #ffffff;
      border-radius: 50%;
    }
    .chat-item-author img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      object-fit: contain;
    }
    .chat-item-body {
	  	flex: 0 1 auto;
	  	max-width: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .chat-item-message {
	  	position: relative;
	  	margin-bottom: 5px;
	  	padding: 7px 12px;
	  	background-color: #ffffff;
	  	border-radius: 3px;
	  	font-size: 14px;
	  	line-height: 1.5;
	  	overflow: hidden;
	  }
	  .chat-item-message:last-child {
	  	margin-bottom: 0;
	  }
	  .chat-item-message:last-child:before {
	  	position: absolute;
	  	left: 0;
	  	bottom: 0;
	  	transform: translateX(-100%);
	  	display: block;
	  	content: '';
	  	width: 0;
	  	height: 0;
	  	border-bottom: 6px solid #ffffff;
	  	border-left: 6px solid transparent;
	  	border-right: none;
	  }
	  .chat-item-message-date {
	    // float: right;
	    // display: inline-block;
	    // max-width: 50px;
	    text-align: left;
	    font-size: 10px;
	    line-height: 1;
	    color: #646464;
      opacity: .5;
      margin-bottom: 5px;
    }

    .chat-item-wrapper {
      margin-bottom: 5px;
    }

    .chat-item_own {
      flex-direction: row-reverse;
    }
    .chat-item_own .chat-item-author {
      margin-right: 0;
      margin-left: 10px;
    }
    .chat-item_own .chat-item-message-date {
	    text-align: right;
	  }
    .chat-item_own .chat-item-message:last-child:before {
      left: auto;
      right: 0;
	  	transform: translateX(100%);
	  	border-right: 6px solid transparent;
	  	border-left: none;
    }
    .chat-item_own .chat-item-body {
      align-items: flex-end;
    }

    .chat-action {
    	padding: 10px;
    }
    .chat-form {
      position: relative;
    }
    .chat-input {
    	display: block;
    	width: 100%;
    	height: 30px;
    	padding: 10px 30px 10px 10px;
    	background: #ffffff;
    	border: none;
    	font-size: 14px;
    	color: #646464;
    }
    .chat-btn {
      position: absolute;
      right: 0;
      top: 0;
    	width: 30px;
    	height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border: none;
      cursor: pointer;
    }
    .chat-btn img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      width: 10px;
    }

    &.chat-theme-blue {
      .chat-btn-toggle {
        box-shadow: 0 10px 25px rgba(2, 55, 134, 0.3), 0 0 3px rgba(255, 255, 255, 0.35);
        background-color: #4a90e2;
      }
      .chat-header {
        background-color: #4a90e2;
      }
    }

    &.chat-theme-purple {
      .chat-btn-toggle {
        box-shadow: 0 10px 25px rgba(34, 61, 158, 0.3);
        background-color: #f8f9fa;
        background-image: linear-gradient(42deg, #8a71ec 0%, #4e7ed0 100%);
      }
      .chat-header {
        background-color: #f8f9fa;
        background-image: linear-gradient(42deg, #8a71ec 0%, #4e7ed0 100%);
      }
    }
  }

  &.chat-type-static {
    .sender {
      margin: 0;
      text-align: left;
      font-weight: normal;
      margin: 15px 0 5px;
    }

    .sender.owner {
        text-align: right;
    }

    .chat-wrapper {
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    .chat-title {
      margin: 0 0 5px;
      font-size: 15px;
      line-height: 20px;

      display: flex;
      justify-content: space-between;

      .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        border: 2px solid #5bdb59;

        &.status-online {
          background-color: #50c14e;
        }
        &.status-offline {
          background-color: red;
        }
      }
    }
    .chat {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      border: 1px solid #dde0e6;
      background-color: #edeff5;
      max-height: 395px;
    }
    .chat-body {
      flex: 1 1 auto;
      min-height: 200px;
      padding: 20px 15px 0;
      overflow-y: auto;
    }
    .chat-item {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      margin-bottom: 15px;
    }
    .chat-item:last-child {
      margin-bottom: 0;
    }
    .chat-item-author {
      flex: 0 0 35px;
      max-width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background-color: #ffffff;
      border-radius: 50%;
    }
    .chat-item-author img {
      max-width: 100%;
      max-height: 100%;
      height: auto;
      object-fit: contain;
    }
    .chat-item-body {
      flex: 0 1 auto;
      max-width: 45%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .chat-item-message {
      position: relative;
      margin-bottom: 5px;
      padding: 15px 20px;
      background-color: #ffffff;
      border-radius: 3px;
      font-size: 14px;
      line-height: 1.5;
    }
    .chat-item-message:last-child {
      margin-bottom: 0;
    }
    .chat-item-message:last-child:before {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateX(-100%);
      display: block;
      content: '';
      width: 0;
      height: 0;
      border-bottom: 6px solid #ffffff;
      border-left: 6px solid transparent;
      border-right: none;
    }

    .chat-item_own {
      flex-direction: row-reverse;
    }
    .chat-item_own .chat-item-author {
      margin-right: 0;
      margin-left: 10px;
    }
    .chat-item_own .chat-item-message:last-child:before {
      left: auto;
      right: 0;
      transform: translateX(100%);
      border-right: 6px solid transparent;
      border-left: none;
    }
    .chat-item_own .chat-item-body {
      align-items: flex-end;
    }
    .chat-date {
      margin: 15px 0;
      padding: 10px;
      border-bottom: 1px solid #dde0e6;
      text-align: center;
      font-weight: bold;
    }

    .chat-action {
      padding: 15px 20px 20px;
    }
    .chat-form {
      display: flex;
    }
    .chat-input {
      flex: 1 1 auto;
      height: 40px;
      padding: 15px;
      background: #ffffff;
      border: none;
      font-size: 14px;
      color: #646464;
    }
    .chat-btn {
      flex: 0 0 135px;
    }
    .btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      padding: 5px 15px;
      background-image: linear-gradient(-101deg, #4e7dd0 0%, #8a71ec 100%);
      border: none;
      text-align: center;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
    .btn:hover, .btn:active, .btn:focus {
      background-image: linear-gradient(105deg,#7b57ee,#306ece);
    }
  }
`;
