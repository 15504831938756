import React from 'react';
import RestorePasswordForm from '../../containers/RestorePasswordForm';
import logo from '../../assets/images/logo.svg';
import Wrapper from './styles';

const RestorePassword = () => (
  <Wrapper>
    <RestorePasswordForm />
    <div className="block">
      <img src={logo} alt="logo" />
    </div>
  </Wrapper>
);

export default RestorePassword;
