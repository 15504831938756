import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { compose, withProps, lifecycle } from 'recompose';

import { showChangePassword, hideChangePassword } from '@/store/actions/view';
import { getUserAccountInfo } from '@/store/actions/user';

import EditProfile from '@/components/Profile/EditProfile';

import {
  setProfileInfo,
  getCountriesList,
  resendEmailApproval,
  checkEmail,
  preloadProfile
} from '@/store/actions/profile';

const lifecycleMethod = lifecycle({
  async componentDidMount () {
    await this.props.getCountriesList();
    this.props.preloadProfile();
  }
});

const mapStateToProps = state => ({
  countries: state.profile.countries,
  error: state.profile.error,
  changePasswordVisible: state.view.changePasswordVisible,
  initialValues: state.profile.data,
  isEmailApproved: state.user.webtemplate.user,
  checkError: state.profile.checkError,
  successEmailChangeText: state.profile.changeText
});

const props = withProps(ownProps => ({
  onSubmit: async form => {
    await ownProps.setProfileInfo(form);
    ownProps.getUserAccountInfo();
  }
}));

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setProfileInfo,
    getUserAccountInfo,
    showChangePassword,
    hideChangePassword,
    getCountriesList,
    preloadProfile,
    resendEmailApproval,
    checkEmail
  },
  dispatch
);

const validate = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Enter first name';
  }
  if (!values.lastName) {
    errors.lastName = 'Enter last name';
  }
  if (!values.phone) {
    errors.phone = 'Enter phone';
  }
  return errors;
};

const form = reduxForm({
  form: 'setProfileForm',
  validate
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  props,
  form,
  lifecycleMethod
)(EditProfile);
