import styled from 'styled-components';

export default styled.div`
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;

  input {
    width: 24px;
    height: 24px;
    margin: 0;
    opacity: 0;
    z-index: 9;
    cursor: pointer;
    position: relative;

    &:checked + span:after {
      content: '';
      display: block;
      width: 23px;
      height: 23px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='11px' viewBox='0 0 15 11' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 51.1 (57501) - http://www.bohemiancoding.com/sketch --%3E%3Ctitle%3Eic-checkbox-check%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='ic-checkbox-check' transform='translate(0.000000, -1.000000)' fill='%234A90E2' fill-rule='nonzero'%3E%3Cpath d='M5.457,9.543 L12.753,1.336 C12.9864351,1.0551975 13.3543685,0.923960394 13.7128179,0.99364524 C14.0712674,1.06333009 14.3632339,1.3228559 14.4744633,1.67066336 C14.5856927,2.01847082 14.4984977,2.3992531 14.247,2.664 L6.247,11.664 C6.02202601,11.9173865 5.68404124,12.0396085 5.34903595,11.9887216 C5.01403066,11.9378348 4.72758427,11.7207624 4.588,11.412 C4.47807642,11.3620727 4.37812416,11.2926143 4.293,11.207 L0.293,7.207 C-0.0859722095,6.81462111 -0.0805523739,6.19091522 0.305181425,5.80518142 C0.690915223,5.41944763 1.31462111,5.41402779 1.707,5.793 L5.457,9.543 Z' id='Shape'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }

  span {
    position: absolute;
    width: 23px;
    height: 23px;
    left: 0;
    background-color: #fff;
    border: 1px solid #dbdbdb;
  }

  label {
    font-size: 11px;
    font-weight: 600;
    color: #646464;
    margin-top: 6px;
  }
`;
