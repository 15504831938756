import React from 'react';
// import MessageImage from '../../assets/images/ic-envelope-blue-s.svg';

const MyMembersBet = ({
  myMembersBet,
  myAffiliateInfo: {
    affiliateCard,
    affiliateRevenues,
    affiliatePaymentsDetails
  }
}) => (
  <>
    <h2 className="main-title">My Affiliates Finance</h2>
    <div className="block">
      <h3 className="block-title">Revenues</h3>
      <div className="block-section">
        {
          affiliateRevenues ?
          <>
            <table>
              <thead>
                <tr>
                  <th>Bet Size</th>
                  <th>Units Won</th>
                  <th>Revenue</th>
                  <th>Start Date</th>
                </tr>
              </thead>
              <tbody>
                {
                  affiliateRevenues.map(rev => (
                    <tr key={`${rev.betSize}${rev.unitsWon}${rev.startDate}`}>
                      <td>{rev.betSize}</td>
                      <td>{rev.unitsWon}</td>
                      <td>{rev.revenue}</td>
                      <td>{rev.startDate}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            Total Revenues: { affiliateRevenues.reduce((res, rev) => res + parseFloat(rev.revenue), 0) }
          </>
          :
          'Data is empty'
        }
      </div>

      <h3 className="block-title">Payments</h3>
      <div className="block-section">
        {
          (affiliatePaymentsDetails && affiliatePaymentsDetails.length) ?
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Info</th>
                <th>Sum ($)</th>
              </tr>
            </thead>
            <tbody>
              {
                affiliatePaymentsDetails.map((pay, i) => (
                  <tr key={`${pay.date}${pay.method}${pay.ammount}${i}`}>
                    <td>{pay.date}</td>
                    <td>{pay.method}</td>
                    <td>{pay.ammount}</td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          :
          'Data is empty'
        }
      </div>

      <h3 className="block-title">Summary</h3>
      <div className="block-section">
        {
          affiliateCard ?
          <div className="block-section">
            <table className="table-summary">
              <tbody>
                <tr>
                  <th>Units Won</th>
                  <td>{affiliateCard.unitsWon}</td>
                </tr>
                <tr>
                  <th>Total Revenue</th>
                  <td>{affiliateCard.totalRevenue}</td>
                </tr>
                <tr>
                  <th>Total Payments</th>
                  <td>{affiliateCard.totalPayments}</td>
                </tr>
                <tr>
                  <th>Balance</th>
                  <td>{affiliateCard.balance}</td>
                </tr>
                <tr>
                  <th>Next Payment Date</th>
                  <td>{affiliateCard.nextPayment}</td>
                </tr>
              </tbody>
            </table>
          </div>
          :
          'Data is empty'
        }
      </div>
    </div>

    <h2 className="main-title">My Members Bet</h2>
    <div className="block block-2">
      <table>
        <thead>
          <tr>
            <td className="member-name">Members Name</td>
            {/* <td>Units Bet</td> */}
            <td>Units Won</td>
            {/* <td>ROI</td> */}
            <td>Date</td>
          </tr>
        </thead>
        <tbody>
          {myMembersBet.affiliateMembers ? (
            myMembersBet.affiliateMembers.map(data => (
              <tr key={data.userId}>
                <td className="member-name">
                  {data.name}
                  {/* <span className="email">
                    <img src={MessageImage} alt="user" />
                    <span>{data.name}</span>
                  </span>
                  <div className="tooltip">{data.email}</div> */}
                </td>
                {/* <td>{data.unitsBet}</td> */}
                <td>{data.unitsWon}</td>
                {/* <td>{data.roi}</td> */}
                <td>{data.lastPickDate}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td>No data available...</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className="note">
        <h5>Please note:</h5>

        <p>
          The list is ordered by ‘Last Bet’ date, to allow you to see how recently active your
          affiliates have been.
        </p>
        <p>
          If you want to contact them, please click the ‘Member Name’ you want to contact and it
          will come up with the details to contact them.
        </p>
        <p>Talk to your members! The more they win the more you gain!</p>
      </div>
    </div>
  </>
);

export default MyMembersBet;
