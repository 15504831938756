import styled from 'styled-components';

export default styled.div`
  background-color: rgba(54, 111, 177, 0.4);
  color: white;
  font-size: 13px;
  padding: 12px 5px;
  margin: 0 15px;

  .status-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #50c14e;
    border: 2px solid #5bdb59;
    margin: 0 5px;
  }

  b {
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.92;
    letter-spacing: normal;
  }
`;
