import {
  GET_ACCOUNT_INFO_FAIL,
  GET_ACCOUNT_INFO_SUCCESS,
  SET_RECOMMEND_NEW_USER_FAIL,
  SET_RECOMMEND_NEW_USER_SUCCESS,
  GET_MY_MEMBERS_BET_SUCCESS,
  GET_MY_MEMBERS_BET_FAIL,
  GET_MY_RECOMENDED_MEMBERS_FAIL,
  GET_MY_RECOMENDED_MEMBERS_SUCCESS,
  GET_WEB_TEMPLATE_FAIL,
  GET_WEB_TEMPLATE_SUCCESS,
} from '../actions/user';

const initialState = {
  user: null,
  account: {},
  webtemplate: {},
  webtemplateError: '',
  accountError: '',
  setRecommendError: '',
  affiliates: {
    myMembersBet: {},
    myMembersBetError: '',
    recomendedMembers: {},
    recomendedMembersError: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ACCOUNT_INFO_SUCCESS: {
      return { ...state, account: action.payload };
    }
    case GET_ACCOUNT_INFO_FAIL: {
      return { ...state, accountError: action.payload };
    }
    case SET_RECOMMEND_NEW_USER_SUCCESS: {
      return state;
    }
    case SET_RECOMMEND_NEW_USER_FAIL: {
      return { ...state, setRecommendError: action.payload };
    }
    case GET_MY_MEMBERS_BET_SUCCESS: {
      return { ...state, affiliates: { ...state.affiliates, myMembersBet: action.payload } };
    }
    case GET_MY_MEMBERS_BET_FAIL: {
      return { ...state, affiliates: { ...state.affiliates, myMembersBetError: action.payload } };
    }
    case GET_MY_RECOMENDED_MEMBERS_SUCCESS: {
      return { ...state, affiliates: { ...state.affiliates, recomendedMembers: action.payload } };
    }
    case GET_MY_RECOMENDED_MEMBERS_FAIL: {
      return {
        ...state,
        affiliates: { ...state.affiliates, recomendedMembersError: action.payload },
      };
    }
    case GET_WEB_TEMPLATE_SUCCESS: {
      return { ...state, webtemplate: action.payload, webtemplateError: '' };
    }
    case GET_WEB_TEMPLATE_FAIL: {
      return { ...state, webtemplateError: action.payload };
    }
    default:
      return state;
  }
};
