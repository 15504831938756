import React from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';

const Table = ({ children, className }) => <Wrapper className={className}>{children}</Wrapper>;

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Table.defaultProps = {
  className: null,
};

export default Table;
