import map from 'lodash/map';

const reduceMessages = (allMessages, index = 0) => {
  const message = allMessages[index];

  if (message && message.messages && message.messages.length) {
    allMessages.splice(index + 1, 0, ...message.messages);
  }

  if (allMessages.length === index + 1) {
    return allMessages;
  }

  return reduceMessages(allMessages, index + 1);
};

const filterMessages = (message, filter) => {
  switch (filter) {
    case 'inbox': return message.isInbox;
    case 'sent': return !message.isInbox;
    default: return true;
  }
};

const filterGroups = (group, filter) => {
  const messages = group.messages && group.messages.length ? reduceMessages([...group.messages]) : [];
  switch (filter) {
    case 'inbox': return messages.find(m => m.isInbox === true);
    case 'sent': return messages.find(m => m.isInbox === false);
    // no default
  }
  return true;
};

export const getGroups = ({ messages = [], inboxFilter = 'inbox' }) => {
  const groups = map(messages.filter(group => filterGroups(group, inboxFilter)), m => ({
    id: m.groupId,
    title: m.subject,
    hasNewMessages: reduceMessages([...m.messages]).some(me => me.isNew)
  }));
  return groups;
};

export const getMessages = ({ messages = [], activeGroup = null, inboxFilter = 'inbox' }) => {
  const group = messages.find(m => m.groupId === activeGroup);
  let allMessages = [];

  if (group) {
    const cloneMessages = [...group.messages];
    allMessages = map(reduceMessages(cloneMessages)
      .filter(message => filterMessages(message, inboxFilter)), gm => ({
      id: gm.id,
      body: gm.body,
      date: gm.messageDate,
      time: gm.messageTime,
      from: gm.fromName,
      isNew: gm.isNew,
    }));
  }

  return allMessages;
};

export const getSubject = ({ messages = [], activeGroup = null }) => {
  const group = messages.find(m => m.groupId === activeGroup);
  let subject = '';
  
  if (group) {
    ({ subject } = group);
  }

  return subject;
};

export const getOpened = ({ openedMessages = [] }, { id }) => openedMessages.indexOf(id) > -1;

export const getUnreadMessages = ({ messages }, activeGroup) => {
  const allMessages = getMessages({ messages, activeGroup })
    .filter(message => message.isNew)
    .map(message => message.id);

  return allMessages;
};
