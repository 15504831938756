/**
 * External dependencies
 */
import { cloneDeep } from 'lodash';

/**
 * Internal dependencies
 */
import {
  GET_EVENTS_IN_PLAY,
  GET_EVENTS_IN_PLAY_SUCCESS,
  GET_EVENTS_IN_PLAY_ERROR,
  GET_SELECTED_MATCH_RESULTS,
  GET_SELECTED_MATCH_RESULTS_SUCCESS,
  GET_SELECTED_MATCH_RESULTS_ERROR,
  GET_LEAGUES_LIST, GET_FILTERED_MATCH_RESULTS
} from './constants';
import { getLeagues } from '@/utils/bet365';
import {
  CORNER,
  CORNER_RAW,
  GOAL,
  GOAL_RAW, PENALTY, PENALTY_RAW, RC, RC_RAW, YC,
  YC_RAW
} from '@/utils/bet365/constants';


export const getEventsInPlay = () => ({ type: GET_EVENTS_IN_PLAY });

export const setEventsInPlay = payload => ({
  type: GET_EVENTS_IN_PLAY_SUCCESS,
  payload
});

export const failedEventsInPlay = error => ({
  type: GET_EVENTS_IN_PLAY_ERROR,
  payload: error
});

export const getLeaguesList = options => {
  try {
    const leagues = getLeagues(options);
    return {
      type: GET_LEAGUES_LIST,
      payload: leagues
    }
  } catch (e) {
    return {
      type: GET_LEAGUES_LIST,
      payload: []
    }
  }
};

export const getSelectedMatch = eventId => ({
  type: GET_SELECTED_MATCH_RESULTS,
  payload: eventId
});

export const getFilteredSelectedMatch = eventId => ({
  type: GET_FILTERED_MATCH_RESULTS,
  payload: eventId
});

export const setSelectedMatch = (selectedMatchResults, selectedEventStats, filteredTime) => {
  const getHistoryEventType = ({ events, home, away }) => {
    const clonedEvents = cloneDeep(events);
    const history = clonedEvents.map(event => {
      const { text } = event;
      const lowercaseText = text.toLowerCase();

      event.time = parseInt(lowercaseText);
      if (lowercaseText.includes(home.name.toLowerCase())) {
        event.team = 'home';
      } else if (lowercaseText.includes(away.name.toLowerCase())) {
        event.team = 'away';
      } else {
        event.team = null;
      }

      if (lowercaseText.indexOf(CORNER_RAW) !== -1) {
        event.type = CORNER;
        return event;
      } else if (lowercaseText.indexOf(GOAL_RAW) !== -1) {
        event.type = GOAL;
        return event;
      } else if (lowercaseText.indexOf(YC_RAW) !== -1) {
        event.type = YC;
        return event;
      } else if (lowercaseText.indexOf(RC_RAW) !== -1) {
        event.type = RC;
        return event;
      } else if (lowercaseText.indexOf(PENALTY_RAW) !== -1) {
        event.type = PENALTY;
        return event;
      } else {
        return event;
      }
    });

    return history;
  };

  const getFilteredEvents = ({ events, home, away }) => {
    const clonedEvents = cloneDeep(events);
    const filteredEvents = {
      filteredCorners: [0, 0],
      filteredGoals:  [0, 0],
      filteredPenalties:  [0, 0],
      filteredRedcards:  [0, 0],
      filteredYellowcards:  [0, 0]
    }
    clonedEvents.map(event => {
      const { text } = event;
      const lowercaseText = text.toLowerCase();

      event.time = parseInt(lowercaseText);

      if (lowercaseText.includes(home.name.toLowerCase())) {
        event.team = 'home';
      } else if (lowercaseText.includes(away.name.toLowerCase())) {
        event.team = 'away';
      } else {
        event.team = null;
      }

      if (lowercaseText.indexOf(CORNER_RAW) !== -1) {
        event.type = CORNER;
        return event;
      } else if (lowercaseText.indexOf(GOAL_RAW) !== -1) {
        event.type = GOAL;
        return event;
      } else if (lowercaseText.indexOf(YC_RAW) !== -1) {
        event.type = YC;
        return event;
      } else if (lowercaseText.indexOf(RC_RAW) !== -1) {
        event.type = RC;
        return event;
      } else if (lowercaseText.indexOf(PENALTY_RAW) !== -1) {
        event.type = PENALTY;
        return event;
      } else {
        return event;
      }
    }).filter(h => h.time >= filteredTime[0] && h.time <= filteredTime[1])
      .forEach(g => {
      if (g.type === 'RED_CARD' && g.team) filteredEvents.filteredRedcards[g.team && g.team === 'away' ? 1 : 0] += 1
      if (g.type === 'CORNER' && g.team && !g.text.match(/Race to/)) filteredEvents.filteredCorners[g.team && g.team === 'away' ? 1 : 0] += 1
      if (g.type === 'GOAL' && g.team) {
        filteredEvents.filteredGoals[g.team === 'away' ? 1 : 0] += 1
        if(!!g.text.match(/Penalty/)) filteredEvents.filteredPenalties[g.team && g.team === 'away' ? 1 : 0] += 1
      }
      if (g.type === 'PENALTY' || (!!g.text.match(/Penalty/) && g.team)) filteredEvents.filteredPenalties[g.team && g.team === 'away' ? 1 : 0] += 1
      if (g.type === 'YELLOW_CARD' && g.team) filteredEvents.filteredYellowcards[g.team && g.team === 'away' ? 1 : 0] += 1
    });
    return filteredEvents;
  };

  return {
    type: GET_SELECTED_MATCH_RESULTS_SUCCESS,
    payload: {
      ...selectedMatchResults,
      selectedEventStats,
      history: getHistoryEventType(selectedMatchResults),
      filteredHistory: getFilteredEvents(selectedMatchResults)
    }
  }
};

export const failedSelectedMatch = error => ({
  type: GET_SELECTED_MATCH_RESULTS_ERROR,
  payload: error
});


