import {
  CONTACT_US_SEND_FAIL,
  CONTACT_US_SEND_SUCCESS,
  PRELOAD_CONTACT_FORM,
} from '../actions/contactus';

const initialState = {
  contactError: '',
  accountError: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTACT_US_SEND_SUCCESS:
      return state;

    case CONTACT_US_SEND_FAIL: {
      return { ...state, contactError: action.payload };
    }

    case PRELOAD_CONTACT_FORM:
      return { ...state, data: action.payload };

    default:
      return state;
  }
};
