import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import {
  compose, withProps, withState, withHandlers,
} from 'recompose';

import ChangePassword from '../../components/Profile/ChangePassword';
import { changePassword } from '../../store/actions/profile';

const mapStateToProps = state => ({
  profile: state.profile.profile,
  error: state.profile.error,
});

const props = withProps(ownProps => ({
  onSubmit: ownProps.changePassword,
}));

const validate = (values) => {
  const errors = {};
  if (!values.oldPassword) {
    errors.oldPassword = 'Required';
  }
  if (!values.newPassword) {
    errors.newPassword = 'Required';
  }
  if (!values.retypeNewPassword) {
    errors.retypeNewPassword = 'Required';
  } else if (values.retypeNewPassword !== values.newPassword) {
    errors.newPassword = 'Values in this fields must be equal!';
    errors.retypeNewPassword = 'Values in this fields must be equal!';
  }

  return errors;
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    changePassword,
  },
  dispatch,
);

const form = reduxForm({
  form: 'changePasswordForm',
  validate,
});

const handlers = withHandlers({
  onClickOld: ({ toggleOld }) => () => toggleOld(oldVisible => !oldVisible),
  onClickNew: ({ toggleNew }) => () => toggleNew(newVisible => !newVisible),
  onClickRetype: ({ toggleRetype }) => () => toggleRetype(retypeVisible => !retypeVisible),
});

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  props,
  withState('oldVisible', 'toggleOld', false),
  withState('newVisible', 'toggleNew', false),
  withState('retypeVisible', 'toggleRetype', false),
  handlers,
  form,
)(ChangePassword);
