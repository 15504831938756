import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import Wrapper from './styles';
import Header from '../../containers/Header';
import NotificationsAlert from '../NotificationsAlert';
import Sidebar from '../../containers/Sidebar';

const PagesWrapper = ({ children }) => (
  <Fragment>
    <NotificationsAlert />
    <Header test={children} />
    <Wrapper>
      <Sidebar test={children} />
      <main>{children}</main>
    </Wrapper>
  </Fragment>
);

PagesWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PagesWrapper;
