import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compose } from 'recompose';

import LastPicks from '../components/LastPicks';

const mapStateToProps = ({ picks }) => ({
  lastPicks: picks.stats.picksLast
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch
);

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(LastPicks);
